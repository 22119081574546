import { Component, OnInit, Host, Inject } from '@angular/core';
import { environment } from 'environments/environment';
import { InPreparationShipmentPanelComponent } from '../in-preparation-shipment-panel.component';
import { InPreparationShipmentService, ShipmentPackageHasOrder } from 'degineo-common';
import * as moment_ from 'moment';
import { L } from 'ic2-lib';
import { FabricationOrderPopupComponent } from '../../fabrication-order-popup/fabrication-order-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-verification-preparation-step',
  templateUrl: './verification-preparation-step.component.html',
  styleUrls: ['./verification-preparation-step.component.scss'],
})
export class VerificationPreparationStepComponent implements OnInit {
  moment = moment_;
  rpcHost: string = environment.rpcHost;
  loading: boolean = false;
  error: string = null;

  constructor(
    @Host() public parent: InPreparationShipmentPanelComponent,
    @Inject(InPreparationShipmentService) private inPreparationShipmentService: InPreparationShipmentService,
    @Inject(NgbModal) private modalService: NgbModal
  ) {}

  ngOnInit() {}

  getValidatedShipmentOrders() {
    return this.parent.dto.shipmentOrders.filter((so) => so.waitForIt);
  }

  cancel() {
    this.parent.step = 2;
  }

  callTransport() {
    this.loading = true;
    this.inPreparationShipmentService.passShipmentInTransportWithPackages(this.parent.dto.idShipment, this.parent.packages).subscribe(
      (data) => {
        this.loading = false;
        this.parent.myOrdersComponent.reloadAndOpenShipment(this.parent.dto.idShipment);
        this.parent.myOrdersComponent.close();
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  openPopup(spho: ShipmentPackageHasOrder) {
    const order = this.parent.dto.shipmentOrders.find((o) => o.idOrder === spho.idOrder);
    //console.log(this.parent.dto.shipmentOrders, order);
    const modalRef = this.modalService.open(FabricationOrderPopupComponent, { size: 'lg', windowClass: 'yellow' });
    modalRef.componentInstance.dto = order;
    modalRef.componentInstance.canCancel = false;
  }
}
