<form autocomplete="off" #f="ngForm" [formGroup]="form" [class.show-errors]="f.submitted && !form.valid" (submit)="submit()">
  <div class="row">
    <div class="col text-center">
      <h2 style="font-size: 1.6rem">Rechercher votre entreprise</h2>
    </div>
  </div>

  <div class="row" *ngIf="loading">
    <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
  </div>

  <ng-container *ngIf="!loading">
    <div class="row mt-5">
      <div class="col">Entrez le numéro de Siret de votre entreprise. Cela va nous permettre de vous inscrire plus rapidement.</div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <label>SIRET</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" class="form-control" formControlName="siret" mask="000 000 000 00000" />
      </div>
    </div>
    <div class="row mt-4" *ngIf="(f.submitted && !form.valid) || error !== null">
      <div class="col">
        <div class="alert alert-danger pr-3 pl-3 pr-md-5 pl-md-5 pt-3 pb-3">
          <ul>
            <li *ngIf="form.hasError('required', 'siret')">Tous les champs sont requis.</li>
            <li *ngIf="form.hasError('invalidSiret', 'siret')">Ce siret est invalide.</li>
            <li *ngIf="error != null">{{ error }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-6">
        <button (click)="accountCreationService.previous()" [disabled]="saving" class="btn btn-secondary w-100"><i class="fas fa-arrow-left"></i></button>
      </div>
      <div class="col-6">
        <button type="submit" [disabled]="saving" class="btn btn-success w-100">
          <i class="fas fa-check"></i>
          <i class="fas fa-spinner spinning ml-3" *ngIf="saving"></i>
        </button>
      </div>
    </div>
  </ng-container>
</form>
