import { DatePipe } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { ProducerDashboardService } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-dashboard-financial',
  templateUrl: './dashboard-financial.component.html',
  styleUrls: ['./dashboard-financial.component.scss'],
})
export class DashboardFinancialComponent implements OnInit {
  multi = [];

  view: any[] = [900, 200];

  colorScheme = {
    domain: ['#06c', '#ffc107', '#00c462'],
  };

  constructor(private producerDashboardService: ProducerDashboardService, public datepipe: DatePipe) {}

  yAxisTickFormatting(data) {
    return data + ' €';
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  ngOnInit(): void {
    this.producerDashboardService.getFinancialStats().subscribe(
      (data) => {
        this.multi = [];
        for (const d of data) {
          this.multi.push({
            name: this.capitalizeFirstLetter(this.datepipe.transform(d.date.toISOString(), 'MMMM yyyy')),
            series: [
              {
                name: "Chiffre d'affaires",
                value: d.chiffreAffaire,
              },
              {
                name: 'Coût de revient',
                value: d.coutRevient,
              },
              {
                name: 'Marge commerciale',
                value: d.marge,
              },
            ],
          });
        }
      },
      (err) => {
        L.e(err);
      }
    );
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}
