import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  ProducerPrinterService,
  ProducerPrinterFullDTO,
  PrinterBrandChoiceDTO,
  PrinterNEW,
  RoleInCompany,
  AuthService,
  ProducerPlanService,
} from 'degineo-common';
import { L } from 'ic2-lib';
import { ActivatedRoute, Router } from '@angular/router';

export interface ManageConsumableItem {
  idConsumableOption: number;
  brand: string;
  name: string;
  color: string;
  active: boolean;
  checked: boolean;
  machineActive: number;
  machineTotal: number;
  machineAttente: number;
}
@Component({
  selector: 'app-my-printers',
  templateUrl: './my-printers.component.html',
  styleUrls: ['./my-printers.component.scss'],
})
export class MyPrintersComponent implements OnInit, OnDestroy {
  addMode: boolean = false;
  loadingAdd: boolean = false;
  printers: ProducerPrinterFullDTO[] = [];
  consumables: ManageConsumableItem[] = []; //for managment tab and gray out unused consumables
  loadingPrinterChoices: boolean = false;
  printerChoices: PrinterBrandChoiceDTO[] = [];
  printerChoices2ndStep: PrinterNEW[] = [];
  loading: boolean = false;
  selectedBrand: PrinterBrandChoiceDTO = null;
  selectedPrinter: PrinterNEW = null;
  open: boolean = false;
  printerShowTabBeforeReload: Map<number, number> = new Map();
  hasRight: boolean;
  canAddPrinter: boolean;
  idProducerPrinterActive: number = null;

  constructor(
    @Inject(ProducerPrinterService) private producerPrinterService: ProducerPrinterService,
    @Inject(AuthService) private authService: AuthService,
    private router: Router,
    @Inject(ProducerPlanService) private producerPlanService: ProducerPlanService,
    private activatedRoute: ActivatedRoute
  ) {
    this.hasRight = this.authService.userBundle.role.id <= RoleInCompany.PRODUCER_ACCOUNTING.id;
  }

  ngOnInit() {
    this.reload();
    this.activatedRoute.params.subscribe((params) => {
      if (params['idProducerPrinter'] !== undefined) this.idProducerPrinterActive = params['idProducerPrinter'];
    });
  }

  ngOnDestroy() {}

  printerBrandChanged() {
    this.selectedPrinter = null;
    this.printerChoices2ndStep = this.selectedBrand.printers;
    if (this.printerChoices2ndStep.length === 1) {
      this.selectedPrinter = this.printerChoices2ndStep[0];
    }
  }

  addPrinter() {
    if (this.selectedPrinter == null || this.loadingAdd) return;
    this.loadingAdd = true;
    this.producerPrinterService.addPrinter(this.selectedPrinter.id).subscribe(
      (data) => {
        this.loadingAdd = false;
        this.reload();
        this.selectedPrinter = null;
        this.selectedBrand = null;
        this.printerChoices = [];
        this.printerChoices2ndStep = [];
        this.addMode = false;
      },
      (err) => {
        this.loadingAdd = false;
        L.e(err);
      }
    );
  }

  cancelAdd() {
    this.selectedPrinter = null;
    this.selectedBrand = null;
    this.printerChoices = [];
    this.printerChoices2ndStep = [];
    this.addMode = false;
  }

  reload() {
    this.loading = true;
    this.producerPrinterService.getPrinters().subscribe(
      (data) => {
        this.printers = data;
        this.printers.forEach((p) => p.ppfs.sort((a, b) => a.name.localeCompare(b.name)));
        this.rebuildConsumablesForManagment();
        this.loading = false;
        console.log(data);
        if (this.idProducerPrinterActive !== null) {
          setTimeout(() => {
            const el = document.getElementById('printer-' + this.idProducerPrinterActive);
            el.scrollIntoView();
          }, 200);
        }
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
    this.reloadCanAddPrinter();
  }

  reloadCanAddPrinter() {
    this.producerPlanService.canAddPrinter().subscribe(
      (data) => {
        this.canAddPrinter = data;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  rebuildConsumablesForManagment() {
    this.consumables = [];
    for (const printer of this.printers) {
      for (const cons of printer.ppcos) {
        if (!cons.checked) continue; //dont add up not checked consumables
        let consumable = this.consumables.find((c) => c.idConsumableOption === cons.idConsumableOption);
        if (consumable === undefined) {
          consumable = {
            idConsumableOption: cons.idConsumableOption,
            brand: cons.brand,
            name: cons.name,
            color: cons.color,
            active: cons.active,
            machineActive: cons.active ? 1 : 0,
            machineTotal: 1,
            checked: true,
          } as ManageConsumableItem;
          this.consumables.push(consumable);
        } else {
          //Existe déjà
          if (!consumable.active && cons.active) consumable.active = true;
          consumable.machineActive += cons.active ? 1 : 0;
          consumable.machineTotal++;
        }
      }
    }
    //Show non checked ones
    for (const printer of this.printers) {
      for (const cons of printer.ppcos) {
        let consumable = this.consumables.find((c) => c.idConsumableOption === cons.idConsumableOption);
        if (!cons.checked && consumable === undefined) {
          //consommables non checkés, qui ne sont pas dans la liste
          consumable = {
            idConsumableOption: cons.idConsumableOption,
            brand: cons.brand,
            name: cons.name,
            color: cons.color,
            active: cons.active,
            checked: false,
            machineActive: 0,
            machineTotal: 0,
            machineAttente: 1,
          } as ManageConsumableItem;
          this.consumables.push(consumable);
        } else {
          consumable.machineAttente++;
        }
      }
    }
  }

  goAddMode() {
    if (!this.canAddPrinter) {
      this.router.navigate(['/evolution-abonnement', 'imprimante']);
      return;
    }
    if (this.addMode) return;
    this.loadingPrinterChoices = true;
    this.producerPrinterService.getPrinterChoices().subscribe(
      (data) => {
        this.addMode = true;
        this.printerChoices = data;
        this.loadingPrinterChoices = false;
      },
      (err) => {
        this.loadingPrinterChoices = false;
        L.e(err);
      }
    );
  }
}
