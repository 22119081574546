<div class="container-fluid h-100">
  <div class="row" *ngIf="loading">
    <div class="col">Chargement ... <i class="fas fa-spinner spinning"></i></div>
  </div>
  <div
    class="row h-100"
    *ngIf="
      loggedUserRole === RoleInCompany.PRODUCER_HOLDER ||
      loggedUserRole === RoleInCompany.PRODUCER_ADMIN ||
      loggedUserRole === RoleInCompany.PRODUCER_SUPERVISER
    "
  >
    <div class="col border-right border-light-30p px-3" style="max-width: 20rem" [ngStyle]="{ opacity: newUser ? '0.5' : '1' }">
      <button class="btn btn-success mt-3 w-100" style="margin-bottom: 2rem" (click)="addUser()">+ Ajouter un utilisateur</button>
      <select id="roles" class="form-control mb-3" [(ngModel)]="searchRole">
        <option [ngValue]="null">Voir tout le monde</option>
        <option [ngValue]="RoleInCompany.PRODUCER_HOLDER">Voir le {{ RoleInCompany.PRODUCER_HOLDER.lib }}</option>
        <option [ngValue]="role" *ngFor="let role of rolesSaufTitu">Voir les {{ role }}s</option>
      </select>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
        </div>
        <input #search type="text" class="form-control" placeholder="Rechercher un utilisateur" (keyup)="searchUser(search.value)" />
      </div>

      <ng-container *ngFor="let user of usersFiltered">
        <ng-container *ngIf="searchRole === null || user.role === searchRole">
          <div
            class="row hoverable px-4 py-2 pointer"
            [class.active]="selectedUser !== null && user.idUser === selectedUser.idUser"
            (click)="selectUser(user.idUser)"
          >
            <div class="d-flex flex-grow-1 w-100">
              <img
                img-preloader="{{ rpc }}userLogo/{{ user.logoToken }}/logo.jpg"
                [onErrorImage]="'assets/image-profil-default.svg'"
                alt="Photo employé"
                style="height: 3.75rem; width: 3.75rem"
                class="rounded-circle mr-md-4 mr-3"
              />
              <div class="d-flex flex-column text-truncate">
                <div class="text-truncate fw-700">{{ user.lastName }} {{ user.firstName }}</div>
                <div>{{ user.job }}</div>
                <div class="text-warning" *ngIf="!user.accountCreationFinished">En attente d'acceptation</div>
                <div class="text-danger" *ngIf="user.disabled">Compte utilisateur en pause</div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="col border-right border-light-30p" style="max-width: 44rem">
      <app-view-edit-user
        *ngIf="editUser"
        [user]="selectedUser"
        [loggedUserRole]="loggedUserRole"
        (updateRole)="selectRole($event)"
        (reloadUser)="getUsers(); selectRole(null)"
      ></app-view-edit-user>
      <app-add-user
        *ngIf="newUser"
        [loggedUserRole]="loggedUserRole"
        (updateRole)="selectRole($event)"
        (addedUser)="resetUser(); pushUser($event)"
        (cancel)="resetUser()"
      ></app-add-user>
    </div>
    <div class="col" style="max-width: 44rem">
      <app-roles [role]="selectedRole"></app-roles>
    </div>
  </div>

  <div
    class="row h-100"
    *ngIf="
      loggedUserRole !== RoleInCompany.PRODUCER_HOLDER &&
      loggedUserRole !== RoleInCompany.PRODUCER_ADMIN &&
      loggedUserRole !== RoleInCompany.PRODUCER_SUPERVISER &&
      !loading
    "
  >
    <div class="col">Vous n'avez pas les droits pour accéder à cette page</div>
  </div>
</div>
