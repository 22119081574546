import { Component, OnInit } from '@angular/core';
import { AuthService, RoleInCompany } from 'degineo-common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  canSeeFinancialChart: boolean = false;
  constructor(public authService: AuthService) {
    this.canSeeFinancialChart =
      this.authService.userBundle.role === RoleInCompany.PRODUCER_ADMIN ||
      this.authService.userBundle.role === RoleInCompany.PRODUCER_ACCOUNTING ||
      this.authService.userBundle.role === RoleInCompany.PRODUCER_HOLDER;
  }

  ngOnInit() {}
}
