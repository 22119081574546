import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddressNEW, ProducerSignupService, ProducerSubscriptionTunnelService } from 'degineo-common';
import { L } from 'ic2-lib';
import { AccountCreationService } from '../../account-creation/account-creation.service';
import { SubscribeService } from '../subscribe.service';

@Component({
  selector: 'app-subscribe-step4-shipping',
  templateUrl: './subscribe-step4-shipping.component.html',
  styleUrls: ['./subscribe-step4-shipping.component.scss'],
})
export class SubscribeStep4ShippingComponent implements OnInit {
  loading: boolean = true;
  saving: boolean = false;
  form: FormGroup = this.fb.group({
    sameAsBilling: [null, [Validators.required]],
    shippingAddress: [null, [Validators.required]],
  });
  billingAddress: AddressNEW;
  readOnly: boolean = false;

  constructor(
    private fb: FormBuilder,
    public subscribeService: SubscribeService,
    private producerSubscriptionTunnelService: ProducerSubscriptionTunnelService
  ) {}

  ngOnInit() {
    this.producerSubscriptionTunnelService.getInfosStep4().subscribe(
      (data) => {
        //console.log(data);
        this.loading = false;
        this.billingAddress = data.billingAddress;
        if (data.shippingAddress === null) {
          this.form.patchValue({
            sameAsBilling: true,
            shippingAddress: data.billingAddress,
          });
          this.readOnly = true;
        } else
          this.form.patchValue({
            sameAsBilling: false,
            shippingAddress: data.shippingAddress,
          });
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  previous() {
    this.subscribeService.step = SubscribeService.STEP_3_COMPANY;
  }

  submit() {
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }
    console.log(this.form.value);
    this.saving = true;
    this.producerSubscriptionTunnelService.saveStep4(this.form.value.shippingAddress).subscribe(
      (data) => {
        this.subscribeService.step = SubscribeService.STEP_5_CONTRACT;
        this.saving = false;
      },
      (err) => {
        this.saving = false;
        L.e(err);
      }
    );
  }

  sameChanged(event) {
    if (event.target.checked) {
      //console.log('setting', this.billingAddress);
      this.form.patchValue({ shippingAddress: this.billingAddress });
      this.readOnly = true;
      console.log(this.form.value);
    } else {
      this.readOnly = false;
      this.form.patchValue({ shippingAddress: null });
    }
  }
}
