import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { AuthService } from 'degineo-common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-creation-step11-bravo',
  templateUrl: './account-creation-step11-bravo.component.html',
  styleUrls: ['./account-creation-step11-bravo.component.scss'],
})
export class AccountCreationStep11BravoComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  submit() {
    this.router.navigate(['/']);
  }
}
