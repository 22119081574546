import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { ProducerCompanyInfoService, ProducerCompanyInfosDTO, AuthService, LegalStatus } from 'degineo-common';
import { L } from 'ic2-lib';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Intercom } from 'ng-intercom';
import { environment } from 'environments/environment';
import { TVAIntracom } from 'degineo-common';

@Component({
  selector: 'app-my-company-infos',
  templateUrl: './my-company-infos.component.html',
  styleUrls: ['./my-company-infos.component.scss'],
})
export class MyCompanyInfosComponent implements OnInit, AfterViewInit {
  dto: ProducerCompanyInfosDTO = null;
  dtoOriginal: ProducerCompanyInfosDTO = null;
  editCompany: boolean = false;
  editDenomination: boolean = false;
  editLegalStatus: boolean = false;
  editCapital: boolean = false;
  editRCS: boolean = false;
  editVAT: boolean = false;
  editBank: boolean = false;
  editDelay: boolean = false;
  editBilling: boolean = false;
  editCollecte: boolean = false;
  editRNA: boolean = false;

  loading: boolean = false;
  stripe: any;
  ibanStripe: any;
  @ViewChildren('iban') iban: QueryList<ElementRef>;
  ibanEl: any;
  debug = environment.name !== 'PRODUCTION';
  clientSecret: string;
  loadingClientSecret: boolean = false;
  newIban: string = null;
  newBic: string = null;
  intercomMessage = '';
  LegalStatus: typeof LegalStatus = LegalStatus;
  getTVAIntracom = TVAIntracom.getTVAIntracom;

  constructor(
    @Inject(ProducerCompanyInfoService) private producerCompanyInfoService: ProducerCompanyInfoService,
    @Inject(AuthService) private authService: AuthService,
    private modalService: NgbModal,
    public intercom: Intercom
  ) {
    this.stripe = Stripe(environment.stripePublishableKey);
  }

  ngOnInit() {
    this.producerCompanyInfoService.getInfos().subscribe(
      (data) => {
        this.dto = data;
        this.dtoOriginal = new ProducerCompanyInfosDTO();
        Object.assign(this.dtoOriginal, this.dto);
      },
      (err) => {
        L.e(err);
      }
    );
  }

  ngAfterViewInit() {
    this.iban.changes.subscribe((r) => {
      if (this.iban.length > 0) {
        console.log(r, this.iban, 'test:', this.iban.first.nativeElement === this.ibanEl);
        if (this.iban.first.nativeElement !== this.ibanEl) this.mountIban(this.iban.first.nativeElement);
      }
    });
  }

  showPopup(evt, content, text) {
    evt.preventDefault();
    this.intercomMessage = text;
    this.modalService
      .open(content, {
        centered: true,
        backdrop: 'static',
        keyboard: false,
      })
      .result.then(
        (result) => {
          //`Closed with: ${result}`;
        },
        (reason) => {
          //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  openChat() {
    this.intercom.show("Bonjour, j'aimerais changer " + this.intercomMessage + ' car ');
  }

  cancelEdit() {
    Object.assign(this.dto, this.dtoOriginal);
  }

  saveEditCompany() {
    this.loading = true;
    this.producerCompanyInfoService.updateName(this.dto.name).subscribe(
      (data) => {
        this.dtoOriginal = new ProducerCompanyInfosDTO();
        Object.assign(this.dtoOriginal, this.dto);
        this.editCompany = false;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  saveEditDenomination() {
    this.loading = true;
    this.producerCompanyInfoService.updateDenomination(this.dto.denomination).subscribe(
      (data) => {
        this.dtoOriginal = new ProducerCompanyInfosDTO();
        Object.assign(this.dtoOriginal, this.dto);
        this.editDenomination = false;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  saveEditLegalStatus() {
    this.loading = true;
    this.producerCompanyInfoService.updateLegalStatus(this.dto.legalStatus).subscribe(
      (data) => {
        this.dtoOriginal = new ProducerCompanyInfosDTO();
        Object.assign(this.dtoOriginal, this.dto);
        this.editLegalStatus = false;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  saveEditCapital() {
    this.loading = true;
    this.producerCompanyInfoService.updateCapital(this.dto.capital).subscribe(
      (data) => {
        this.dtoOriginal = new ProducerCompanyInfosDTO();
        Object.assign(this.dtoOriginal, this.dto);
        this.editCapital = false;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  saveEditRCS() {
    this.loading = true;
    this.producerCompanyInfoService.updateRCS(this.dto.RCS).subscribe(
      (data) => {
        this.dtoOriginal = new ProducerCompanyInfosDTO();
        Object.assign(this.dtoOriginal, this.dto);
        this.editRCS = false;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  saveEditRNA() {
    this.loading = true;
    this.producerCompanyInfoService.updateRNANumber(this.dto.RNANumber).subscribe(
      (data) => {
        this.dtoOriginal = new ProducerCompanyInfosDTO();
        Object.assign(this.dtoOriginal, this.dto);
        this.editRNA = false;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  saveEditUseVAT() {
    this.loading = true;
    this.producerCompanyInfoService.updateUseVAT(this.dto.useVAT).subscribe(
      (data) => {
        this.dtoOriginal = new ProducerCompanyInfosDTO();
        Object.assign(this.dtoOriginal, this.dto);
        this.authService.userBundle.useVAT = this.dto.useVAT;
        this.editVAT = false;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  saveEditDelay() {
    this.loading = true;
    this.producerCompanyInfoService.updateProductionDelay(this.dto.productionDelay).subscribe(
      (data) => {
        this.dtoOriginal = new ProducerCompanyInfosDTO();
        Object.assign(this.dtoOriginal, this.dto);
        this.editDelay = false;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  saveEditBank() {
    this.loading = true;
    console.log('iban is', this.ibanStripe);
    if (this.ibanStripe._empty) {
      this.producerCompanyInfoService.updateBillingInformations(null, this.newIban, this.newBic).subscribe(
        (data) => {
          this.dtoOriginal = new ProducerCompanyInfosDTO();
          if (this.newIban !== null && this.newBic !== null) {
            this.dto.iban = this.newIban;
            this.dto.bic = this.newBic;
            this.newIban = null;
            this.newBic = null;
          }
          Object.assign(this.dtoOriginal, this.dto);
          this.editBank = false;
          this.loading = false;
        },
        (err) => {
          L.e(err);
          this.loading = false;
        }
      );
    } else {
      this.stripe
        .confirmSepaDebitSetup(this.clientSecret, {
          payment_method: {
            sepa_debit: this.ibanStripe,
            billing_details: {
              name: this.authService.userBundle.user.firstName + ' ' + this.authService.userBundle.user.lastName,
              email: this.authService.userBundle.user.email,
            },
          },
        })
        .catch((error) => {
          console.error(error);
        })
        .then((result) => {
          if (!result || result.error) {
            console.error(result.error);
          } else {
            this.producerCompanyInfoService.updateBillingInformations(result.setupIntent.payment_method, this.newIban, this.newBic).subscribe(
              (data) => {
                this.dtoOriginal = new ProducerCompanyInfosDTO();
                this.dto.stripeIbanLast4Digits = "En cours d'actualisation";
                if (this.newIban !== null && this.newBic !== null) {
                  this.dto.iban = this.newIban;
                  this.dto.bic = this.newBic;
                  this.newIban = null;
                  this.newBic = null;
                }
                Object.assign(this.dtoOriginal, this.dto);
                this.editBank = false;
                this.loading = false;
              },
              (err) => {
                L.e(err);
                this.loading = false;
              }
            );
            /*setupIntent:
            id: "seti_1Ga47UB8npfiVmwMwSyZ2YBK"
            object: "setup_intent"
            cancellation_reason: null
            client_secret: "seti_1Ga47UB8npfiVmwMwSyZ2YBK_secret_H8KnaK1BIKaFsBcknoNpZxVOYKMVqKC"
            created: 1587406404
            description: null
            last_setup_error: null
            livemode: false
            next_action: null
            payment_method: "pm_1Ga482B8npfiVmwMjabjvUEu"
            payment_method_types: ["sepa_debit"]
            status: "succeeded"
            usage: "off_session"
            */
            console.log(result);
          }
        });
    }
  }

  saveEditBillingAddress() {
    console.log(this.dto.billingAddress);
    if (this.dto.billingAddress.id !== 0) {
      this.editBilling = false;
      return;
    }
    this.loading = true;
    this.producerCompanyInfoService.updateBillingAddress(this.dto.billingAddress).subscribe(
      (data) => {
        this.dtoOriginal = new ProducerCompanyInfosDTO();
        Object.assign(this.dtoOriginal, this.dto);
        this.editBilling = false;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  saveEditCollecteAddress() {
    console.log(this.dto.collecteAddress);
    if (this.dto.collecteAddress.id !== 0) {
      this.editCollecte = false;
      return;
    }
    this.loading = true;
    this.producerCompanyInfoService.updateCollectAddress(this.dto.collecteAddress).subscribe(
      (data) => {
        this.dtoOriginal = new ProducerCompanyInfosDTO();
        Object.assign(this.dtoOriginal, this.dto);
        this.editCollecte = false;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  mountIban(el) {
    this.ibanEl = el;
    console.log('MOUNTING STRIPE ELEMENT');
    const opts = {
      placeholder: '',
      supportedCountries: ['SEPA'],
      style: {
        base: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontSize: '16px',
          lineHeight: '1.5rem',
          color: 'rgb(49, 69, 89)',
        },
        invalid: {
          color: 'red',
        },
      },
    };
    const stripeElements = this.stripe.elements();
    this.ibanStripe = stripeElements.create('iban', opts);
    this.ibanStripe.mount(el);
    /*
    this.ibanStripe.addEventListener('change', (e) => {
      console.log(e);
      console.log(stripeElements, el, this);
    });
    console.log(stripeElements, el, this);
    */
  }

  toggleEditBank() {
    this.loadingClientSecret = true;
    this.producerCompanyInfoService.getStripeClientSecret().subscribe(
      (data) => {
        this.loadingClientSecret = false;
        this.clientSecret = data;
        this.editBank = true;
        //this.mountIban(this.iban.first.nativeElement);
      },
      (err) => {
        this.loadingClientSecret = false;
        L.e(err);
      }
    );
  }
}
