<div class="d-flex flex-column" style="height: 100vh !important">
  <div class="container-fluid bg-light-30p flex-grow-1 bg-logo pb-5" style="box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1)">
    <div class="row mt-5">
      <div class="col text-center">
        <img src="assets/header/logo_degineo_bleu_vert.svg" width="310px" />
      </div>
    </div>
    <div class="row my-5">
      <div class="col">
        <div class="container-small bg-white rounded shadow-2">
          <form autocomplete="off" #f="ngForm" [formGroup]="form" [class.show-errors]="f.submitted && !form.valid" (submit)="submit()">
            <div class="row" style="padding-top: 2rem !important">
              <div class="col text-center">
                <h3>{{ companyName }} vous invite à rejoindre la team sur Degineo.</h3>
                <p class="mt-3">
                  Pour cela, vous avez juste à créer un compte. <br />
                  (Veuillez vérifier que les informations ci-dessous sont correctes avant de cliquer sur le bouton)
                </p>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6 order-md-0 order-0">
                <label>Prénom</label>
              </div>
              <div class="col-md-6 order-md-1 order-2">
                <label>Nom</label>
              </div>
              <div class="col-md-6 order-md-2 order-1 mt-1">
                <input type="text" class="form-control" formControlName="firstName" />
              </div>
              <div class="col-md-6 order-md-3 order-3 mt-1">
                <input type="text" class="form-control" formControlName="lastName" />
              </div>
            </div>

            <div class="row mt-4">
              <div class="col">
                <label>E-mail</label>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <input type="text" class="form-control" formControlName="email" />
              </div>
            </div>

            <div class="row mt-4">
              <div class="col">
                <label>Téléphone mobile</label>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <input type="text" class="form-control" formControlName="phoneNumber" mask="00 00 00 00 00" />
              </div>
            </div>

            <div class="row mt-4">
              <div class="col">
                <label>Mot de passe</label>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <input type="password" class="form-control" formControlName="password" />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label>Confirmez votre mot de passe</label>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <input type="password" class="form-control" formControlName="password2" />
              </div>
            </div>
            <div class="row mt-4" *ngIf="f.submitted && !form.valid">
              <div class="col">
                <div class="alert alert-danger pr-3 pl-3 pr-md-5 pl-md-5 pt-3 pb-3">
                  <ul>
                    <li *ngIf="form.hasError('required', 'email')">Tous les champs sont requis.</li>
                    <li *ngIf="form.hasError('email', 'email')">L'e-mail est incorrect.</li>
                    <li *ngIf="form.hasError('alreadyExists', 'email')">Cet e-mail est déjà utilisé.</li>
                    <li *ngIf="form.hasError('required', 'password') || form.hasError('pattern', 'password')">
                      Le mot de passe doit comporter au moins 6 caractères.
                    </li>
                    <li *ngIf="form.hasError('notSame')">La confirmation du mot de passe et le mot de passe doivent être identiques.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row mt-4" style="padding-bottom: 2rem !important">
              <div class="col text-center">
                <button type="submit" [disabled]="loading || !form.valid" class="btn btn-success text-uppercase w-100">
                  Créer mon compte et rejoindre {{ companyName }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
