import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ProducerSignupService, RegisterService, UserEditValidators } from 'degineo-common';
import { AccountCreationService } from '../account-creation.service';
import { L } from 'ic2-lib';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-account-creation-step1-account',
  templateUrl: './account-creation-step1-account.component.html',
  styleUrls: ['./account-creation-step1-account.component.scss'],
})
export class AccountCreationStep1AccountComponent implements OnInit {
  loading: boolean = true;
  saving: boolean = false;
  form: FormGroup = this.fb.group(
    {
      email: [{ value: null, disabled: true }, [Validators.required, Validators.email], this.uniqueEmailValidator.bind(this)],
      password: [null, [Validators.required, Validators.minLength(6), UserEditValidators.passwordDifficulty]],
      password2: [null, [Validators.required, Validators.minLength(6), UserEditValidators.passwordDifficulty]],
    },
    {
      updateOn: 'blur',
      validators: UserEditValidators.checkPasswords('password', 'password2'),
    }
  );

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(ProducerSignupService) private producerSignupService: ProducerSignupService,
    @Inject(AccountCreationService) public accountCreationService: AccountCreationService,
    @Inject(RegisterService) public registerService: RegisterService
  ) {}

  ngOnInit() {
    this.producerSignupService.getInfosStep1(this.accountCreationService.token).subscribe(
      (data) => {
        this.loading = false;
        this.form.patchValue({ email: data });
        if (data === '') this.form.get('email').enable();

        if (environment.name === 'DEV') {
          this.form.patchValue({ password: 'testtest1', password2: 'testtest1' });
        }
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  uniqueEmailValidator(input: FormControl): Observable<{}> {
    return new Observable((obs) => {
      this.registerService.emailAvailable(input.value).subscribe(
        (data) => {
          obs.next(!data ? { alreadyExists: true } : null);
          obs.complete();
        },
        (err) => {
          L.e(err);
          obs.next({ couldNotCheckEmailAvailability: true });
          obs.complete();
        }
      );
    });
  }

  submit() {
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }
    this.saving = true;
    this.producerSignupService.saveStep1(this.accountCreationService.token, this.form.value.email, this.form.value.password).subscribe(
      (data) => {
        this.accountCreationService.next();
        this.saving = false;
      },
      (err) => {
        this.saving = false;
        L.e(err);
      }
    );
  }
}
