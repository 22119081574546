import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, ProducerPlanService, DegineoRight, RoleInCompany, LegalStatus, EvolutionPlanInfoDTO, Plan } from 'degineo-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { L } from 'ic2-lib';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'app-evolution-abonnement',
  templateUrl: './evolution-abonnement.component.html',
  styleUrls: ['./evolution-abonnement.component.scss'],
})
export class EvolutionAbonnementComponent implements OnInit {
  printer: boolean = false;
  holder: boolean;
  dto: EvolutionPlanInfoDTO;
  selectedPlan: Plan = null;

  constructor(
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(AuthService) private authService: AuthService,
    private router: Router,
    @Inject(ProducerPlanService) private producerPlanService: ProducerPlanService,
    private modalService: NgbModal,
    private intercom: Intercom
  ) {
    this.holder = this.authService.isAtLeast(RoleInCompany.PRODUCER_HOLDER);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.printer = params['type'] === 'imprimante';
    });
    this.producerPlanService.getInfosPlan().subscribe(
      (data) => {
        this.dto = data;
      },
      (err) => {
        L.e(err);
      }
    );
    console.log(this);
  }

  openModal(content, plan: Plan) {
    if (plan.planButton) {
      this.intercom.show('Bonjour, je suis intéressé par le plan ' + plan.name + ', est-il possible de me recontacter au numéro suivant : ');
      return;
    }
    this.selectedPlan = plan;
    console.log(plan);
    const modalRef = this.modalService.open(content, {
      centered: true,
      size: 'lg',
      /*beforeDismiss: () => {
        return false;
      },*/
    });
    modalRef.result.then((userResponse) => {
      if (!userResponse) return;
      this.producerPlanService.changePlanTo(this.selectedPlan.id).subscribe(
        (data) => {
          this.previous();
        },
        (err) => {
          L.e(err);
        }
      );
    });
  }

  previous() {
    if (this.printer) this.router.navigate(['/mes-imprimantes']);
    else this.router.navigate(['/utilisateurs']);
  }
}
