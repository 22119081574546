import { Component, OnInit, Host, Inject } from '@angular/core';
import { WaitingForShippingPanelComponent } from '../waiting-for-shipping-panel.component';
import { ShipmentOrderPanelDTO, ShipmentPackageHasOrder, WaitingCarrierShipmentService, AuthService, RoleInCompany } from 'degineo-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FabricationOrderPopupComponent } from '../../fabrication-order-popup/fabrication-order-popup.component';
import { Intercom } from 'ng-intercom';
import { PanelHeaderComponent } from '../../panel-header/panel-header.component';
import { environment } from 'environments/environment';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-shipment-carrier',
  templateUrl: './shipment-carrier.component.html',
  styleUrls: ['./shipment-carrier.component.scss'],
})
export class ShipmentCarrierComponent implements OnInit {
  loading: boolean = false;
  rpcHost: string = environment.rpcHost;

  RoleInCompany: typeof RoleInCompany = RoleInCompany;

  constructor(
    @Host() public parent: WaitingForShippingPanelComponent,
    @Inject(WaitingCarrierShipmentService) public waitingCarrierShipmentService: WaitingCarrierShipmentService,
    @Inject(NgbModal) private modalService: NgbModal,
    public intercom: Intercom,
    @Inject(AuthService) public authService: AuthService
  ) {}

  ngOnInit() {}

  openPopup(spho: ShipmentPackageHasOrder) {
    const order = this.parent.dto.shipmentOrders.find((o) => o.idOrder === spho.idOrder);
    //console.log(this.parent.dto.shipmentOrders, order);
    const modalRef = this.modalService.open(FabricationOrderPopupComponent, { size: 'lg', windowClass: 'yellow' });
    modalRef.componentInstance.dto = order;
    modalRef.componentInstance.canCancel = false;
  }

  openChat() {
    this.intercom.show("Bonjour, j'ai un soucis avec l'expédition N°" + this.parent.dto.idShipment + ', ');
  }

  transportOk() {
    if (!confirm('Confirmez-vous que le transporteur est bien passé ?')) return;
    this.loading = true;
    this.waitingCarrierShipmentService.transportOk(this.parent.dto.idShipment).subscribe(
      (data) => {
        this.loading = false;
        this.parent.myOrdersComponent.reload();
        this.parent.myOrdersComponent.close();
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  isOutdatedOrEqual(date: Date) {
    if (date === null || date === undefined) return false;
    const copiedDate = new Date(date.getTime());
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    copiedDate.setHours(0, 0, 0, 0);
    return copiedDate <= now;
  }
}
