import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProducerSubscriptionTunnelService, UserEditValidators } from 'degineo-common';
import { environment } from 'environments/environment';
import { L } from 'ic2-lib';
import { SubscribeService } from '../subscribe.service';

@Component({
  selector: 'app-subscribe-step2-user',
  templateUrl: './subscribe-step2-user.component.html',
  styleUrls: ['./subscribe-step2-user.component.scss'],
})
export class SubscribeStep2UserComponent implements OnInit {
  loading: boolean = true;
  saving: boolean = false;
  form: FormGroup = this.fb.group({
    firstname: [null, [Validators.required]],
    lastname: [null, [Validators.required]],
    job: [null, [Validators.required]],
    phoneNumber: [null, [Validators.required, UserEditValidators.phoneNumber]],
  });

  constructor(
    private fb: FormBuilder,
    public subscribeService: SubscribeService,
    private router: Router,
    private producerSubscriptionTunnelService: ProducerSubscriptionTunnelService
  ) {
    subscribeService.checkPlanSelected();
  }

  ngOnInit() {
    this.producerSubscriptionTunnelService.getInfosStep2().subscribe(
      (data) => {
        this.loading = false;
        this.form.patchValue({ firstname: data.firstname, lastname: data.lastname, job: data.job, phoneNumber: data.phoneNumber });

        if (environment.name === 'DEV') {
          this.form.patchValue({ firstname: 'Prenom', lastname: 'Nom', job: 'Job', phoneNumber: '0615635188' });
        }
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  previous() {
    this.router.navigate(['mes-commandes']);
  }

  submit() {
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }
    this.saving = true;
    this.producerSubscriptionTunnelService
      .saveStep2(this.form.value.firstname, this.form.value.lastname, this.form.value.job, this.form.value.phoneNumber)
      .subscribe(
        (data) => {
          this.subscribeService.step = SubscribeService.STEP_3_COMPANY;
          this.saving = false;
        },
        (err) => {
          this.saving = false;
          L.e(err);
        }
      );
  }
}
