<form autocomplete="off" #f="ngForm" [formGroup]="form" [class.show-errors]="f.submitted && !form.valid" (submit)="submit()">
  <div class="row">
    <div class="col text-center">
      <h2 style="font-size: 1.6rem">Votre entreprise</h2>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col">Merci de vérifier vos informations légales ci-dessous.<br />Elles serviront à l'édition de vos factures et de vos devis.</div>
  </div>
  <div class="row" *ngIf="loading">
    <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
  </div>

  <ng-container *ngIf="!loading">
    <div class="row mt-5">
      <div class="col">
        <label>SIRET</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" class="form-control" formControlName="siret" mask="000 000 000 00000" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <label>Raison sociale</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" class="form-control" formControlName="name" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <label>Forme juridique</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <select class="form-control" formControlName="legalStatus" (change)="revalidateForm()">
          <option [ngValue]="null" disabled="disabled">-- Choisir --</option>
          <option *ngFor="let legalStatus of LegalStatus.values" [ngValue]="legalStatus">{{ legalStatus.lib }}</option>
        </select>
      </div>
    </div>
    <ng-container
      *ngIf="
        form.value.legalStatus !== LegalStatus.STATUS_AE &&
        form.value.legalStatus !== LegalStatus.STATUS_EI &&
        form.value.legalStatus !== LegalStatus.STATUS_ASSOCIATION
      "
    >
      <div class="row mt-5">
        <div class="col">
          <label>Capital social</label>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <input type="number" class="form-control" formControlName="capital" />
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="form.value.legalStatus == LegalStatus.STATUS_ASSOCIATION">
      <div class="row mt-5">
        <div class="col">
          <label>Numéro RNA</label>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <input type="text" class="form-control" formControlName="RNANumber" />
        </div>
      </div>
    </ng-container>
    <div class="row mt-5">
      <div class="col">
        <label>Ville d'immatriculation au RCS</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" class="form-control" formControlName="RCS" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <label>Adresse de facturation de l'entreprise</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <app-degineo-gmaps-address-input formControlName="billingAddress"></app-degineo-gmaps-address-input>
      </div>
    </div>
    <div class="row mt-4" *ngIf="f.submitted && !form.valid">
      <div class="col">
        <div class="alert alert-danger pr-3 pl-3 pr-md-5 pl-md-5 pt-3 pb-3">
          <ul>
            <li *ngIf="form.hasError('required', 'name')">Une raison sociale est requise.</li>
            <li *ngIf="form.hasError('required', 'legalStatus')">Une forme juridique est requise.</li>
            <li *ngIf="form.hasError('required', 'capital')">Un capital est requis.</li>
            <li *ngIf="form.hasError('min', 'capital')">Un capital supérieur à 0 est requis.</li>
            <li *ngIf="form.hasError('required', 'RNANumber')">Un numéro RNA est requis.</li>
            <li *ngIf="form.hasError('required', 'RCS')">Une ville d'immatriculation est requise.</li>
            <li *ngIf="form.hasError('required', 'billingAddress')">Une addresse est requise.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-6">
        <button (click)="accountCreationService.previous()" [disabled]="saving" class="btn btn-secondary w-100"><i class="fas fa-arrow-left"></i></button>
      </div>
      <div class="col-6">
        <button type="submit" [disabled]="saving" class="btn btn-success w-100">
          <i class="fas fa-check"></i><i class="fas fa-spinner spinning ml-3" *ngIf="saving"></i>
        </button>
      </div></div
  ></ng-container>
</form>
