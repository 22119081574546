import { Component, OnInit, Inject } from '@angular/core';
import { Intercom } from 'ng-intercom';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DegineoGmapsAddressInputComponent } from '../account-creation/degineo-gmaps-address-input/degineo-gmaps-address-input.component';
import { ProducerSubscriptionService, ProducerSubscriptionInfosDTO, Filter, FilterOrder, GenericFilter, SubscriptionInvoiceDTO } from 'degineo-common';
import { L } from 'ic2-lib';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-my-subscription',
  templateUrl: './my-subscription.component.html',
  styleUrls: ['./my-subscription.component.scss'],
})
export class MySubscriptionComponent implements OnInit {
  loading: boolean = false;
  count = 0;
  nbPerPage = 20;
  page = 1;
  dto: ProducerSubscriptionInfosDTO = null;
  formatAddress = DegineoGmapsAddressInputComponent.formatLineAddress;
  builtFiltersOrder: FilterOrder[] = [];
  rows: SubscriptionInvoiceDTO[];
  rpcHost = environment.rpcHost;

  constructor(
    private modalService: NgbModal,
    public intercom: Intercom,
    @Inject(ProducerSubscriptionService) private producerSubscriptionService: ProducerSubscriptionService
  ) {}

  ngOnInit() {
    this.reload();
    this.producerSubscriptionService.getInfos().subscribe(
      (data) => {
        this.dto = data;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  reload() {
    const genericFilter = new GenericFilter();
    genericFilter.filters = [];
    genericFilter.orderFilters = this.builtFiltersOrder;
    genericFilter.page = this.page;
    genericFilter.nbPerPage = this.nbPerPage;
    this.producerSubscriptionService.getSubscriptionInvoices(genericFilter).subscribe(
      (data) => {
        this.count = data.count;
        this.rows = data.data;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  showPopup(evt, content, canClose) {
    evt.preventDefault();
    this.modalService.open(content, { centered: true, backdrop: canClose ? true : 'static', keyboard: false }).result.then(
      (result) => {
        //`Closed with: ${result}`;
      },
      (reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  openChat() {
    this.intercom.show("Bonjour, j'aimerais suspendre mon abonnement Degineo car ");
  }
}
