<ngx-charts-bar-vertical-2d
  [view]="view"
  [scheme]="colorScheme"
  [results]="multi"
  [legend]="true"
  [legendTitle]="''"
  [xAxis]="true"
  [yAxis]="true"
  (select)="onSelect($event)"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)"
  [yAxisTickFormatting]="yAxisTickFormatting"
>
  <ng-template #tooltipTemplate let-model="model">
    <span class="tooltip-label">{{ model.series }} • {{ model.label }}</span>
    <span class="tooltip-val">{{ model.value | currency: 'EUR':'symbol':'1.0-2':'fr' }}</span>
  </ng-template>
</ngx-charts-bar-vertical-2d>
