import { Component, OnInit, Input, Inject, Host, OnChanges, SimpleChanges } from '@angular/core';
import * as moment_ from 'moment';
import { InProductionOrderPanelDTO, InProductionOrderService, AuthService, RoleInCompany } from 'degineo-common';
import { MyOrdersComponent } from '../my-orders.component';
import { L } from 'ic2-lib';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-in-production-order-panel',
  templateUrl: './in-production-order-panel.component.html',
  styleUrls: ['./in-production-order-panel.component.scss'],
})
export class InProductionOrderPanelComponent implements OnInit, OnChanges {
  @Input()
  id: number;
  dto: InProductionOrderPanelDTO = null;
  moment = moment_;
  rpcHost: string = environment.rpcHost;
  loading: boolean = false;

  RoleInCompany: typeof RoleInCompany = RoleInCompany;
  constructor(
    @Inject(InProductionOrderService) private inProductionOrderService: InProductionOrderService,
    @Host() public myOrdersComponent: MyOrdersComponent,
    @Inject(AuthService) public authService: AuthService
  ) {
    this.moment.locale('fr');
  }

  ngOnInit() {
    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dto = null;
    this.load();
  }

  load() {
    this.inProductionOrderService.getProductionOrder(this.id).subscribe(
      (data) => {
        this.dto = data;
        console.log(data);
      },
      (err) => {
        L.e(err);
      }
    );
  }

  accept() {
    if (this.loading) return;
    this.loading = true;
    this.inProductionOrderService.finishProduction(this.id).subscribe(
      (data) => {
        this.myOrdersComponent.reload();
        this.myOrdersComponent.close();
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  cancel() {
    if (this.loading) return;
    this.loading = true;
    this.inProductionOrderService.cancelProductionOrder(this.id).subscribe(
      (data) => {
        this.myOrdersComponent.reload();
        this.myOrdersComponent.close();
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }
}
