<ng-template #changeEmailModal let-modal>
  <div class="container shadow-5 rounded py-4 px-5">
    <i class="close-icon fas fa-times" (click)="modal.dismiss()"></i>
    <div class="row mt-2">
      <div class="col text-center">
        <h6>Indiquez ici l’adresse e-mail qui vous servira <br />d’identifiant Degineo :</h6>
      </div>
    </div>
    <form #f="ngForm" [formGroup]="changeEmailForm" [class.show-errors]="f.submitted && !changeEmailForm.valid" (submit)="changeEmail(modal)">
      <div class="row mt-3">
        <div class="col">
          <input type="text" class="form-control" placeholder="exemple@e-mail.com" autocomplete="email" formControlName="email" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-center">
          <button type="submit" class="btn btn-success" [disabled]="loading">Valider<i class="fas fa-spinner spinning ml-2" *ngIf="loading"></i></button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #resentEmailModal let-modal>
  <div class="container shadow-5 rounded py-4 px-5">
    <i class="close-icon fas fa-times" (click)="modal.dismiss()"></i>
    <div class="row mt-4">
      <div class="col text-center">
        <p>
          Nous venons de vous renvoyer un e-mail de validation à l'adresse suivante&nbsp;: <span class="fw-700">{{ authService.userBundle.user.email }}</span
          >. Pensez à vérifier dans vos courriers indésirables.
        </p>
      </div>
    </div>
  </div>
</ng-template>
<div id="content">
  <div *ngIf="mobile" [ngStyle]="{ 'padding-left': show ? '17rem' : '0px', 'padding-top': show ? '0px' : '3.75rem' }">
    <div class="w-100 bg-warning text-center fs-2 py-2" *ngIf="!authService.userBundle.du.emailConfirmed">
      Afin de vérifier l’authenticité de votre adresse e-mail, nous avons envoyé un e-mail de validation à l’adresse :
      <span class="fw-700">{{ authService.userBundle.user.email }}</span
      >.<br />
      Si l’adresse e-mail ci-dessus n’est pas correcte, <a [routerLink]="null" (click)="showPopup($event, changeEmailModal)">cliquez ici</a>. Si vous avez perdu
      l'e-mail que nous vous avons envoyé, <a [routerLink]="null" (click)="resendEmail($event, resentEmailModal)">cliquez ici</a> afin d'en recevoir un nouveau.
    </div>
    <div id="nav-icon3" [ngClass]="{ open: show }" (click)="show = !show">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>

    <img class="logo" src="assets/header/logo_degineo_blanc_vert.svg" width="110px" style="width: 7rem" />

    <nav class="overflow-hidden topnav w-100 fixed-top bg-primary" [ngStyle]="{ height: show ? '0px' : '3.75rem' }" id="navBar">
      <div class="col">
        <div class="row bg-primary" style="height: 3.75rem">
          <div class="col-auto align-self-center"></div>
        </div>
      </div>
    </nav>

    <nav [ngStyle]="{ left: show ? '0px' : '-17rem' }" class="sidenav fixed-top shadow-1">
      <div class="position-relative w-100 navListContainer">
        <ul class="list-unstyled components navList">
          <li>
            <a routerLink="/mon-profil" (click)="clicked()" class="text-uppercase header-nav-btn">
              <img
                [src]="avatarChangeService.avatar"
                height="35px"
                width="35px"
                style="width: 2.2rem; height: 2.2rem; border-radius: 50%"
                class="mr-2"
                (error)="avatarChangeService.errorLoading()"
              />
              <div class="name d-inline-block align-middle fw-500" style="font-size: 1rem; letter-spacing: 0.025em">
                {{ authService.userBundle?.user.firstName == null ? authService.userBundle?.producerName : authService.userBundle?.user.firstName }}
              </div>
            </a>
          </li>
          <li *ngFor="let link of getLinks()">
            <ng-container *ngIf="link.children">
              <a
                (click)="link.open = !link.open"
                class="text-uppercase fw-500 header-nav-btn"
                style="letter-spacing: 0.025em"
                routerLinkActive="active"
                [routerLinkActiveOptions]="link.routerLink === '/' ? { exact: true } : {}"
                #rla="routerLinkActive"
              >
                <img [src]="getLinkMobile(link, rla.isActive)" style="margin-right: 0.3rem; height: 1.2rem" />{{ link.name }}</a
              >
              <ul *ngIf="link.open">
                <li *ngFor="let childLink of link.children">
                  <a class="link-unstyled" [routerLink]="childLink.routerLink" (click)="clicked()">{{ childLink.name }}</a>
                </li>
              </ul>
            </ng-container>
            <a
              *ngIf="link.routerLink"
              (click)="clicked($event, link)"
              [routerLink]="link.routerLink"
              routerLinkActive="active"
              [routerLinkActiveOptions]="link.routerLink === '/' ? { exact: true } : {}"
              #rla="routerLinkActive"
              class="text-uppercase fw-500 header-nav-btn"
              style="letter-spacing: 0.025em"
              ><img [src]="getLinkMobile(link, rla.isActive)" style="margin-right: 0.3rem; height: 1.2rem" />{{ link.name }}</a
            >
            <a *ngIf="link.logout" class="text-uppercase fw-500 header-nav-btn" style="letter-spacing: 0.025em" (click)="logout(); clicked()">
              <img [src]="getLinkMobile(link, false)" style="margin-right: 0.3rem; height: 1.2rem" />{{ link.name }}
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <div (click)="clicked()">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </div>
  </div>

  <div *ngIf="!mobile">
    <div class="w-100 bg-warning text-center fs-2 py-2" *ngIf="!authService.userBundle.du.emailConfirmed">
      Afin de vérifier l’authenticité de votre adresse e-mail, nous avons envoyé un e-mail de validation à l’adresse :
      <span class="fw-700">{{ authService.userBundle.user.email }}</span
      >.<br />
      Si l’adresse e-mail ci-dessus n’est pas correcte, <a [routerLink]="null" (click)="showPopup($event, changeEmailModal)">cliquez ici</a>. Si vous avez perdu
      l'e-mail que nous vous avons envoyé, <a [routerLink]="null" (click)="resendEmail($event, resentEmailModal)">cliquez ici</a> afin d'en recevoir un nouveau.
    </div>
    <div class="container-fluid">
      <div class="row flex-nowrap justify-content-between" style="height: 3.75rem; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); z-index: 1; position: relative">
        <div class="col-2 align-self-center">
          <img src="assets/header/logo_degineo_bleu_vert.svg" width="110px" style="width: 7rem" />
        </div>
        <div class="col-10 h-100 align-self-center d-flex align-items-center justify-content-end">
          <a class="link-unstyled hover-style" [routerLink]="null" (click)="openProfilePopup()">
            <div class="text-center mb-1">
              <span class="d-none d-md-inline name fs-4" style="letter-spacing: 0.08em; line-height: 1.4em">
                {{ authService.userBundle?.user.firstName == null ? authService.userBundle?.producerName : authService.userBundle?.user.firstName }} </span
              ><br />
              <span class="badge user-badge">{{ authService.userBundle?.role?.lib }}</span>
            </div>
          </a>
          <input type="file" id="file" style="display: none" accept="image/png,image/jpeg,image/gif" #fileInput (change)="handleFileInput($event)" /><br />
          <a [routerLink]="null" class="fs-2 fw-500" (click)="fileInput.click()">
            <img [src]="avatarChangeService.avatar" class="mr-2 profile-pic" (error)="avatarChangeService.errorLoading()" />
            <span class="text-danger d-block" *ngIf="errorFile">{{ errorFile }}</span>
          </a>
        </div>
      </div>
      <div class="row" style="height: calc(100vh - 3.75rem)">
        <div class="" style="flex: 0 0 15rem; box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.1); z-index: 1">
          <div class="container-fluid pt-3">
            <ng-container *ngFor="let link of getLinks()">
              <ng-container *ngIf="!link.children">
                <div
                  class="row left-menu-item"
                  routerLinkActive="active"
                  #rla="routerLinkActive"
                  [class.active]="isActive(link)"
                  [routerLinkActiveOptions]="link.routerLink === '/' ? { exact: true } : {}"
                >
                  <div class="col p-0">
                    <a
                      [routerLink]="link.routerLink"
                      *ngIf="link.routerLink"
                      class="no-select"
                      (mouseover)="link.img.hover = true"
                      (mouseout)="link.img.hover = false"
                    >
                      <img [src]="getLink(link, rla.isActive)" class="picto mr-3" />{{ link.name }}</a
                    >
                    <a *ngIf="link.logout" (click)="logout()" class="no-select pointer" (mouseover)="link.img.hover = true" (mouseout)="link.img.hover = false">
                      <img [src]="getLink(link, rla.isActive)" class="picto mr-3" />{{ link.name }}
                    </a>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="link.children">
                <div class="row left-menu-item">
                  <div class="col p-0">
                    <ng-container *ngIf="link.children">
                      <a
                        (click)="link.open = !link.open"
                        class="no-select pointer"
                        [class.active]="link.active"
                        [class.fw-700]="link.open"
                        (mouseover)="link.img.hover = true"
                        (mouseout)="link.img.hover = false"
                      >
                        <img [src]="getLink(link, false, link.active)" class="picto mr-3" />{{ link.name }}
                      </a>
                      <div class="container-fluid sous-menu" *ngIf="link.open">
                        <div class="row left-menu-child-item" routerLinkActive="active" *ngFor="let childLink of link.children">
                          <div class="col p-0 no-select fs-2">
                            <a [routerLink]="childLink.routerLink">
                              <!--<img
                            [src]="childLink.img.path + '/' + childLink.img.name +  childLink.img.ext"
                            style="height: 1.2rem"
                            class="mr-3"
                          />-->{{ childLink.name }}</a
                            >
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <a
                      [routerLink]="link.routerLink"
                      *ngIf="link.routerLink"
                      class="no-select"
                      (mouseover)="link.img.hover = true"
                      (mouseout)="link.img.hover = false"
                    >
                      <img [src]="getLink(link, rla.isActive)" class="picto mr-3" />{{ link.name }}</a
                    >
                    <a *ngIf="link.logout" (click)="logout()" class="no-select pointer" (mouseover)="link.img.hover = true" (mouseout)="link.img.hover = false">
                      <img [src]="getLink(link, rla.isActive)" class="picto mr-3" /><!-- prettier-ignore -->{{ link.name }}
                    </a>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="overflow-auto d-inline-block" style="flex: 1; height: calc(100vh - 3.75rem)" (click)="clicked()">
          <ng-container *ngTemplateOutlet="template"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <router-outlet> </router-outlet>
</ng-template>
