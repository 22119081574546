import { Component, OnInit, Inject } from '@angular/core';
import {
  KanbanService,
  KanbanDTO,
  ReceivedOrderDTO,
  InPreparationOrderDTO,
  OrderFileStatus,
  InProductionOrderDTO,
  InPreparationShipmentDTO,
  WaitingCarrierShipmentDTO,
  ShipmentStatus,
  AuthService,
  RoleInCompany,
} from 'degineo-common';
import { L } from 'ic2-lib';
import * as moment_ from 'moment';
import { PanelHeaderComponent } from './panel-header/panel-header.component';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss'],
})
export class MyOrdersComponent implements OnInit {
  static DefaultSearchText = null;
  openPanel: boolean = false;
  kanbanDTO: KanbanDTO = null;
  moment = moment_;
  panelColor: string = 'blue';
  receivedOrder: ReceivedOrderDTO = null;
  inPreparationOrder: InPreparationOrderDTO = null;
  inProductionOrder: InProductionOrderDTO = null;
  inPreparationShipment: InPreparationShipmentDTO = null;
  waitingCarrierShipment: WaitingCarrierShipmentDTO = null;
  OrderFileStatus: typeof OrderFileStatus = OrderFileStatus;
  ShipmentStatus: typeof ShipmentStatus = ShipmentStatus;
  searchText: string = null;
  highlightedItems: any[] = [];

  RoleInCompany: typeof RoleInCompany = RoleInCompany;
  isOutdated = PanelHeaderComponent.isOutdatedStatic;

  constructor(@Inject(KanbanService) private kanbanService: KanbanService, private intercom: Intercom, @Inject(AuthService) public authService: AuthService) {
    this.moment.locale('fr');
  }

  ngOnInit() {
    this.reload();
  }

  reload() {
    this.kanbanService.getKanban().subscribe(
      (data) => {
        this.kanbanDTO = data;
        if (MyOrdersComponent.DefaultSearchText !== null) {
          this.searchText = MyOrdersComponent.DefaultSearchText;
          MyOrdersComponent.DefaultSearchText = null;
          this.searchChanged();
        }
      },
      (err) => {
        L.e(err);
      }
    );
  }

  reloadAndOpenShipment(idShipment: number) {
    this.kanbanService.getKanban().subscribe(
      (data) => {
        this.kanbanDTO = data;
        const shipmentdto = this.kanbanDTO.waitingCarrierShipments.find((s) => s.idShipment === idShipment);
        if (shipmentdto === undefined) {
          L.e('erreur pas possible de trouver le shipment ' + idShipment + ' après reload');
        } else {
          this.openWaitingCarrierShipment(shipmentdto);
        }
      },
      (err) => {
        L.e(err);
      }
    );
  }

  openReceivedOrder(order: ReceivedOrderDTO) {
    this.close();
    this.moveIntercom();
    this.panelColor = 'blue';
    this.openPanel = true;
    this.receivedOrder = order;
  }

  openInPreparationOrder(order: InPreparationOrderDTO) {
    this.close();
    this.moveIntercom();
    this.panelColor = 'purple';
    this.openPanel = true;
    this.inPreparationOrder = order;
  }

  openInProductionOrder(order: InProductionOrderDTO) {
    this.close();
    this.moveIntercom();
    this.panelColor = 'red';
    this.openPanel = true;
    this.inProductionOrder = order;
  }

  openInPreparationShipment(shipment: InPreparationShipmentDTO) {
    this.close();
    this.moveIntercom();
    this.panelColor = 'yellow';
    this.openPanel = true;
    this.inPreparationShipment = shipment;
  }

  openWaitingCarrierShipment(shipment: WaitingCarrierShipmentDTO) {
    this.close();
    this.moveIntercom();
    this.panelColor = 'green';
    this.openPanel = true;
    this.waitingCarrierShipment = shipment;
  }

  moveIntercom() {
    this.intercom.update({ horizontal_padding: '672' }); //672 = 42rem, car panel fait 40rem
  }

  close() {
    this.intercom.update({ horizontal_padding: '20' }); //reset
    this.openPanel = false;
    this.receivedOrder = null;
    this.inPreparationOrder = null;
    this.inProductionOrder = null;
    this.inPreparationShipment = null;
    this.waitingCarrierShipment = null;
  }

  searchChanged() {
    this.highlightedItems = [];
    console.log('SEARCH CHANGED', this.searchText);
    //nom de l'imprimante
    //nom du consommable
    //demande degineo -> "Degineo" ou "Demande"
    //vous avez l'exclusivité : "Exclusivité" ou "Degineo" ou "Demande"
    //Nom du client
    //n° impression
    //n° expédition
    //impression en attente : "attentes" ou "attente"
    //Toutes les impressions sont prêtes > "Prêtes" ou "Prête" ou "Prêt"
    if (this.searchText === null || this.searchText === '') return;
    //supprimer les accents
    const str = this.normalizeText(this.searchText);
    //probablement split tout les mots et faire le test pour chaque mot
    for (const word of str.split(' ')) {
      if (word.length === 0) continue;
      for (const item of this.kanbanDTO.receivedOrders) {
        //console.log(word, this.normalizeText(item.clientName), this.normalizeText(item.clientName).indexOf(word));
        if (this.normalizeText(item.clientName).indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (this.normalizeText(item.printerName).indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (item.idOrder + '' === word) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (!item.clientChoosedThisProducer && 'degineo demande'.indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (item.clientChoosedThisProducer && 'exclusivite'.indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }
        if (this.isOutdated(item.wishedDeliveryDate) && 'retard'.indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }
      }
      for (const item of this.kanbanDTO.inPreparationOrders) {
        //console.log(word, this.normalizeText(item.clientName), this.normalizeText(item.clientName).indexOf(word));
        if (this.normalizeText(item.clientName).indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (this.normalizeText(item.printerName).indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (item.idOrder + '' === word) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (!item.clientChoosedThisProducer && 'degineo demande'.indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (item.clientChoosedThisProducer && 'exclusivite'.indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }
        if (this.isOutdated(item.wishedDeliveryDate) && 'retard'.indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }
      }
      for (const item of this.kanbanDTO.inProductionOrders) {
        //console.log(word, this.normalizeText(item.clientName), this.normalizeText(item.clientName).indexOf(word));
        if (this.normalizeText(item.clientName).indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (this.normalizeText(item.printerName).indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (item.idOrder + '' === word) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (!item.clientChoosedThisProducer && 'degineo demande'.indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (item.clientChoosedThisProducer && 'exclusivite'.indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }
        if (this.isOutdated(item.wishedDeliveryDate) && 'retard'.indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }
      }
      for (const item of this.kanbanDTO.inPreparationShipments) {
        //console.log(word, this.normalizeText(item.clientName), this.normalizeText(item.clientName).indexOf(word));
        if (this.normalizeText(item.clientName).indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (item.idShipment + '' === word) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        let allReady = true;
        for (const order of item.orders) {
          if (order.idOrder + '' === word) {
            if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
          }

          if (order.waitForIt && !order.ready) allReady = false;
        }

        if (allReady && 'pretes'.indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (!allReady && 'attentes'.indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (!item.clientChoosedThisProducer && 'degineo demande'.indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (item.clientChoosedThisProducer && 'exclusivite'.indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }
        if (this.isOutdated(item.minWishedDeliveryDate) && 'retard'.indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }
      }
      for (const item of this.kanbanDTO.waitingCarrierShipments) {
        //console.log(word, this.normalizeText(item.clientName), this.normalizeText(item.clientName).indexOf(word));
        if (this.normalizeText(item.clientName).indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (item.idShipment + '' === word) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (!item.clientChoosedThisProducer && 'degineo demande'.indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }

        if (item.clientChoosedThisProducer && 'exclusivite'.indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }
        if (this.isOutdated(item.minWishedDeliveryDate) && 'retard'.indexOf(word) >= 0) {
          if (!this.highlightedItems.includes(item)) this.highlightedItems.push(item);
        }
      }
    }
  }

  normalizeText(str) {
    return str
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  getOrderIds(shipment) {
    return shipment.orders.map((order) => order.idOrder).join(', ');
  }

  sort(list: any[]) {
    return list.sort((a, b) => {
      if (this.highlightedItems.includes(a) && !this.highlightedItems.includes(b)) {
        return -1;
      } else if (!this.highlightedItems.includes(a) && this.highlightedItems.includes(b)) {
        return 1;
      } else {
        if (a instanceof ReceivedOrderDTO) return a.wishedDeliveryDate > b.wishedDeliveryDate ? 1 : a.wishedDeliveryDate < b.wishedDeliveryDate ? -1 : 0;
        else if (a instanceof InPreparationOrderDTO)
          return a.wishedDeliveryDate > b.wishedDeliveryDate ? 1 : a.wishedDeliveryDate < b.wishedDeliveryDate ? -1 : 0;
        else if (a instanceof InProductionOrderDTO)
          return a.wishedDeliveryDate > b.wishedDeliveryDate ? 1 : a.wishedDeliveryDate < b.wishedDeliveryDate ? -1 : 0;
        else if (a instanceof InPreparationShipmentDTO)
          return a.minWishedDeliveryDate > b.minWishedDeliveryDate ? 1 : a.minWishedDeliveryDate < b.minWishedDeliveryDate ? -1 : 0;
        else if (a instanceof WaitingCarrierShipmentDTO)
          return a.minWishedDeliveryDate > b.minWishedDeliveryDate ? 1 : a.minWishedDeliveryDate < b.minWishedDeliveryDate ? -1 : 0;
      }
    });
  }
}
