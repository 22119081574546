<div class="container-fluid mt-5">
  <div class="row">
    <div class="col">
      <h3>Rôle</h3>
      <p class="mt-3">
        Le rôle détermine ce qui peut être vu et fait par l'utilisateur.<br />
        Vous pourrez par la suite changer le rôle d'un utilisateur à tout moment.
      </p>
      <ng-container *ngIf="role">
        <h3 class="mb-3">{{ role }}</h3>

        <ng-container [ngSwitch]="role">
          <ul *ngSwitchCase="RoleInCompany.PRODUCER_HOLDER">
            <li>Vous êtes le seul à pouvoir avoir ce rôle</li>
            <li>Vous avez tous les droits</li>
          </ul>

          <ul *ngSwitchCase="RoleInCompany.PRODUCER_ADMIN">
            <li>Accepter ou refuser toutes demandes</li>
            <li>Réaliser toutes les demandes et appeler un transporteur</li>
            <li>Consulter et réaliser un devis client</li>
            <li>Ajouter une machine, un consommable et inviter un client</li>
            <li>Consulter et modifier les paramètres des machines</li>
            <li>Consulter et extraire les documents administratifs</li>
            <li>Modifier les informations de {{ companyName }}</li>
            <li>Gérer l’équipe {{ companyName }}</li>
          </ul>

          <ul *ngSwitchCase="RoleInCompany.PRODUCER_ACCOUNTING">
            <li>Consulter toutes les demandes</li>
            <li class="text-light">Réaliser une demande et appeler un transporteur</li>
            <li class="text-light">Consulter et réaliser un devis client</li>
            <li>Ajouter une machine, un consommable et inviter un client</li>
            <li>Consulter et modifier les paramètres des machines</li>
            <li>Consulter et extraire les documents administratifs</li>
            <li>Modifier les informations de {{ companyName }}</li>
            <li class="text-light">Gérer son équipe</li>
          </ul>

          <ul *ngSwitchCase="RoleInCompany.PRODUCER_SUPERVISER">
            <li>Lancer en production une demande</li>
            <li>Réaliser les demandes de l'équipe et appeler un transporteur</li>
            <li class="text-light">Consulter et réaliser un devis client</li>
            <li>Ajouter un consommable</li>
            <li>Modifier les paramètres de disponibilité des machines</li>
            <li class="text-light">Consulter et extraire les documents administratifs (hormis Superviseur comptable)</li>
            <li class="text-light">Modifier les informations de {{ companyName }}</li>
            <li>Gérer son équipe</li>
          </ul>

          <ul *ngSwitchCase="RoleInCompany.PRODUCER_COLLABORATOR">
            <li class="text-light">Lancer en production une demande</li>
            <li>Réaliser les demandes de l’équipe et appeler un transporteur</li>
            <li class="text-light">Consulter et réaliser un devis client</li>
            <li>Ajouter un consommable</li>
            <li>Modifier les paramètres de disponibilité des machines</li>
            <li class="text-light">Consulter et extraire les documents administratifs</li>
            <li class="text-light">Modifier les informations de {{ companyName }}</li>
            <li class="text-light">Gérer son équipe</li>
          </ul>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
