import { Component, OnInit, Input, Inject, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ProducerUserDTO, RoleInCompany, ProducerUserService, AuthService } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-view-edit-user',
  templateUrl: './view-edit-user.component.html',
  styleUrls: ['./view-edit-user.component.scss'],
})
export class ViewEditUserComponent implements OnInit {
  @Input()
  user: ProducerUserDTO;
  @Input()
  loggedUserRole: RoleInCompany = null;
  @Output()
  updateRole: EventEmitter<RoleInCompany> = new EventEmitter<RoleInCompany>();
  @Output()
  reloadUser: EventEmitter<any> = new EventEmitter<any>();

  userCopy: ProducerUserDTO;

  roles: RoleInCompany[] = [
    RoleInCompany.PRODUCER_HOLDER,
    RoleInCompany.PRODUCER_ADMIN,
    RoleInCompany.PRODUCER_SUPERVISER,
    RoleInCompany.PRODUCER_COLLABORATOR,
    RoleInCompany.PRODUCER_ACCOUNTING,
  ];
  RoleInCompany: typeof RoleInCompany = RoleInCompany;

  loading: boolean = false;
  isSelf: boolean = false;
  editEmail: boolean = false;
  editJob: boolean = false;
  editRole: boolean = false;
  emailResent: boolean = false;

  constructor(@Inject(ProducerUserService) private producerUserService: ProducerUserService, @Inject(AuthService) private authService: AuthService) {}

  ngOnInit() {
    this.emitRole();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.authService.userBundle.du.idUser === this.user.idUser) this.isSelf = true;
    else this.isSelf = false;
    if (!changes.user.previousValue) {
      this.reload();
      return;
    } else if (JSON.parse(JSON.stringify(changes.user.previousValue)) !== JSON.parse(JSON.stringify(changes.user.currentValue))) this.reload();
    this.cancelEdit();
  }

  reload() {
    this.userCopy = new ProducerUserDTO();
    Object.assign(this.userCopy, this.user);
  }

  cancelEdit() {
    this.userCopy.job = this.user.job;
    this.userCopy.role = this.user.role;
    if (!this.user.accountCreationFinished) this.userCopy.email = this.user.email;
    this.editJob = false;
    this.editRole = false;
    this.editEmail = false;
    this.emitRole();
  }

  emitRole() {
    this.updateRole.emit(this.userCopy.role);
  }

  saveEditJob() {
    if (this.user.job !== this.userCopy.job) {
      this.loading = true;
      this.producerUserService.updateJob(this.user.idUser, this.userCopy.job).subscribe(
        (data) => {
          this.user.job = this.userCopy.job;
          this.editJob = false;
          this.loading = false;
        },
        (err) => {
          L.e(err);
          this.loading = false;
        }
      );
    } else this.editJob = false;
  }

  saveEditRole() {
    if (this.user.role !== this.userCopy.role) {
      this.loading = true;
      this.producerUserService.updateRole(this.user.idUser, this.userCopy.role).subscribe(
        (data) => {
          this.user.role = this.userCopy.role;
          this.editRole = false;
          this.loading = false;
        },
        (err) => {
          L.e(err);
          this.loading = false;
        }
      );
    } else this.editRole = false;
  }

  saveEditEmail() {
    if (this.user.email !== this.userCopy.email) {
      this.loading = true;

      this.producerUserService.updateEmail(this.user.idUser, this.userCopy.email).subscribe(
        (data) => {
          this.user.email = this.userCopy.email;
          this.editEmail = false;
          this.emailResent = true;
          this.loading = false;
        },
        (err) => {
          L.e(err);
          this.loading = false;
        }
      );
    } else this.editEmail = false;
  }

  resendEmail() {
    this.producerUserService.resendInvitationEmail(this.user.idUser).subscribe(
      (data) => {
        this.emailResent = true;
        this.loading = false;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  cancelInvitation() {
    this.loading = true;
    this.producerUserService.cancelInvitation(this.user.idUser).subscribe(
      (data) => {
        this.user = null;
        this.userCopy = null;
        this.reloadUser.emit();
        this.loading = false;
      },
      (err) => {
        L.e(err);
      }
    );
  }
  disableUser() {
    this.loading = true;
    this.producerUserService.disableUser(this.user.idUser).subscribe(
      (data) => {
        this.user.disabled = true;
        this.userCopy.disabled = true;
        this.reloadUser.emit();
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  enableUser() {
    this.loading = true;
    this.producerUserService.enableUser(this.user.idUser).subscribe(
      (data) => {
        this.user.disabled = false;
        this.userCopy.disabled = false;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  deleteUser() {
    this.loading = true;
    this.producerUserService.deleteUser(this.user.idUser).subscribe(
      (data) => {
        this.user = null;
        this.userCopy = null;
        this.reloadUser.emit();
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }
}
