<div class="row" *ngIf="loading">
  <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
</div>
<app-offer
  *ngIf="!loading"
  [legalStatus]="dto.legalStatus"
  [nbPrinterFixe]="dto.nbPrinter"
  [idPreviousPlan]="dto.idPlan"
  (previous)="accountCreationService.previous()"
  (planSelected)="planSelected($event)"
></app-offer>
