import { Component, OnInit, Input, EventEmitter, Output, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProducerSignupService, ProducerSignupStep8DTO } from 'degineo-common';
import { AccountCreationService } from '../account-creation.service';
import { L } from 'ic2-lib';
import { DegineoGmapsAddressInputComponent } from '../degineo-gmaps-address-input/degineo-gmaps-address-input.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-account-creation-step8-contract',
  templateUrl: './account-creation-step8-contract.component.html',
  styleUrls: ['./account-creation-step8-contract.component.scss'],
})
export class AccountCreationStep8ContractComponent implements OnInit {
  form: FormGroup = this.fb.group({
    engagment: [false, [Validators.requiredTrue]],
  });
  loading: boolean = true;
  saving: boolean = false;
  dto: ProducerSignupStep8DTO;
  formatAddress = DegineoGmapsAddressInputComponent.formatLineAddress;

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(ProducerSignupService) private producerSignupService: ProducerSignupService,
    @Inject(AccountCreationService) public accountCreationService: AccountCreationService,
    @Inject(NgbModal) private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.producerSignupService.getInfosStep8(this.accountCreationService.token).subscribe(
      (data) => {
        this.dto = data;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  submit() {
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }
    this.saving = true;
    this.producerSignupService.saveStep8(this.accountCreationService.token).subscribe(
      (data) => {
        this.accountCreationService.next();
        this.saving = false;
      },
      (err) => {
        this.saving = false;
        L.e(err);
      }
    );
  }

  showPopup(evt, content, canClose) {
    evt.preventDefault();
    this.modalService.open(content, { centered: true, backdrop: canClose ? true : 'static', keyboard: false }).result.then(
      (result) => {
        //`Closed with: ${result}`;
      },
      (reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
}
