<div class="container my-4">
  <div class="row" *ngIf="loading">
    <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
  </div>
  <div class="row mt-5" *ngIf="devis.length === 0">
    <div class="col text-center text-dark fs-4 fw-500">Vous n'avez aucun devis en attente.</div>
  </div>

  <div class="row">
    <div class="col">
      <table class="table table-striped table-center">
        <tr *ngFor="let d of devis">
          <td class="align-middle fw-700">N°&nbsp;{{ d.numDevisProducteur }}</td>
          <td class="align-middle">{{ d.clientCompanyRS }}</td>
          <td class="align-middle">Envoyé le {{ d.creationDate | date: 'dd/MM/yyyy' }}</td>
          <td class="align-middle">
            <ng-container [ngSwitch]="d.status">
              <span class="text-primary" *ngSwitchCase="DevisStatus.DEMANDE_ENVOYEE"> Nouvelle demande de devis</span>
              <a class="underline fw-500 text-medium" *ngSwitchCase="DevisStatus.NOUVEAU_DEVIS" routerLink="/devis/{{ d.idDevis }}/preview">
                Devis envoyé au client</a
              >
              <span class="text-warning" *ngSwitchCase="DevisStatus.DEVIS_EN_COURS_REVISION"> {{ d.status.lib }}</span>
              <a class="underline fw-500 text-medium" *ngSwitchCase="DevisStatus.DEVIS_REVISE" routerLink="/devis/{{ d.idDevis }}/preview">{{
                d.status.lib
              }}</a>
              <a class="underline fw-500 text-danger" *ngSwitchCase="DevisStatus.DEMANDE_REVISION_REFUSEE" routerLink="/devis/{{ d.idDevis }}/preview">
                Vous avez refusé cette demande de révision</a
              >
              <span class="text-danger" *ngSwitchCase="DevisStatus.DEMANDE_DEVIS_REFUSEE"> Vous avez refusé cette demande de devis</span>
              <span class="text-success" *ngSwitchCase="DevisStatus.DEVIS_ACCEPTE"> {{ d.status.lib }}</span>
              <a class="underline fw-500 text-danger" *ngSwitchCase="DevisStatus.DEVIS_REFUSE_PAR_CLIENT" routerLink="/devis/{{ d.idDevis }}/preview">
                Devis refusé</a
              >
            </ng-container>
          </td>
          <td class="align-middle">
            <ng-container [ngSwitch]="d.status">
              <button class="btn btn-primary w-100" *ngSwitchCase="DevisStatus.DEMANDE_ENVOYEE" routerLink="/devis/{{ d.idDevis }}">
                Traiter la demande
              </button>
              <button class="btn btn-warning w-100" *ngSwitchCase="DevisStatus.DEVIS_EN_COURS_REVISION" routerLink="/devis/{{ d.idDevis }}">
                Réviser le devis
              </button>
              <button class="btn btn-danger w-100" *ngSwitchCase="DevisStatus.DEMANDE_DEVIS_REFUSEE" routerLink="/devis/{{ d.idDevis }}">
                Voir le motif
              </button>
              <button class="btn btn-danger w-100" *ngSwitchCase="DevisStatus.DEVIS_REFUSE_PAR_CLIENT" routerLink="/devis/{{ d.idDevis }}">
                Voir le motif
              </button>
            </ng-container>
          </td>
          <td class="align-middle p-0 pr-2">
            <i
              class="far fa-times-circle pointer text-danger ml-2"
              (click)="deleteDevis(d)"
              ngbTooltip="Retirer de cette liste"
              *ngIf="isDeletable(d.status)"
            ></i>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
