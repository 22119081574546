import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, LegalStatus, Plan, ProducerPlanService } from 'degineo-common';
import { L } from 'ic2-lib';
import { SubscribeService } from './subscribe.service';
@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss'],
})
export class SubscribeComponent implements OnInit {
  SubscribeService: typeof SubscribeService = SubscribeService;
  constructor(public subscribeService: SubscribeService, public authService: AuthService, private router: Router) {
    if (authService.userBundle.subscribed) {
      router.navigate(['/']);
    }
  }

  ngOnInit(): void {}
}
