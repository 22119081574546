import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientInfosForKanbanPopupDTO } from 'degineo-common';

@Component({
  selector: 'app-client-infos-popup',
  templateUrl: './client-infos-popup.component.html',
  styleUrls: ['./client-infos-popup.component.scss'],
})
export class ClientInfosPopupComponent implements OnInit {
  data: ClientInfosForKanbanPopupDTO;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
