<div class="container my-4">
  <div class="row" *ngIf="loading">
    <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
  </div>

  <ng-container *ngIf="!loading">
    <div class="row mt-3 align-items-center">
      <div class="col-6">
        <button class="btn btn-secondary" routerLink="/devis/{{ devis.id }}">Retour</button>
        <button
          class="btn btn-success ml-2"
          [disabled]="sending"
          *ngIf="devis.status === DevisStatus.DEMANDE_ENVOYEE || devis.status === DevisStatus.DEVIS_EN_COURS_REVISION"
          (click)="sendDevis()"
        >
          Envoyer le devis <i class="fa fa-spinner spinning" *ngIf="sending"></i>
        </button>
      </div>
      <div class="col-6 text-right fs-5">
        <a [href]="devisUrl"><i class="fas fa-download"></i></a>
      </div>
    </div>
    <div class="row mt-3 p-3" style="height: 900px">
      <div class="col border rounded">
        <iframe [src]="iframeUrl" class="h-100 w-100 border-0"></iframe>
      </div>
    </div>
  </ng-container>
</div>
