import { Component, OnInit, Input } from '@angular/core';
import * as moment_ from 'moment';
import { ClientInfosForKanbanPopupDTO } from 'degineo-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientInfosPopupComponent } from '../client-infos-popup/client-infos-popup.component';

@Component({
  selector: 'app-panel-header',
  templateUrl: './panel-header.component.html',
  styleUrls: ['./panel-header.component.scss'],
})
export class PanelHeaderComponent implements OnInit {
  @Input()
  id: number = null; //idOrder ou idShipment
  @Input()
  deadline: Date = null;
  @Input()
  sinceDate: Date = null;
  @Input()
  dateLabel: string = '...'; //Accepté
  @Input()
  expedition: boolean = false;
  @Input()
  clientChoosedThisProducer: boolean = false;
  @Input()
  crossMargin: boolean = true;
  @Input()
  set colorClass(str) {
    this.class = {};
    this.class[str] = true;
  }
  @Input()
  depuis: boolean = false;
  @Input()
  client: ClientInfosForKanbanPopupDTO;

  class = null;

  moment = moment_;
  isOutdated = PanelHeaderComponent.isOutdatedStatic;

  constructor(private modalService: NgbModal) {}

  static isOutdatedStatic(date: Date) {
    if (date === null || date === undefined) return false;
    const copiedDate = new Date(date.getTime());
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    copiedDate.setHours(0, 0, 0, 0);
    return copiedDate < now;
  }

  ngOnInit() {}

  openPopupInfos() {
    const modal = this.modalService.open(ClientInfosPopupComponent, {
      centered: true,
    });
    modal.componentInstance.data = this.client;
    return false;
  }
}
