import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProducerSignupStep9DTO, ProducerSubscriptionTunnelService } from 'degineo-common';
import { environment } from 'environments/environment';
import { L } from 'ic2-lib';
import { SubscribeService } from '../subscribe.service';

@Component({
  selector: 'app-subscribe-step6-bankaccount',
  templateUrl: './subscribe-step6-bankaccount.component.html',
  styleUrls: ['./subscribe-step6-bankaccount.component.scss'],
})
export class SubscribeStep6BankaccountComponent implements OnInit {
  @ViewChild('iban') iban: ElementRef;
  debug = environment.name !== 'PRODUCTION';
  form: FormGroup = this.fb.group({
    iban: [null, [Validators.required]],
    bic: [null, [Validators.required]],
    fakeBic: [null, [Validators.required]],
  });
  loading: boolean = true;
  saving: boolean = false;
  stripe: any;
  ibanStripe: any;
  dto: ProducerSignupStep9DTO;
  stripePaymentMethodId: string;

  constructor(
    private fb: FormBuilder,
    public subscribeService: SubscribeService,
    private producerSubscriptionTunnelService: ProducerSubscriptionTunnelService
  ) {
    this.stripe = Stripe(environment.stripePublishableKey);
  }

  ngOnInit() {
    this.producerSubscriptionTunnelService.getInfosStep6().subscribe(
      (data) => {
        this.loading = false;
        this.dto = data;
        this.form.patchValue({
          iban: data.iban,
          bic: data.bic,
        });
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  previous() {
    this.subscribeService.step = SubscribeService.STEP_5_CONTRACT;
  }

  ngAfterViewInit() {
    this.mountIban(this.iban.nativeElement);
  }

  mountIban(el) {
    const opts = {
      placeholder: '',
      supportedCountries: ['SEPA'],
      style: {
        base: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontSize: '16px',
          lineHeight: '1.5rem',
          color: 'rgb(49, 69, 89)',
        },
        invalid: {
          color: 'red',
        },
      },
    };
    const stripeElements = this.stripe.elements();
    this.ibanStripe = stripeElements.create('iban', opts);
    this.ibanStripe.mount(el);
    this.ibanStripe.addEventListener('change', (e) => {
      console.log(e);
      console.log(stripeElements, el, this);
    });
    console.log(stripeElements, el, this);
  }

  /*setupIntent:
            id: "seti_1Ga47UB8npfiVmwMwSyZ2YBK"
            object: "setup_intent"
            cancellation_reason: null
            client_secret: "seti_1Ga47UB8npfiVmwMwSyZ2YBK_secret_H8KnaK1BIKaFsBcknoNpZxVOYKMVqKC"
            created: 1587406404
            description: null
            last_setup_error: null
            livemode: false
            next_action: null
            payment_method: "pm_1Ga482B8npfiVmwMjabjvUEu"
            payment_method_types: ["sepa_debit"]
            status: "succeeded"
            usage: "off_session"
            */
  async submit(event) {
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }
    this.saving = true;
    this.stripe
      .confirmSepaDebitSetup(this.dto.stripeSetupIntentClientSecret, {
        payment_method: {
          sepa_debit: this.ibanStripe,
          billing_details: {
            name: this.dto.firstName + ' ' + this.dto.lastName,
            email: this.dto.email,
          },
        },
      })
      .catch((error) => {
        console.error(error);
      })
      .then((result) => {
        if (result.error) {
          console.error(result.error);
        } else {
          this.stripePaymentMethodId = result.setupIntent.payment_method;
          this.producerSubscriptionTunnelService.saveStep6(this.form.value.iban, this.form.value.bic, this.stripePaymentMethodId).subscribe(
            (data) => {
              this.subscribeService.step = SubscribeService.STEP_7_SIGN;
              this.saving = false;
            },
            (err) => {
              this.saving = false;
              L.e(err);
            }
          );
        }
      });
  }
}
