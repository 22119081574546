import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Inject, PLATFORM_ID, LOCALE_ID } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserCookiesModule } from 'ngx-utils-cookies-port';
import { CookiesService } from 'ngx-utils-cookies-port';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ic2Module, IRPC, RpcError } from 'ic2-lib';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService, DegineoCommonModule, ForgotPwdPopupComponent } from 'degineo-common';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'environments/environment';
import { LoginComponent } from './Auth/login/login.component';
import { RouterModule, Router } from '@angular/router';
import { ProducerLayoutComponent } from './producer-layout/producer-layout.component';
import { ProducerRoutingModule } from './producer-routing.module';
import { AccountCreationComponent } from './Pages/account-creation/account-creation.component';
import { AccountCreationStep1AccountComponent } from './Pages/account-creation/account-creation-step1-account/account-creation-step1-account.component';
import { AccountCreationStep3SiretComponent } from './Pages/account-creation/account-creation-step3-siret/account-creation-step3-siret.component';
import { AccountCreationStep4CompanyComponent } from './Pages/account-creation/account-creation-step4-company/account-creation-step4-company.component';
import { AccountCreationStep5ShippingComponent } from './Pages/account-creation/account-creation-step5-shipping/account-creation-step5-shipping.component';
import { AccountCreationStep2UserComponent } from './Pages/account-creation/account-creation-step2-user/account-creation-step2-user.component';
import { AccountCreationStep7PlanComponent } from './Pages/account-creation/account-creation-step7-plan/account-creation-step7-plan.component';
import { AccountCreationStep8ContractComponent } from './Pages/account-creation/account-creation-step8-contract/account-creation-step8-contract.component';
import { AccountCreationStep9BankaccountComponent } from './Pages/account-creation/account-creation-step9-bankaccount/account-creation-step9-bankaccount.component';
import { AccountCreationStep10SignComponent } from './Pages/account-creation/account-creation-step10-sign/account-creation-step10-sign.component';
import { AccountCreationStep11BravoComponent } from './Pages/account-creation/account-creation-step11-bravo/account-creation-step11-bravo.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { DegineoGmapsAddressInputComponent } from './Pages/account-creation/degineo-gmaps-address-input/degineo-gmaps-address-input.component';
import { MyPrintersComponent } from './Pages/my-printers/my-printers.component';
import { MyPrintersItemComponent } from './Pages/my-printers/my-printers-item/my-printers-item.component';
import { MyPrintersConsumablesComponent } from './Pages/my-printers/my-printers-consumables/my-printers-consumables.component';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { MyPrintersProducerMarginComponent } from './Pages/my-printers/my-printers-producer-margin/my-printers-producer-margin.component';
import { MyPrintersPiecesPricesComponent } from './Pages/my-printers/my-printers-pieces-prices/my-printers-pieces-prices.component';
import { MyPrintersManageConsumablesComponent } from './Pages/my-printers/my-printers-manage-consumables/my-printers-manage-consumables.component';
import { MyOrdersComponent } from './Pages/my-orders/my-orders.component';
import { RightPanelComponent } from './Pages/my-orders/right-panel/right-panel.component';
import { OrderItemComponent } from './Pages/my-orders/order-item/order-item.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { ReceivedOrderPanelComponent } from './Pages/my-orders/received-order-panel/received-order-panel.component';
import { InPreparationOrderPanelComponent } from './Pages/my-orders/in-preparation-order-panel/in-preparation-order-panel.component';
import { InProductionOrderPanelComponent } from './Pages/my-orders/in-production-order-panel/in-production-order-panel.component';
import { IntercomModule, Intercom } from 'ng-intercom';
import { MyPackagesComponent } from './Pages/my-packages/my-packages.component';
import { ProducerDevisListPageComponent } from './Pages/devis/producer-devis-list-page/producer-devis-list-page.component';
import { ProducerDevisPreviewPageComponent } from './Pages/devis/producer-devis-preview-page/producer-devis-preview-page.component';
import { ProducerDevisPageComponent } from './Pages/devis/producer-devis-page/producer-devis-page.component';
import { ProducerDevisRefusalComponent } from './Pages/devis/producer-devis-refusal/producer-devis-refusal.component';
import { NgxMaskModule } from 'ngx-mask';
import { FabricationOrderPopupComponent } from './Pages/my-orders/fabrication-order-popup/fabrication-order-popup.component';
import { InPreparationShipmentPanelComponent } from './Pages/my-orders/in-preparation-shipping-panel/in-preparation-shipment-panel.component';
import { ShipmentPreparationStepComponent } from './Pages/my-orders/in-preparation-shipping-panel/shipment-preparation-step/shipment-preparation-step.component';
import { PackagePreparationStepComponent } from './Pages/my-orders/in-preparation-shipping-panel/package-preparation-step/package-preparation-step.component';
import { VerificationPreparationStepComponent } from './Pages/my-orders/in-preparation-shipping-panel/verification-preparation-step/verification-preparation-step.component';
import { PanelDetailComponent } from './Pages/my-orders/panel-detail/panel-detail.component';
import { PanelHeaderComponent } from './Pages/my-orders/panel-header/panel-header.component';
import { ShipmentProducerComponent } from './Pages/my-orders/waiting-for-shipping/shipment-producer/shipment-producer.component';
import { ShipmentCarrierComponent } from './Pages/my-orders/waiting-for-shipping/shipment-carrier/shipment-carrier.component';
import { WaitingForShippingPanelComponent } from './Pages/my-orders/waiting-for-shipping/waiting-for-shipping-panel.component';
import { DashboardComponent } from './Pages/dashboard/dashboard.component';
import { PanelAddressComponent } from './Pages/my-orders/panel-address/panel-address.component';
import { MyCompanyInfosComponent } from './Pages/my-company-infos/my-company-infos.component';
import { MyProfileComponent } from './Pages/my-profile/my-profile.component';
import { ConfirmEmailPopupComponent } from './Pages/my-profile/confirm-email-popup/confirm-email-popup.component';
import { MyInvoicesComponent } from './Pages/administrative/my-invoices/my-invoices.component';
import { PendingPaymentsComponent } from './Pages/administrative/pending-payments/pending-payments.component';
import { ClientInfosPopupComponent } from './Pages/my-orders/client-infos-popup/client-infos-popup.component';
import { MyClientsComponent } from './Pages/my-clients/my-clients.component';
import { ImportClientPopupComponent } from './Pages/my-clients/import-client-popup/import-client-popup.component';
import { MySubscriptionComponent } from './Pages/my-subscription/my-subscription.component';
import { UsersComponent } from './Pages/users/users.component';
import { RolesComponent } from './Pages/users/roles/roles.component';
import { ViewEditUserComponent } from './Pages/users/view-edit-user/view-edit-user.component';
import { AddUserComponent } from './Pages/users/add-user/add-user.component';
import { RegisterComponent } from './Auth/register/register.component';
import { AccountCreationStep6PrintersComponent } from './Pages/account-creation/account-creation-step6-printers/account-creation-step6-printers.component';
import { OfferComponent } from '../../../site-vitrine/src/app/offer/offer.component';
import { EvolutionAbonnementComponent } from './Pages/evolution-abonnement/evolution-abonnement.component';
import { ValidationEmailComponent } from './Pages/validation-email/validation-email.component';
import { TakeControlComponent } from './Pages/take-control/take-control.component';
import { TakeControlWelcomeComponent } from './Pages/take-control/take-control-welcome/take-control-welcome.component';
import { TakeControlStep1UserComponent } from './Pages/take-control/take-control-step1-user/take-control-step1-user.component';
import { TakeControlStep2BusinesstypeComponent } from './Pages/take-control/take-control-step2-businesstype/take-control-step2-businesstype.component';
import { TakeControlStep3SiretComponent } from './Pages/take-control/take-control-step3-siret/take-control-step3-siret.component';
import { TakeControlStep4CompanyComponent } from './Pages/take-control/take-control-step4-company/take-control-step4-company.component';
import { TakeControlStep5PrintersComponent } from './Pages/take-control/take-control-step5-printers/take-control-step5-printers.component';
import { SubscribeComponent } from './Pages/subscribe/subscribe.component';
import { SubscribeStep1PlanComponent } from './Pages/subscribe/subscribe-step1-plan/subscribe-step1-plan.component';
import { SubscribeStep2UserComponent } from './Pages/subscribe/subscribe-step2-user/subscribe-step2-user.component';
import { SubscribeStep3CompanyComponent } from './Pages/subscribe/subscribe-step3-company/subscribe-step3-company.component';
import { SubscribeStep4ShippingComponent } from './Pages/subscribe/subscribe-step4-shipping/subscribe-step4-shipping.component';
import { SubscribeStep5ContractComponent } from './Pages/subscribe/subscribe-step5-contract/subscribe-step5-contract.component';
import { SubscribeStep6BankaccountComponent } from './Pages/subscribe/subscribe-step6-bankaccount/subscribe-step6-bankaccount.component';
import { SubscribeStep7SignComponent } from './Pages/subscribe/subscribe-step7-sign/subscribe-step7-sign.component';
import { SubscribeStep8BravoComponent } from './Pages/subscribe/subscribe-step8-bravo/subscribe-step8-bravo.component';
import { PanelClientComponent } from './Pages/my-clients/panel-client/panel-client.component';
import { PanelInvitedComponent } from './Pages/my-clients/panel-invited/panel-invited.component';
import { MarqueBlancheManagmentComponent } from './Pages/marque-blanche-managment/marque-blanche-managment.component';
import { InvoicePanelComponent } from './Pages/administrative/my-invoices/invoice-panel/invoice-panel.component';
import { InvoiceShareComponent } from './Pages/administrative/invoice-share/invoice-share.component';
import { MyPrintersFinishComponent } from './Pages/my-printers/my-printers-finish/my-printers-finish.component';
import { DashboardWarningComponent } from './Pages/dashboard/dashboard-warning/dashboard-warning.component';
import { DashboardCountersComponent } from './Pages/dashboard/dashboard-counters/dashboard-counters.component';
import { DashboardPrintersComponent } from './Pages/dashboard/dashboard-printers/dashboard-printers.component';
import { DashboardFinancialComponent } from './Pages/dashboard/dashboard-financial/dashboard-financial.component';
import { DashboardStatsComponent } from './Pages/dashboard/dashboard-stats/dashboard-stats.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ProducerLayoutComponent,
    AccountCreationComponent,
    AccountCreationStep1AccountComponent,
    AccountCreationStep2UserComponent,
    AccountCreationStep3SiretComponent,
    AccountCreationStep4CompanyComponent,
    AccountCreationStep5ShippingComponent,
    AccountCreationStep6PrintersComponent,
    AccountCreationStep7PlanComponent,
    AccountCreationStep8ContractComponent,
    AccountCreationStep9BankaccountComponent,
    AccountCreationStep10SignComponent,
    AccountCreationStep11BravoComponent,
    DegineoGmapsAddressInputComponent,
    MyPrintersComponent,
    MyPrintersItemComponent,
    MyPrintersConsumablesComponent,
    MyPrintersProducerMarginComponent,
    MyPrintersPiecesPricesComponent,
    MyPrintersManageConsumablesComponent,
    MyOrdersComponent,
    RightPanelComponent,
    OrderItemComponent,
    ReceivedOrderPanelComponent,
    InPreparationOrderPanelComponent,
    InProductionOrderPanelComponent,
    MyPackagesComponent,
    ProducerDevisListPageComponent,
    ProducerDevisPreviewPageComponent,
    ProducerDevisPageComponent,
    ProducerDevisRefusalComponent,
    FabricationOrderPopupComponent,
    InPreparationShipmentPanelComponent,
    ShipmentPreparationStepComponent,
    PackagePreparationStepComponent,
    VerificationPreparationStepComponent,
    PanelDetailComponent,
    PanelHeaderComponent,
    ShipmentProducerComponent,
    ShipmentCarrierComponent,
    WaitingForShippingPanelComponent,
    DashboardComponent,
    PanelAddressComponent,
    MyCompanyInfosComponent,
    MyProfileComponent,
    ConfirmEmailPopupComponent,
    MyInvoicesComponent,
    PendingPaymentsComponent,
    ClientInfosPopupComponent,
    MyClientsComponent,
    ImportClientPopupComponent,
    MySubscriptionComponent,
    UsersComponent,
    RolesComponent,
    ViewEditUserComponent,
    AddUserComponent,
    RegisterComponent,
    OfferComponent,
    EvolutionAbonnementComponent,
    ValidationEmailComponent,
    TakeControlComponent,
    TakeControlStep1UserComponent,
    TakeControlStep2BusinesstypeComponent,
    TakeControlStep3SiretComponent,
    TakeControlStep4CompanyComponent,
    TakeControlStep5PrintersComponent,
    TakeControlWelcomeComponent,
    SubscribeComponent,
    SubscribeStep1PlanComponent,
    SubscribeStep2UserComponent,
    SubscribeStep3CompanyComponent,
    SubscribeStep4ShippingComponent,
    SubscribeStep5ContractComponent,
    SubscribeStep6BankaccountComponent,
    SubscribeStep7SignComponent,
    SubscribeStep8BravoComponent,
    PanelClientComponent,
    PanelInvitedComponent,
    MarqueBlancheManagmentComponent,
    InvoicePanelComponent,
    InvoiceShareComponent,
    MyPrintersFinishComponent,
    DashboardWarningComponent,
    DashboardCountersComponent,
    DashboardPrintersComponent,
    DashboardFinancialComponent,
    DashboardStatsComponent,
  ],
  imports: [
    BrowserModule,
    AngularMyDatePickerModule,
    BrowserCookiesModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgbModule,
    Ic2Module,
    FormsModule,
    ReactiveFormsModule,
    DegineoCommonModule,
    UiSwitchModule,
    GooglePlaceModule,
    NgxChartsModule, //needs BrowserAnimationsModule below
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gmapsApiKey,
    }),
    IntercomModule.forRoot({
      appId: environment.intercomAppId, // from your Intercom config
      updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
    }),
    //RouterModule.forRoot([{ path: 'test', component: LoginComponent }]),
    RouterModule,
    ProducerRoutingModule, //needs to be last
  ],
  providers: [
    { provide: NgbActiveModal, useValue: { fake: true } }, //feinte pour que my-profile-component marche quand il n'est pas utilisé en tant que modal
    DatePipe,
    { provide: LOCALE_ID, useValue: 'fr' },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    FabricationOrderPopupComponent,
    MyProfileComponent,
    ConfirmEmailPopupComponent,
    ForgotPwdPopupComponent,
    ClientInfosPopupComponent,
    ImportClientPopupComponent,
  ],
})
export class AppModule {
  constructor(
    @Inject(IRPC) public irpc: IRPC,
    router: Router,
    @Inject(PLATFORM_ID) private platform: Object,
    private cookiesService: CookiesService,
    public intercom: Intercom,
    authService: AuthService
  ) {
    if (isPlatformBrowser(this.platform)) {
      irpc.authToken = (<any>window).jwtToken;
      irpc.url = environment.rpcHost + 'rpc';
      if (this.irpc.authToken === null || this.irpc.authToken === undefined) {
        const token = cookiesService.get('token');
        //console.log(token);
        if (token === null) {
          return;
        }
        this.irpc.authToken = token;
      }
    } else {
      irpc.url = environment.ssrRpcHost + 'rpc';
    }
    //Auto load token from localStorage

    irpc.log = environment.name !== 'PRODUCTION';

    irpc.interceptErrorHandlers.push((req, err) => {
      if (err.code === RpcError.NOT_LOGGED) {
        console.log('INTERCEPTED NOT LOGGED ERROR on ' + req.req.serviceDotMethod);
        authService.logout();
        router.navigate(['/connexion']);
        return true;
      }
      return false;
    });

    this.intercom.boot({
      app_id: environment.intercomAppId,
      /*
      alignment: 'left',
      horizontal_padding: 20,
      vertical_padding: 20,
      */
    });

    authService.loginEvent.subscribe((event) => {
      let name = event.userBundle.user.firstName + ' ' + event.userBundle.user.lastName;
      if (event.userBundle.user.firstName === null && event.userBundle.producerName !== null) {
        name = event.userBundle.producerName;
      }
      const data = {
        user_id: event.userBundle.user.idUser,
        email: event.userBundle.user.email,
        name: name,
        avatar: {
          type: 'avatar',
          image_url: environment.rpcHost + 'userLogo/' + event.userBundle.logoToken + '/logo.png',
        },
        company: {
          id: event.userBundle.idProducer,
          name: event.userBundle.producerName,
        },
      };
      console.log('updating intercom as logged in user', data, this.intercom);
      this.intercom.update(data);
    });

    /*

    //TODO add events to login(checklogin)/logout in auth.service.ts ? + remove all direct calls to loginservice.login 

    this.intercom.update({
      user_id: data.user.idUser,
      email: data.user.email,
      name: data.user.firstName+' '+data.user.lastName,
      avatar: {
        type: 'avatar',
        image_url: environment.rpcHost + 'userLogo/' + data.logoToken + '/logo.png',
      },
      company: {
        id: data.idProducer === 0 ? data.idProducer : data.client.id,
        name: data.producerName,
      },
    });
    */
    //this.intercom.show('Hello !');
  }
}
