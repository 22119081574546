<div class="container-fluid" *ngIf="user">
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col my-5">
          <h3 class="mb-2" style="font-size: 1.6rem">{{ user.lastName | uppercase }} {{ user.firstName | titlecase }}</h3>
          <h6 class="text-warning" *ngIf="!user.accountCreationFinished">En attente d'acceptation</h6>
        </div>
      </div>

      <div class="row">
        <div class="col-9">
          <label for="email">Adresse e-mail de {{ user.firstName | titlecase }}</label>
        </div>
        <div class="col-3 text-right" *ngIf="!user.accountCreationFinished && !isSelf">
          <a [routerLink]="null" *ngIf="!editEmail" (click)="editEmail = true">modifier</a>
          <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editEmail && !loading" (click)="saveEditEmail()"></i>
          <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editEmail && !loading" (click)="cancelEdit()"></i>
          <i class="fas fa-spinner spinning" *ngIf="editEmail && loading"></i>
        </div>
      </div>
      <div class="row mt-2 mb-5">
        <div class="col">
          <div class="px-2" *ngIf="user.accountCreationFinished">{{ user.email }}</div>
          <input
            *ngIf="!user.accountCreationFinished"
            [(ngModel)]="userCopy.email"
            class="form-control dashed-when-disabled"
            id="email"
            [disabled]="!editEmail"
          />
          <div class="text-success fs-2 mt-2" *ngIf="emailResent">
            Un e-mail a été renvoyé à {{ user.firstName | titlecase }} {{ user.lastName | uppercase }} à l'adresse suivante : {{ user.email }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-9">
          <label for="job">Intitulé du poste de {{ user.firstName | titlecase }}</label>
        </div>
        <div class="col-3 text-right" *ngIf="isSelf || (user.role !== RoleInCompany.PRODUCER_HOLDER && loggedUserRole.id <= user.role.id)">
          <a [routerLink]="null" *ngIf="!editJob" (click)="editJob = true">modifier</a>
          <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editJob && !loading" (click)="saveEditJob()"></i>
          <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editJob && !loading" (click)="cancelEdit()"></i>
          <i class="fas fa-spinner spinning" *ngIf="editJob && loading"></i>
        </div>
      </div>
      <div class="row mt-2 mb-5">
        <div class="col" *ngIf="isSelf || (user.role !== RoleInCompany.PRODUCER_HOLDER && loggedUserRole.id <= user.role.id); else afficheJob">
          <input [(ngModel)]="userCopy.job" class="form-control dashed-when-disabled" id="job" [disabled]="!editJob" />
        </div>
        <ng-template #afficheJob>
          <div class="col">
            <div class="px-2">{{ user.job }}</div>
          </div>
        </ng-template>
      </div>

      <div class="row">
        <div class="col-9">
          <label for="role">Rôle de {{ user.firstName | titlecase }}</label>
        </div>
        <div
          class="col-3 text-right"
          *ngIf="
            user.role !== RoleInCompany.PRODUCER_HOLDER &&
            !isSelf &&
            loggedUserRole.id <= user.role.id &&
            (loggedUserRole !== RoleInCompany.PRODUCER_SUPERVISER || user.role !== RoleInCompany.PRODUCER_ACCOUNTING)
          "
        >
          <a [routerLink]="null" *ngIf="!editRole" (click)="editRole = true">modifier</a>
          <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editRole && !loading" (click)="saveEditRole()"></i>
          <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editRole && !loading" (click)="cancelEdit()"></i>
          <i class="fas fa-spinner spinning" *ngIf="editRole && loading"></i>
        </div>
      </div>
      <div class="row mt-2 mb-5">
        <div class="col">
          <div
            class="px-2"
            *ngIf="
              user.role === RoleInCompany.PRODUCER_HOLDER ||
              loggedUserRole.id > user.role.id ||
              (loggedUserRole === RoleInCompany.PRODUCER_SUPERVISER && userCopy.role === RoleInCompany.PRODUCER_ACCOUNTING)
            "
          >
            {{ user.role.lib }}
          </div>
          <select
            *ngIf="
              user.role !== RoleInCompany.PRODUCER_HOLDER &&
              loggedUserRole.id <= user.role.id &&
              (loggedUserRole !== RoleInCompany.PRODUCER_SUPERVISER || user.role !== RoleInCompany.PRODUCER_ACCOUNTING)
            "
            class="form-control dashed-when-disabled"
            id="role"
            [(ngModel)]="userCopy.role"
            [disabled]="!editRole"
            (change)="emitRole()"
          >
            <ng-container *ngFor="let role of roles">
              <option
                *ngIf="
                  role !== RoleInCompany.PRODUCER_HOLDER &&
                  loggedUserRole.id <= role.id &&
                  (loggedUserRole !== RoleInCompany.PRODUCER_SUPERVISER || role !== RoleInCompany.PRODUCER_ACCOUNTING)
                "
                [ngValue]="role"
              >
                {{ role.lib }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>

      <div class="mb-3">
        <button
          *ngIf="
            !user.disabled &&
            user.accountCreationFinished &&
            user.role !== RoleInCompany.PRODUCER_HOLDER &&
            !isSelf &&
            loggedUserRole.id <= user.role.id &&
            (loggedUserRole !== RoleInCompany.PRODUCER_SUPERVISER || user.role !== RoleInCompany.PRODUCER_ACCOUNTING)
          "
          class="btn btn-secondary w-100 px-5"
          (click)="disableUser()"
        >
          <i class="fas fa-pause mr-3"></i>Désactiver temporairement cet utilisateur
        </button>
        <button *ngIf="user.disabled && user.accountCreationFinished" class="btn btn-success w-100 px-5" (click)="enableUser()">
          <i class="fas fa-play mr-3"></i>Activer cet utilisateur
        </button>
      </div>
      <div
        class="mb-3"
        *ngIf="
          user.accountCreationFinished &&
          user.role !== RoleInCompany.PRODUCER_HOLDER &&
          !isSelf &&
          loggedUserRole.id <= user.role.id &&
          (loggedUserRole !== RoleInCompany.PRODUCER_SUPERVISER || user.role !== RoleInCompany.PRODUCER_ACCOUNTING)
        "
      >
        <div class="text-danger text-center pointer hover-underline fw-500 w-100 px-5" (click)="deleteUser()">
          <i class="fas fa-trash-alt mr-3"></i>Supprimer définitivement cet utilisateur
        </div>
      </div>
      <div class="mb-3" *ngIf="!user.accountCreationFinished">
        <button class="btn btn-primary w-100 px-5" (click)="resendEmail()">Renvoyer un e-mail d'invitation</button>
      </div>
      <div class="mb-3" *ngIf="!user.accountCreationFinished">
        <button class="btn btn-danger w-100 px-5" (click)="cancelInvitation()">Annuler cette invitation</button>
      </div>
    </div>
  </div>
</div>
