import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientDevisService } from 'degineo-common';
import { ProducerDevisDTO, DevisStatus } from 'degineo-common';
import { L } from 'ic2-lib';
import { ProducerDevisService } from 'degineo-common';

@Component({
  selector: 'app-producer-devis-refusal',
  templateUrl: './producer-devis-refusal.component.html',
  styleUrls: ['./producer-devis-refusal.component.scss'],
})
export class ProducerDevisRefusalComponent implements OnInit, OnDestroy {
  sub: Subscription;
  success: boolean;
  dto: ProducerDevisDTO;
  reason: string;
  DevisStatus: typeof DevisStatus = DevisStatus;

  constructor(
    @Inject(ProducerDevisService) public producerDevisService: ProducerDevisService,
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.producerDevisService.getDevis(+params['id']).subscribe(
        (data) => {
          this.dto = data;
        },
        (error) => {
          L.e(error);
        }
      );
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  sendRefusal() {
    if (this.reason === null || this.reason === undefined || this.reason.length === 0) return;
    this.producerDevisService.refuseDevis(this.dto.devis.id, this.reason).subscribe(
      (data) => {
        this.success = true;
        setTimeout(() => {
          this.router.navigate(['/devis']);
        }, 2000);
      },
      (error) => {
        L.e(error);
      }
    );
  }
}
