<div class="container my-4">
  <degineo-my-table [rows]="data" (rowClick)="tableRowClick($event)" [hoverable]="false" [pointer]="false">
    <degineo-my-column [disableOrder]="true" name="Client">
      <td *cv="let r" class="text-nowrap text-center align-middle">
        {{ r.client }}
      </td>
    </degineo-my-column>
    <degineo-my-column [disableOrder]="true" name="Référence">
      <td *cv="let r" class="text-nowrap text-center align-middle">
        {{ r.referenceInvoice }}
      </td>
    </degineo-my-column>
    <degineo-my-column [disableOrder]="true" name="Montant dû">
      <td *cv="let r" class="text-nowrap text-center align-middle">
        {{ (r.useVAT ? r.totalTTC : r.totalHT) | currency: 'EUR':'symbol':'1.2-2':'fr' }} {{ r.useVAT ? 'TTC' : 'HT' }}
      </td>
    </degineo-my-column>
    <degineo-my-column [disableOrder]="true" name="Date d'échéance">
      <td *cv="let r" class="text-nowrap text-center align-middle">
        {{ r.date | date: 'dd/MM/yyyy' }}
      </td>
    </degineo-my-column>
    <degineo-my-column [disableOrder]="true" name="Virement effectué">
      <td *cv="let r" class="text-nowrap text-center align-middle">
        <span class="text-success" *ngIf="r.paidByClient">Oui</span>
        <span class="text-danger" *ngIf="isOutdated(r.date) && !r.paidByClient">En retard</span>
        <span *ngIf="!isOutdated(r.date) && !r.paidByClient">À venir</span>
      </td>
    </degineo-my-column>
    <degineo-my-column [disableOrder]="true" align="center" name="Valider le paiement">
      <td *cv="let r" class="text-nowrap text-center align-middle">
        <button class="btn btn-primary ml-5 mr-3" (click)="paymentReceived(r)" [style.visibility]="r.invoiceId !== 0 ? 'visible' : 'hidden'">
          Paiement reçu
        </button>
      </td>
    </degineo-my-column>
  </degineo-my-table>
</div>
