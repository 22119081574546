import { Component, OnInit, Output, EventEmitter, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.scss'],
})
export class RightPanelComponent implements OnInit {
  @Output()
  close: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  panelSize: string = '41rem';

  @HostBinding('class.open')
  @Input()
  open: string;

  @HostBinding('style.left')
  get left() {
    return this.open ? 'calc(100vw - ' + this.panelSize + ')' : 'calc(100%)';
  }
  @HostBinding('style.right')
  get right() {
    return this.open ? '0px' : 'calc(-' + this.panelSize + ')';
  }

  constructor() {}

  ngOnInit() {
    console.log(this);
  }
}
