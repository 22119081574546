import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BusinessType, ProducerTakeControlService } from 'degineo-common';
import { L } from 'ic2-lib';
import { TakeControlService } from '../take-control.service';

@Component({
  selector: 'app-take-control-step2-businesstype',
  templateUrl: './take-control-step2-businesstype.component.html',
  styleUrls: ['./take-control-step2-businesstype.component.scss'],
})
export class TakeControlStep2BusinesstypeComponent implements OnInit {
  BusinessType: typeof BusinessType = BusinessType;
  constructor(public takeControlService: TakeControlService, private producerTakeControlService: ProducerTakeControlService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      console.log(params);
      if (params.idProducer) {
        this.producerTakeControlService.getBusinessType(+params['idProducer']).subscribe(
          (data) => {
            this.takeControlService.step2form.patchValue({
              businessType: data,
            });
          },
          (error) => {
            L.e(error);
          }
        );
      }
    });
  }

  submit() {
    if (!this.takeControlService.step2form.valid) {
      console.log(this.takeControlService.step2form);
      return;
    }
    this.takeControlService.step = TakeControlService.STEP_3_SIRET;
  }

  previous() {
    this.takeControlService.step = TakeControlService.STEP_1_USER;
  }
}
