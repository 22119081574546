import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent, AuthGuard, SubscribedAuthGuard, AutologComponent } from 'degineo-common';
import { LoginComponent } from './Auth/login/login.component';
import { ForgotPwdComponent } from 'degineo-common';
import { ForgotPwdPopupComponent } from 'degineo-common';
import { ProducerLayoutComponent } from './producer-layout/producer-layout.component';
import { AccountCreationComponent } from './Pages/account-creation/account-creation.component';
import { MyPrintersComponent } from './Pages/my-printers/my-printers.component';
import { MyOrdersComponent } from './Pages/my-orders/my-orders.component';
import { MyPackagesComponent } from './Pages/my-packages/my-packages.component';
import { ProducerDevisListPageComponent } from './Pages/devis/producer-devis-list-page/producer-devis-list-page.component';
import { ProducerDevisPageComponent } from './Pages/devis/producer-devis-page/producer-devis-page.component';
import { ProducerDevisPreviewPageComponent } from './Pages/devis/producer-devis-preview-page/producer-devis-preview-page.component';
import { ProducerDevisRefusalComponent } from './Pages/devis/producer-devis-refusal/producer-devis-refusal.component';
import { DashboardComponent } from './Pages/dashboard/dashboard.component';
import { MyCompanyInfosComponent } from './Pages/my-company-infos/my-company-infos.component';
import { MyProfileComponent } from './Pages/my-profile/my-profile.component';
import { environment } from 'environments/environment';
import { MyInvoicesComponent } from './Pages/administrative/my-invoices/my-invoices.component';
import { PendingPaymentsComponent } from './Pages/administrative/pending-payments/pending-payments.component';
import { MyClientsComponent } from './Pages/my-clients/my-clients.component';
import { MySubscriptionComponent } from './Pages/my-subscription/my-subscription.component';
import { ProducerDisplayComponent, ProducerResolverService, ProducerEditComponent } from 'degineo-common';
import { UsersComponent } from './Pages/users/users.component';
import { RegisterComponent } from './Auth/register/register.component';
import { EvolutionAbonnementComponent } from './Pages/evolution-abonnement/evolution-abonnement.component';
import { ValidationEmailComponent } from './Pages/validation-email/validation-email.component';
import { ValidationEmailResolverService } from './Pages/validation-email/validation-email-resolver.service';
import { TakeControlComponent } from './Pages/take-control/take-control.component';
import { TakeControlWelcomeComponent } from './Pages/take-control/take-control-welcome/take-control-welcome.component';
import { SubscribeComponent } from './Pages/subscribe/subscribe.component';
import { SubscribeStep1PlanComponent } from './Pages/subscribe/subscribe-step1-plan/subscribe-step1-plan.component';
import { MarqueBlancheManagmentComponent } from './Pages/marque-blanche-managment/marque-blanche-managment.component';
import { InvoiceShareComponent } from './Pages/administrative/invoice-share/invoice-share.component';

const routes: Routes = [
  {
    path: '',
    component: ProducerLayoutComponent,
    //canActivate: [AuthGuard],
    //canActivateChild: [AuthGuard],
    children: [
      //without auth
      {
        path: 'validation-email/:token',
        component: ValidationEmailComponent,
        resolve: { user: ValidationEmailResolverService },
      },

      //with auth
      {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuard, SubscribedAuthGuard],
      },
      {
        path: 'mes-imprimantes',
        component: MyPrintersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'mes-imprimantes/:idProducerPrinter',
        component: MyPrintersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'mes-imprimantes/:idProducerPrinter/:tab',
        component: MyPrintersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'mes-commandes',
        component: MyOrdersComponent,
        canActivate: [AuthGuard, SubscribedAuthGuard],
      },
      {
        path: 'mes-conditionnements',
        component: MyPackagesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'infos-entreprise',
        component: MyCompanyInfosComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'mon-profil', //uniquement pour le mode mobile, sinon c'est un popup
        component: MyProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'ma-page-degineo',
        component: ProducerDisplayComponent,
        data: { editMode: true, rpcHost: environment.rpcHost, svUrl: environment.svUrl },
        resolve: { producer: ProducerResolverService },
        canActivate: [AuthGuard],
      },
      {
        path: 'ma-page-degineo/edit',
        component: ProducerEditComponent,
        data: { editMode: true, rpcHost: environment.rpcHost, svUrl: environment.svUrl },
        resolve: { producer: ProducerResolverService },
        canActivate: [AuthGuard],
      },
      {
        path: 'mes-factures-de-vente',
        component: MyInvoicesComponent,
        canActivate: [AuthGuard, SubscribedAuthGuard],
      },
      {
        path: 'partage-des-factures',
        component: InvoiceShareComponent,
        canActivate: [AuthGuard, SubscribedAuthGuard],
      },
      {
        path: 'paiements-en-attente',
        component: PendingPaymentsComponent,
        canActivate: [AuthGuard, SubscribedAuthGuard],
      },
      {
        path: 'mon-portefeuille-client',
        component: MyClientsComponent,
        canActivate: [AuthGuard, SubscribedAuthGuard],
      },
      {
        path: 'mon-abonnement-degineo',
        component: MySubscriptionComponent,
        canActivate: [AuthGuard, SubscribedAuthGuard],
      },
      {
        path: 'evolution-abonnement/:type',
        component: EvolutionAbonnementComponent,
        canActivate: [AuthGuard],
      },
      { path: 'devis', component: ProducerDevisListPageComponent, canActivate: [AuthGuard, SubscribedAuthGuard] },
      { path: 'devis/:id', component: ProducerDevisPageComponent, canActivate: [AuthGuard] },
      { path: 'devis/:id/preview', component: ProducerDevisPreviewPageComponent, canActivate: [AuthGuard] },
      { path: 'devis/:id/refusal', component: ProducerDevisRefusalComponent, canActivate: [AuthGuard] },
      { path: 'utilisateurs', component: UsersComponent, canActivate: [AuthGuard, SubscribedAuthGuard] },
      {
        path: 'bienvenue',
        component: TakeControlWelcomeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'abonnement/:page',
        component: SubscribeStep1PlanComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'prise-de-commande',
        component: MarqueBlancheManagmentComponent,
        canActivate: [AuthGuard, SubscribedAuthGuard],
      },
    ],
  },
  {
    path: 'abonnement',
    component: SubscribeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'creation-compte/:token',
    component: AccountCreationComponent,
  },
  {
    path: 'creation-compte/create/:idPlan',
    component: AccountCreationComponent,
  },
  {
    path: 'inscription/:idProducer',
    component: TakeControlComponent,
  },
  {
    path: 'inscription',
    component: TakeControlComponent,
  },
  {
    path: 'invitation/:token',
    component: RegisterComponent,
  },
  {
    path: 'autolog/:token',
    component: AutologComponent,
  },
  { path: 'connexion', component: LoginComponent },
  {
    path: 'mdp-oublie/:token',
    component: ForgotPwdComponent,
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  providers: [],
})
export class ProducerRoutingModule {}
