import { Component, OnInit, Input, Host, Inject } from '@angular/core';
import { ProducerPrinterService, ProducerPrinterFullDTO, PPCOFullDTO, AuthService, RoleInCompany } from 'degineo-common';
import { MyPrintersComponent, ManageConsumableItem } from '../my-printers.component';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-my-printers-manage-consumables',
  templateUrl: './my-printers-manage-consumables.component.html',
  styleUrls: ['./my-printers-manage-consumables.component.scss'],
})
export class MyPrintersManageConsumablesComponent implements OnInit {
  localCopy: ProducerPrinterFullDTO[] = null;
  hasRight: boolean;

  constructor(
    @Host() public myPrintersComponent: MyPrintersComponent,
    @Inject(ProducerPrinterService) private producerPrinterService: ProducerPrinterService,
    @Inject(AuthService) private authService: AuthService
  ) {
    this.hasRight = this.authService.userBundle.role.id <= RoleInCompany.PRODUCER_ACCOUNTING.id;
  }

  ngOnInit() {}

  delete(consumable: ManageConsumableItem) {
    console.log('del', consumable);
    if (
      !confirm(
        'Êtes vous sûr de vouloir supprimer le consommable : ' +
          consumable.brand +
          ' ' +
          consumable.name +
          ' ' +
          consumable.color +
          ' de toutes vos imprimantes ?'
      )
    )
      return;
    this.producerPrinterService.massDeleteConsumable(consumable.idConsumableOption).subscribe(
      (data) => {
        this.myPrintersComponent.reload();
      },
      (err) => {
        L.e(err);
      }
    );
  }

  onChange(consumable: ManageConsumableItem, active: boolean) {
    console.log(consumable, active);
    this.producerPrinterService.massChangeActiveStatusConsumable(consumable.idConsumableOption, active).subscribe(
      (data) => {
        this.myPrintersComponent.reload();
      },
      (err) => {
        L.e(err);
      }
    );
  }
}
