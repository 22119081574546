import { Component, OnInit } from '@angular/core';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'app-take-control-welcome',
  templateUrl: './take-control-welcome.component.html',
  styleUrls: ['./take-control-welcome.component.scss'],
})
export class TakeControlWelcomeComponent implements OnInit {
  constructor(private intercom: Intercom) {}

  ngOnInit(): void {}

  openChat() {
    this.intercom.show();
  }
}
