<i class="fas fa-spinner spinning" *ngIf="invoice === null"></i>
<div class="container-fluid pb-5" *ngIf="invoice !== null">
  <div class="row fw-500 fs-2 mt-2 text-primary">
    <div class="col">Facture N°&nbsp;{{ invoice.invoiceId }}</div>
    <div class="col text-right mr-5">
      Montant {{ authService.userBundle.useVAT ? 'TTC' : 'HT' }} :
      {{ (authService.userBundle.useVAT ? invoice.totalTTC : invoice.totalHT) | currency: 'EUR':'symbol':'1.2-2':'fr' }}
    </div>
  </div>
  <div class="row mt-3 fs-4">
    <div class="col fw-700" *ngIf="!invoice.producerSelected">Demande Degineo</div>
    <div class="col fw-700" *ngIf="invoice.producerSelected">
      {{ invoice.client }}
      <!--<a [routerLink]="null" class="ml-2" (click)="openPopupInfos()"><i class="fas fa-info-circle"></i></a>-->
    </div>
  </div>
  <div class="row mt-3">
    <div class="col text-success fw-500" *ngIf="invoice.status === InvoiceProducerStatus.PAYMENT_OK">
      <i class="fas fa-check-circle"></i> {{ invoice.status }} <span class="fs-2 fw-400">(payé le&nbsp;: {{ invoice.datePaid | date: 'dd/MM/yyyy' }})</span>
    </div>
    <div class="col text-warning fw-500" *ngIf="invoice.status === InvoiceProducerStatus.DELAYED_PAYMENT_WAITING">
      <i class="fas fa-clock"></i> {{ invoice.status }} <span class="fs-2 fw-400">(prévu le&nbsp;: TODO)</span>
    </div>
    <div class="col text-danger fw-500" *ngIf="invoice.status === InvoiceProducerStatus.LATE_PAYMENT">
      <i class="fas fa-exclamation-circle"></i> {{ invoice.status }} <span class="fs-2 fw-400">(dû le&nbsp;: {{ invoice.dueDate | date: 'dd/MM/yyyy' }})</span>
    </div>
    <div class="col text-primary fw-500" *ngIf="invoice.status === InvoiceProducerStatus.WAITING_PAYMENT">
      <i class="fas fa-clock"></i> {{ invoice.status }} <span class="fs-2 fw-400">(prévu le&nbsp;: {{ invoice.dueDate | date: 'dd/MM/yyyy' }})</span>
    </div>
    <div class="col text-primary fw-500" *ngIf="invoice.status === InvoiceProducerStatus.ORDER_PENDING">
      <i class="fas fa-sync-alt"></i> {{ invoice.status }} <span class="fs-2 fw-400">(prévu le&nbsp;: {{ invoice.dueDate | date: 'dd/MM/yyyy' }})</span>
    </div>
    <div class="col text-medium fw-500" *ngIf="invoice.status === InvoiceProducerStatus.INVOICE_CANCELLED">
      <i class="fas fa-times-circle"></i> {{ invoice.status }}
      <span class="fs-2 fw-400">(annulée le&nbsp;: {{ invoice.dateCreatedCancelInvoice | date: 'dd/MM/yyyy' }})</span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col"><span class="fw-700">Méthode de paiement&nbsp;:</span> {{ invoice.virementType }}</div>
  </div>
  <div class="row mt-3">
    <div class="col"><span class="fw-700">Date de facturation&nbsp;:</span> {{ invoice.dateCreated | date: 'dd/MM/yyyy' }}</div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <hr style="border-width: 3px" />
    </div>
  </div>

  <div class="row" *ngIf="invoice.orders.length === 1">
    <div class="col fs-4">
      <span class="fw-700">Nom de la pièce&nbsp;:</span> {{ order.orderName }} <span class="fs-2">(id&nbsp;:&nbsp;{{ order.orderId }})</span>
    </div>
  </div>
  <div class="row mb-2" *ngIf="invoice.orders.length > 1">
    <div class="col text-right text-secondary fs-4 fw-700">{{ invoice.orders.length }} pièces au total</div>
  </div>
  <div class="row" *ngIf="invoice.orders.length > 1">
    <div class="col fs-4 form-inline d-flex">
      <span class="fw-700">Nom de la pièce&nbsp;:</span>
      <select class="form-control ml-2 flex-grow-1" [(ngModel)]="order">
        <option [ngValue]="o" *ngFor="let o of invoice.orders">{{ o.orderName }} (id&nbsp;:&nbsp;{{ o.orderId }})</option>
      </select>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-4">
      <degineo-preview-modele
        style="display: block; width: 200px"
        modelUrl="{{ rpcHost }}orderModele3D/{{ order.token3DModelFinal }}/{{ order.filenameFinal }}"
        [meshColor]="'#' + order.colorHex"
      ></degineo-preview-modele>
    </div>
    <div class="col-8">
      <div class="container-fluid d-flex flex-column h-100 justify-content-around">
        <div class="row">
          <div class="col"><span class="fw-700">Nombre d'exemplaires&nbsp;:</span> {{ order.quantity }}</div>
        </div>

        <div class="row">
          <div class="col">
            <span class="fw-700">Taille&nbsp;:</span> {{ order.sizeXmm | number: '.0-2':'fr-FR' }} x {{ order.sizeYmm | number: '.0-2':'fr-FR' }} x
            {{ order.sizeZmm | number: '.0-2':'fr-FR' }} mm
          </div>
        </div>

        <div class="row">
          <div class="col"><span class="fw-700">Couleur&nbsp;:</span> {{ order.colorName }}</div>
        </div>

        <div class="row">
          <div class="col"><span class="fw-700">Finition&nbsp;:</span> {{ order.finishName }}</div>
        </div>

        <div class="row">
          <div class="col">
            <span class="fw-700">Expédié par&nbsp;: </span>
            <ng-container *ngIf="order.shipmentCreated">
              <span *ngIf="order.producerDeliver">Nos propres moyens</span>
              <img *ngIf="!order.producerDeliver" alt="Degineo" class="ml-1" style="height: 1rem" src="assets/header/logo_degineo_bleu_vert.svg" />
            </ng-container>
            <ng-container *ngIf="!order.shipmentCreated"> --- </ng-container>
          </div>
        </div>

        <app-panel-address *ngIf="order.shippingAddress !== null" [address]="order.shippingAddress"></app-panel-address>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-10 offset-1"><hr /></div>
  </div>

  <div class="row mt-3">
    <div class="col"><span class="fw-700">Imprimé sur&nbsp;:</span> {{ order.printerBrand }} - {{ order.printerName }}</div>
  </div>
  <div class="row mt-3">
    <div class="col"><span class="fw-700">Consommable utilisé&nbsp;:</span> {{ order.consumableName }}</div>
  </div>
  <div class="row mt-3" *ngIf="!order.dental">
    <div class="col">
      <span class="fw-700">Consommation matière&nbsp;:</span> {{ order.materialQuantityPerPiece | number: '.2':'fr-FR' }} cm<sup>3</sup> par pièce
    </div>
  </div>
  <div class="row mt-3" *ngIf="!order.dental">
    <div class="col"><span class="fw-700">Utilisation machine&nbsp;:</span> {{ order.machineUsagePerPiece | number: '.2':'fr-FR' }} % par pièce</div>
  </div>

  <div class="row mt-3">
    <div class="col-10 offset-1"><hr /></div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <span class="fw-700">Accepté par&nbsp;:</span> {{ order.userProducerAccepted !== null ? order.userProducerAccepted : '---' }}
      <span *ngIf="order.dateProducerAccepted !== null">({{ order.dateProducerAccepted | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <span class="fw-700">Préparé par&nbsp;:</span> {{ order.userStartPrinting !== null ? order.userStartPrinting : '---' }}
      <span *ngIf="order.dateStartPrinting !== null">({{ order.dateStartPrinting | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <span class="fw-700">Produit par&nbsp;:</span> {{ order.userShippingPreparation !== null ? order.userShippingPreparation : '---' }}
      <span *ngIf="order.dateShippingPreparation !== null">({{ order.dateShippingPreparation | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>
  <ng-container *ngIf="!order.producerDeliver">
    <div class="row mt-3">
      <div class="col">
        <span class="fw-700">Envoyé en conditionnement par&nbsp;:</span> {{ order.userPackagePreparation !== null ? order.userPackagePreparation : '---' }}
        <span *ngIf="order.datePackagePreparation !== null">({{ order.datePackagePreparation | date: 'dd/MM/yyyy HH:mm' }})</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <span class="fw-700">Colis préparé par&nbsp;:</span> {{ order.userShippingProgress !== null ? order.userShippingProgress : '---' }}
        <span *ngIf="order.dateShippingProgress !== null">({{ order.dateShippingProgress | date: 'dd/MM/yyyy HH:mm' }})</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <span class="fw-700">Transmis au transporteur Degineo par&nbsp;:</span>
        {{ order.userCarrierTransport !== null ? order.userCarrierTransport : '---' }}
        <span *ngIf="order.dateCarrierTransport !== null">({{ order.dateCarrierTransport | date: 'dd/MM/yyyy HH:mm' }})</span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="order.producerDeliver">
    <div class="row mt-3">
      <div class="col">
        <span class="fw-700">Validé pour l'expédition par&nbsp;:</span> {{ order.userShippingProgress !== null ? order.userShippingProgress : '---' }}
        <span *ngIf="order.dateShippingProgress !== null">({{ order.dateShippingProgress | date: 'dd/MM/yyyy HH:mm' }})</span>
      </div>
    </div>
  </ng-container>
  <!-- 
    A réactiver le jour ou boxtal me donnera la date de réception, le "userArticleReceived" n'est pour l'instant jamais utilisé, et ne le sera probablement pas dans le futur
    a moins de faire une bouton "j'ai bien reçu mon article" côté client
  <div class="row mt-3">
    <div class="col">
      <span class="fw-700">Reçu par l'utilisateur par&nbsp;:</span> {{ order.userArticleReceived !== null ? order.userArticleReceived : '---' }} ({{
        invoice.dateArticleReceived | date: 'dd/MM/yyyy HH:mm'
      }})
    </div>
  </div>
  -->
  <!-- *ngIf="order.userCarrierTransport !== null || order.userShippingProgress !== null"-->

  <div class="row mt-3">
    <div class="col-10 offset-1"><hr /></div>
  </div>

  <div class="row mt-4">
    <div class="col text-center">
      <a class="btn btn-outline-primary" href="{{ rpcHost }}invoice/{{ invoice.invoiceToken }}?dl" target="_blank"
        ><i class="fas fa-download mr-2"></i>Télécharger la facture</a
      >
    </div>
  </div>
  <div class="row mt-3" *ngIf="invoice.idCancelledInvoice != 0">
    <div class="col text-center">
      <a class="btn btn-outline-danger" href="{{ rpcHost }}invoice/{{ invoice.cancelInvoiceToken }}?dl" target="_blank"
        ><i class="fas fa-download mr-2"></i>Télécharger la facture d'annulation</a
      >
    </div>
  </div>
</div>
