<div class="row">
  <div class="col text-center">
    <img src="assets/account-creation/trophies.svg" />
  </div>
</div>
<div class="row mt-5">
  <div class="col text-center">
    <h3>Félicitation !</h3>
  </div>
</div>
<div class="row mt-2">
  <div class="col text-center">
    <h3>Votre inscription est maintenant terminée.</h3>
  </div>
</div>

<div class="row mt-5">
  <div class="col text-center">
    <button class="btn btn-primary" (click)="submit()">Commencer</button>
  </div>
</div>
