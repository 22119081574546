<div class="container-fluid pt-4 pb-5 pl-5 pr-5" [class.shadow-5]="!activeModal.fake">
  <div class="row">
    <div class="col text-center">
      <i class="fas fa-times close-icon" *ngIf="!activeModal.fake" (click)="activeModal.dismiss()"></i>
      <h2 class="mt-3">Mon profil</h2>
    </div>
  </div>

  <div class="row" *ngIf="dto === null">
    <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
  </div>
  <ng-container *ngIf="dto !== null">
    <div class="row mt-4">
      <div class="col">
        <label>Prénom</label>
      </div>
      <div class="col text-right">
        <a [routerLink]="null" *ngIf="!editFirstName" (click)="editFirstName = true">modifier</a>

        <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editFirstName && !loading" (click)="saveEditFirstName()"></i>
        <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editFirstName && !loading" (click)="cancelEdit()"></i>
        <i class="fas fa-spinner spinning" *ngIf="editFirstName && loading"></i>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" class="form-control dashed-when-disabled" [(ngModel)]="dto.firstName" required [disabled]="!editFirstName" />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <label>Nom de famille</label>
      </div>
      <div class="col text-right">
        <a [routerLink]="null" *ngIf="!editLastName" (click)="editLastName = true">modifier</a>

        <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editLastName && !loading" (click)="saveEditLastName()"></i>
        <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editLastName && !loading" (click)="cancelEdit()"></i>
        <i class="fas fa-spinner spinning" *ngIf="editLastName && loading"></i>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" class="form-control dashed-when-disabled" [(ngModel)]="dto.lastName" required [disabled]="!editLastName" />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-9">
        <label>Votre numéro de téléphone portable</label>
      </div>
      <div class="col-3 text-right">
        <a [routerLink]="null" *ngIf="!editPhoneNumber" (click)="editPhoneNumber = true; phoneNumberForm.enable()">modifier</a>

        <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editPhoneNumber && !loading" (click)="saveEditPhoneNumber()"></i>
        <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editPhoneNumber && !loading" (click)="cancelEdit()"></i>
        <i class="fas fa-spinner spinning" *ngIf="editPhoneNumber && loading"></i>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <form #f="ngForm" [class.show-errors]="!emailForm.valid">
          <input type="text" class="form-control dashed-when-disabled" [formControl]="phoneNumberForm" mask="00 00 00 00 00" />
        </form>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-9">
        <label>Votre poste</label>
      </div>
      <div class="col-3 text-right">
        <a [routerLink]="null" *ngIf="!editJob" (click)="editJob = true">modifier</a>

        <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editJob && !loading" (click)="saveEditJob()"></i>
        <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editJob && !loading" (click)="cancelEdit()"></i>
        <i class="fas fa-spinner spinning" *ngIf="editJob && loading"></i>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" class="form-control dashed-when-disabled" [(ngModel)]="dto.job" required [disabled]="!editJob" />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <label>E-mail</label>
      </div>
      <div class="col text-right">
        <a [routerLink]="null" *ngIf="!editEmail" (click)="editEmail = true; emailForm.enable()">modifier</a>

        <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editEmail && !loading" (click)="saveEditEmail()"></i>
        <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editEmail && !loading" (click)="cancelEdit()"></i>
        <i class="fas fa-spinner spinning" *ngIf="editEmail && loading"></i>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <form #f="ngForm" [class.show-errors]="!emailForm.valid">
          <input type="text" class="form-control dashed-when-disabled" [formControl]="emailForm" />
        </form>
      </div>
    </div>
    <div class="row mt-1" *ngIf="error">
      <div class="col">
        <div class="alert alert-danger">
          <ul>
            <li>{{ error }}</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <label>Mot de passe</label>
      </div>
      <div class="col text-right">
        <a [routerLink]="null" (click)="$event.preventDefault(); openResetPasswordPopup()">modifier</a>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="password" class="form-control dashed-when-disabled" disabled value="fakepassword" />
      </div>
    </div>
  </ng-container>
</div>
