import { Injectable } from '@angular/core';
import { ProducerSignupStep, Producer } from 'degineo-common';

@Injectable({
  providedIn: 'root',
})
export class AccountCreationService {
  step: ProducerSignupStep = null;
  token: string = null;
  nbPrinters: number = 1;
  idPlan: number = 0;
  constructor() {}

  next() {
    const nextStep = ProducerSignupStep.values.find((e) => e.id === this.step.id + 1);
    this.step = nextStep;
    window.scrollTo(0, 0);
  }
  previous() {
    const previousStep = ProducerSignupStep.values.find((e) => e.id === this.step.id - 1);
    this.step = previousStep;
    window.scrollTo(0, 0);
  }
}
