<div class="bg-light-30p">
  <div class="container px-5 py-5">
    <div class="row shadow-2 bg-white rounded py-4 px-3">
      <div class="col">
        <app-dashboard-warning></app-dashboard-warning>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col p-0">
        <app-dashboard-counters></app-dashboard-counters>
      </div>
    </div>
    <div class="row shadow-2 bg-white rounded mt-5 p-3">
      <div class="col d-flex justify-content-around">
        <app-dashboard-printers></app-dashboard-printers>
      </div>
    </div>
    <div class="row shadow-2 bg-white rounded mt-5 p-3">
      <div class="col d-flex justify-content-around">
        <app-dashboard-stats></app-dashboard-stats>
      </div>
    </div>
    <div class="row shadow-2 bg-white rounded mt-5 px-3 pb-3 pt-5" *ngIf="canSeeFinancialChart">
      <div class="col d-flex justify-content-around">
        <app-dashboard-financial></app-dashboard-financial>
      </div>
    </div>
  </div>
</div>
