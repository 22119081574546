<i class="fas fa-spinner spinning" *ngIf="dto === null"></i>
<ng-container *ngIf="dto !== null">
  <ng-template #modalContent let-modal>
    <div class="container shadow-5">
      <div class="row mt-4">
        <div class="col text-center">
          Si vous souhaitez suspendre votre abonnement Degineo,<br />
          vous devez <span class="fw-700">nous contacter via le Chat live</span>.
        </div>
      </div>
      <div class="row mt-4 justify-content-center" style="margin-bottom: 2rem">
        <div class="col-6">
          <button type="button" class="btn btn-success w-100" (click)="modal.close('OK'); openChat()">OK</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #sepaContent let-modal>
    <div class="container shadow-5 p-5">
      <i class="close-icon fas fa-times" (click)="modal.close()"></i>
      <div class="row mt-2">
        <div class="col text-center">
          <h2>Mandat de prélèvement SEPA</h2>
        </div>
      </div>
      <div class="row mt-3 bg-light-30p">
        <div class="col p-4">
          <p class="fw-700">Créancier: Degineo</p>
          <p [innerHTML]="formatAddress(dto.degineoAddress)"></p>
          <p>
            Identifiant créancier SEPA: <span class="fw-700">{{ dto.degineoSepaIdentifier }}</span>
          </p>
          <p>Référence Unique de Mandat (RUM) : {{ dto.stripeMandateRef }}</p>
        </div>
      </div>
      <div class="row mt-3 bg-light-30p">
        <div class="col p-4">
          <p class="fw-700">Débiteur: {{ dto.name }}</p>
          <p [innerHTML]="formatAddress(dto.producerBillingAddress)"></p>
          <p>
            Numéro IBAN du compte qui sera prélevé tout les mois : <span class="fw-700">**** {{ dto.stripeIbanLast4Digits }}</span>
          </p>
          <!--<p><span class="fw-700">BIC : </span> {{ step7form.value.fakeBic }}</p>-->
        </div>
      </div>
    </div>
  </ng-template>

  <div class="container my-4">
    <div class="row">
      <div class="col text-center mb-2">
        <h2>Abonnement en cours</h2>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col"><label>Tarif mensuel HT de base : </label> {{ dto.baseSubscriptionPrice | currency: 'EUR':'symbol':'1.2-2':'fr' }}</div>
    </div>
    <div class="row mt-2">
      <div class="col"><label>Tarif mensuel HT par machine connecté : </label> {{ dto.printerSubscriptionPrice | currency: 'EUR':'symbol':'1.2-2':'fr' }}</div>
    </div>
    <div class="row mt-2">
      <div class="col"><label>Nombre de machines connectés : </label> {{ dto.nbPrinters }}</div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <label>Prix total mensuel HT : </label> {{ dto.baseSubscriptionPrice }} + ({{ dto.printerSubscriptionPrice }} * {{ dto.nbPrinters }}) =
        {{ dto.totalHT | currency: 'EUR':'symbol':'1.2-2':'fr' }}
      </div>
    </div>
    <div class="row mt-2">
      <div class="col fs-4" *ngIf="dto.dateDiscountEnd === null">
        <label>Prix total mensuel TTC : </label> {{ dto.totalTTC | currency: 'EUR':'symbol':'1.2-2':'fr' }}
      </div>
      <div class="col fs-4" *ngIf="dto.dateDiscountEnd !== null">
        <label>Prix total mensuel TTC : </label>&nbsp;<span class="text-line-through">{{ dto.totalTTC | currency: 'EUR':'symbol':'1.2-2':'fr' }}</span>
        <span class="text-success fw-700 ml-3"
          >{{ dto.totalTTC * (1 - dto.discountPercent / 100) | currency: 'EUR':'symbol':'1.2-2':'fr' }}
          <span class="fs-3">(Remise de {{ dto.discountPercent }}% jusqu'au {{ dto.dateDiscountEnd | date: 'dd/MM/yyyy' }}</span
          >)</span
        >
      </div>
    </div>
    <div class="row mt-4 justify-content-center">
      <div class="col-10">
        <hr />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col text-center mb-2">
        <h2>Infos relatives à votre contrat d'abonnement</h2>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col"><label>Raison sociale : </label> {{ dto.name }}</div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <label>Adresse de facturation : </label>&nbsp;
        <span [innerHTML]="formatAddress(dto.producerBillingAddress)"></span>
        <a routerLink="/infos-entreprise" class="ml-3">modifier</a>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col"><label>Date de signature du contrat : </label> {{ dto.dateContractSigned | date: 'dd/MM/yyyy' }}</div>
    </div>
    <div class="row mt-2">
      <div class="col"><a routerLink="/todo" class="ml-2">Voir le contrat d'abonnement Degineo</a></div>
    </div>
    <div class="row mt-2">
      <div class="col"><a [routerLink]="null" (click)="showPopup($event, sepaContent, true)" class="ml-2">Voir votre mandat de prélèvement SEPA</a></div>
    </div>
    <div class="row mt-2">
      <div class="col"><a routerLink="/infos-entreprise" class="ml-2">Modifier vos coordonnées bancaires</a></div>
    </div>
    <div class="row mt-2">
      <div class="col"><a [routerLink]="null" (click)="showPopup($event, modalContent)" class="ml-2">Suspendre votre abonnement Degineo</a></div>
    </div>
    <div class="row mt-4 justify-content-center">
      <div class="col-10">
        <hr />
      </div>
    </div>
    <div class="row mt-3 mb-4">
      <div class="col text-center mb-2">
        <h2>Mes factures d'abonnement</h2>
      </div>
    </div>
    <degineo-my-table [rows]="rows" [hoverable]="false" [pointer]="false" [builtFiltersOrder]="builtFiltersOrder">
      <degineo-my-column [disableOrder]="true" name="Date de prélèvement">
        <td *cv="let r" class="text-nowrap text-center">
          {{ r.datePrelevement | date: 'dd/MM/yyyy' }}
        </td>
      </degineo-my-column>
      <degineo-my-column [disableOrder]="true" name="Référence">
        <td *cv="let r" class="text-nowrap text-center">
          {{ r.refInvoice }}
        </td>
      </degineo-my-column>
      <degineo-my-column [disableOrder]="true" name="Montant TTC">
        <td *cv="let r" class="text-nowrap text-center">
          {{ r.montantTTC | currency: 'EUR':'symbol':'1.2-2':'fr' }}
        </td>
      </degineo-my-column>
      <degineo-my-column [disableOrder]="true" name="Facture">
        <td *cv="let r" class="text-nowrap text-center">
          <a href="{{ rpcHost }}invoice/{{ r.invoiceToken }}?dl">
            <i class="fas fa-file-download"></i>
          </a>
        </td>
      </degineo-my-column>
    </degineo-my-table>
    <div class="row justify-content-center mt-3" *ngIf="!loading">
      <ngb-pagination
        [collectionSize]="count"
        [(page)]="page"
        [pageSize]="nbPerPage"
        [maxSize]="6"
        [rotate]="true"
        (pageChange)="reload()"
        [disabled]="loading"
      ></ngb-pagination>
    </div>
  </div>
</ng-container>
