import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, PrinterBrandChoiceDTO, PrinterNEW, ProducerSignupService, ProducerTakeControlService, ProducerTakeControlStep4DTO } from 'degineo-common';
import { L } from 'ic2-lib';
import { TakeControlService } from '../take-control.service';

@Component({
  selector: 'app-take-control-step5-printers',
  templateUrl: './take-control-step5-printers.component.html',
  styleUrls: ['./take-control-step5-printers.component.scss'],
})
export class TakeControlStep5PrintersComponent implements OnInit {
  loading: boolean = true;
  saving: boolean = false;
  printers: { brand: PrinterBrandChoiceDTO; printer: PrinterNEW }[] = [];
  printerChoices: PrinterBrandChoiceDTO[];
  showErrors: boolean = false;

  constructor(
    public takeControlService: TakeControlService,
    private producerSignupService: ProducerSignupService,
    private producerTakeControlService: ProducerTakeControlService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.producerSignupService.getPrinterChoices().subscribe(
      (data) => {
        this.printerChoices = data;
        this.printers.push({ brand: null, printer: null });
        this.loading = false;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  addPrinter() {
    this.printers.push({ brand: null, printer: null });
  }

  removePrinter(printer) {
    this.printers.splice(this.printers.indexOf(printer), 1);
  }

  previous() {
    this.takeControlService.step = TakeControlService.STEP_4_COMPANY;
  }

  submit() {
    this.showErrors = false;
    if (this.printers.length === 0 || this.printers.find((p) => p.printer === null) !== undefined) {
      this.showErrors = true;
      console.log(this.takeControlService);
      return;
    }
    this.saving = true;
    const dto = new ProducerTakeControlStep4DTO();
    dto.name = this.takeControlService.step4form.value.name;
    dto.legalStatus = this.takeControlService.step4form.value.legalStatus;
    dto.billingAddress = this.takeControlService.step4form.value.billingAddress;
    this.producerTakeControlService
      .producerTakeControl(
        this.takeControlService.step3form.value.siret, //step 3 because step4 is disabled so it does not show up in step4.values
        this.takeControlService.step1form.value.email,
        this.takeControlService.step1form.value.password,
        this.takeControlService.step2form.value.businessType,
        dto,
        this.printers.map((p) => p.printer.id)
      )
      .subscribe(
        (data) => {
          this.saving = false;
          this.authService.loginWith(data);
          this.router.navigate(['/bienvenue']);
        },
        (err) => {
          this.saving = false;
          L.e(err);
        }
      );
  }
}
