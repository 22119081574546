<div class="container mb-5" (click)="open = false; $event.stopPropagation()">
  <div class="row text-primary dotted-border border-primary py-3 no-select pointer mt-4" *ngIf="!addMode && hasRight" (click)="goAddMode()">
    <div class="col-1 text-right">
      <i class="fas fa-plus fs-5"></i>
    </div>
    <div class="col-11 fs-4 fw-700 align-self-center">Ajouter une imprimante <i class="fas fa-spinner spinning" *ngIf="loadingPrinterChoices"></i></div>
  </div>

  <div class="row" *ngIf="loading">
    <div class="col">Chargement ... <i class="fas fa-spinner spinning"></i></div>
  </div>

  <div class="row dotted-border border-primary py-3" *ngIf="addMode">
    <div class="col">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 fs-4 fw-700 align-self-center text-primary mb-3">
            <i class="fas fa-plus mr-2"></i>Ajouter une imprimante <i class="fas fa-spinner spinning" *ngIf="loadingPrinterChoices"></i>
          </div>
          <div class="col">
            <form class="form-inline">
              <select class="form-control mr-3" name="brand" (change)="printerBrandChanged()" [(ngModel)]="selectedBrand">
                <option [ngValue]="null" disabled="disabled">-- Marque de l'imprimante --</option>
                <option *ngFor="let printer of printerChoices" [ngValue]="printer">{{ printer.brand }}</option>
              </select>
              <select class="form-control mr-3" name="printer" [(ngModel)]="selectedPrinter" [disabled]="selectedBrand === null">
                <option [ngValue]="null" disabled="disabled">-- Référence de la machine --</option>
                <option *ngFor="let printer of printerChoices2ndStep" [ngValue]="printer">{{ printer.name }}</option>
              </select>
              <button class="btn btn-success mr-3" [disabled]="selectedPrinter === null" (click)="addPrinter()">
                <i class="fas fa-plus"></i> Ajouter cette imprimante
              </button>
              <button class="btn btn-secondary" (click)="cancelAdd()">Annuler</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row mt-3 pt-3 pb-4"
    [class.printer-border]="printer.checked"
    [class.printer-border-warning]="!printer.checked"
    [class.border-primary]="printer.idProducerPrinter == idProducerPrinterActive"
    id="printer-{{ printer.idProducerPrinter }}"
    *ngFor="let printer of printers"
  >
    <div class="col">
      <app-my-printers-item [printerDto]="printer"></app-my-printers-item>
    </div>
  </div>
</div>

<side-panel color="#06c" icon="fa-cog" panelTitle="Gérer mes consommables" [(open)]="open">
  <app-my-printers-manage-consumables></app-my-printers-manage-consumables>
</side-panel>
