import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, ClientService, DashboardWarningsDTO, DegineoRight, ProducerDashboardService, RoleInCompany } from 'degineo-common';
import { L } from 'ic2-lib';
import { AvatarChangeService } from 'projects/client/src/app/Front/front-layout/avatar-change.service';
import { MyProfileComponent } from '../../my-profile/my-profile.component';

@Component({
  selector: 'app-dashboard-warning',
  templateUrl: './dashboard-warning.component.html',
  styleUrls: ['./dashboard-warning.component.scss'],
})
export class DashboardWarningComponent implements OnInit {
  data: DashboardWarningsDTO = null;
  errorFile: string = null;
  public file: File = null;
  progressPercent = null;
  oldImgURL: any = null;

  constructor(
    private producerDashboardService: ProducerDashboardService,
    public authService: AuthService,
    private modalService: NgbModal,
    private router: Router,
    private clientService: ClientService,
    private avatarChangeService: AvatarChangeService
  ) {}

  ngOnInit(): void {
    this.producerDashboardService.getWarnings().subscribe(
      (data) => {
        this.data = data;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  openProfilePopup() {
    this.modalService.open(MyProfileComponent, { centered: true });
  }

  handleFileInput(event) {
    const files: FileList = event.target.files;
    const fr = new FileReader();

    fr.onload = (e) => {
      // file is loaded
      const img = new Image();
      img.onload = () => {
        console.log(img.width + ' x ' + img.height);
        const dimensionsMin = { w: 150, h: 150 };
        if (img.width < dimensionsMin.w || img.height < dimensionsMin.h) {
          //dimensions minis
          this.errorFile = 'Votre fichier doit au minimum faire ' + dimensionsMin.w + 'x' + dimensionsMin.h;
          this.avatarChangeService.avatar = this.oldImgURL;
        } else {
          this.file = files[0];
          console.log(this.file, this.file.size / 1024 / 1024 + 'MB', this.file.name.split('.').pop());
          this.uploadLogo();
        }
      };
      this.oldImgURL = this.avatarChangeService.avatar;
      this.avatarChangeService.avatar = (e.target as any).result;
      img.src = (e.target as any).result; // is the data URL because called with readAsDataURL
    };
    fr.readAsDataURL(files[0]);
  }

  uploadLogo() {
    this.errorFile = null;
    this.clientService
      .changeLogo()
      .withFile(this.file)
      .subscribeWithProgress(
        (data) => {
          this.progressPercent = null;
          this.authService.userBundle.du.logoExtension = this.file.name.split('.').pop();
          this.file = null;
        },
        (loaded, total) => {
          this.progressPercent = ((loaded / total) * 100).toFixed(0);
        },
        (err) => {
          L.e(err);
          this.progressPercent = null;
          this.errorFile = err.message;
        }
      );
  }
}
