import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthService, InvoiceProducerDTO, InvoiceProducerOrderDTO, InvoiceProducerStatus } from 'degineo-common';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-invoice-panel',
  templateUrl: './invoice-panel.component.html',
  styleUrls: ['./invoice-panel.component.scss'],
})
export class InvoicePanelComponent implements OnInit, OnChanges {
  @Input() invoice: InvoiceProducerDTO;
  order: InvoiceProducerOrderDTO;
  InvoiceProducerStatus: typeof InvoiceProducerStatus = InvoiceProducerStatus;

  rpcHost: string = environment.rpcHost;

  constructor(public authService: AuthService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.invoice) this.order = this.invoice.orders[0];
  }
}
