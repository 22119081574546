import { Component, OnInit, Host, Inject } from '@angular/core';
import * as moment_ from 'moment';
import { environment } from 'environments/environment';
import { InPreparationShipmentPanelComponent } from '../in-preparation-shipment-panel.component';
import { ShipmentOrderPanelDTO, InPreparationShipmentService, ShipmentOrder, AuthService, RoleInCompany } from 'degineo-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FabricationOrderPopupComponent } from '../../fabrication-order-popup/fabrication-order-popup.component';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-shipment-preparation-step',
  templateUrl: './shipment-preparation-step.component.html',
  styleUrls: ['./shipment-preparation-step.component.scss'],
})
export class ShipmentPreparationStepComponent implements OnInit {
  moment = moment_;
  rpcHost: string = environment.rpcHost;
  loading: boolean = false;
  error: string = null;

  RoleInCompany: typeof RoleInCompany = RoleInCompany;
  constructor(
    @Host() public parent: InPreparationShipmentPanelComponent,
    @Inject(InPreparationShipmentService) private inPreparationShipmentService: InPreparationShipmentService,
    @Inject(NgbModal) private modalService: NgbModal,
    @Inject(AuthService) public authService: AuthService
  ) {}

  ngOnInit() {}

  cancel() {
    this.loading = true;
    this.inPreparationShipmentService.cancelShippingOrder(this.parent.dto.shipmentOrders[0].idOrder).subscribe(
      (data) => {
        this.loading = false;
        this.parent.myOrdersComponent.reload();
        this.parent.myOrdersComponent.close();
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  getValidatedShipmentOrders() {
    return this.parent.dto.shipmentOrders.filter((so) => so.waitForIt);
  }

  accept() {
    //TODO verif au moins 1 shipmentorder à oui sur une commande ready
    if (this.parent.dto.shipmentOrders.filter((so) => so.ready && so.waitForIt).length < 1) {
      this.error = 'Au moins une impression prête doit être sélectionnée.';
      return;
    }

    //On enregistre
    const arr: ShipmentOrder[] = [];
    for (const ipso of this.parent.dto.shipmentOrders) {
      const so = new ShipmentOrder();
      so.idOrder = ipso.idOrder;
      so.idShipment = this.parent.dto.idShipment;
      so.waitForIt = ipso.waitForIt;
      arr.push(so);
    }

    this.loading = true;
    this.inPreparationShipmentService.updateShipment(this.parent.dto.idShipment, this.parent.dto.producerDeliver, arr).subscribe(
      (data) => {
        if (this.parent.dto.shipmentOrders.filter((so) => so.waitForIt).every((so) => so.ready)) {
          //Toutes les commandes en attente sont ready
          if (this.parent.dto.producerDeliver) {
            //skip colisage
            this.inPreparationShipmentService.passShipmentInTransport(this.parent.dto.idShipment).subscribe(
              (data) => {
                this.loading = false;
                this.parent.myOrdersComponent.reloadAndOpenShipment(this.parent.dto.idShipment);
                this.parent.myOrdersComponent.close();
              },
              (err) => {
                this.loading = false;
                L.e(err);
              }
            );
          } else {
            this.parent.myOrdersComponent.reload();
            //go colisage
            this.inPreparationShipmentService.passShipmentInPackaging(this.parent.dto.idShipment).subscribe(
              (data) => {
                this.loading = false;
                this.parent.step = 2;
                window.scrollTo(0, 0);
              },
              (err) => {
                this.loading = false;
                L.e(err);
              }
            );
          }
        } else {
          this.loading = false;
          this.parent.myOrdersComponent.reload();
          this.parent.myOrdersComponent.close();
        }
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  isReadyAndProducerDeliver() {
    /*console.log(
      this.parent.dto.producerDeliver,
      this.getValidatedShipmentOrders().length === 1,
      this.getValidatedShipmentOrders()[0].waitForIt,
      this.getValidatedShipmentOrders()[0].ready
    );*/
    return (
      this.parent.dto.producerDeliver &&
      this.getValidatedShipmentOrders().length === 1 &&
      this.getValidatedShipmentOrders()[0].waitForIt &&
      this.getValidatedShipmentOrders()[0].ready
    );
  }

  openPopup(order: ShipmentOrderPanelDTO) {
    const modalRef = this.modalService.open(FabricationOrderPopupComponent, { size: 'lg', windowClass: 'yellow' });
    modalRef.componentInstance.dto = order;
    modalRef.result.then((val) => {
      console.log('modal closed', val);
      if (val === 'reload') {
        //C'est qu'on à annulé la mise en conditionnement de la commande
        this.parent.myOrdersComponent.reload();
        this.parent.myOrdersComponent.close();
      }
    });
  }
}
