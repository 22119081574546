import { Component, OnInit, Input, Inject, Host, SimpleChanges, OnChanges } from '@angular/core';
import { InPreparationShipmentPanelDTO, InPreparationShipmentService, ShipmentStatus, ShipmentPackageDTO, AuthService, RoleInCompany } from 'degineo-common';
import { MyOrdersComponent } from '../my-orders.component';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-in-preparation-shipment-panel',
  templateUrl: './in-preparation-shipment-panel.component.html',
  styleUrls: ['./in-preparation-shipment-panel.component.scss'],
})
export class InPreparationShipmentPanelComponent implements OnInit, OnChanges {
  @Input()
  id: number;
  dto: InPreparationShipmentPanelDTO = null;
  step: number = 0;
  packages: ShipmentPackageDTO[] = [];

  constructor(
    @Inject(InPreparationShipmentService) private inPreparationShipmentService: InPreparationShipmentService,
    @Host() public myOrdersComponent: MyOrdersComponent
  ) {}

  ngOnInit() {
    //this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.id) {
      this.dto = null;
      this.load();
    }
  }

  load() {
    this.inPreparationShipmentService.getShipment(this.id).subscribe(
      (data) => {
        this.dto = data;
        console.log(data);
        if (data.shipmentOrders.length === 1 && !data.shipmentOrders[0].clientChoosedThisProducer && data.status !== ShipmentStatus.PACKAGE_PREPARATION) {
          this.inPreparationShipmentService.passShipmentInPackaging(this.id).subscribe(
            () => {
              data.status = ShipmentStatus.PACKAGE_PREPARATION;
              this.step = 2;
            },
            (err) => {
              L.e(err);
            }
          );
        } else {
          if (data.status === ShipmentStatus.PACKAGE_PREPARATION)
            //si une seule commande en attente et qu'on est en demande degineo on valide l'expe passe directement en step 2
            this.step = 2;
          else this.step = 1;
        }
      },
      (err) => {
        L.e(err);
      }
    );
  }
}
