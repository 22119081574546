import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCounterDTO, ProducerDashboardService } from 'degineo-common';
import { L } from 'ic2-lib';
import { MyOrdersComponent } from '../../my-orders/my-orders.component';

@Component({
  selector: 'app-dashboard-counters',
  templateUrl: './dashboard-counters.component.html',
  styleUrls: ['./dashboard-counters.component.scss'],
})
export class DashboardCountersComponent implements OnInit {
  data: DashboardCounterDTO = null;
  constructor(private producerDashboardService: ProducerDashboardService, private router: Router) {}

  ngOnInit(): void {
    this.producerDashboardService.getCounters().subscribe(
      (data) => {
        this.data = data;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  goLateOrder() {
    MyOrdersComponent.DefaultSearchText = 'retard';
    this.router.navigate(['/mes-commandes']);
  }
}
