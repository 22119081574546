import { Component, OnInit, Input, Inject, Host, OnChanges, SimpleChanges } from '@angular/core';
import { ReceivedOrderService, ReceivedOrderPanelDTO, AuthService, RoleInCompany } from 'degineo-common';
import { L } from 'ic2-lib';
import * as moment_ from 'moment';
import { environment } from 'environments/environment';
import { MyOrdersComponent } from '../my-orders.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientInfosPopupComponent } from '../client-infos-popup/client-infos-popup.component';

@Component({
  selector: 'app-received-order-panel',
  templateUrl: './received-order-panel.component.html',
  styleUrls: ['./received-order-panel.component.scss'],
})
export class ReceivedOrderPanelComponent implements OnInit, OnChanges {
  @Input()
  id: number;
  dto: ReceivedOrderPanelDTO = null;
  moment = moment_;
  rpcHost: string = environment.rpcHost;
  loading: boolean = false;

  RoleInCompany: typeof RoleInCompany = RoleInCompany;

  constructor(
    @Inject(ReceivedOrderService) private receivedOrderService: ReceivedOrderService,
    @Host() public myOrdersComponent: MyOrdersComponent,
    private modalService: NgbModal,
    @Inject(AuthService) public authService: AuthService
  ) {
    this.moment.locale('fr');
  }

  ngOnInit() {
    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dto = null;
    this.load();
  }

  hasRightToAcceptOrRefuse() {
    return this.dto.clientChoosedThisProducer || this.authService.isAtLeast(RoleInCompany.PRODUCER_ADMIN);
  }

  load() {
    this.receivedOrderService.getReceivedOrderInfos(this.id).subscribe(
      (data) => {
        this.dto = data;
        console.log(data);
      },
      (err) => {
        L.e(err);
      }
    );
  }

  refuse() {
    this.loading = true;
    this.receivedOrderService.refuseOrder(this.id).subscribe(
      (data) => {
        this.myOrdersComponent.reload();
        this.myOrdersComponent.close();
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  accept() {
    this.loading = true;
    this.receivedOrderService.acceptOrder(this.id).subscribe(
      (data) => {
        this.myOrdersComponent.reload();
        this.myOrdersComponent.close();
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  openPopupInfos() {
    const modal = this.modalService.open(ClientInfosPopupComponent, {
      centered: true,
    });
    modal.componentInstance.data = this.dto.client;
    return false;
  }
}
