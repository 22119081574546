<div class="container-fluid shadow-5 p-5" *ngIf="dto !== null">
  <app-panel-header
    [id]="dto.idOrder"
    [sinceDate]="dto.dateShippingPreparation"
    [clientChoosedThisProducer]="dto.clientChoosedThisProducer"
    [client]="dto.client"
    dateLabel="En conditionnement"
    colorClass="text-k-yellow"
    [crossMargin]="false"
  ></app-panel-header>
  <app-panel-detail
    [token3DModelFinal]="dto.token3DModelFinal"
    [filenameFinal]="dto.filenameFinal"
    [quantity]="dto.quantity"
    [sizeXMm]="dto.sizeXMm"
    [sizeYMm]="dto.sizeYMm"
    [sizeZMm]="dto.sizeZMm"
    [colorName]="dto.colorName"
    [colorHex]="dto.colorHex"
    [deadline]="dto.wishedDeliveryDate"
    [finishName]="dto.finishName"
  ></app-panel-detail>

  <app-panel-address class="mt-3" *ngIf="dto.shippingAddress !== null" [address]="dto.shippingAddress"></app-panel-address>

  <div class="row mt-5">
    <div class="col" *ngIf="dto.ready && canCancel">
      <button
        class="btn btn-secondary w-100"
        [disabled]="loading"
        *ngIf="authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING"
        (click)="cancelShipmentForOrder()"
      >
        <i class="fas fa-fast-backward text-white mr-2"></i>L'impression n'est pas finie
      </button>
    </div>
    <div class="col">
      <button class="btn btn-primary w-100" [disabled]="loading" (click)="close()">Fermer</button>
    </div>
  </div>
</div>
