<div class="container-fluid">
  <div class="row" [class.mt-3]="!first" *ngFor="let first = first; let ppf; of: myPrintersItemComponent.printerDto.ppfs">
    <div class="col">
      <div class="d-inline-block">
        <span class="fw-500 mr-2">{{ ppf.name }}&nbsp;:</span>
      </div>

      <div class="d-inline-block">
        <a [routerLink]="null" *ngIf="!edit[ppf.idProducerPrinterFinish]" (click)="edit[ppf.idProducerPrinterFinish] = true">{{ ppf.price }} €/cm³</a>
        <form class="form-inline" *ngIf="edit[ppf.idProducerPrinterFinish]">
          <div class="mr-3 input-group">
            <input type="number" class="form-control" #input [value]="ppf.price" style="width: 90px !important" />
            <div class="input-group-append">
              <span class="input-group-text">€/cm³</span>
            </div>
          </div>
          <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" (click)="saveEdit(ppf, input.value)"></i>
          <i class="fas fa-times-circle fs-4 text-danger pointer no-select" (click)="edit[ppf.idProducerPrinterFinish] = false"></i>
        </form>
        <i
          class="far fa-trash-alt pointer hover-danger ml-3"
          ngbTooltip="Retirer cette marge sectorielle"
          *ngIf="!edit[ppf.idProducerPrinterFinish]"
          (click)="deleteMarge(ppf)"
        ></i>
      </div>
    </div>
  </div>

  <div class="row mt-3 pointer no-select" *ngIf="!add" (click)="addMode()">
    <div class="col text-primary pointer no-select">
      <i class="fas fa-plus-circle"></i> Ajouter une finition
      <span class="text-danger" *ngIf="noMoreFinish">Aucune finition disponible à l'ajout</span>
    </div>
  </div>
  <div class="row mt-3" *ngIf="add">
    <div class="col">
      <form class="form-inline">
        <select class="form-control mr-3" name="ct" [(ngModel)]="selectedFinish">
          <option [ngValue]="null" disabled="disabled">-- Finition --</option>
          <option *ngFor="let f of finishChoices" [ngValue]="f">{{ f.name }}</option>
        </select>
        <div class="mr-3 input-group">
          <input type="number" class="form-control" name="price" style="width: 90px !important" [(ngModel)]="price" />
          <div class="input-group-append">
            <span class="input-group-text">€/cm³</span>
          </div>
        </div>
        <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" (click)="addCT()"></i>
        <i class="fas fa-times-circle fs-4 text-danger pointer no-select" (click)="add = false"></i>
      </form>
    </div>
  </div>
</div>
