import { Component, Host, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProducerClientDTO, ProducerClientPaymentStatus, ProducerClientService } from 'degineo-common';
import { L } from 'ic2-lib';
import { MyOrdersComponent } from '../../my-orders/my-orders.component';
import { MyInvoicesComponent } from '../../administrative/my-invoices/my-invoices.component';
import { MyClientsComponent } from '../my-clients.component';

@Component({
  selector: 'app-panel-client',
  templateUrl: './panel-client.component.html',
  styleUrls: ['./panel-client.component.scss'],
})
export class PanelClientComponent implements OnInit {
  @Input()
  client: ProducerClientDTO = null;
  @Input()
  parent: MyClientsComponent;
  loadingAllowedPayment: boolean = false;
  loadingDelete: boolean = false;
  ProducerClientPaymentStatus: typeof ProducerClientPaymentStatus = ProducerClientPaymentStatus;

  constructor(private producerClientService: ProducerClientService, private router: Router) {}

  ngOnInit(): void {}

  checkboxChanged() {
    this.loadingAllowedPayment = true;
    this.producerClientService.updateAllowedDelayedPayment(this.client.idClient, this.client.allowedDelayedPayment).subscribe(
      (data) => {
        this.loadingAllowedPayment = false;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  goOrders() {
    MyOrdersComponent.DefaultSearchText = this.client.companyName;
    this.router.navigate(['/mes-commandes']);
  }

  goInvoices() {
    MyInvoicesComponent.FilterDefault = {
      client: this.client.companyName,
    };
    this.router.navigate(['/mes-factures-de-vente']);
  }

  del() {
    if (!confirm('Êtes-vous sûr de vouloir supprimer ce client ?')) return;
    this.loadingDelete = true;
    this.producerClientService.deleteClients([this.client.idClient]).subscribe(
      (data) => {
        this.parent.reload();
        this.parent.selectedClient = null;
        this.loadingDelete = false;
      },
      (err) => {
        L.e(err);
        this.loadingDelete = false;
      }
    );
  }
}
