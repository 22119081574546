<div class="container-fluid">
  <div class="row">
    <div class="col-3">
      <img src="{{ rpcHost }}printerImage/{{ printerDto.idPrinter }}/printer.png?size=large" />
    </div>
    <div class="col-9">
      <div class="container-fluid">
        <div class="row">
          <div class="col-10">
            <span class="fs-4 fw-700">{{ printerDto.printerBrand }}</span>
          </div>
          <div class="col-2 text-right" *ngIf="hasRight">
            <i
              class="far fa-trash-alt hover-danger pointer ml-3"
              ngbTooltip="Retirer cette imprimante"
              (click)="removePrinter()"
              *ngIf="printerDto.checked"
            ></i>
          </div>
        </div>
        <div class="row">
          <div class="col fs-4">
            {{ printerDto.printerName }}
          </div>
        </div>
        <div class="row mt-3" *ngIf="printerDto.checked && hasRight && authService.userBundle.subscribed">
          <div class="col fw-500">
            <span *ngIf="!editProductionCostMode" [class.text-danger]="printerDto.currentProductionCost === 0">
              <i class="fas fa-exclamation-triangle text-warning mr-2" *ngIf="printerDto.currentProductionCost === 0"></i>Coût production par cm<sup>3</sup> :
              <a [routerLink]="null" (click)="editProductionCostMode = true">{{ printerDto.currentProductionCost | number: '.3':'fr-FR' }} €</a>
            </span>

            <form class="form-inline" *ngIf="editProductionCostMode">
              Coût production par cm<sup>3</sup> :
              <div class="ml-2 input-group">
                <input type="number" #input class="form-control" style="width: 90px !important" [value]="printerDto.currentProductionCost" />
                <div class="input-group-append">
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <i class="fas fa-check-circle fs-4 text-success pointer mx-2" (click)="editProductionCost(input.value)"></i>
              <i class="fas fa-times-circle fs-4 text-danger pointer mr-2" (click)="editProductionCostMode = false"></i>
              <span class="fs-2 text-primary"
                ><i class="fas fa-info-circle"></i> Le coût production conseillé est de {{ printerDto.defaultProductionCost | number: '.3':'fr-FR' }} €</span
              >
            </form>
          </div>
        </div>
        <div class="row mt-3" *ngIf="printerDto.checked && (activeAvailable() || printerDto.active)">
          <div class="col">
            <span class="text-success fw-500" style="vertical-align: super" *ngIf="printerDto.active">Disponible </span>
            <span class="text-danger fw-500" style="vertical-align: super" *ngIf="!printerDto.active">Indisponible </span>
            <ui-switch
              size="small"
              color="#00c462"
              defaultBgColor="#d82929"
              [disabled]="loading"
              [checked]="printerDto.active"
              [(ngModel)]="printerDto.active"
              (ngModelChange)="activeChanged()"
            >
            </ui-switch>
          </div>
        </div>
        <div class="row mt-3" *ngIf="!activeAvailable()">
          <div class="col text-danger">
            <span *ngIf="this.printerDto.ppctms.length === 0"
              >Vous devez ajouter au moins une <b>Marge sectorielle</b> afin de rendre cette imprimante disponible.<br
            /></span>
            <span *ngIf="printerDto.currentProductionCost === 0"
              >Vous devez ajouter au moins le <b>Coût production</b> afin de rendre cette imprimante disponible.<br
            /></span>
            <span *ngIf="printerDto.ppfs.length === 0">Vous devez ajouter au moins une <b>Finition</b> afin de rendre cette imprimante disponible.<br /></span>
            <span *ngIf="!atLeastOneConsumableOptionCorrect()"
              >Vous devez ajouter au moins un <b>Consommable</b> afin de rendre cette imprimante disponible.<br
            /></span>
            <span *ngIf="!atLeastOneUnitPieceCorrect()"
              >Vous devez ajouter au moins un <b>Prix unitaire</b> afin de rendre cette imprimante disponible.<br
            /></span>
          </div>
        </div>
        <div class="row mt-3" *ngIf="!printerDto.checked">
          <div class="col text-warning fs-4">Imprimante en attente de validation</div>
        </div>
        <div class="row mt-3" *ngIf="!printerDto.checked && hasRight">
          <div class="col">
            <button class="btn btn-danger mb-2" (click)="cancelAdd()"><i class="fas fa-ban mr-2"></i>Annuler l'ajout</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <span class="faketab" [class.text-danger]="!atLeastOneConsumableOptionCorrect()" [class.active]="showTab === 1" (click)="show(1)"
        ><i class="fas fa-exclamation-triangle text-warning mr-2" *ngIf="!atLeastOneConsumableOptionCorrect()"></i>Consommables
        <span *ngIf="hasRight && authService.userBundle.subscribed">& Coûts matières </span>({{ printerDto.ppcos.length }})
        <i class="fas ml-2" [class.fa-chevron-down]="showTab !== 1" [class.fa-chevron-up]="showTab === 1"></i
      ></span>
      <span
        class="faketab"
        [class.text-danger]="printerDto.ppfs.length === 0"
        [class.active]="showTab === 2"
        (click)="show(2)"
        *ngIf="hasRight && authService.userBundle.subscribed"
        ><i class="fas fa-exclamation-triangle text-warning mr-2" *ngIf="printerDto.ppfs.length === 0"></i>Finitions ({{ printerDto.ppfs.length }})
        <i class="fas ml-2" [class.fa-chevron-down]="showTab !== 2" [class.fa-chevron-up]="showTab === 2"></i
      ></span>
      <span
        class="faketab"
        [class.text-danger]="this.printerDto.ppctms.length === 0"
        [class.active]="showTab === 3"
        (click)="show(3)"
        *ngIf="hasRight && authService.userBundle.subscribed"
        ><i class="fas fa-exclamation-triangle text-warning mr-2" *ngIf="this.printerDto.ppctms.length === 0"></i>Marges sectorielles ({{
          printerDto.ppctms.length
        }}) <i class="fas ml-2" [class.fa-chevron-down]="showTab !== 3" [class.fa-chevron-up]="showTab === 3"></i
      ></span>
      <span
        class="faketab"
        [class.text-danger]="!atLeastOneUnitPieceCorrect()"
        [class.active]="showTab === 4"
        (click)="show(4)"
        *ngIf="printerDto.hasUnitPieces && hasRight && authService.userBundle.subscribed"
        ><i class="fas fa-exclamation-triangle text-warning mr-2" *ngIf="!atLeastOneUnitPieceCorrect()"></i>Pièces unitaires ({{ printerDto.ppatps.length }})
        <i class="fas ml-2" [class.fa-chevron-down]="showTab !== 4" [class.fa-chevron-up]="showTab === 4"></i
      ></span>
    </div>
  </div>

  <div class="row mt-3" *ngIf="showTab === 1">
    <div class="col">
      <app-my-printers-consumables></app-my-printers-consumables>
    </div>
  </div>
  <div class="row mt-3" *ngIf="showTab === 2">
    <div class="col">
      <app-my-printers-finish></app-my-printers-finish>
    </div>
  </div>
  <div class="row mt-3" *ngIf="showTab === 3">
    <div class="col">
      <app-my-printers-producer-margin></app-my-printers-producer-margin>
    </div>
  </div>
  <div class="row mt-3" *ngIf="showTab === 4">
    <div class="col">
      <app-my-printers-pieces-prices></app-my-printers-pieces-prices>
    </div>
  </div>
</div>
