<form
  autocomplete="off"
  #f="ngForm"
  [formGroup]="takeControlService.step4form"
  [class.show-errors]="f.submitted && !takeControlService.step4form.valid"
  (submit)="submit()"
>
  <div class="container-small py-5">
    <div class="row">
      <div class="col text-center">
        <img src="assets/header/logo_degineo_bleu_vert.svg" style="max-height: 4rem" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center">
        <h2 style="font-size: 1.6rem">Votre entreprise</h2>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col text-center">
        Merci de vérifier vos informations légales ci-dessous.<br />Elles serviront à l'édition de vos factures et de vos devis.
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <label>SIRET</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" class="form-control" formControlName="siret" mask="000 000 000 00000" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <label>Raison sociale</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" class="form-control" formControlName="name" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <label>Forme juridique</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <select class="form-control" formControlName="legalStatus">
          <option [ngValue]="null" disabled="disabled">-- Choisir --</option>
          <option *ngFor="let legalStatus of LegalStatus.values" [ngValue]="legalStatus">{{ legalStatus.lib }}</option>
        </select>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <label>Adresse de facturation de l'entreprise</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <app-degineo-gmaps-address-input formControlName="billingAddress"></app-degineo-gmaps-address-input>
      </div>
    </div>
    <div class="row mt-4" *ngIf="f.submitted && !takeControlService.step4form.valid">
      <div class="col">
        <div class="alert alert-danger pr-3 pl-3 pr-md-5 pl-md-5 pt-3 pb-3">
          <ul>
            <li *ngIf="takeControlService.step4form.hasError('required', 'name')">Une raison sociale est requise.</li>
            <li *ngIf="takeControlService.step4form.hasError('required', 'legalStatus')">Une forme juridique est requise.</li>
            <li *ngIf="takeControlService.step4form.hasError('required', 'billingAddress')">Une addresse est requise.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-6">
        <button (click)="previous()" class="btn btn-secondary w-100"><i class="fas fa-arrow-left"></i></button>
      </div>
      <div class="col-6">
        <button type="submit" class="btn btn-success w-100">
          <i class="fas fa-check"></i>
        </button>
      </div>
    </div>
  </div>
</form>
