<div class="kanban" *ngIf="kanbanDTO !== null">
  <div class="kanban-header mt-3 mx-3">
    <i class="fas fa-search search-icon"></i>
    <i class="fas fa-times search-reset-icon" *ngIf="searchText !== null" (click)="searchText = null; searchChanged()"></i>
    <input type="text" class="form-control rounded-pill search-bar" [(ngModel)]="searchText" (ngModelChange)="searchChanged()" placeholder="Recherche" />
  </div>
  <div class="kanban-content">
    <div class="kanban-col blue" *ngIf="authService.userBundle.role !== RoleInCompany.PRODUCER_COLLABORATOR">
      <h3 class="no-select">Demandes reçues ({{ kanbanDTO.receivedOrders.length }})</h3>
      <div class="kanban-col-content">
        <app-order-item
          class="mt-3"
          [class.shade]="highlightedItems.length > 0 && !highlightedItems.includes(order)"
          *ngFor="let order of sort(kanbanDTO.receivedOrders)"
          (click)="openReceivedOrder(order)"
        >
          <div class="header ellipsis">Reçu {{ moment(order.dateCreated).fromNow() }}</div>
          <div class="title" *ngIf="order.exclusive">Vous avez l'exclusivité <i class="fas fa-heart text-danger ml-1"></i></div>
          <div class="title" *ngIf="!order.exclusive && !order.clientChoosedThisProducer">Demande Degineo <i class="fas fa-clock ml-1 text-warning"></i></div>
          <div class="title" title="{{ order.clientName }}" *ngIf="!order.exclusive && order.clientChoosedThisProducer">{{ order.clientName }}</div>
          <div class="smaller">{{ order.printerBrand }} - {{ order.printerName }}</div>
          <div class="smaller" [class.outdated-deadline]="isOutdated(order.wishedDeliveryDate)">
            Deadline: {{ order.wishedDeliveryDate | date: 'dd/MM/yyyy' }}
          </div>
          <div class="smaller footer" *ngIf="authService.isAtLeast(RoleInCompany.PRODUCER_ADMIN)">
            Payé: {{ order.subcontractingTotalPriceTTC | number: '.2':'fr-FR' }} €
          </div>
        </app-order-item>
      </div>
    </div>

    <div class="kanban-col purple">
      <h3 class="no-select">En préparation ({{ kanbanDTO.inPreparationOrders.length }})</h3>
      <div class="kanban-col-content">
        <app-order-item
          class="mt-3"
          [class.shade]="highlightedItems.length > 0 && !highlightedItems.includes(order)"
          *ngFor="let order of sort(kanbanDTO.inPreparationOrders)"
          (click)="openInPreparationOrder(order)"
        >
          <div class="float-right" *ngIf="order.producerFileStatus !== OrderFileStatus.FILE_TO_CHECK">
            <img
              src="assets/kanban/file-success.svg"
              style="width: 24px"
              *ngIf="order.producerFileStatus === OrderFileStatus.FILE_OK"
              ngbTooltip="Fichier 3D correct"
            />
            <img
              src="assets/kanban/file-error.svg"
              style="width: 24px"
              *ngIf="order.producerFileStatus === OrderFileStatus.FILE_TO_CORRECT"
              ngbTooltip="Fichier 3D à corriger"
            />
            <img
              src="assets/kanban/file-updated.svg"
              style="width: 24px"
              *ngIf="order.producerFileStatus === OrderFileStatus.FILE_CORRECTED"
              ngbTooltip="Fichier 3D corrigé"
            />
          </div>
          <div class="header ellipsis">En préparation depuis {{ moment(order.dateAccepted).fromNow(true) }}</div>
          <div class="title" *ngIf="!order.clientChoosedThisProducer">Demande Degineo</div>
          <div class="title" title="{{ order.clientName }}" *ngIf="order.clientChoosedThisProducer">{{ order.clientName }}</div>
          <div class="smaller">{{ order.printerBrand }} - {{ order.printerName }}</div>
          <div class="smaller" [class.outdated-deadline]="isOutdated(order.wishedDeliveryDate)">
            Deadline: {{ order.wishedDeliveryDate | date: 'dd/MM/yyyy' }}
          </div>
          <div class="smaller footer">Impression n° {{ order.idOrder }}</div>
        </app-order-item>
      </div>
    </div>

    <div class="kanban-col red">
      <h3 class="no-select">En production ({{ kanbanDTO.inProductionOrders.length }})</h3>
      <div class="kanban-col-content">
        <app-order-item
          class="mt-3"
          [class.shade]="highlightedItems.length > 0 && !highlightedItems.includes(order)"
          *ngFor="let order of sort(kanbanDTO.inProductionOrders)"
          (click)="openInProductionOrder(order)"
        >
          <div class="header ellipsis">En production depuis {{ moment(order.dateStartPrinting).fromNow(true) }}</div>
          <div class="title" *ngIf="!order.clientChoosedThisProducer">Demande Degineo</div>
          <div class="title" title="{{ order.clientName }}" *ngIf="order.clientChoosedThisProducer">{{ order.clientName }}</div>
          <div class="smaller">{{ order.printerBrand }} - {{ order.printerName }}</div>
          <div class="smaller" [class.outdated-deadline]="isOutdated(order.wishedDeliveryDate)">
            Deadline: {{ order.wishedDeliveryDate | date: 'dd/MM/yyyy' }}
          </div>
          <div class="smaller footer">Impression n° {{ order.idOrder }}</div>
        </app-order-item>
      </div>
    </div>

    <div class="kanban-col yellow">
      <h3 class="no-select">En conditionnement ({{ kanbanDTO.inPreparationShipments.length }})</h3>
      <div class="kanban-col-content">
        <app-order-item
          class="mt-3"
          [class.shade]="highlightedItems.length > 0 && !highlightedItems.includes(shipment)"
          *ngFor="let shipment of sort(kanbanDTO.inPreparationShipments)"
          (click)="openInPreparationShipment(shipment)"
        >
          <div class="header ellipsis">En conditionnement depuis {{ moment(shipment.shipmentCreationDate).fromNow(true) }}</div>
          <div class="title" *ngIf="!shipment.clientChoosedThisProducer">Demande Degineo</div>
          <div class="title" title="{{ shipment.clientName }}" *ngIf="shipment.clientChoosedThisProducer">{{ shipment.clientName }}</div>
          <div class="smaller" *ngIf="shipment.orders.length === 1">Impression n° {{ shipment.orders[0].idOrder }}</div>
          <div class="smaller" *ngIf="shipment.orders.length > 1">
            <u [ngbTooltip]="getOrderIds(shipment)">{{ shipment.orders.length }} impressions</u>
          </div>
          <div class="smaller" [class.outdated-deadline]="isOutdated(shipment.minWishedDeliveryDate)">
            Deadline: {{ shipment.minWishedDeliveryDate | date: 'dd/MM/yyyy' }}
          </div>
          <div class="smaller footer">Expédition n° {{ shipment.idShipment }}</div>
        </app-order-item>
      </div>
    </div>

    <div class="kanban-col green">
      <h3 class="no-select">En attente d'expédition ({{ kanbanDTO.waitingCarrierShipments.length }})</h3>
      <div class="kanban-col-content">
        <app-order-item
          class="mt-3"
          [class.shade]="highlightedItems.length > 0 && !highlightedItems.includes(shipment)"
          [class.red-border]="isOutdated(shipment.minWishedDeliveryDate)"
          *ngFor="let shipment of sort(kanbanDTO.waitingCarrierShipments)"
          (click)="openWaitingCarrierShipment(shipment)"
        >
          <div class="header ellipsis">En attente depuis {{ moment(shipment.dateWaitingCarrier).fromNow(true) }}</div>
          <div class="title" *ngIf="!shipment.clientChoosedThisProducer">Demande Degineo</div>
          <div class="title" title="{{ shipment.clientName }}" *ngIf="shipment.clientChoosedThisProducer">{{ shipment.clientName }}</div>

          <div class="smaller text-danger" *ngIf="shipment.status === ShipmentStatus.CALL_CARRIER_ERROR">Erreur d'appel du transporteur</div>
          <div class="smaller" *ngIf="shipment.producerDeliver && shipment.status !== ShipmentStatus.CALL_CARRIER_ERROR">
            En attente d'être livré par vos soins
          </div>
          <div class="smaller" *ngIf="!shipment.producerDeliver && shipment.status !== ShipmentStatus.CALL_CARRIER_ERROR">
            En attente du transporteur<img src="assets/header/logo_degineo_bleu_vert.svg" class="ml-1" style="height: 1rem" />
          </div>
          <div class="smaller" [class.outdated-deadline]="isOutdated(shipment.minWishedDeliveryDate)">
            Deadline: {{ shipment.minWishedDeliveryDate | date: 'dd/MM/yyyy' }}
          </div>
          <div class="smaller footer">Expédition n° {{ shipment.idShipment }}</div>
        </app-order-item>
      </div>
    </div>
  </div>
</div>

<div class="cover" *ngIf="openPanel"></div>
<app-right-panel [ngClass]="panelColor" [open]="openPanel" (close)="close()">
  <app-received-order-panel *ngIf="receivedOrder !== null" [id]="receivedOrder.idProposalProducer"></app-received-order-panel>
  <app-in-preparation-order-panel *ngIf="inPreparationOrder !== null" [id]="inPreparationOrder.idOrder"></app-in-preparation-order-panel>
  <app-in-production-order-panel *ngIf="inProductionOrder !== null" [id]="inProductionOrder.idOrder"></app-in-production-order-panel>
  <app-in-preparation-shipment-panel *ngIf="inPreparationShipment !== null" [id]="inPreparationShipment.idShipment"></app-in-preparation-shipment-panel>
  <app-waiting-for-shipping-panel *ngIf="waitingCarrierShipment !== null" [id]="waitingCarrierShipment.idShipment"></app-waiting-for-shipping-panel>
</app-right-panel>
