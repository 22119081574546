import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LegalStatus, ProducerSignupService, ProducerSubscriptionTunnelService, UserEditValidators } from 'degineo-common';
import { environment } from 'environments/environment';
import { L } from 'ic2-lib';
import { AccountCreationService } from '../../account-creation/account-creation.service';
import { SubscribeService } from '../subscribe.service';

@Component({
  selector: 'app-subscribe-step3-company',
  templateUrl: './subscribe-step3-company.component.html',
  styleUrls: ['./subscribe-step3-company.component.scss'],
})
export class SubscribeStep3CompanyComponent implements OnInit {
  options = {
    componentRestrictions: { country: ['fr', 'be', 'ch'] },
    types: ['address'],
  };

  loading: boolean = true;
  saving: boolean = false;
  form: FormGroup = this.fb.group({
    siret: [{ value: null, disabled: true }, []],
    name: [null, [Validators.required]],
    legalStatus: [null, [Validators.required]],
    capital: [
      null,
      [
        UserEditValidators.conditionalValidator(() => {
          return (
            this.form.value.legalStatus !== LegalStatus.STATUS_AE &&
            this.form.value.legalStatus !== LegalStatus.STATUS_EI &&
            this.form.value.legalStatus !== LegalStatus.STATUS_ASSOCIATION
          );
        }, [Validators.required, Validators.min(1)]),
      ],
    ],
    RNANumber: [
      null,
      [
        UserEditValidators.conditionalValidator(() => {
          return this.form.value.legalStatus === LegalStatus.STATUS_ASSOCIATION;
        }, Validators.required),
      ],
    ],
    RCS: [null, [Validators.required]],
    billingAddress: [null, [Validators.required]],
  });
  LegalStatus: typeof LegalStatus = LegalStatus;

  constructor(
    public subscribeService: SubscribeService,
    private fb: FormBuilder,
    private producerSubscriptionTunnelService: ProducerSubscriptionTunnelService
  ) {}

  ngOnInit() {
    this.producerSubscriptionTunnelService.getInfosStep3().subscribe(
      (data) => {
        this.loading = false;
        this.form.patchValue({
          siret: data.siret,
          name: data.name,
          legalStatus: data.legalStatus,
          capital: data.capital,
          RCS: data.RCS,
          billingAddress: data.billingAddress,
          RNANumber: data.RNANumber,
        });

        if (environment.name === 'DEV') {
          this.form.patchValue({
            legalStatus: LegalStatus.STATUS_SAS,
            capital: 1000,
            RCS: 'Châlons-en-champagne',
          });
        }
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  previous() {
    this.subscribeService.step = SubscribeService.STEP_2_USER;
  }

  submit() {
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }
    this.saving = true;
    this.producerSubscriptionTunnelService
      .saveStep3(
        this.form.value.name,
        this.form.value.legalStatus,
        this.form.value.capital === null ? 0 : this.form.value.capital,
        this.form.value.RCS,
        this.form.value.RNANumber,
        this.form.value.billingAddress
      )
      .subscribe(
        (data) => {
          this.subscribeService.step = SubscribeService.STEP_4_SHIPPING;
          this.saving = false;
        },
        (err) => {
          this.saving = false;
          L.e(err);
        }
      );
  }

  revalidateForm() {
    Object.values(this.form.controls).forEach((control) => {
      control.updateValueAndValidity();
    });
  }
}
