<div class="container-small py-5">
  <div class="row">
    <div class="col text-center">
      <img src="assets/header/logo_degineo_bleu_vert.svg" style="max-height: 4rem" />
    </div>
  </div>
  <div class="row mt-5">
    <div class="col text-center">
      <h2 style="font-size: 1.6rem">Vos imprimantes&nbsp;3D</h2>
    </div>
  </div>
  <div class="row" *ngIf="loading">
    <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
  </div>

  <ng-container *ngIf="!loading">
    <div class="row mt-4" style="margin-bottom: 1.5rem">
      <div class="col text-center">Ajoutez les imprimantes&nbsp;3D que vous possédez.</div>
    </div>
    <div class="row mt-3" *ngFor="let printer of printers">
      <div class="col">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-6 p-0 pr-2">
              <select class="form-control" [(ngModel)]="printer.brand" (ngModelChange)="printer.printer = null">
                <option [ngValue]="null" disabled="disabled">-- Choisir --</option>
                <option *ngFor="let brand of printerChoices" [ngValue]="brand">{{ brand.brand }}</option>
              </select>
            </div>
            <div class="col-6 p-0 pl-2 d-flex">
              <div class="flex-grow-1">
                <select class="form-control" [(ngModel)]="printer.printer">
                  <option [ngValue]="null" disabled="disabled">-- Choisir --</option>
                  <ng-container *ngIf="printer.brand !== null">
                    <option *ngFor="let p of printer.brand.printers" [ngValue]="p">{{ p.name }}</option>
                  </ng-container>
                </select>
              </div>
              <div class="px-2 text-danger fs-4 pointer" style="line-height: 2" (click)="removePrinter(printer)" *ngIf="printers.length > 1">
                <i class="far fa-times-circle"></i>
              </div>
            </div>
            <!--<div class="col-2 text-center d-flex align-items-center">
              
            </div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col fw-500">
        <a [routerLink]="null" (click)="addPrinter()">+ Ajouter une imprimante</a>
      </div>
    </div>
    <div class="row mt-4" *ngIf="showErrors">
      <div class="col">
        <div class="alert alert-danger pr-3 pl-3 pr-md-5 pl-md-5 pt-3 pb-3">
          <ul>
            <li>Une imprimante est requise.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-6">
        <button (click)="previous()" [disabled]="saving" class="btn btn-secondary w-100"><i class="fas fa-arrow-left"></i></button>
      </div>
      <div class="col-6">
        <button type="button" (click)="submit()" [disabled]="saving" class="btn btn-success w-100">
          <i class="fas fa-check"></i><i class="fas fa-spinner spinning ml-3" *ngIf="saving"></i>
        </button>
      </div>
    </div>
  </ng-container>
</div>
