<form autocomplete="off" #f="ngForm" [formGroup]="form" [class.show-errors]="f.submitted && !form.valid" (ngSubmit)="submit($event)">
  <div class="row">
    <div class="col text-center">
      <h2 style="font-size: 1.6rem">Comptes bancaires</h2>
    </div>
  </div>

  <div class="bg-light-30p rounded p-3 pb-4 mt-5">
    <div class="row">
      <div class="col">
        <label>Compte de prélèvement</label><br />
        C'est sur ce compte qu'est débité votre abonnement Degineo.
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <label>IBAN de prélèvement</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <div class="alert alert-warning mb-2" *ngIf="debug">
          Iban test :
          <ul>
            <li>ok: FR1420041010050500013M02606</li>
            <li>rate: FR8420041010050500013M02607</li>
            <li>opposition bancaire: FR5720041010050500013M02608</li>
          </ul>
        </div>
        <div class="fakeinput" #iban>
          <!-- A Stripe Element will be inserted here. -->
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label>BIC</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" class="form-control" formControlName="fakeBic" />
      </div>
    </div>
  </div>

  <div class="bg-light-30p rounded p-3 pb-4 mt-5">
    <div class="row">
      <div class="col">
        <label>Compte de paiement</label><br />
        C'est sur ce compte que vous recevez les versements de vos clients.<br />
        Il peut être identique au compte de prélèvement.
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <label>IBAN de paiement</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" class="form-control" formControlName="iban" mask="AAAA AAAA AAAA AAAA AAAA AAAA AAA" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label>BIC</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" class="form-control" formControlName="bic" />
      </div>
    </div>
  </div>

  <div class="row mt-4" *ngIf="f.submitted && !form.valid">
    <div class="col">
      <div class="alert alert-danger pr-3 pl-3 pr-md-5 pl-md-5 pt-3 pb-3">
        <ul>
          <li *ngIf="form.hasError('required', 'iban')">L'IBAN est requis.</li>
          <li *ngIf="form.hasError('required', 'bic')">Le BIC est requis.</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-6">
      <button (click)="previous()" [disabled]="saving" class="btn btn-secondary w-100"><i class="fas fa-arrow-left"></i></button>
    </div>
    <div class="col-6">
      <button type="submit" [disabled]="saving" class="btn btn-success w-100">
        <i class="fas fa-check"></i><i class="fas fa-spinner spinning ml-3" *ngIf="saving"></i>
      </button>
    </div>
  </div>
</form>
