<ng-template #mescommandes>
  La fonctionnalité <span class="fw-500">Mes commandes</span> est uniquement disponible pour les comptes abonnés.<br />
  Ici vous pourrez recevoir les demandes de vos clients et piloter celles-ci de leur réception jusqu'à leur expédition.
</ng-template>
<ng-template #devis>
  Vous devez posséder un compte abonné afin de recevoir des demandes de <span class="fw-500">devis</span> et pouvoir y répondre.<br />
  Une fois les devis acceptés par vos clients, vous pourrez les piloter depuis <span class="fw-500">Mes commandes</span>.
</ng-template>
<ng-template #monportefeuilleclient>
  L'utilisation du <span class="fw-500">portefeuille client</span> est réservée aux comptes abonnés.<br />
  Vous pourrez inviter vos clients à créer un espace Degineo, afin qu'il puisse communiquer facilement avec vous et suivre l'avancement de ses demandes.
</ng-template>
<ng-template #administratif>
  Vous n'avez aucun document <span class="fw-500">administratif</span>, car vous ne possédez pas de compte abonné.<br />
  Ici sera rangé et organisé votre rapport financier, vos factures de vente, ainsi que vos paiements en attente.
</ng-template>
<ng-template #utilisateurs>
  Pour accéder à la gestion des <span class="fw-500">utilisateurs</span>, vous devez posséder un compte abonné.<br />
  Avec un compte abonné, tous vos collaborateurs pourront accéder au logiciel depuis leur espace personnel, et agir dans celui-ci suivant les droits qui lui
  seront attribués.
</ng-template>
<ng-template #prisedecommande
  >Pour pouvoir accéder aux modules de <span class="fw-500">Prise de commande en ligne</span> et de <span class="fw-500">Demandes de devis en ligne</span>, vous
  devez posséder un compte abonné.
</ng-template>
<ng-template #dashboard>Pour consulter votre <span class="fw-500">Tableau de bord</span>, vous devez posséder un compte abonné. </ng-template>

<div class="container-fluid">
  <div class="row mt-5">
    <div class="col text-center">
      <img src="assets/subscription/icon_compte_abonne.svg" style="height: 13rem" />
    </div>
  </div>
  <div class="row mt-5">
    <div class="col text-center">
      <ng-template *ngIf="page !== null" [ngTemplateOutlet]="page"></ng-template>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col text-center">
      <h1 class="text-primary mb-5 mt-3 col-12">Choisissez l'abonnement adapté à vos besoins</h1>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <app-offer
        *ngIf="!loading"
        [legalStatus]="legalStatus"
        [nbPrinterFixe]="nbPrinter"
        [nbUserFixe]="0"
        [idPreviousPlan]="0"
        [modeEvolutionPlan]="true"
        [evolutionCauseIsPrinter]="false"
        (planSelected)="selected($event)"
      ></app-offer>
    </div>
  </div>
</div>
