import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Finish, ProducerDevisService } from 'degineo-common';
import { L } from 'ic2-lib';
import { ProducerDevisDTO, DevisNEW, DevisStatus, ProducerPrinterDTO, ProducerPrinterConsumableOptionDTO } from 'degineo-common';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../../../environments/environment';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrinterService } from 'degineo-common';
import { ConsumableService } from 'degineo-common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DateStringFr } from 'degineo-common';

@Component({
  selector: 'app-producer-devis-page',
  templateUrl: './producer-devis-page.component.html',
  styleUrls: ['./producer-devis-page.component.scss'],
})
export class ProducerDevisPageComponent implements OnInit, OnDestroy {
  sub: Subscription;
  loading: boolean = true;
  dto: ProducerDevisDTO = null;
  devis: DevisNEW = null;
  showPreview: boolean = false;
  sending: boolean = false;

  DevisStatus: typeof DevisStatus = DevisStatus;
  public readonly STEP_INFOS = 0;
  public readonly STEP_REFUSE = 1;
  public readonly STEP_DEVIS = 2;
  public readonly STEP_PREVIEW_DEVIS = 3;

  step: number = this.STEP_INFOS;
  rpcHost: string = environment.rpcHost;

  public INgxMyDpOptions: IAngularMyDpOptions = Object.assign(
    { disableUntil: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() } },
    DateStringFr.INgxMyDpOptions
  );
  public INgxMyDpOptionsSansWeekend: IAngularMyDpOptions = Object.assign(
    { disableUntil: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }, disableWeekends: true },
    DateStringFr.INgxMyDpOptions
  );
  devisForm: FormGroup = this.fb.group({
    deliveryDate: [null, [Validators.required]],
    validUntil: [null, [Validators.required]],
    printer: [null, [Validators.required]],
    consumable: [{ value: null, disabled: true }, [Validators.required]],
    finish: [{ value: null, disabled: true }, [Validators.required]],
    printTimeInMin: [null, [Validators.required, Validators.min(1)]],
    materialQuantityInCm3: [null, [Validators.required, Validators.min(1)]],
    unitPriceHT: [null, [Validators.required, Validators.min(1)]],
  });
  printers: ProducerPrinterDTO[] = [];
  consumables: ProducerPrinterConsumableOptionDTO[] = [];
  iframeUrl: SafeResourceUrl;
  devisUrl: SafeResourceUrl;
  finishs: Finish[] = [];

  constructor(
    @Inject(ProducerDevisService) public producerDevisService: ProducerDevisService,
    @Inject(PrinterService) public printerService: PrinterService,
    @Inject(ConsumableService) public consumableService: ConsumableService,
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    @Inject(FormBuilder) private fb: FormBuilder,
    public sanitizer: DomSanitizer,
    public router: Router
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.producerDevisService.getDevis(+params['id']).subscribe(
        (data) => {
          this.dto = data;
          this.devis = this.dto.devis;
          this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.rpcHost + 'previewDevis/' + data.tokenPreview);
          this.devisUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.rpcHost + 'devis/' + data.tokenPreview);
          this.loading = false;
        },
        (error) => {
          L.e(error);
          this.loading = false;
        }
      );
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  goStepDevis() {
    this.step = this.STEP_DEVIS;
    let validUntil = null;
    if (this.dto.devis.validUntil === null) {
      validUntil = new Date();
      validUntil.setDate(validUntil.getDate() + 60);
    } else {
      validUntil = this.devis.validUntil;
    }
    //TODO load printer & consumable data + patch value une fois le consumable reçu
    let deliveryDate = this.devis.wishedDeliveryDate;
    if (this.devis.proposedDeliveryDate) deliveryDate = this.devis.proposedDeliveryDate;
    console.log(this.devis, deliveryDate);

    this.devisForm.patchValue({
      deliveryDate: { isRange: false, singleDate: { jsDate: deliveryDate } } as IMyDateModel,
      validUntil: { isRange: false, singleDate: { jsDate: validUntil } } as IMyDateModel,
      printTimeInMin: this.devis.printTimeInMin,
      materialQuantityInCm3: this.devis.materialQuantityInCm3,
      unitPriceHT: this.devis.unitPriceHT,
      finish: this.devis.idFinish,
    });

    if (this.devis.idSelectedCompanyPrinterConsumableOption !== 0) {
      this.devisForm.get('consumable').enable();
      this.devisForm.get('finish').enable();
      this.devisForm.get('consumable').patchValue(this.devis.idSelectedCompanyPrinterConsumableOption);
      this.devisForm.get('printer').patchValue(this.dto.idSelectedCompanyPrinter);
    }
    this.printerService.getCompanyPrintersUnfiltered().subscribe(
      //c'est voulu voir #705
      (data) => {
        this.printers = data;
        if (this.devis.idSelectedCompanyPrinterConsumableOption !== 0) {
          this.loadConsumable(false);
        }
        this.loadFinish();
      },
      (error) => {
        L.e(error);
      }
    );
  }

  loadConsumable(resetValue: boolean = true) {
    if (resetValue) this.devisForm.patchValue({ consumable: null });
    if (this.devisForm.value.printer === null) {
      this.consumables = [];
      return;
    }
    this.consumableService.getCompanyConsumableUnfiltered(parseInt(this.devisForm.value.printer, 10)).subscribe(
      (data) => {
        this.consumables = data;
        console.log(
          'selected cpc',
          this.devis.idSelectedCompanyPrinterConsumableOption,
          this.dto.idSelectedCompanyPrinter,
          this.devisForm.value,
          this.consumables
        );
        this.devisForm.get('consumable').enable();
        this.devisForm.get('finish').enable();
        if (this.consumables.length === 1) {
          this.devisForm.get('consumable').patchValue(this.consumables[0].producerPrinterConsumableOption.id);
        }
      },
      (error) => {
        L.e(error);
      }
    );
  }

  loadFinish() {
    if (this.devisForm.value.printer === null) {
      this.finishs = [];
      return;
    }
    this.producerDevisService.getFinishFor(parseInt(this.devisForm.value.printer, 10)).subscribe(
      (data) => {
        this.finishs = data;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  previewDevis() {
    if (!this.devisForm.valid) {
      console.log(this.devisForm);
      return;
    }
    const value = this.devisForm.value;
    console.log(value);
    this.producerDevisService
      .saveDevis(
        this.devis.id,
        new Date(value.deliveryDate.singleDate.jsDate),
        new Date(value.validUntil.singleDate.jsDate),
        parseInt(value.consumable, 10),
        parseInt(value.finish, 10),
        value.materialQuantityInCm3,
        value.printTimeInMin,
        value.unitPriceHT
      )
      .subscribe(
        (data) => {
          this.dto.idSelectedCompanyPrinter = value.printer;
          this.devis.idSelectedCompanyPrinterConsumableOption = value.consumable;

          this.router.navigate(['/devis', this.devis.id, 'preview']);
        },
        (error) => {
          L.e(error);
        }
      );
  }
}
