<div class="d-flex flex-column" style="height: 100vh !important">
  <div class="container-fluid bg-light-30p bg-logo flex-grow-1 pb-5">
    <div class="row mt-5">
      <div class="col-12 text-center">
        <a [href]="svProducerUrl">
          <img src="assets/header/logo_degineo_bleu_vert.svg" width="310px" />
        </a>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 text-center fw-300 fs-5 text-dark">Espace Imprimeur 3D</div>
    </div>

    <div class="row mt-5">
      <div class="col">
        <div class="container-small bg-white rounded shadow-2">
          <!--<div style="width: 23rem"></div>-->
          <form #f="ngForm" [formGroup]="form" [class.show-errors]="f.submitted && !form.valid" (submit)="submit()">
            <div class="row" style="padding-top: 2rem !important">
              <div class="col text-center">
                <h3>J'ai déjà un compte Degineo</h3>
              </div>
            </div>
            <div class="container-fluid goodwith p-0 mt-4">
              <div class="row">
                <div class="col">
                  <div class="form-group" style="margin-bottom: 2rem">
                    <i class="fas fa-envelope input-icon"></i>
                    <input
                      type="email"
                      class="form-control input-with-icon"
                      id="email"
                      aria-describedby="emailHelp"
                      autocomplete="username"
                      placeholder="E-mail"
                      formControlName="email"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group m-0">
                    <i class="fas fa-lock input-icon"></i>
                    <input
                      type="password"
                      class="form-control input-with-icon"
                      id="password"
                      autocomplete="current-password"
                      placeholder="Mot de passe"
                      formControlName="password"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="row mt-1 justify-content-end">
                    <div class="col-auto">
                      <a class="fw-400 fs-2 text-info pointer" (click)="openResetPasswordPopup()">Mot de passe oublié ?</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4" *ngIf="loginError || (f.submitted && !form.valid)">
                <div class="col">
                  <div class="alert alert-danger px-3 px-md-5 py-3">
                    <ul>
                      <li *ngIf="loginError">{{ loginError }}.</li>
                      <li *ngIf="form.hasError('required', 'email')">L'E-mail est requis.</li>
                      <li *ngIf="form.hasError('email', 'email')">L'E-mail est invalide.</li>
                      <li *ngIf="form.hasError('required', 'password')">Le mot de passe est requis.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col text-center">
                <button type="submit" [disabled]="loading" class="btn btn-success text-uppercase text-nowrap w-100">Se connecter</button>
              </div>
            </div>
            <div class="row my-2 justify-content-center">
              <div class="col-9">
                <hr />
              </div>
            </div>
            <div class="row pb-4">
              <div class="col text-center fs-2">
                Si vous souhaitez trouver un imprimeur 3D<br />
                <a class="fw-500" href="{{ clientUrl }}connexion">Connectez-vous ici</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <div class="container-small bg-white rounded shadow-2 py-4">
          <div class="row">
            <div class="col text-center">
              <h3>Nouveau sur Degineo ?</h3>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-center text-uppercase">
              <a class="fw-500" style="letter-spacing: 0.025em" routerLink="/inscription">Créer un compte</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
