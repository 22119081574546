<ng-template #sepaContent let-modal>
  <div class="container shadow-5 p-5">
    <i class="close-icon fas fa-times" (click)="modal.close()"></i>
    <div class="row mt-2">
      <div class="col text-center">
        <h2 style="font-size: 1.6rem">Mandat de prélèvement SEPA</h2>
      </div>
    </div>
    <div class="row mt-3 bg-light-30p">
      <div class="col p-4">
        <p class="fw-700">Créancier: Degineo</p>
        <p [innerHTML]="formatAddress(dto.degineoAddress)"></p>
        <p>
          Identifiant créancier SEPA: <span class="fw-700">{{ dto.degineoSepaIdentifier }}</span>
        </p>
        <p>Référence Unique de Mandat (RUM) : A venir</p>
      </div>
    </div>
    <div class="row mt-3 bg-light-30p">
      <div class="col p-4">
        <p class="fw-700">Débiteur: {{ dto.name }}</p>
        <p [innerHTML]="formatAddress(dto.billingAddress)"></p>
        <p>Numéro IBAN du compte qui sera prélevé tout les mois : <span class="fw-700">A venir</span></p>
        <!--<p><span class="fw-700">BIC : </span> {{ step7form.value.fakeBic }}</p>-->
      </div>
    </div>
  </div>
</ng-template>

<form autocomplete="off" #f="ngForm" [formGroup]="form" [class.show-errors]="f.submitted && !form.valid" (submit)="submit()">
  <div class="row">
    <div class="col text-center">
      <h2>Veuillez prendre connaissance des documents</h2>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col">
      <h3>Votre contrat d'abonnement</h3>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <a href="todo">Voir le contrat (TODO)</a>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col">
      <h3>Votre mandat de facturation</h3>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <a [routerLink]="null" (click)="showPopup($event, sepaContent, true)">Voir le mandat</a>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col d-flex">
      <div><input type="checkbox" formControlName="engagment" id="engagment" /></div>
      <div class="ml-2">
        <label for="engagment" class="pl-0 fw-400" style="line-height: 1.2">
          Je m'engage à tenir Degineo informé dans les plus brefs délais si les informations transmises venaient à changer.<br />
          (Vous pourrez le faire depuis votre Espace Degineo).
        </label>
      </div>
    </div>
  </div>

  <div class="row mt-4" *ngIf="f.submitted && !form.valid">
    <div class="col">
      <div class="alert alert-danger pr-3 pl-3 pr-md-5 pl-md-5 pt-3 pb-3">
        <ul>
          <li *ngIf="form.hasError('required', 'engagment')">Pour continuer, vous devez accepter les conditions ci-dessus.</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-6">
      <button (click)="accountCreationService.previous()" [disabled]="saving" class="btn btn-secondary w-100"><i class="fas fa-arrow-left"></i></button>
    </div>
    <div class="col-6">
      <button type="submit" [disabled]="saving" class="btn btn-success w-100">
        <i class="fas fa-check"></i><i class="fas fa-spinner spinning ml-3" *ngIf="saving"></i>
      </button>
    </div>
  </div>
</form>
