<div class="container" style="margin-top: 15vh">
  <div class="row">
    <div class="col text-center">
      <i class="far fa-check-circle text-success" style="font-size: 6rem"></i>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col text-center fs-5 fw-300">Merci d'avoir validé votre e-mail.</div>
  </div>
  <div class="row mt-5">
    <div class="col text-center">
      <a class="btn btn-primary" routerLink="/">Terminer</a>
    </div>
  </div>
</div>
