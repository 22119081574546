<i class="fas fa-spinner spinning" *ngIf="dto === null"></i>
<ng-template #excluMessage>
  Nous avons évalué que vous étiez le plus qualifié du réseau Degineo pour réaliser cette demande.<br />
  Nous vous en avons donc donné l'exclusivité
</ng-template>
<ng-template #degineoMessage>
  D'autres producteurs voient cette même demande en ce moment.<br />
  Le 1er qui l'acceptera l'obtiendra.<br />
  Si un autre que vous l'accepte, elle disparaitra.
</ng-template>
<div class="container-fluid" *ngIf="dto !== null">
  <div class="row text-k-blue fw-500 fs-2 mt-2">
    <div class="col" *ngIf="authService.isAtLeast(RoleInCompany.PRODUCER_ADMIN)">Payé&nbsp;: {{ dto.subcontractingTotalPriceTTC | number: '.2':'fr-FR' }}€</div>
    <div class="col text-right mr-5">Reçu {{ moment(dto.dateCreated).fromNow() }}</div>
  </div>
  <div class="row mt-3 fs-4">
    <div class="col fw-700" *ngIf="dto.exclusive">
      Vous avez l'exclusivité
      <i class="fas fa-heart text-danger" style="margin-left: 0.2rem" [ngbTooltip]="excluMessage"></i>
    </div>
    <div class="col fw-700" *ngIf="!dto.exclusive && !dto.clientChoosedThisProducer">
      Demande Degineo
      <i class="fas fa-clock text-warning" style="margin-left: 0.2rem" [ngbTooltip]="degineoMessage"></i>
    </div>
    <div class="col fw-700" *ngIf="!dto.exclusive && dto.clientChoosedThisProducer">
      {{ dto.client.clientName }} <a [routerLink]="null" class="ml-2" (click)="openPopupInfos()"><i class="fas fa-info-circle"></i></a>
    </div>
  </div>

  <app-panel-detail
    [token3DModelFinal]="dto.token3DModel"
    [filenameFinal]="dto.filename"
    [quantity]="dto.quantity"
    [sizeXMm]="dto.sizeXMm"
    [sizeYMm]="dto.sizeYMm"
    [sizeZMm]="dto.sizeZMm"
    [colorName]="dto.colorName"
    [colorHex]="dto.colorHex"
    [deadline]="dto.wishedDeliveryDate"
    [finishName]="dto.finishName"
  ></app-panel-detail>

  <app-panel-address class="mt-3" [address]="dto.shippingAddress" *ngIf="dto.shippingAddress !== null"></app-panel-address>

  <div class="row mt-3">
    <div class="col"><span class="fw-700">À imprimer sur&nbsp;:</span> {{ dto.printerBrand }} - {{ dto.printerName }}</div>
  </div>
  <div class="row mt-3">
    <div class="col"><span class="fw-700">Consommable à utiliser&nbsp;:</span> {{ dto.consumableName }}</div>
  </div>
  <div class="row mt-3" *ngIf="!dto.dental">
    <div class="col">
      <span class="fw-700">Consommation matière&nbsp;:</span> {{ dto.materialQuantityPerPiece | number: '.2':'fr-FR' }} cm<sup>3</sup> par pièce
    </div>
  </div>
  <div class="row mt-3" *ngIf="!dto.dental">
    <div class="col"><span class="fw-700">Utilisation machine&nbsp;:</span> {{ dto.machineUsagePerPiece | number: '.2':'fr-FR' }} % par pièce</div>
  </div>
  <div class="row mt-3" *ngIf="dto.comment !== null">
    <div class="col fw-700">Commentaire client&nbsp;:</div>
  </div>
  <div class="row" *ngIf="dto.comment !== null">
    <div class="col">
      {{ dto.comment }}
    </div>
  </div>
  <div class="row mt-3" *ngIf="dto.noteDegineo !== null">
    <div class="col fw-700">Commentaire Degineo&nbsp;:</div>
  </div>
  <div class="row" *ngIf="dto.noteDegineo !== null">
    <div class="col">
      {{ dto.noteDegineo }}
    </div>
  </div>
  <div class="row mt-5" *ngIf="authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING">
    <div
      class="col"
      *ngIf="!dto.clientChoosedThisProducer"
      [ngbTooltip]="
        hasRightToAcceptOrRefuse() ? null : 'Vous n\'avez pas les droits pour accepter les demandes Degineo, seuls le Titulaire ou un Admin peuvent le faire'
      "
    >
      <button class="btn btn-danger w-100" [disabled]="loading || !hasRightToAcceptOrRefuse()" (click)="refuse()">
        <i class="fas fa-times-circle text-white mr-2"></i>Refuser cette demande
      </button>
    </div>
    <div
      class="col"
      [class.text-center]="dto.clientChoosedThisProducer"
      [ngbTooltip]="
        hasRightToAcceptOrRefuse() ? null : 'Vous n\'avez pas les droits pour accepter les demandes Degineo, seuls le Titulaire ou un Admin peuvent le faire'
      "
    >
      <button class="btn btn-success" [class.w-100]="!dto.clientChoosedThisProducer" [disabled]="loading || !hasRightToAcceptOrRefuse()" (click)="accept()">
        {{ dto.clientChoosedThisProducer ? 'Lancer en préparation' : 'Accepter cette demande' }} <i class="fas fa-fast-forward text-white ml-2"></i>
      </button>
    </div>
  </div>
</div>
