import { Component, OnInit, Host, Inject } from '@angular/core';
import { MyPrintersItemComponent } from '../my-printers-item/my-printers-item.component';
import { ProducerPrinterService, ArticleTypeNEW, PPATPFullDTO } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-my-printers-pieces-prices',
  templateUrl: './my-printers-pieces-prices.component.html',
  styleUrls: ['./my-printers-pieces-prices.component.scss'],
})
export class MyPrintersPiecesPricesComponent implements OnInit {
  add: boolean = false;
  edit: { [key: number]: boolean } = {};
  noMoreArticleType: boolean = false;
  atChoice: ArticleTypeNEW[] = [];
  selectedAt: ArticleTypeNEW = null;
  price: number = null;

  constructor(
    @Host() public myPrintersItemComponent: MyPrintersItemComponent,
    @Inject(ProducerPrinterService) private producerPrinterService: ProducerPrinterService
  ) {}

  ngOnInit() {}

  onChange() {
    this.price = this.selectedAt.defaultPrice;
  }

  addMode() {
    this.producerPrinterService.getArticleTypeChoices(this.myPrintersItemComponent.printerDto.idProducerPrinter).subscribe(
      (data) => {
        this.atChoice = data;
        if (this.atChoice.length === 0) this.noMoreArticleType = true;
        else this.add = true;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  addAT() {
    if (this.price === null || this.selectedAt === null) return;
    this.producerPrinterService.addArticleTypePrice(this.myPrintersItemComponent.printerDto.idProducerPrinter, this.selectedAt.id, this.price).subscribe(
      (data) => {
        this.add = false;
        this.myPrintersItemComponent.printerDto.ppatps.push(data);
      },
      (err) => {
        L.e(err);
      }
    );
  }

  saveEdit(ppatp: PPATPFullDTO, value: string) {
    const val = parseFloat(value);
    this.producerPrinterService.changeArticleTypePrice(ppatp.idProducerPrinterArticleTypePrice, val).subscribe(
      (data) => {
        ppatp.unitPrice = val;
        this.edit[ppatp.idProducerPrinterArticleTypePrice] = false;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  deleteAt(ppatp: PPATPFullDTO) {
    if (!confirm('Voulez vous vraiment supprimer la pièce unitaire : ' + ppatp.name + ' ?')) return;
    this.producerPrinterService.deleteArticleTypePrice(ppatp.idProducerPrinterArticleTypePrice).subscribe(
      (data) => {
        this.myPrintersItemComponent.printerDto.ppatps.splice(this.myPrintersItemComponent.printerDto.ppatps.indexOf(ppatp), 1);
      },
      (err) => {
        L.e(err);
      }
    );
  }
}
