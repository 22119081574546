<ng-template #modal let-modal>
  <div class="container-fluid shadow-5 p-4">
    <div class="row">
      <div class="col text-center">
        Êtes-vous sûr de vouloir choisir l'abonnement <span class="fw-700">{{ selectedPlan.name }}</span
        >&nbsp;?
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center">
        <button class="btn btn-secondary" (click)="modal.close(false)">Annuler</button>
        <button class="btn btn-success ml-3" (click)="modal.close(true)">Changer d'abonnement</button>
      </div>
    </div>
  </div>
</ng-template>

<div class="container-fluid">
  <div class="mt-4 w-100 ml-2 position-relative text-primary text-left">
    <a class="fs-4 pointer" style="left: 0; top: 0" (click)="previous()"><i class="fas fa-long-arrow-alt-left pl-3 mr-2"></i>Retour</a>
  </div>
  <div class="row mt-3">
    <div class="col text-center">
      Vous utilisez actuellement l’abonnement <span class="fw-700">{{ dto.currentPlanName }}</span> qui est limité à
      <span class="fw-700">
        {{ printer ? dto.currentNbPrinter : dto.currentNbUser }}
        {{
          printer
            ? 'imprimante' + ((printer ? dto.currentNbPrinter : dto.currentNbUser) > 1 ? 's' : '')
            : 'utilisateur' + ((printer ? dto.currentNbPrinter : dto.currentNbUser) > 1 ? 's' : '')
        }}</span
      >,<br />
      si vous souhaitez ajouter plus d’{{ printer ? 'imprimantes' : 'utilisateurs' }} vous devez passer sur un abonnement supérieur.
    </div>
  </div>
  <div class="row mt-3" *ngIf="!holder">
    <div class="col text-center">
      Seul <span class="fw-700">{{ dto.firstNameHolder }} {{ dto.lastNameHolder }}</span> a les droits pour effectuer un changement d’abonnement.<br />
      Vous devez le contacter afin qu’il fasse le changement vers un abonnement supérieur.
    </div>
  </div>
  <div class="row mt-3" *ngIf="!holder">
    <div class="col text-center">
      <button class="btn btn-secondary" (click)="previous()">Retour</button>
    </div>
  </div>
  <div class="row mt-5" *ngIf="holder">
    <div class="col">
      <app-offer
        *ngIf="!loading"
        [legalStatus]="dto.legalStatus"
        [nbPrinterFixe]="dto.currentNbPrinter"
        [nbUserFixe]="dto.currentNbUser"
        [idPreviousPlan]="dto.idPlan"
        [modeEvolutionPlan]="true"
        [evolutionCauseIsPrinter]="printer"
        (previous)="previous()"
        (planSelected)="openModal(modal, $event)"
      ></app-offer>
    </div>
  </div>
</div>
