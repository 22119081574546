<div class="row mt-4">
  <div class="col">
    <div class="text-center alert alert-success">
      {{ parent.dto.collectionLabel }}
    </div>
  </div>
</div>

<div class="row mt-4" *ngIf="isOutdatedOrEqual(parent.dto.collectionDate)">
  <div class="col">
    <div class="text-center border rounded border-danger text-danger p-4" style="border-width: 2px !important">
      <span class="fw-700">Le transporteur est-il passé récupérer {{ parent.dto.shipmentPackages.length > 1 ? 'les' : 'le' }} colis ?</span><br />
      <br />
      <span class="fw-500">Si le transporteur n'est pas passé&nbsp;:</span><br />
      contactez-nous via le <a [routerLink]="null" (click)="openChat()">chat live</a>.<br />
      <br />
      <span class="fw-500">Si le transporteur est bien passé&nbsp;:</span><br />
      cliquez sur le bouton ci&#8209;dessous&nbsp;:<br />
      <button
        class="btn btn-success mt-2"
        (click)="transportOk()"
        [disabled]="loading"
        *ngIf="authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING"
      >
        Le transporteur est passé
      </button>
    </div>
  </div>
</div>

<div class="row mt-4">
  <div class="col text-center">
    <h3>
      Collez le{{ parent.dto.shipmentPackages.length > 1 ? 's' : '' }} bordereau{{ parent.dto.shipmentPackages.length > 1 ? 'x' : '' }} ci&#8209;dessous sur
      le{{ parent.dto.shipmentPackages.length > 1 ? 's' : '' }}
      colis&nbsp;:
    </h3>
  </div>
</div>

<div class="row mt-3" *ngFor="let waybill of parent.dto.waybills">
  <div class="col text-center">
    <a class="btn btn-primary text-white" href="{{ rpcHost }}waybill/{{ waybill.token }}/{{ waybill.pdfName }}?dl"
      ><i class="fas fa-file-download mr-2"></i>{{ waybill.pdfName }}</a
    >
  </div>
</div>

<div class="row mt-4" *ngFor="let first = first; let p; of: parent.dto.shipmentPackages">
  <div class="col">
    <div class="container-fluid border border-secondary rounded-lg py-3" style="border-width: 2px !important">
      <div class="row">
        <div class="col text-center">
          <h4 class="text-secondary">Colis n°&nbsp;{{ p.id }}</h4>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label>Dimensions du carton utilisé&nbsp;:</label> {{ p.sizeXCm | number: '.2':'fr-FR' }} x {{ p.sizeZCm | number: '.2':'fr-FR' }} x
          {{ p.sizeYCm | number: '.2':'fr-FR' }} cm
        </div>
      </div>

      <div class="row mt-3">
        <div class="col"><label>Poids total du colis&nbsp;:</label> {{ p.weightInGr | number: '.0':'fr-FR' }} grammes</div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <label>Impressions et nombre d'exemplaires par colis&nbsp;:</label>
        </div>
      </div>

      <div class="row mt-2" *ngFor="let firstOrder = first; let spho; of: p.orders">
        <div class="col ml-4">
          N° <u class="pointer" (click)="openPopup(spho)">{{ spho.idOrder }}</u> x {{ spho.quantity }}
        </div>
      </div>
    </div>
  </div>
</div>
