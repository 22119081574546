import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { AuthService, Filter, FilterOrder, GenericFilter, InvoiceProducerDTO, OrderBy, ProducerInvoiceService, InvoiceProducerStatus } from 'degineo-common';
import { environment } from 'environments/environment';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-my-invoices',
  templateUrl: './my-invoices.component.html',
  styleUrls: ['./my-invoices.component.scss'],
})
export class MyInvoicesComponent implements OnInit, AfterViewInit {
  static FilterCache = {
    client: null,
    date: null,
    reference: null,
  };
  static FilterDefault: {} = {
    client: null,
    date: null,
    reference: null,
  };
  static OrderCache = {};
  static page = 1;
  MyInvoicesComponent: typeof MyInvoicesComponent = MyInvoicesComponent;
  InvoiceProducerStatus: typeof InvoiceProducerStatus = InvoiceProducerStatus;
  invoices: InvoiceProducerDTO[] = [];
  invoice: InvoiceProducerDTO = null;
  OrderBy: typeof OrderBy = OrderBy;
  loaded: boolean = false;
  loading: boolean = false;
  count = 0;
  nbPerPage = 20;
  rpcHost = environment.rpcHost;
  builtFilters: Filter[] = [];
  builtFiltersOrder: FilterOrder[] = [];

  constructor(@Inject(ProducerInvoiceService) public producerInvoiceService: ProducerInvoiceService, public authService: AuthService) {}

  ngOnInit() {}
  ngAfterViewInit() {
    //dans le afterviewinit pour prendre en compte les default filter
    this.reload();
  }

  reload() {
    const genericFilter = new GenericFilter();
    genericFilter.filters = this.builtFilters;
    genericFilter.orderFilters = this.builtFiltersOrder;
    genericFilter.page = MyInvoicesComponent.page;
    genericFilter.nbPerPage = this.nbPerPage;
    //console.log(MyInvoicesComponent.FilterCache);
    this.loading = true;
    this.producerInvoiceService.getBills(genericFilter).subscribe(
      (data) => {
        this.invoices = data.data;
        this.count = data.count;
        this.loaded = true;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
  orderChange(order) {
    console.log('orderChange', order);
    this.reload();
  }
  tableRowClick(row) {
    console.log('tableRowClick', row);
    this.invoice = row;
  }

  close() {
    this.invoice = null;
  }
}
