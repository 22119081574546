import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProducerDevisDTO, DevisNEW, DevisStatus } from 'degineo-common';
import { environment } from '../../../../../../../environments/environment';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ProducerDevisService } from 'degineo-common';
import { ActivatedRoute, Router } from '@angular/router';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-producer-devis-preview-page',
  templateUrl: './producer-devis-preview-page.component.html',
  styleUrls: ['./producer-devis-preview-page.component.scss'],
})
export class ProducerDevisPreviewPageComponent implements OnInit, OnDestroy {
  sub: Subscription;
  loading: boolean = true;
  dto: ProducerDevisDTO = null;
  devis: DevisNEW = null;
  sending: boolean = false;

  DevisStatus: typeof DevisStatus = DevisStatus;

  rpcHost: string = environment.rpcHost;

  iframeUrl: SafeResourceUrl;
  devisUrl: SafeResourceUrl;

  constructor(
    @Inject(ProducerDevisService) public producerDevisService: ProducerDevisService,
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public router: Router
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.producerDevisService.getDevis(+params['id']).subscribe(
        (data) => {
          this.dto = data;
          this.devis = this.dto.devis;
          this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.rpcHost + 'previewDevis/' + data.tokenPreview);
          this.devisUrl = this.rpcHost + 'devis/' + data.tokenPreview;
          this.loading = false;
        },
        (error) => {
          L.e(error);
          this.loading = false;
        }
      );
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  sendDevis() {
    this.sending = true;
    this.producerDevisService.sendDevis(this.devis.id).subscribe(
      (data) => {
        this.router.navigate(['/devis']);
        this.sending = false;
      },
      (error) => {
        this.sending = false;
        L.e(error);
      }
    );
  }
}
