import { Component, OnInit, Inject } from '@angular/core';
import { ShipmentOrderPanelDTO, InPreparationShipmentService, RoleInCompany, AuthService } from 'degineo-common';
import * as moment_ from 'moment';
import { environment } from 'environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { L } from 'ic2-lib';
import { PanelHeaderComponent } from '../panel-header/panel-header.component';

@Component({
  selector: 'app-fabrication-order-popup',
  templateUrl: './fabrication-order-popup.component.html',
  styleUrls: ['./fabrication-order-popup.component.scss'],
})
export class FabricationOrderPopupComponent implements OnInit {
  moment = moment_;
  rpcHost: string = environment.rpcHost;
  dto: ShipmentOrderPanelDTO;
  loading: boolean = false;
  canCancel: boolean = true;

  isOutdated = PanelHeaderComponent.isOutdatedStatic;
  RoleInCompany: typeof RoleInCompany = RoleInCompany;

  constructor(
    public activeModal: NgbActiveModal,
    @Inject(InPreparationShipmentService) private inPreparationShipmentService: InPreparationShipmentService,
    @Inject(AuthService) public authService: AuthService
  ) {}

  ngOnInit() {}

  cancelShipmentForOrder() {
    this.loading = true;
    this.inPreparationShipmentService.cancelShippingOrder(this.dto.idOrder).subscribe(
      (data) => {
        this.activeModal.close('reload');
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  close() {
    this.activeModal.close();
  }
}
