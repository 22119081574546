<form
  autocomplete="off"
  #f="ngForm"
  [formGroup]="takeControlService.step2form"
  [class.show-errors]="f.submitted && !takeControlService.step2form.valid"
  (submit)="submit()"
>
  <div class="container-small py-5">
    <div class="row">
      <div class="col text-center">
        <img src="assets/header/logo_degineo_bleu_vert.svg" style="max-height: 4rem" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center fs-4 fw-500">Selectionnez la catégorie qui décrit le mieux votre structure.</div>
    </div>
    <div class="row mt-2">
      <div class="col text-center">Cela permettra à vos clients de vous trouver plus facilement.</div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <select class="form-control" formControlName="businessType">
          <option [ngValue]="null">-- Choisir votre catégorie --</option>
          <option *ngFor="let type of BusinessType.values" [ngValue]="type">{{ type.lib }}</option>
        </select>
      </div>
    </div>
    <div class="row mt-4" *ngIf="f.submitted && !takeControlService.step2form.valid">
      <div class="col">
        <div class="alert alert-danger pr-3 pl-3 pr-md-5 pl-md-5 pt-3 pb-3">
          <ul>
            <li *ngIf="takeControlService.step2form.hasError('required', 'businessType')">Vous devez choisir une catégorie.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-6">
        <button (click)="previous()" class="btn btn-secondary w-100"><i class="fas fa-arrow-left"></i></button>
      </div>
      <div class="col-6">
        <button type="submit" class="btn btn-success w-100">
          <i class="fas fa-check"></i>
        </button>
      </div>
    </div>
  </div>
</form>
