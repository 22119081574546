import { Component, OnInit } from '@angular/core';
import { ProducerShareInvoiceDTO, ShareInvoiceService } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-invoice-share',
  templateUrl: './invoice-share.component.html',
  styleUrls: ['./invoice-share.component.scss'],
})
export class InvoiceShareComponent implements OnInit {
  dto: ProducerShareInvoiceDTO = null;
  dtoSave: ProducerShareInvoiceDTO = null;
  loading: boolean = false;
  days: number[] = [];
  edit: boolean = false;

  constructor(private shareInvoiceService: ShareInvoiceService) {
    for (let index = 1; index < 29; index++) {
      this.days.push(index);
    }
  }

  ngOnInit(): void {
    this.shareInvoiceService.getShareInfos().subscribe(
      (data: ProducerShareInvoiceDTO) => {
        this.dto = data;
        if (this.dto.shareInvoiceEmails === null || this.dto.shareInvoiceEmails.length === 0) this.dto.shareInvoiceEmails = [''];
        this.dtoSave = Object.assign(new ProducerShareInvoiceDTO(), this.dto);
        this.dtoSave.shareInvoiceEmails = Array.from(this.dto.shareInvoiceEmails);
      },
      (error) => L.e(error)
    );
  }

  deleteEmail(email) {
    this.dto.shareInvoiceEmails.splice(this.dto.shareInvoiceEmails.indexOf(email), 1);
  }

  addEmail() {
    this.dto.shareInvoiceEmails.push('');
  }

  cancel() {
    this.dto = Object.assign(new ProducerShareInvoiceDTO(), this.dtoSave);
    this.dto.shareInvoiceEmails = Array.from(this.dtoSave.shareInvoiceEmails);
    this.edit = false;
  }

  save(form) {
    if (!form.form.valid) {
      console.log(form);
      return;
    }
    this.shareInvoiceService.updateShare(this.dto).subscribe(
      (data) => {
        this.dtoSave = Object.assign(new ProducerShareInvoiceDTO(), this.dto);
        this.edit = false;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  trackByFn(index: any, item: any) {
    return index;
  }
}
