<div class="container mt-5">
  <div *ngIf="loading" class="row">
    <div class="col">
      <i class="fas fa-spinner spinning" *ngIf="loading"></i>
    </div>
  </div>
  <form [formGroup]="form" *ngIf="!loading" (ngSubmit)="sendMail()">
    <div class="row">
      <div class="col">
        <label for="lastName">Nom de l'utilisateur</label>
      </div>
    </div>
    <div class="row mt-2 mb-5">
      <div class="col">
        <input type="text" formControlName="lastName" class="form-control" id="lastName" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <label for="firstName">Prénom de l'utilisateur</label>
      </div>
    </div>
    <div class="row mt-2 mb-5">
      <div class="col">
        <input type="text" formControlName="firstName" class="form-control" id="firstName" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <label for="email">Adresse e-mail de {{ form.value.firstName !== null ? form.value.firstName : "l'utilisateur" }}</label>
      </div>
    </div>
    <div class="row mt-2 mb-5">
      <div class="col">
        <input type="email" formControlName="email" class="form-control" id="email" />
        <div *ngIf="emailUsed" class="text-danger">Cette adresse e-mail est déjà utilisée</div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <label for="job">Intitulé du poste de {{ form.value.firstName !== null ? form.value.firstName : "l'utilisateur" }}</label>
      </div>
    </div>
    <div class="row mt-2 mb-5">
      <div class="col">
        <input type="text" formControlName="job" class="form-control" id="job" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <label for="role">Rôle de {{ form.value.firstName !== null ? form.value.firstName : "l'utilisateur" }}</label>
      </div>
    </div>
    <div class="row mt-2 mb-5">
      <div class="col">
        <select class="form-control" id="role" formControlName="role" (change)="emitRole()">
          <option [ngValue]="null" disabled="disabled">-- Choisir le rôle de cet utilisateur --</option>
          <ng-container *ngFor="let r of roles">
            <ng-container *ngIf="loggedUserRole !== RoleInCompany.PRODUCER_SUPERVISER">
              <option *ngIf="r !== RoleInCompany.PRODUCER_HOLDER" [ngValue]="r">{{ r.lib }}</option>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="loggedUserRole === RoleInCompany.PRODUCER_SUPERVISER">
            <option [ngValue]="RoleInCompany.PRODUCER_COLLABORATOR">{{ RoleInCompany.PRODUCER_COLLABORATOR.lib }}</option>
          </ng-container>
        </select>
      </div>
    </div>

    <div class="mb-3">
      <button [disabled]="!form.valid" type="submit" class="btn btn-success w-100 px-5">Envoyer l'invitation à cet utilisateur</button>
    </div>
    <div>
      <button class="btn btn-secondary w-100 px-5" (click)="cancel.emit()">Annuler</button>
    </div>
  </form>
</div>
