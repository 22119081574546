<div class="container-fluid px-4" *ngIf="client !== null">
  <div class="row">
    <div class="col text-center fs-4 fw-700">
      {{ client.companyName }}
    </div>
  </div>

  <div class="row mt-2">
    <div class="col text-center text-danger" *ngIf="client.paymentStatus === ProducerClientPaymentStatus.LATE_PAYMENT">
      <i class="fas fa-exclamation-circle mr-2"></i> <span class="fw-500">Paiement non régularisé</span>
    </div>
    <div class="col text-center text-warning" *ngIf="client.paymentStatus === ProducerClientPaymentStatus.DELAYED_PAYMENT_WAITING">
      <i class="fas fa-clock mr-2"></i> <span class="fw-500">Paiement fin de mois en attente</span>
    </div>
    <div class="col text-center text-success" *ngIf="client.paymentStatus === ProducerClientPaymentStatus.ALL_OK">
      <i class="fas fa-check-circle mr-2"></i> <span class="fw-500">Paiement à jour</span>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col fw-700">{{ client.firstName }} {{ client.lastName }}</div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <ng-container *ngIf="client.phoneNumber === null"> - </ng-container>
      <ng-container *ngIf="client.phoneNumber !== null">
        <a href="tel:{{ client.phoneNumber }}">{{ client.phoneNumber | mask: '00 00 00 00 00' }}</a>
      </ng-container>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <a href="mailto:{{ client.email }}">{{ client.email }}</a>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <ng-container *ngIf="client.billingAddress === null"> - </ng-container>
      <ng-container *ngIf="client.billingAddress !== null">
        {{ client.billingAddress.line1 }}
        <ng-container *ngIf="client.billingAddress.line2"> <br />{{ client.billingAddress.line2 }} </ng-container>
        <ng-container *ngIf="client.billingAddress.line3"> <br />{{ client.billingAddress.line3 }} </ng-container>
        <br />
        {{ client.billingAddress.zipCode }} {{ client.billingAddress.city }}<br />
        {{ client.billingAddress.country }}
      </ng-container>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <hr />
    </div>
  </div>

  <div class="row mt-3">
    <div class="col fw-700">Options de paiement autorisées</div>
  </div>

  <div class="row mt-3">
    <div class="col">
      Paiement à 30 jours&nbsp;:
      <ui-switch
        style="vertical-align: sub"
        size="small"
        color="#00c462"
        defaultBgColor="#d82929"
        [(ngModel)]="client.allowedDelayedPayment"
        (ngModelChange)="checkboxChanged()"
        *ngIf="!loadingAllowedPayment"
      >
      </ui-switch>
      <i class="fas fa-spinner spinning" *ngIf="loadingAllowedPayment"></i>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <hr />
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">Dernière commande&nbsp;: {{ client.dateLastOrder === null ? 'Aucune commande' : (client.dateLastOrder | date: 'dd MMM yyyy') }}</div>
  </div>
  <div class="row mt-2">
    <div class="col">Chiffre d'affaires total&nbsp;: {{ client.totalCAHT | currency: 'EUR':'symbol':'1.0-2':'fr' }}</div>
  </div>
  <div class="row mt-2">
    <div class="col">Nombre de commandes&nbsp;: {{ client.nbOrder | number: '.0-2':'fr-FR' }}</div>
  </div>
  <div class="row mt-2">
    <div class="col">Nombre de pièces commandées&nbsp;: {{ client.nbQuantity | number: '.0-2':'fr-FR' }}</div>
  </div>
  <div class="row mt-2">
    <div class="col">Prix du panier moyen&nbsp;: {{ client.avgOrderTotalPrice | currency: 'EUR':'symbol':'1.0-2':'fr' }}</div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <hr />
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <a [routerLink]="null" (click)="goOrders()">Voir les Commandes</a>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col">
      <a [routerLink]="null" (click)="goInvoices()">Voir les Factures</a>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <hr />
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <a class="text-danger underline" [routerLink]="null" (click)="del()">Supprimer ce client <i class="fas fa-spinner spinning" *ngIf="loadingDelete"></i></a>
    </div>
  </div>
</div>
