import { Component, OnInit, Host, Inject, Input, EventEmitter, Output } from '@angular/core';
import { MyPrintersComponent } from '../my-printers.component';
import { ProducerPrinterFullDTO, ProducerPrinterService, AuthService, RoleInCompany } from 'degineo-common';
import { L } from 'ic2-lib';
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-my-printers-item',
  templateUrl: './my-printers-item.component.html',
  styleUrls: ['./my-printers-item.component.scss'],
})
export class MyPrintersItemComponent implements OnInit {
  @Input()
  printerDto: ProducerPrinterFullDTO;
  loading: boolean = false;
  rpcHost: string = environment.rpcHost;
  editProductionCostMode: boolean = false;
  showTab: number = 0;
  hasRight: boolean;

  constructor(
    @Host() public myPrintersComponent: MyPrintersComponent,
    @Inject(ProducerPrinterService) private producerPrinterService: ProducerPrinterService,
    @Inject(AuthService) public authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {
    this.hasRight = this.authService.userBundle.role.id <= RoleInCompany.PRODUCER_ACCOUNTING.id;
  }

  ngOnInit() {
    const previousValue = this.myPrintersComponent.printerShowTabBeforeReload[this.printerDto.idProducerPrinter];
    if (previousValue !== undefined) this.showTab = previousValue;

    this.activatedRoute.params.subscribe((params) => {
      if (params['idProducerPrinter'] !== undefined && params['idProducerPrinter'] == this.printerDto.idProducerPrinter && params['tab'] !== undefined)
        switch (params['tab']) {
          case 'consommables':
            this.showTab = 1;
            break;
          case 'finitions':
            this.showTab = 2;
            break;
          case 'marges':
            this.showTab = 3;
            break;
          case 'pieces':
            this.showTab = 4;
            break;
        }
    });
  }

  show(nb: number) {
    if (nb === this.showTab) this.showTab = 0;
    else this.showTab = nb;
    this.myPrintersComponent.printerShowTabBeforeReload[this.printerDto.idProducerPrinter] = this.showTab;
  }

  activeChanged() {
    this.loading = true;
    this.producerPrinterService.changeActiveStatus(this.printerDto.idProducerPrinter, this.printerDto.active).subscribe(
      (data) => {
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  cancelAdd() {
    this.producerPrinterService.deletePrinter(this.printerDto.idProducerPrinter).subscribe(
      (data) => {
        this.myPrintersComponent.printers.splice(this.myPrintersComponent.printers.indexOf(this.printerDto), 1);
        this.myPrintersComponent.reloadCanAddPrinter();
      },
      (err) => {
        L.e(err);
      }
    );
  }

  removePrinter() {
    if (!confirm("Voulez vous vraiment supprimer l'imprimante " + this.printerDto.printerName + ' ?')) return;
    this.producerPrinterService.deletePrinter(this.printerDto.idProducerPrinter).subscribe(
      (data) => {
        this.myPrintersComponent.printers.splice(this.myPrintersComponent.printers.indexOf(this.printerDto), 1);
        this.myPrintersComponent.reloadCanAddPrinter();
      },
      (err) => {
        L.e(err);
      }
    );
  }

  editProductionCost(value: string) {
    const val = parseFloat(value);
    this.editProductionCostMode = false;
    this.producerPrinterService.changeProductionCost(this.printerDto.idProducerPrinter, val).subscribe(
      (data) => {
        this.printerDto.currentProductionCost = val;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  activeAvailable() {
    return (
      this.printerDto.currentProductionCost !== 0 &&
      this.atLeastOneConsumableOptionCorrect() &&
      this.printerDto.ppctms.length > 0 &&
      this.printerDto.ppfs.length > 0 &&
      this.atLeastOneUnitPieceCorrect()
    );
  }

  atLeastOneConsumableOptionCorrect() {
    return this.printerDto.ppcos.length > 0 && this.printerDto.ppcos.find((ppco) => ppco.currentMaterialCost !== 0) !== undefined;
  }

  atLeastOneUnitPieceCorrect() {
    return !this.printerDto.hasUnitPieces || this.printerDto.ppatps.length > 0;
  }
}
