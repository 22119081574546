<div class="container my-4">
  <ng-container *ngIf="dto">
    <div class="row">
      <div class="col text-center">
        <h3>Refuser le devis n°{{ dto.devis.numDevisProducteur }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <h5>Client: {{ dto.clientFirstName + ' ' + dto.clientLastName }}</h5>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <h5>Tél: {{ dto.clientPhoneNumber === null ? 'Non renseigné' : dto.clientPhoneNumber }}</h5>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="dto && !success">
    <div class="row mt-4">
      <div class="col">
        <label *ngIf="dto.devis.status == DevisStatus.DEMANDE_ENVOYEE">
          Expliquez au client les raisons pour lesquelles vous refusez de donner suite à sa demande :
        </label>
        <label *ngIf="dto.devis.status == DevisStatus.DEVIS_EN_COURS_REVISION">
          Expliquez au client les raisons pour lesquelles vous refusez la révision du devis :
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <textarea [(ngModel)]="reason" class="form-control w-100"></textarea>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col text-right">
        <button class="btn btn-allonge btn-secondary" routerLink="/devis/{{ dto.devis.id }}">Retour</button>
      </div>
      <div class="col">
        <button class="btn btn-allonge btn-success" (click)="sendRefusal()">Envoyer le refus</button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="success">
    <div class="row mt-3 align-items-center mt-4 mb-4">
      <div class="col text-center text-success fs-5 fw-500"><i class="fas fa-check"></i> Envoyé</div>
    </div>
  </ng-container>
</div>
