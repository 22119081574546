import { Component, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ProducerClientDTO, ProducerClientService } from 'degineo-common';
import { L } from 'ic2-lib';
import { MyClientsComponent } from '../my-clients.component';

@Component({
  selector: 'app-panel-invited',
  templateUrl: './panel-invited.component.html',
  styleUrls: ['./panel-invited.component.scss'],
})
export class PanelInvitedComponent implements OnInit, OnChanges {
  @Input()
  client: ProducerClientDTO = null;
  @Input()
  parent: MyClientsComponent;
  companyName: string;
  email: string;
  editCompany: boolean = false;
  editEmail: boolean = false;
  loading: boolean = false;
  loadingDelete: boolean = false;
  error: string = null;
  constructor(private producerClientService: ProducerClientService) {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.cancelEdit();
  }

  saveEditCompany() {
    this.loading = true;
    this.producerClientService.updateClientCompanyName(this.client.idClient, this.companyName).subscribe(
      (data) => {
        this.client.companyName = this.companyName;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  saveEditEmail() {
    this.loading = true;
    this.producerClientService.updateClientEmail(this.client.idClient, this.email).subscribe(
      (data) => {
        this.client.email = this.email;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        if (err.isBusinessError()) this.error = err.message;
        L.e(err);
      }
    );
  }

  cancelEdit() {
    this.companyName = this.client.companyName;
    this.email = this.client.email;
  }

  del() {
    if (!confirm('Êtes-vous sûr de vouloir annuler cette invitation ?')) return;
    this.loadingDelete = true;
    this.producerClientService.deleteClients([this.client.idClient]).subscribe(
      (data) => {
        this.parent.reload();
        this.parent.selectedClient = null;
        this.loadingDelete = false;
      },
      (err) => {
        L.e(err);
        this.loadingDelete = false;
      }
    );
  }
}
