<ng-container *ngIf="getValidatedShipmentOrders().length === 1">
  <app-panel-header
    [id]="parent.dto.idShipment"
    [expedition]="true"
    [sinceDate]="parent.dto.shipmentCreationDate"
    [clientChoosedThisProducer]="getValidatedShipmentOrders()[0].clientChoosedThisProducer"
    [client]="getValidatedShipmentOrders()[0].client"
    dateLabel="En conditionnement"
    [depuis]="true"
    colorClass="text-k-yellow"
  ></app-panel-header>

  <app-panel-detail
    [token3DModelFinal]="getValidatedShipmentOrders()[0].token3DModelFinal"
    [filenameFinal]="getValidatedShipmentOrders()[0].filenameFinal"
    [idOrder]="getValidatedShipmentOrders()[0].idOrder"
    [quantity]="getValidatedShipmentOrders()[0].quantity"
    [sizeXMm]="getValidatedShipmentOrders()[0].sizeXMm"
    [sizeYMm]="getValidatedShipmentOrders()[0].sizeYMm"
    [sizeZMm]="getValidatedShipmentOrders()[0].sizeZMm"
    [colorName]="getValidatedShipmentOrders()[0].colorName"
    [colorHex]="getValidatedShipmentOrders()[0].colorHex"
    [deadline]="getValidatedShipmentOrders()[0].wishedDeliveryDate"
    [finishName]="getValidatedShipmentOrders()[0].finishName"
  ></app-panel-detail>
</ng-container>

<ng-container *ngIf="getValidatedShipmentOrders().length > 1">
  <app-panel-header
    [id]="parent.dto.idShipment"
    [expedition]="true"
    [sinceDate]="parent.dto.shipmentCreationDate"
    [clientChoosedThisProducer]="parent.dto.shipmentOrders[0].clientChoosedThisProducer"
    [client]="parent.dto.shipmentOrders[0].client"
    [deadline]="parent.dto.minWishedDeliveryDate"
    dateLabel="En conditionnement"
    colorClass="text-k-yellow"
  ></app-panel-header>
</ng-container>

<ng-container *ngIf="parent.dto.shipmentOrders[0].shippingAddress !== null">
  <app-panel-address class="mt-3" [address]="parent.dto.shipmentOrders[0].shippingAddress"></app-panel-address>
</ng-container>

<div class="row mt-3">
  <div class="col text-center"><h3>Service de livraison Degineo</h3></div>
</div>
<div class="row mt-2">
  <div class="col text-center"><h4>Préparation colis</h4></div>
</div>
<div class="row mt-4 mb-3">
  <div class="col text-danger text-center">
    IMPORTANT !!!<br />
    Veuillez vérifier attentivement que les données ci&#8209;dessous sont correctes.<br />
    Vous ne pourrez plus les modifier une fois validées.
  </div>
</div>

<div class="row" [class.mt-4]="!first" *ngFor="let first = first; let index = index; let p; of: parent.packages">
  <div class="col">
    <div class="container-fluid border border-secondary rounded-lg py-3" style="border-width: 2px !important">
      <div class="row">
        <div class="col text-center">
          <h4 class="text-secondary">Carton {{ index + 1 }}</h4>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label>Dimensions du carton utilisé&nbsp;:</label> {{ p.sizeXCm | number: '.2':'fr-FR' }} x {{ p.sizeZCm | number: '.2':'fr-FR' }} x
          {{ p.sizeYCm | number: '.2':'fr-FR' }} cm
        </div>
      </div>

      <div class="row mt-3">
        <div class="col"><label>Poids total du colis&nbsp;:</label> {{ p.weightInGr | number: '.0':'fr-FR' }} grammes</div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <label>Impressions et nombre d'exemplaires par colis&nbsp;:</label>
        </div>
      </div>

      <div class="row mt-2" *ngFor="let firstOrder = first; let spho; of: p.orders">
        <div class="col ml-4">
          N° <u class="pointer" (click)="openPopup(spho)">{{ spho.idOrder }}</u> x {{ spho.quantity }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-5 justify-content-center">
  <div class="col-6">
    <button class="btn btn-secondary w-100" [disabled]="loading" (click)="cancel()">Modifier</button>
  </div>
  <div class="col-6">
    <button class="btn btn-success w-100" [disabled]="loading" (click)="callTransport()">
      Valider <i class="fas fa-spinner spinning" *ngIf="loading"></i>
    </button>
  </div>
</div>
