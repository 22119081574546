<div class="container-fluid">
  <div class="row mb-5">
    <div class="col text-center">
      <h1>Mes emballages</h1>
    </div>
  </div>

  <div class="row mb-3" *ngIf="selected.length > 0">
    <div class="col">
      <button class="btn btn-danger" [disabled]="loading" (click)="deleteSelected()">
        Supprimer les {{ selected.length }} emballage sélectionné{{ selected.length > 1 ? 's' : '' }}
      </button>
      <button class="btn btn-secondary ml-3" [disabled]="loading" (click)="selected = []">Tout désélectionner</button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <form #f="ngForm" [formGroup]="form" [class.show-errors]="f.submitted && !form.valid" (submit)="add()">
        <table class="table table-responsive">
          <tr class="bg-light-30p">
            <th></th>
            <th class="text-center">Largeur (cm)</th>
            <th class="text-center">Longueur (cm)</th>
            <th class="text-center">Hauteur (cm)</th>
            <th class="text-center" colspan="2">Quantité disponible</th>
          </tr>
          <tr class="bg-light">
            <td></td>
            <td><input type="number" formControlName="largeur" class="form-control" /></td>
            <td><input type="number" formControlName="longueur" class="form-control" /></td>
            <td><input type="number" formControlName="hauteur" class="form-control" /></td>
            <td>
              <input type="number" formControlName="quantity" class="form-control" />
            </td>
            <td><button type="submit" class="btn btn-success w-200px" [disabled]="loading">+ Ajouter une réf.</button></td>
          </tr>
          <tr class="bg-light-30p" *ngFor="let box of packages">
            <td class="text-center align-middle">
              <input
                type="checkbox"
                [checked]="selected.includes(box.id)"
                [disabled]="loading"
                style="width: 30px;"
                class="form-control"
                (change)="toggleSelected(box)"
              />
            </td>
            <td class="text-center align-middle">{{ box.sizeXCm | number: '.2':'fr-FR' }}</td>
            <td class="text-center align-middle">{{ box.sizeZCm | number: '.2':'fr-FR' }}</td>
            <td class="text-center align-middle">{{ box.sizeYCm | number: '.2':'fr-FR' }}</td>
            <td class="text-center align-middle" *ngIf="!edit.includes(box.id)">
              {{ box.quantity }}
            </td>
            <td class="text-center align-middle" *ngIf="!edit.includes(box.id)">
              <button class="btn btn-primary ml-3" (click)="toggleEdit(box)">Modifier quantité</button>
            </td>
            <td class="text-center align-middle" *ngIf="edit.includes(box.id)">
              <input type="number" class="form-control w-100 d-inline-block" #qty [ngModelOptions]="{ standalone: true }" [(ngModel)]="box.quantity" />
            </td>
            <td class="text-center align-middle" *ngIf="edit.includes(box.id)">
              <button type="button" class="btn btn-success ml-3" (click)="save(box)">Mettre à jour</button>
            </td>
          </tr>
        </table>
      </form>
    </div>
  </div>
</div>
