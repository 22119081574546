import { Component, OnInit, Inject } from '@angular/core';
import { ProducerDevisListItemDTO, DevisStatus } from 'degineo-common';
import { ProducerDevisService } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-producer-devis-list-page',
  templateUrl: './producer-devis-list-page.component.html',
  styleUrls: ['./producer-devis-list-page.component.scss'],
})
export class ProducerDevisListPageComponent implements OnInit {
  devis: ProducerDevisListItemDTO[] = [];
  loaded: boolean = false;
  loading: boolean = false;
  DevisStatus: typeof DevisStatus = DevisStatus;

  constructor(@Inject(ProducerDevisService) public producerDevisService: ProducerDevisService) {}

  ngOnInit() {
    this.load();
  }

  isDeletable(status: DevisStatus) {
    return (
      status === DevisStatus.DEMANDE_DEVIS_REFUSEE ||
      status === DevisStatus.DEVIS_ACCEPTE ||
      status === DevisStatus.DEMANDE_REVISION_REFUSEE ||
      status === DevisStatus.DEVIS_REFUSE_PAR_CLIENT
    );
  }

  deleteDevis(d: ProducerDevisListItemDTO) {
    this.producerDevisService.hideDevis(d.idDevis).subscribe(
      (data) => {
        this.load();
      },
      (err) => {
        L.e(err);
      }
    );
  }

  load() {
    this.loading = true;
    this.producerDevisService.getListDevis().subscribe(
      (data) => {
        this.devis = data;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }
}
