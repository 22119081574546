<i class="fas fa-spinner spinning" *ngIf="dto === null"></i>
<div class="container-fluid" *ngIf="dto !== null">
  <app-panel-header
    [id]="dto.idOrder"
    [sinceDate]="dto.dateStartPrinting"
    [clientChoosedThisProducer]="dto.clientChoosedThisProducer"
    [client]="dto.client"
    dateLabel="En production"
    colorClass="text-k-red"
    [depuis]="true"
  ></app-panel-header>

  <app-panel-detail
    [token3DModelFinal]="dto.token3DModelFinal"
    [filenameFinal]="dto.filenameFinal"
    [quantity]="dto.quantity"
    [sizeXMm]="dto.sizeXMm"
    [sizeYMm]="dto.sizeYMm"
    [sizeZMm]="dto.sizeZMm"
    [colorName]="dto.colorName"
    [colorHex]="dto.colorHex"
    [deadline]="dto.wishedDeliveryDate"
    [finishName]="dto.finishName"
  ></app-panel-detail>

  <div class="row mt-3">
    <div class="col"><span class="fw-700">À imprimer sur&nbsp;:</span> {{ dto.printerBrand }} - {{ dto.printerName }}</div>
  </div>
  <div class="row mt-3">
    <div class="col"><span class="fw-700">Consommable à utiliser&nbsp;:</span> {{ dto.consumableName }}</div>
  </div>
  <div class="row mt-3" *ngIf="!dto.dental">
    <div class="col">
      <span class="fw-700">Consommation matière&nbsp;:</span> {{ dto.materialQuantityPerPiece | number: '.2':'fr-FR' }} cm<sup>3</sup> par pièce
    </div>
  </div>
  <div class="row mt-3" *ngIf="!dto.dental">
    <div class="col"><span class="fw-700">Utilisation machine&nbsp;:</span> {{ dto.machineUsagePerPiece | number: '.2':'fr-FR' }} % par pièce</div>
  </div>
  <div class="row mt-3" *ngIf="dto.comment !== null">
    <div class="col fw-700">Commentaire client&nbsp;:</div>
  </div>
  <div class="row" *ngIf="dto.comment !== null">
    <div class="col">
      {{ dto.comment }}
    </div>
  </div>
  <div class="row mt-3" *ngIf="dto.noteDegineo !== null">
    <div class="col fw-700">Commentaire Degineo&nbsp;:</div>
  </div>
  <div class="row" *ngIf="dto.noteDegineo !== null">
    <div class="col">
      {{ dto.noteDegineo }}
    </div>
  </div>

  <app-panel-address class="mt-3" *ngIf="dto.shippingAddress !== null" [address]="dto.shippingAddress"></app-panel-address>

  <div class="row justify-content-center mt-3">
    <div class="col-6 border rounded pt-2 pb-2">
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-1 text-center align-self-center">
            <i class="fas fa-download text-primary fs-4"></i>
          </div>
          <div class="col-10 text-primary text-center ellipsis">
            <span class="fw-500">Télécharger le fichier 3D&nbsp;:</span><br />
            <a target="_blank" [title]="dto.filenameFinal" href="{{ rpcHost }}orderModele3D/{{ dto.token3DModelFinal }}/{{ dto.filenameFinal }}?dl">{{
              dto.filenameFinal
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col">
      <button class="btn btn-secondary w-100" [disabled]="loading" *ngIf="authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING" (click)="cancel()">
        <i class="fas fa-fast-backward text-white mr-2"></i>Annuler mise en production
      </button>
    </div>
    <div class="col">
      <button class="btn btn-success w-100" [disabled]="loading" *ngIf="authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING" (click)="accept()">
        Impression terminée <i class="fas fa-fast-forward text-white ml-2"></i>
      </button>
    </div>
  </div>
</div>
