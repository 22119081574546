import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'environments/environment';
import * as moment_ from 'moment';
import { PanelHeaderComponent } from '../panel-header/panel-header.component';

@Component({
  selector: 'app-panel-detail',
  templateUrl: './panel-detail.component.html',
  styleUrls: ['./panel-detail.component.scss'],
})
export class PanelDetailComponent implements OnInit {
  @Input()
  token3DModelFinal: string;
  @Input()
  filenameFinal: string;
  @Input()
  quantity: number;
  @Input()
  sizeXMm: number;
  @Input()
  sizeYMm: number;
  @Input()
  sizeZMm: number;
  @Input()
  colorName: string;
  @Input()
  colorHex: string;
  @Input()
  deadline: Date = null;
  @Input()
  idOrder: number = null;
  @Input()
  finishName: string;

  moment = moment_;
  rpcHost: string = environment.rpcHost;

  isOutdated = PanelHeaderComponent.isOutdatedStatic;

  constructor() {}

  ngOnInit() {}
}
