import { Component, OnInit } from '@angular/core';
import { AuthService, ProducerSubscriptionTunnelService } from 'degineo-common';
import { L } from 'ic2-lib';
import { SubscribeService } from '../subscribe.service';

@Component({
  selector: 'app-subscribe-step7-sign',
  templateUrl: './subscribe-step7-sign.component.html',
  styleUrls: ['./subscribe-step7-sign.component.scss'],
})
export class SubscribeStep7SignComponent implements OnInit {
  loading: boolean = true;
  saving: boolean = false;

  constructor(
    private authService: AuthService,
    public subscribeService: SubscribeService,
    private producerSubscriptionTunnelService: ProducerSubscriptionTunnelService
  ) {}

  ngOnInit() {
    this.producerSubscriptionTunnelService.getInfosStep7().subscribe(
      (data) => {
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  previous() {
    this.subscribeService.step = SubscribeService.STEP_6_BANKACCOUNT;
  }

  submit() {
    this.saving = true;
    this.producerSubscriptionTunnelService.saveStep7(this.subscribeService.idPlan).subscribe(
      (data) => {
        this.authService.loginWith(data);
        this.subscribeService.step = SubscribeService.STEP_8_BRAVO;
        this.saving = false;
      },
      (err) => {
        this.saving = false;
        L.e(err);
      }
    );
  }
}
