import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProducerTakeControlService } from 'degineo-common';
import { L } from 'ic2-lib';
import { TakeControlService } from '../take-control.service';

@Component({
  selector: 'app-take-control-step3-siret',
  templateUrl: './take-control-step3-siret.component.html',
  styleUrls: ['./take-control-step3-siret.component.scss'],
})
export class TakeControlStep3SiretComponent implements OnInit {
  error: string = null;
  loading: boolean = true;
  saving: boolean = false;
  constructor(public takeControlService: TakeControlService, private route: ActivatedRoute, private producerTakeControlService: ProducerTakeControlService) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      console.log(params);
      if (params.idProducer) {
        this.producerTakeControlService.getSiret(+params['idProducer']).subscribe(
          (data) => {
            this.takeControlService.step3form.patchValue({
              siret: data,
            });
          },
          (err) => {
            if (err.isBusinessError()) this.error = err.message;
            else L.e(err);
          }
        );
      }
    });
  }

  submit() {
    if (!this.takeControlService.step3form.valid) {
      console.log(this.takeControlService.step3form);
      return;
    }
    this.saving = true;
    this.producerTakeControlService.getInfos(this.takeControlService.step3form.value.siret).subscribe(
      (data) => {
        this.takeControlService.data = data;
        this.takeControlService.step4form.patchValue({
          siret: this.takeControlService.step3form.value.siret,
          name: data.name,
          legalStatus: data.legalStatus,
          billingAddress: data.billingAddress,
        });
        this.takeControlService.step = TakeControlService.STEP_4_COMPANY;
        this.saving = false;
      },
      (err) => {
        this.saving = false;
        if (err.isBusinessError()) this.error = err.message;
        else L.e(err);
      }
    );
  }

  previous() {
    this.takeControlService.step = TakeControlService.STEP_2_BUSINESSTYPE;
  }
}
