<i class="fas fa-spinner spinning" *ngIf="dto === null"></i>
<div class="container-fluid" *ngIf="dto !== null">
  <app-panel-header
    [id]="dto.idShipment"
    [expedition]="true"
    [sinceDate]="dto.dateWaitingCarrier"
    [clientChoosedThisProducer]="dto.clientChoosedThisProducer"
    [client]="dto.client"
    [deadline]="dto.producerDeliver ? null : dto.minWishedDeliveryDate"
    dateLabel="En attente"
    [depuis]="true"
    colorClass="text-k-green"
  ></app-panel-header>

  <app-shipment-carrier *ngIf="dto.status !== ShipmentStatus.CALL_CARRIER_ERROR && !dto.producerDeliver"></app-shipment-carrier>

  <app-shipment-producer *ngIf="dto.status !== ShipmentStatus.CALL_CARRIER_ERROR && dto.producerDeliver"></app-shipment-producer>

  <div class="row mt-5" *ngIf="dto.status === ShipmentStatus.CALL_CARRIER_ERROR">
    <div class="col text-center text-danger fw-700">
      Une erreur est survenue lors de l'appel du transporteur.<br />
      L'équipe Degineo à été prévenue et travaille déjà à régler le problème.<br />
      N'hésitez pas à nous contacter via le chat si besoin.
    </div>
  </div>
</div>
