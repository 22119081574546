<div class="d-flex w-100" *ngIf="data !== null">
  <div
    class="flex-grow-1 shadow-2 mr-3 p-3 rounded bg-white border-bottom border-primary pointer"
    style="border-bottom-width: 2rem"
    routerLink="/mes-commandes"
  >
    <h1>{{ data.nbOrderReceived }}</h1>
    {{ data.nbOrderReceived > 1 ? 'Demandes reçues' : 'Demande reçue' }}
  </div>
  <div class="flex-grow-1 shadow-2 mx-3 p-3 rounded bg-white border-bottom border-success pointer" routerLink="/devis">
    <h1>{{ data.nbDevisWaiting }}</h1>
    Devis en attente
  </div>
  <div class="flex-grow-1 shadow-2 ml-3 p-3 rounded bg-white border-bottom border-warning pointer" routerLink="/devis">
    <h1>{{ data.nbRevisionDevis }}</h1>
    {{ data.nbRevisionDevis > 1 ? 'Révisions de devis' : 'Révision de devis' }}
  </div>
  <div class="flex-grow-1 shadow-2 ml-3 p-3 rounded bg-white border-bottom border-danger pointer" (click)="goLateOrder()">
    <h1>{{ data.nbLateOrder }}</h1>
    {{ data.nbLateOrder > 1 ? 'Commandes en retard' : 'Commande en retard' }}
  </div>
</div>
<div class="row" *ngIf="data === null">
  <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
</div>
