import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ProducerSignupService, PrinterBrandChoiceDTO, PrinterNEW } from 'degineo-common';
import { AccountCreationService } from '../account-creation.service';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-account-creation-step6-printers',
  templateUrl: './account-creation-step6-printers.component.html',
  styleUrls: ['./account-creation-step6-printers.component.scss'],
})
export class AccountCreationStep6PrintersComponent implements OnInit {
  loading: boolean = true;
  saving: boolean = false;
  printers: { brand: PrinterBrandChoiceDTO; printer: PrinterNEW }[] = [];
  printerChoices: PrinterBrandChoiceDTO[];
  showErrors: boolean = false;

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(ProducerSignupService) private producerSignupService: ProducerSignupService,
    @Inject(AccountCreationService) public accountCreationService: AccountCreationService
  ) {}

  ngOnInit() {
    this.producerSignupService.getPrinterChoices().subscribe(
      (data) => {
        this.printerChoices = data;
        this.producerSignupService.getInfosStep6(this.accountCreationService.token).subscribe(
          (data) => {
            //console.log(data);
            //TODO rebuild printers
            for (const idPrinter of data) {
              for (const brand of this.printerChoices) {
                for (const printer of brand.printers) {
                  if (printer.id === idPrinter) {
                    this.printers.push({ brand: brand, printer: printer });
                  }
                }
              }
            }
            if (this.printers.length === 0) this.printers.push({ brand: null, printer: null });
            this.loading = false;
          },
          (err) => {
            L.e(err);
            this.loading = false;
          }
        );
      },
      (err) => {
        L.e(err);
      }
    );
  }

  addPrinter() {
    this.printers.push({ brand: null, printer: null });
  }

  removePrinter(printer) {
    this.printers.splice(this.printers.indexOf(printer), 1);
  }

  submit() {
    this.showErrors = false;
    if (this.printers.length === 0 || this.printers.find((p) => p.printer === null) !== undefined) {
      this.showErrors = true;
      return;
    }
    this.saving = true;
    this.producerSignupService
      .saveStep6(
        this.accountCreationService.token,
        this.printers.map((p) => p.printer.id)
      )
      .subscribe(
        (data) => {
          this.accountCreationService.next();
          this.saving = false;
        },
        (err) => {
          this.saving = false;
          L.e(err);
        }
      );
  }
}
