<ngx-charts-advanced-pie-chart
  [view]="view"
  label="Nombre de machines"
  [tooltipDisabled]="true"
  [scheme]="colorScheme"
  [results]="single"
  (select)="onSelect($event)"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)"
>
</ngx-charts-advanced-pie-chart>
