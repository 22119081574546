import { Component, OnInit } from '@angular/core';
import { TakeControlService } from './take-control.service';

@Component({
  selector: 'app-take-control',
  templateUrl: './take-control.component.html',
  styleUrls: ['./take-control.component.scss'],
})
export class TakeControlComponent implements OnInit {
  TakeControlService: typeof TakeControlService = TakeControlService;
  constructor(public takeControlService: TakeControlService) {}

  ngOnInit(): void {}
}
