<form autocomplete="off" #f="ngForm" [formGroup]="form" [class.show-errors]="f.submitted && !form.valid" (submit)="submit()">
  <div class="row">
    <div class="col text-center">
      <h2 style="font-size: 1.6rem">Abonnez-vous et accédez à Degineo en&nbsp;5&nbsp;min&nbsp;!</h2>
    </div>
  </div>

  <div class="row" *ngIf="loading">
    <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
  </div>

  <ng-container *ngIf="!loading">
    <div class="row mt-5">
      <div class="col">
        <label>E-mail</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" class="form-control" formControlName="email" autocomplete="email" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <label>Mot de passe</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="password" class="form-control" formControlName="password" autocomplete="new-password" />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <label>Confirmez votre mot de passe</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="password" class="form-control" formControlName="password2" autocomplete="new-password" />
      </div>
    </div>
    <div class="row mt-4" *ngIf="f.submitted && !form.valid">
      <div class="col">
        <div class="alert alert-danger pr-3 pl-3 pr-md-5 pl-md-5 pt-3 pb-3">
          <ul>
            <li *ngIf="form.hasError('required', 'email')">Tous les champs sont requis.</li>
            <li *ngIf="form.hasError('email', 'email')">L'e-mail est incorrect.</li>
            <li *ngIf="form.hasError('alreadyExists', 'email')">Cet e-mail est déjà utilisé.</li>
            <li *ngIf="form.hasError('required', 'password') || form.hasError('pattern', 'password')">Le mot de passe doit comporter au moins 6 caractères.</li>
            <li *ngIf="form.hasError('notSame')">La confirmation du mot de passe et le mot de passe doivent être identiques.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <button type="submit" [disabled]="saving" class="btn btn-success w-100">
          Créer mon compte Degineo <i class="fas fa-spinner spinning ml-3" *ngIf="saving"></i>
        </button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col text-center">
        <a routerLink="/connexion">J'ai déjà un compte Degineo - Se connecter </a>
      </div>
    </div>
  </ng-container>
</form>
