import { Component, OnInit, Host, Inject } from '@angular/core';
import * as moment_ from 'moment';
import { InPreparationShipmentPanelComponent } from '../in-preparation-shipment-panel.component';
import { environment } from 'environments/environment';
import { InPreparationShipmentService, ShipmentPackageDTO, ShipmentPackageHasOrder, ShipmentOrderPanelDTO, RoleInCompany, AuthService } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-package-preparation-step',
  templateUrl: './package-preparation-step.component.html',
  styleUrls: ['./package-preparation-step.component.scss'],
})
export class PackagePreparationStepComponent implements OnInit {
  moment = moment_;
  rpcHost: string = environment.rpcHost;
  loading: boolean = false;
  error: string = null;

  abs = Math.abs;
  RoleInCompany: typeof RoleInCompany = RoleInCompany;

  constructor(
    @Host() public parent: InPreparationShipmentPanelComponent,
    @Inject(InPreparationShipmentService) private inPreparationShipmentService: InPreparationShipmentService,
    @Inject(AuthService) public authService: AuthService
  ) {}

  ngOnInit() {
    if (this.parent.packages.length === 0) this.addPackage();
  }

  getValidatedShipmentOrders() {
    return this.parent.dto.shipmentOrders.filter((so) => so.waitForIt);
  }

  getValidatedShipmentOrdersNotAlreadyIn(p: ShipmentPackageDTO, spho: ShipmentPackageHasOrder) {
    return this.parent.dto.shipmentOrders.filter((so) => {
      return so.waitForIt && !p.orders.some((allSpho) => allSpho.idOrder === so.idOrder && allSpho.idOrder !== spho.idOrder);
    });
  }

  canAddOtherOrders(p: ShipmentPackageDTO) {
    return (
      this.parent.dto.shipmentOrders.filter((so) => {
        return so.waitForIt && !p.orders.some((allSpho) => allSpho.idOrder === so.idOrder);
      }).length > 0 && !p.orders.some((o) => o.idOrder === 0)
    );
  }

  canAddPackage() {
    const map = new Map<number, number>();
    for (const p of this.parent.packages) {
      for (const spho of p.orders) {
        let nb = map.get(spho.idOrder) || 0;
        nb += spho.quantity;
        map.set(spho.idOrder, nb);
      }
    }
    for (const order of this.getValidatedShipmentOrders()) {
      if (order.quantity > map.get(order.idOrder)) {
        return true;
      }
    }

    return false;
  }

  addPackage() {
    const sp = new ShipmentPackageDTO();
    sp.sizeXCm = null; //pour voir les placeholder
    sp.sizeYCm = null;
    sp.sizeZCm = null;
    sp.weightInGr = null;
    sp.orders = [];
    const spho = new ShipmentPackageHasOrder();
    if (this.getValidatedShipmentOrders().length === 1) {
      spho.idOrder = this.getValidatedShipmentOrders()[0].idOrder;
      spho.quantity = this.getValidatedShipmentOrders()[0].quantity;
    }
    sp.orders.push(spho);
    this.parent.packages.push(sp);
  }

  deletePackage(p: ShipmentPackageDTO) {
    this.parent.packages.splice(this.parent.packages.indexOf(p), 1);
  }

  addPrint(p: ShipmentPackageDTO) {
    p.orders.push(new ShipmentPackageHasOrder());
  }

  deletePrint(p: ShipmentPackageDTO, spho: ShipmentPackageHasOrder) {
    p.orders.splice(p.orders.indexOf(spho), 1);
  }

  getRemainingQuantity(order: ShipmentOrderPanelDTO) {
    let qty = order.quantity;
    for (const p of this.parent.packages) {
      for (const spho of p.orders) {
        if (spho.idOrder !== order.idOrder) continue;
        qty -= spho.quantity;
      }
    }
    return qty;
  }

  callTransport() {
    //verif des quantitées
    const map = new Map<number, number>();
    for (const p of this.parent.packages) {
      //verif tailles & grammage
      if (p.sizeXCm === null || p.sizeYCm === null || p.sizeZCm === null) {
        this.error = 'Taille non saisie pour un des colis';
        return;
      }
      if (p.sizeXCm <= 0) {
        this.error = 'Taille incorrecte pour un des colis: ' + p.sizeXCm;
        return;
      }
      if (p.sizeYCm <= 0) {
        this.error = 'Taille incorrecte pour un des colis: ' + p.sizeYCm;
        return;
      }
      if (p.sizeZCm <= 0) {
        this.error = 'Taille incorrecte pour un des colis: ' + p.sizeZCm;
        return;
      }

      if (p.weightInGr <= 0) {
        this.error = 'Poids invalide pour un des colis';
        return;
      }

      for (const spho of p.orders) {
        let nb = map.get(spho.idOrder) || 0;
        nb += spho.quantity;
        map.set(spho.idOrder, nb);

        if (spho.quantity <= 0) {
          this.error = "Impossible d'avoir une quantitée de " + spho.quantity;
          return;
        }
      }
    }

    let hasError = false;
    let errorMsg = 'Il y a une erreur de quantité :<br />';
    for (const order of this.getValidatedShipmentOrders()) {
      if (order.quantity !== map.get(order.idOrder)) {
        hasError = true;
        errorMsg += 'Impression N° ' + order.idOrder + ' : ' + map.get(order.idOrder) + '/' + order.quantity + '<br />';
      }
    }

    if (hasError) {
      this.error = errorMsg;
      return;
    } else this.error = null;

    this.parent.step = 3;
    window.scrollTo(0, 0);
  }

  cancel() {
    if (this.loading) return;
    this.loading = true;
    this.inPreparationShipmentService.cancelShipmentInPackaging(this.parent.dto.idShipment).subscribe(
      (data) => {
        this.loading = false;
        this.parent.step = 1;
        window.scrollTo(0, 0);
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }
}
