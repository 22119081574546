import { Component, OnInit, Inject } from '@angular/core';
import { environment } from 'environments/environment';
import { WaitingBillDTO, ProducerInvoiceService } from 'degineo-common';
import { L } from 'ic2-lib';
import { PanelHeaderComponent } from '../../my-orders/panel-header/panel-header.component';

@Component({
  selector: 'app-pending-payments',
  templateUrl: './pending-payments.component.html',
  styleUrls: ['./pending-payments.component.scss'],
})
export class PendingPaymentsComponent implements OnInit {
  data: WaitingBillDTO[] = [];
  lastDayOfMonth: Date;
  loaded: boolean = false;
  rpcHost = environment.rpcHost;

  isOutdated = PanelHeaderComponent.isOutdatedStatic;

  constructor(@Inject(ProducerInvoiceService) public producerInvoiceService: ProducerInvoiceService) {}

  ngOnInit() {
    const today = new Date();
    this.lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    this.load();
  }

  load() {
    this.producerInvoiceService.getWaitingBills().subscribe(
      (data) => {
        this.data = data;
        this.loaded = true;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  paymentReceived(wp: WaitingBillDTO) {
    if (!confirm('Confirmez-vous avoir bien reçu le paiement de ' + wp.client + ' ?')) return;
    //console.log('TODO', wp);
    this.producerInvoiceService.invoicePaid(wp.invoiceId).subscribe(
      (data) => {
        this.load();
      },
      (err) => {
        L.e(err);
      }
    );
  }

  orderChange(order) {
    console.log('orderChange', order);
    this.load();
  }
  tableRowClick(row) {
    console.log('tableRowClick', row);
    //this.router.navigate(['/clients', this.slugService.string_to_slug(row.companyName), row.id]);
  }
}
