import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProducerClientService, ImportedClientDTO } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-import-client-popup',
  templateUrl: './import-client-popup.component.html',
  styleUrls: ['./import-client-popup.component.scss'],
})
export class ImportClientPopupComponent implements OnInit {
  uploadStep: boolean = true;
  loading: boolean = false;
  clients: ImportedClientDTO[] = [];
  hoverExcel: boolean = false;
  hoverCSV: boolean = false;
  constructor(public activeModal: NgbActiveModal, private producerClientService: ProducerClientService) {}

  ngOnInit() {}

  onUpload(file: File) {
    console.log(file);
    this.loading = true;
    this.producerClientService
      .inviteNewClientFromFile()
      .withFile(file)
      .subscribeWithProgress(
        (data) => {
          this.clients = data;
          this.uploadStep = false;
          this.loading = false;
        },
        (loaded, total) => {},
        (err) => {
          L.e(err);
          this.loading = false;
        }
      );
  }

  getValidClients() {
    return this.clients.filter((c) => c.message === null);
  }

  valid() {
    this.loading = true;
    this.producerClientService.inviteNewClients(this.getValidClients()).subscribe(
      (data) => {
        this.loading = false;
        this.activeModal.close(true);
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  cancel() {
    /*
    this.uploadStep = true;
    this.clients = [];
    */
    console.log(this);
    this.activeModal.close(false);
  }
}
