<div class="container-fluid">
  <div class="row" [class.mt-3]="!first" *ngFor="let first = first; let ctMarge; of: myPrintersItemComponent.printerDto.ppctms">
    <div class="col">
      <div class="d-inline-block">
        <span class="fw-500 mr-2">{{ ctMarge.clientType?.lib }}&nbsp;:</span>
      </div>

      <div class="d-inline-block">
        <a [routerLink]="null" *ngIf="!edit[ctMarge.id]" (click)="edit[ctMarge.id] = true">{{ ctMarge.producerMargin }} %</a>
        <form class="form-inline" *ngIf="edit[ctMarge.id]">
          <div class="mr-3 input-group">
            <input type="number" class="form-control" #input [value]="ctMarge.producerMargin" style="width: 90px !important" />
            <div class="input-group-append">
              <span class="input-group-text">%</span>
            </div>
          </div>
          <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" (click)="saveEdit(ctMarge, input.value)"></i>
          <i class="fas fa-times-circle fs-4 text-danger pointer no-select" (click)="edit[ctMarge.id] = false"></i>
        </form>
        <i
          class="far fa-trash-alt pointer hover-danger ml-3"
          ngbTooltip="Retirer cette marge sectorielle"
          *ngIf="!edit[ctMarge.id]"
          (click)="deleteMarge(ctMarge)"
        ></i>
      </div>
    </div>
  </div>

  <div class="row mt-3 pointer no-select" *ngIf="!add" (click)="addMode()">
    <div class="col text-primary pointer no-select">
      <i class="fas fa-plus-circle"></i> Ajouter une marge sectorielle
      <span class="text-danger" *ngIf="noMoreMarges">Aucune marge sectorielle disponible à l'ajout</span>
    </div>
  </div>
  <div class="row mt-3" *ngIf="add">
    <div class="col">
      <form class="form-inline">
        <select class="form-control mr-3" name="ct" [(ngModel)]="selectedCt">
          <option [ngValue]="null" disabled="disabled">-- Secteur d'activité --</option>
          <option *ngFor="let ct of ctChoice" [ngValue]="ct">{{ ct.lib }}</option>
        </select>
        <div class="mr-3 input-group">
          <input type="number" class="form-control" name="marge" style="width: 90px !important" [(ngModel)]="marge" />
          <div class="input-group-append">
            <span class="input-group-text">%</span>
          </div>
        </div>
        <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" (click)="addCT()"></i>
        <i class="fas fa-times-circle fs-4 text-danger pointer no-select" (click)="add = false"></i>
      </form>
    </div>
  </div>
</div>
