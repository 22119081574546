import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserEditValidators, ProducerSignupService, AddressNEW, AuthService, ProducerSignupStep } from 'degineo-common';
import { L } from 'ic2-lib';
import { environment } from 'environments/environment';
import { AccountCreationService } from './account-creation.service';

//lien test
//http://producer.degineo.local:4202/creation-compte/UC2YnVP82Q6xKSBlhlJlVn8eWJ7ZHz7Ev6vOf5mOUTqb73RGd4AHNoDXyDEDalbgthuHKFi2RwCNYEAhk6i9RrhSliNy9jMfm6EN6c6XZkBgheWQro6MWl6cuZ1K0ycn
@Component({
  selector: 'app-account-creation',
  templateUrl: './account-creation.component.html',
  styleUrls: ['./account-creation.component.scss'],
})
export class AccountCreationComponent implements OnInit {
  error: string = null;
  loading: boolean;
  ProducerSignupStep: typeof ProducerSignupStep = ProducerSignupStep;

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(Router) private router: Router,
    @Inject(AuthService) private authService: AuthService,
    @Inject(ProducerSignupService) private producerSignupService: ProducerSignupService,
    @Inject(AccountCreationService) public accountCreationService: AccountCreationService
  ) {}

  ngOnInit() {
    this.authService.logout();
    this.activatedRoute.params.subscribe((params) => {
      if (params['token'] === undefined) {
        this.accountCreationService.idPlan = +params['idPlan'];
        this.accountCreationService.nbPrinters = +params['nbPrinters'];
        console.log('creating fake token');
        this.producerSignupService.createFakeToken().subscribe(
          (data) => {
            this.router.navigate(['creation-compte', data]);
          },
          (err) => {
            L.e(err);
          }
        );
      } else {
        this.accountCreationService.token = params['token'];
        this.producerSignupService.getStep(this.accountCreationService.token).subscribe(
          (data) => {
            this.accountCreationService.step = data;
          },
          (err) => {
            L.e(err);
            this.router.navigate(['/connexion']);
          }
        );
      }
    });
  }
}
