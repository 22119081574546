<form autocomplete="off">
  <input
    type="text"
    [ngClass]="classes"
    ngx-google-places-autocomplete
    [options]="options"
    (onAddressChange)="handleShippingAddressChange($event)"
    autocomplete="off"
    [placeholder]="placeholder"
    [(ngModel)]="text"
    [ngModelOptions]="{ standalone: true }"
    (ngModelChange)="innerOnChange($event)"
    (blur)="onBlur($event)"
    [disabled]="disabled"
    [readOnly]="readOnly"
  />
</form>
