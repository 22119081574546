<div class="container my-4">
  <degineo-filter
    class="d-inline-block w-100 mb-4"
    [builtFilters]="builtFilters"
    [default]="MyInvoicesComponent.FilterDefault"
    (filterChanged)="filterChanged($event)"
    [cache]="MyInvoicesComponent.FilterCache"
  >
    <div class="form-inline">
      <degineo-text-filter #f class="input-group mr-2 mt-1 w-200px" name="Client" filterName="client"> </degineo-text-filter>
      <degineo-text-filter #f class="input-group mr-2 mt-1 w-200px" name="Référence" filterName="reference"> </degineo-text-filter>
      <degineo-daterange-filter #f class="input-group mr-2 mt-1 w-200px" name="Date d'émission" filterName="date"> </degineo-daterange-filter>
    </div>
  </degineo-filter>
  <degineo-my-table
    [rows]="invoices"
    (rowClick)="tableRowClick($event)"
    [hoverable]="true"
    [pointer]="true"
    [orderCache]="MyInvoicesComponent.OrderCache"
    (orderChange)="orderChange($event)"
    [builtFiltersOrder]="builtFiltersOrder"
  >
    <degineo-my-column [disableOrder]="true" name="">
      <td *cv="let r" class="text-nowrap text-center fs-4">
        <ng-container [ngSwitch]="r.status">
          <span *ngSwitchCase="InvoiceProducerStatus.PAYMENT_OK" class="text-success" ngbTooltip="Paiement à jour"><i class="fas fa-check-circle"></i></span>
          <span *ngSwitchCase="InvoiceProducerStatus.DELAYED_PAYMENT_WAITING" class="text-primary" ngbTooltip="Paiement fin de mois en attente">
            <i class="far fa-calendar-alt"></i>
          </span>
          <span *ngSwitchCase="InvoiceProducerStatus.WAITING_PAYMENT" class="text-primary" ngbTooltip="Paiement en attente">
            <i class="fas fa-clock"></i>
          </span>
          <span *ngSwitchCase="InvoiceProducerStatus.LATE_PAYMENT" class="text-danger" ngbTooltip="Paiement en retard">
            <i class="fas fa-exclamation-circle"></i>
          </span>
          <span *ngSwitchCase="InvoiceProducerStatus.ORDER_PENDING" class="text-primary" ngbTooltip="Commande en cours"><i class="fas fa-sync-alt"></i></span>
          <span *ngSwitchCase="InvoiceProducerStatus.INVOICE_CANCELLED" class="text-medium" ngbTooltip="Commande annulée">
            <i class="fas fa-times-circle"></i>
          </span>
        </ng-container>
      </td>
    </degineo-my-column>
    <degineo-my-column [disableOrder]="true" name="Nom de la pièce">
      <td *cv="let r" class="text-nowrap text-center">
        {{ r.orders.length > 1 ? r.orders.length + ' pièces' : r.orders[0].orderName }}
      </td>
    </degineo-my-column>
    <degineo-my-column orderName="reference" name="Référence">
      <td *cv="let r" class="text-nowrap text-center">
        {{ r.reference }}
      </td>
    </degineo-my-column>
    <degineo-my-column orderName="client" name="Client">
      <td *cv="let r" class="text-nowrap text-center">
        {{ r.client }}
      </td>
    </degineo-my-column>
    <degineo-my-column [disableOrder]="true" name="Méthode de paiement">
      <td *cv="let r" class="text-nowrap text-center">
        {{ r.virementType }}
      </td>
    </degineo-my-column>
    <degineo-my-column orderName="date" name="Date de facturation" [defaultOrder]="OrderBy.DESC">
      <td *cv="let r" class="text-nowrap text-center">
        {{ r.dateCreated | date: 'dd/MM/yyyy' }}
      </td>
    </degineo-my-column>
    <degineo-my-column orderName="totalHT" [name]="authService.userBundle.useVAT ? 'Montant TTC' : 'Montant HT'">
      <td *cv="let r" class="text-nowrap text-center">
        {{ authService.userBundle.useVAT ? (r.totalTTC | currency: 'EUR':'symbol':'1.2-2':'fr') : (r.totalHT | currency: 'EUR':'symbol':'1.2-2':'fr') }}
      </td>
    </degineo-my-column>
  </degineo-my-table>
  <div class="row justify-content-center mt-3" *ngIf="!loading">
    <ngb-pagination
      [collectionSize]="count"
      [(page)]="MyInvoicesComponent.page"
      [pageSize]="nbPerPage"
      [maxSize]="6"
      [rotate]="true"
      (pageChange)="reload()"
      [disabled]="loading"
    ></ngb-pagination>
  </div>
</div>

<app-right-panel class="primary" [open]="invoice !== null" (close)="close()">
  <app-invoice-panel *ngIf="invoice !== null" [invoice]="invoice"></app-invoice-panel>
</app-right-panel>
