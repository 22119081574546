<div class="container-fluid">
  <div class="row mb-2" *ngIf="data !== null">
    <div class="col-12 mt-2" *ngIf="data.noPrinter">
      <i class="fas fa-exclamation-triangle text-warning mr-2"></i><a routerLink="/mes-imprimantes">Vous n'avez pas d'imprimante 3D d'enregistrée.</a>
    </div>
    <div class="col-12 mt-2" *ngIf="!data.noPrinter && data.noAvailablePrinter">
      <i class="fas fa-exclamation-triangle text-warning mr-2"></i><a routerLink="/mes-imprimantes">Vous n'avez aucune imprimante 3D disponible.</a>
    </div>
    <ng-container *ngFor="let printer of data.printerWarnings">
      <div class="col-12 mt-2" *ngIf="printer.noProductionCost">
        <i class="fas fa-exclamation-triangle text-warning mr-2"></i>
        <a routerLink="/mes-imprimantes/{{ printer.idProducerPrinter }}"
          >L'imprimante {{ printer.brand }} - {{ printer.name }} n'a pas de coût production renseigné.</a
        >
      </div>
      <div class="col-12 mt-2" *ngIf="printer.noMaterialCost">
        <i class="fas fa-exclamation-triangle text-warning mr-2"></i>
        <a routerLink="/mes-imprimantes/{{ printer.idProducerPrinter }}/consommables"
          >Les coûts matières de l'imprimante {{ printer.brand }} - {{ printer.name }} ne sont pas renseignés.</a
        >
      </div>
      <div class="col-12 mt-2" *ngIf="printer.noConsumableOptions">
        <i class="fas fa-exclamation-triangle text-warning mr-2"></i>
        <a routerLink="/mes-imprimantes/{{ printer.idProducerPrinter }}/consommables"
          >L'imprimante {{ printer.brand }} - {{ printer.name }} n'a pas de consommable associé.</a
        >
      </div>
      <div class="col-12 mt-2" *ngIf="printer.noMargins">
        <i class="fas fa-exclamation-triangle text-warning mr-2"></i>
        <a routerLink="/mes-imprimantes/{{ printer.idProducerPrinter }}/marges"
          >L'imprimante {{ printer.brand }} - {{ printer.name }} n'a aucune marge sectorielle associée.</a
        >
      </div>
      <div class="col-12 mt-2" *ngIf="printer.noFinish">
        <i class="fas fa-exclamation-triangle text-warning mr-2"></i>
        <a routerLink="/mes-imprimantes/{{ printer.idProducerPrinter }}/finitions"
          >L'imprimante {{ printer.brand }} - {{ printer.name }} n'a aucune finition associée.</a
        >
      </div>
      <div class="col-12 mt-2" *ngIf="printer.noArticleTypePrice">
        <i class="fas fa-exclamation-triangle text-warning mr-2"></i>
        <a routerLink="/mes-imprimantes/{{ printer.idProducerPrinter }}/pieces"
          >L'imprimante {{ printer.brand }} - {{ printer.name }} n'a aucune pièce unitaire associée.</a
        >
      </div>
    </ng-container>
    <div class="col-12 mt-2" *ngIf="!authService.userBundle.du.phoneNumber">
      <i class="fas fa-exclamation-triangle text-warning mr-2"></i>
      <a class="d-none d-md-inline" [routerLink]="null" (click)="openProfilePopup()"
        >Vous n'avez pas indiqué votre numéro de téléphone portable dans votre profil personnel.</a
      >
      <a class="d-inline d-md-none" routerLink="/mon-profil">Vous n'avez pas indiqué votre numéro de téléphone portable dans votre profil personnel.</a>
    </div>
    <div class="col-12 mt-2" *ngIf="data.noPresentation">
      <i class="fas fa-exclamation-triangle text-warning mr-2"></i>
      <a routerLink="/ma-page-degineo/edit">Vous n'avez pas de présentation sur votre Page Degineo.</a>
    </div>
    <div class="col-12 mt-2" *ngIf="!authService.userBundle.du.logoExtension">
      <input type="file" id="file" style="display: none" accept="image/png,image/jpeg,image/gif" #fileInput (change)="handleFileInput($event)" />
      <i class="fas fa-exclamation-triangle text-warning mr-2"></i><a [routerLink]="null" (click)="fileInput.click()">Vous n'avez pas de photo de profil.</a>
    </div>
  </div>
  <div class="row" *ngIf="data === null">
    <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
  </div>
</div>
