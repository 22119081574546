<div class="container-fluid">
  <div class="row" [class.mt-3]="!first" *ngFor="let first = first; let consumable; of: myPrintersItemComponent.printerDto.ppcos">
    <div class="col align-self-center">
      <input
        type="checkbox"
        (change)="changeActiveStatus(consumable)"
        [checked]="consumable.active"
        [disabled]="!consumable.checked"
        id="consumable-{{ consumable.idProducerPrinterConsumableOption }}"
      />
      <label
        class="fw-500 pointer no-select"
        [class.ellipsis]="editMaterialCostMode[consumable.idProducerPrinterConsumableOption]"
        [class.maxwidth]="editMaterialCostMode[consumable.idProducerPrinterConsumableOption]"
        [class.opacity]="!consumable.active"
        for="consumable-{{ consumable.idProducerPrinterConsumableOption }}"
        >{{ consumable.brand }} - {{ consumable.name }} - {{ consumable.color }}</label
      >
    </div>
    <div class="col-auto text-right text-warning fw-500" *ngIf="!consumable.checked">
      En attente de validation<i
        class="fas fa-ban hover-danger pointer ml-3"
        ngbTooltip="Annuler l'ajout du consommable"
        *ngIf="hasRight"
        (click)="deleteConsumable(consumable)"
      ></i>
    </div>
    <div class="col-auto text-right" *ngIf="consumable.checked && hasRight && authService.userBundle.subscribed">
      <span
        [class.text-danger]="consumable.currentMaterialCost === 0"
        [class.opacity]="!consumable.active"
        *ngIf="!editMaterialCostMode[consumable.idProducerPrinterConsumableOption]"
      >
        <i class="fas fa-exclamation-triangle text-warning mr-2" *ngIf="consumable.currentMaterialCost === 0"></i>Coût matière par cm<sup>3</sup>&nbsp;:
        <a [routerLink]="null" (click)="editMaterialCostMode[consumable.idProducerPrinterConsumableOption] = true"
          >{{ consumable.currentMaterialCost | number: '.3':'fr-FR' }} €</a
        >
      </span>

      <form class="form-inline" *ngIf="editMaterialCostMode[consumable.idProducerPrinterConsumableOption]">
        Coût matière par cm<sup>3</sup>&nbsp;:
        <div class="ml-2 input-group">
          <input type="number" #input class="form-control" style="width: 90px !important" [value]="consumable.currentMaterialCost" />
          <div class="input-group-append">
            <span class="input-group-text">€</span>
          </div>
        </div>
        <i class="fas fa-check-circle fs-4 text-success pointer mx-2" (click)="editMaterialCost(consumable, input.value)"></i>
        <i class="fas fa-times-circle fs-4 text-danger pointer mr-2" (click)="editMaterialCostMode[consumable.idProducerPrinterConsumableOption] = false"></i>
        <span class="fs-2 text-primary"
          ><i class="fas fa-info-circle"></i> Le coût matière conseillé est de {{ consumable.defaultMaterialCost | number: '.3':'fr-FR' }} €</span
        >
      </form>

      <i
        class="far fa-trash-alt pointer hover-danger ml-3"
        *ngIf="!editMaterialCostMode[consumable.idProducerPrinterConsumableOption] && hasRight"
        ngbTooltip="Retirer ce consommable"
        (click)="deleteConsumable(consumable)"
      ></i>
    </div>
  </div>

  <div class="row mt-3 pointer no-select" *ngIf="!add && hasRight" (click)="addMode()">
    <div class="col text-primary pointer no-select">
      <i class="fas fa-plus-circle"></i> Ajouter un consommable <i class="fas fa-spinner spinning" *ngIf="loadingChoices"></i>
      <!--<span class="text-danger ml-3" *ngIf="noMoreConsumables">Aucun consommable disponible à l'ajout</span>-->
    </div>
  </div>
  <div class="row mt-3" *ngIf="add">
    <div class="col">
      <form class="form-inline">
        <select class="form-control mr-3" name="brand" (change)="consumableBrandChanged()" [(ngModel)]="selectedBrand">
          <option [ngValue]="null" disabled="disabled">-- Marque --</option>
          <option *ngFor="let consumable of brandChoice" [ngValue]="consumable">{{ consumable.brand }}</option>
        </select>
        <select class="form-control mr-3" name="name" (change)="consumableNameChanged()" [(ngModel)]="selectedName">
          <option [ngValue]="null" disabled="disabled">-- Référence --</option>
          <option *ngFor="let name of nameChoice" [ngValue]="name">{{ name.name }}</option>
        </select>
        <select class="form-control mr-3" name="color" [(ngModel)]="selectedColor">
          <option [ngValue]="null" disabled="disabled">-- Couleur --</option>
          <option *ngFor="let color of colorChoice" [ngValue]="color">{{ color.colorName }}</option>
        </select>
        <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" (click)="addConsumable()"></i>
        <i class="fas fa-times-circle fs-4 text-danger pointer no-select" (click)="cancelAdd()"></i>
      </form>
      <div class="mt-2 ml-1"><a [routerLink]="null" (click)="openChat()">Vous ne trouvez pas votre consommable dans la liste&nbsp;?</a></div>
    </div>
  </div>
</div>
