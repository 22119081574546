<div class="container-fluid">
  <div class="row" style="min-height: 100vh !important">
    <div class="col-2 bg-primary d-md-block d-none" style="min-width: 20rem; max-width: 21rem">
      <div class="container-fluid mt-5">
        <div class="row">
          <div class="col text-center"><img src="assets/header/logo_degineo_blanc_vert.svg" style="height: 3rem" /></div>
        </div>
        <div class="ligne">
          <div class="row mt-5 bullet-text" [class.active]="subscribeService.step === SubscribeService.STEP_2_USER">
            <div class="col">Bienvenue</div>
          </div>
          <div
            class="row mt-4 bullet-text"
            [class.active]="subscribeService.step === SubscribeService.STEP_3_COMPANY || subscribeService.step === SubscribeService.STEP_4_SHIPPING"
          >
            <div class="col">Votre entreprise</div>
          </div>
          <div class="row mt-4 bullet-text" [class.active]="subscribeService.step === SubscribeService.STEP_5_CONTRACT">
            <div class="col">Contrat</div>
          </div>
          <div class="row mt-4 bullet-text" [class.active]="subscribeService.step === SubscribeService.STEP_6_BANKACCOUNT">
            <div class="col">Coordonnées Bancaires</div>
          </div>
          <div class="row mt-4 bullet-text" [class.active]="subscribeService.step === SubscribeService.STEP_7_SIGN">
            <div class="col">Signature</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <!--align-items-center d-flex pour centrer completement-->
      <div class="py-5 container-small">
        <app-subscribe-step2-user *ngIf="subscribeService.step === SubscribeService.STEP_2_USER"></app-subscribe-step2-user>
        <app-subscribe-step3-company *ngIf="subscribeService.step === SubscribeService.STEP_3_COMPANY"></app-subscribe-step3-company>
        <app-subscribe-step4-shipping *ngIf="subscribeService.step === SubscribeService.STEP_4_SHIPPING"></app-subscribe-step4-shipping>
        <app-subscribe-step5-contract *ngIf="subscribeService.step === SubscribeService.STEP_5_CONTRACT"></app-subscribe-step5-contract>
        <app-subscribe-step6-bankaccount *ngIf="subscribeService.step === SubscribeService.STEP_6_BANKACCOUNT"></app-subscribe-step6-bankaccount>
        <app-subscribe-step7-sign *ngIf="subscribeService.step === SubscribeService.STEP_7_SIGN"></app-subscribe-step7-sign>
        <app-subscribe-step8-bravo *ngIf="subscribeService.step === SubscribeService.STEP_8_BRAVO"></app-subscribe-step8-bravo>
      </div>
    </div>
  </div>
</div>
