import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProducerTakeControlStep4DTO, RegisterService, UserEditValidators } from 'degineo-common';
import { L } from 'ic2-lib';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TakeControlService {
  public static readonly STEP_1_USER = 1;
  public static readonly STEP_2_BUSINESSTYPE = 2;
  public static readonly STEP_3_SIRET = 3;
  public static readonly STEP_4_COMPANY = 4;
  public static readonly STEP_5_PRINTERS = 5;

  public step: number = TakeControlService.STEP_1_USER;
  public data: ProducerTakeControlStep4DTO;

  step1form: FormGroup = this.fb.group(
    {
      email: [null, [Validators.required, Validators.email], this.uniqueEmailValidator.bind(this)],
      password: [null, [Validators.required, Validators.minLength(6), UserEditValidators.passwordDifficulty]],
      password2: [null, [Validators.required, Validators.minLength(6), UserEditValidators.passwordDifficulty]],
    },
    {
      updateOn: 'blur',
      validators: UserEditValidators.checkPasswords('password', 'password2'),
    }
  );
  step2form: FormGroup = this.fb.group({
    businessType: [null, [Validators.required]],
  });

  step3form: FormGroup = this.fb.group({
    siret: [null, [Validators.required, UserEditValidators.siretValide]],
  });

  step4form: FormGroup = this.fb.group({
    siret: [{ value: null, disabled: true }, []],
    name: [null, [Validators.required]],
    legalStatus: [null, [Validators.required]],
    billingAddress: [null, [Validators.required]],
  });

  constructor(private fb: FormBuilder, public registerService: RegisterService) {}

  uniqueEmailValidator(input: FormControl): Observable<{}> {
    return new Observable((obs) => {
      this.registerService.emailAvailable(input.value).subscribe(
        (data) => {
          obs.next(!data ? { alreadyExists: true } : null);
          obs.complete();
        },
        (err) => {
          L.e(err);
          obs.next({ couldNotCheckEmailAvailability: true });
          obs.complete();
        }
      );
    });
  }
}
