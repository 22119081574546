<div class="row fw-500 fs-2 mt-2" [ngClass]="class">
  <div class="col">{{ expedition ? 'Expedition' : 'Impression' }} N°&nbsp;{{ id }}</div>
  <div class="col text-right" [class.mr-5]="crossMargin">{{ dateLabel }} {{ depuis ? 'depuis ' : ' ' }}{{ moment(sinceDate).fromNow(depuis) }}</div>
</div>
<div class="row mt-3 fs-4">
  <div class="col fw-700" *ngIf="!clientChoosedThisProducer">
    Demande Degineo
  </div>
  <div class="col fw-700" *ngIf="clientChoosedThisProducer">
    {{ client.clientName }} <a [routerLink]="null" class="ml-2" (click)="openPopupInfos()"><i class="fas fa-info-circle"></i></a>
  </div>
</div>
<div class="row mt-2" *ngIf="deadline !== null">
  <div class="col" [class.text-danger]="isOutdated(deadline)">
    <span class="fw-700">Deadline&nbsp;: </span>
    <span>{{ deadline | date: 'dd/MM/yyyy' }}</span>
  </div>
</div>
