import { Component, OnInit, Host, Inject } from '@angular/core';
import { MyPrintersItemComponent } from '../my-printers-item/my-printers-item.component';
import { ProducerPrinterService, ProducerPrinterClientTypeMargin, BusinessTargetLevel1, Finish, PPFFullDTO } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-my-printers-finish',
  templateUrl: './my-printers-finish.component.html',
  styleUrls: ['./my-printers-finish.component.scss'],
})
export class MyPrintersFinishComponent implements OnInit {
  add: boolean = false;
  edit: { [key: number]: boolean } = {};
  noMoreFinish: boolean = false;
  finishChoices: Finish[] = [];
  selectedFinish: Finish = null;
  price: number = null;

  constructor(
    @Host() public myPrintersItemComponent: MyPrintersItemComponent,
    @Inject(ProducerPrinterService) private producerPrinterService: ProducerPrinterService
  ) {}

  ngOnInit() {}

  addMode() {
    this.producerPrinterService.getFinishChoices(this.myPrintersItemComponent.printerDto.idProducerPrinter).subscribe(
      (data) => {
        this.price = null;
        console.log(data, this.myPrintersItemComponent.printerDto.ppfs);
        this.finishChoices = data.filter((f) => this.myPrintersItemComponent.printerDto.ppfs.find((ppf) => ppf.idFinish === f.id) === undefined);
        if (this.finishChoices.length === 0) this.noMoreFinish = true;
        else this.add = true;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  addCT() {
    if (this.price === null || this.selectedFinish === null) return;
    this.producerPrinterService.addFinishPrice(this.myPrintersItemComponent.printerDto.idProducerPrinter, this.selectedFinish.id, this.price).subscribe(
      (data) => {
        this.add = false;
        this.myPrintersItemComponent.printerDto.ppfs.push(data);
        this.selectedFinish = null;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  saveEdit(ppf: PPFFullDTO, value: string) {
    const val = parseFloat(value);
    this.producerPrinterService.changeFinishPrice(ppf.idProducerPrinterFinish, val).subscribe(
      (data) => {
        ppf.price = val;
        this.edit[ppf.idProducerPrinterFinish] = false;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  deleteMarge(ppf: PPFFullDTO) {
    if (!confirm('Voulez vous vraiment supprimer la finition : ' + ppf.name + ' ?')) return;
    this.producerPrinterService.deleteFinishPrice(ppf.idProducerPrinterFinish).subscribe(
      (data) => {
        this.myPrintersItemComponent.printerDto.ppfs.splice(this.myPrintersItemComponent.printerDto.ppfs.indexOf(ppf), 1);
      },
      (err) => {
        L.e(err);
      }
    );
  }
}
