import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { UserEditValidators, ProducerUserSignupService, AuthService } from 'degineo-common';
import { Observable } from 'rxjs';
import { L } from 'ic2-lib';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  token: string;
  companyName: string;
  loading: boolean = false;
  form: FormGroup = this.fb.group(
    {
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email], this.uniqueEmailValidator.bind(this)],
      phoneNumber: [null, [Validators.required, UserEditValidators.phoneNumber]],
      password: [null, [Validators.required, Validators.minLength(6)]],
      password2: [null, [Validators.required, Validators.minLength(6)]],
    },
    {
      updateOn: 'blur',
      validator: UserEditValidators.checkPasswords('password', 'password2'),
    }
  );

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(ProducerUserSignupService) private producerUserSignupService: ProducerUserSignupService,
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(AuthService) private authService: AuthService,
    @Inject(Router) public router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params['token']) {
        this.token = params['token'];
        this.producerUserSignupService.getInfos(this.token).subscribe(
          (data) => {
            this.companyName = data.companyName;
            this.form.patchValue({
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
            });
          },
          (err) => {
            L.e(err);
          }
        );
      }
    });
  }

  uniqueEmailValidator(input: FormControl): Observable<{}> {
    let obs = Observable.create((obs) => {
      this.producerUserSignupService.emailAvailable(this.token, input.value).subscribe(
        (data) => {
          obs.next(!data ? { alreadyExists: true } : null);
          obs.complete();
        },
        (err) => {
          L.e(err);
          obs.next({ couldNotCheckEmailAvailability: true });
          obs.complete();
        }
      );
    });
    return obs;
  }

  submit() {
    this.loading = true;
    this.producerUserSignupService
      .register(
        this.token,
        this.form.get('firstName').value,
        this.form.get('lastName').value,
        this.form.get('email').value,
        this.form.get('phoneNumber').value,
        this.form.get('password').value
      )
      .subscribe(
        (data) => {
          this.authService.loginWith(data, () => {
            this.router.navigate(['/']);
          });
          this.loading = false;
        },
        (err) => {
          L.e(err);
        }
      );
  }
}
