<ng-container *ngIf="getValidatedShipmentOrders().length === 1">
  <app-panel-header
    [id]="parent.dto.idShipment"
    [expedition]="true"
    [sinceDate]="parent.dto.shipmentCreationDate"
    [clientChoosedThisProducer]="getValidatedShipmentOrders()[0].clientChoosedThisProducer"
    [client]="getValidatedShipmentOrders()[0].client"
    dateLabel="En conditionnement"
    [depuis]="true"
    colorClass="text-k-yellow"
  ></app-panel-header>

  <app-panel-detail
    [token3DModelFinal]="getValidatedShipmentOrders()[0].token3DModelFinal"
    [filenameFinal]="getValidatedShipmentOrders()[0].filenameFinal"
    [idOrder]="getValidatedShipmentOrders()[0].idOrder"
    [quantity]="getValidatedShipmentOrders()[0].quantity"
    [sizeXMm]="getValidatedShipmentOrders()[0].sizeXMm"
    [sizeYMm]="getValidatedShipmentOrders()[0].sizeYMm"
    [sizeZMm]="getValidatedShipmentOrders()[0].sizeZMm"
    [colorName]="getValidatedShipmentOrders()[0].colorName"
    [colorHex]="getValidatedShipmentOrders()[0].colorHex"
    [deadline]="getValidatedShipmentOrders()[0].wishedDeliveryDate"
    [finishName]="getValidatedShipmentOrders()[0].finishName"
  ></app-panel-detail>
</ng-container>

<ng-container *ngIf="getValidatedShipmentOrders().length > 1 || getValidatedShipmentOrders().length === 0">
  <app-panel-header
    [id]="parent.dto.idShipment"
    [expedition]="true"
    [sinceDate]="parent.dto.shipmentCreationDate"
    [clientChoosedThisProducer]="parent.dto.shipmentOrders[0].clientChoosedThisProducer"
    [client]="parent.dto.shipmentOrders[0].client"
    [deadline]="parent.dto.minWishedDeliveryDate"
    dateLabel="En conditionnement"
    colorClass="text-k-yellow"
  ></app-panel-header>
</ng-container>

<ng-container *ngIf="parent.dto.shipmentOrders[0].shippingAddress !== null">
  <app-panel-address class="mt-3" [address]="parent.dto.shipmentOrders[0].shippingAddress"></app-panel-address>
</ng-container>

<ng-container *ngIf="parent.dto.shipmentOrders[0].clientChoosedThisProducer">
  <div class="row mt-5">
    <div class="col text-center fw-700">Comment souhaitez-vous livrer votre client ?</div>
  </div>
  <div class="row mt-2 justify-content-center">
    <div class="col-8 py-2 text-center border border-secondary rounded-pill">
      <input type="radio" name="deliverMode" id="degineoDeliver" [value]="false" [(ngModel)]="parent.dto.producerDeliver" />
      <label class="form-check-label no-select" style="line-height: 1.5" for="degineoDeliver"
        >Utiliser le service de livraison <img alt="Degineo" class="ml-1" style="height: 1rem" src="assets/header/logo_degineo_bleu_vert.svg"
      /></label>
    </div>
  </div>
  <div class="row mt-2 justify-content-center">
    <div class="col-8 py-2 text-center border border-secondary rounded-pill">
      <input type="radio" name="deliverMode" id="producerDeliver" [value]="true" [(ngModel)]="parent.dto.producerDeliver" />
      <label class="form-check-label no-select" style="line-height: 1.5" for="producerDeliver">Livrer ce client par vos propres moyens</label>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="parent.dto.shipmentOrders.length > 1">
  <div class="row mt-5">
    <div class="col text-center">
      D'autres impressions en cours seront à livrer à la même adresse.<br />
      Souhaitez-vous attendre ces impressions afin de faire un envoi groupé ?
    </div>
  </div>

  <div class="row mt-3 justify-content-center">
    <div class="col-7 border border-secondary rounded bg-light-30p">
      <div class="container-fluid" style="padding-bottom: 13px">
        <div class="row">
          <div class="col text-center py-3 fw-700">Attendre les impressions suivantes :</div>
        </div>
        <div class="row my-1 py-1 bg-white rounded-pill" *ngFor="let order of parent.dto.shipmentOrders">
          <div class="col-4">
            N° <u class="pointer" (click)="openPopup(order)">{{ order.idOrder }}</u>
          </div>
          <div class="col-2 text-success">
            <span *ngIf="order.ready">Prêt</span>
          </div>
          <div class="col-6 text-right">
            <input type="radio" [name]="'wait-' + order.idOrder" [id]="'wait-' + order.idOrder + '-yes'" [value]="true" [(ngModel)]="order.waitForIt" />
            <label class="form-check-label no-select mr-2" style="line-height: 1.5" [for]="'wait-' + order.idOrder + '-yes'">Oui</label>
            <input type="radio" [name]="'wait-' + order.idOrder" [id]="'wait-' + order.idOrder + '-no'" [value]="false" [(ngModel)]="order.waitForIt" />
            <label class="form-check-label no-select" style="line-height: 1.5" [for]="'wait-' + order.idOrder + '-no'">Non</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="row mt-4" *ngIf="error">
  <div class="col">
    <div class="alert alert-danger px-3 px-md-5 py-3">
      <ul>
        <li>{{ error }}</li>
      </ul>
    </div>
  </div>
</div>

<div class="row mt-5 justify-content-center">
  <div class="col-6" *ngIf="getValidatedShipmentOrders().length === 1">
    <button class="btn btn-secondary w-100" [disabled]="loading" *ngIf="authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING" (click)="cancel()">
      <i class="fas fa-fast-backward text-white mr-2"></i>L'impression n'est pas finie
    </button>
  </div>
  <div class="col-6">
    <button
      class="btn btn-primary w-100"
      [disabled]="loading"
      (click)="accept()"
      *ngIf="!isReadyAndProducerDeliver() && authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING"
    >
      Valider
    </button>
    <button
      class="btn btn-success w-100"
      [disabled]="loading"
      (click)="accept()"
      *ngIf="isReadyAndProducerDeliver() && authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING"
    >
      Prêt à être expédié <i class="fas fa-fast-forward text-white float-right"></i>
    </button>
  </div>
</div>
