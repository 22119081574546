import { Component, OnInit } from '@angular/core';
import { ProducerDashboardService } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-dashboard-stats',
  templateUrl: './dashboard-stats.component.html',
  styleUrls: ['./dashboard-stats.component.scss'],
})
export class DashboardStatsComponent implements OnInit {
  single = [
    {
      name: 'Clients actifs',
      value: 0,
    },
    {
      name: 'Fichier 3D modifiés',
      value: 0,
    },
    {
      name: 'Pièces produites',
      value: 0,
    },
    {
      name: 'Commandes traitées',
      value: 0,
    },
  ];

  view: any[] = [600, 200];

  colorScheme = {
    domain: ['#d82929', '#ffc107', '#06c', '#00c462'],
  };

  constructor(private producerDashboardService: ProducerDashboardService) {}

  ngOnInit(): void {
    this.producerDashboardService.getStats().subscribe(
      (data) => {
        this.single = [
          {
            name: 'Clients actifs',
            value: data.nbClientActifs,
          },
          {
            name: 'Fichier 3D modifiés',
            value: data.nbFichier3DModifie,
          },
          {
            name: 'Pièces produites',
            value: data.nbPieceProduites,
          },
          {
            name: 'Commandes traitées',
            value: data.nbCommandesTraites,
          },
        ];
      },
      (err) => {
        L.e(err);
      }
    );
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}
