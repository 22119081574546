import { Component, OnInit, Host, Inject } from '@angular/core';
import { MyPrintersComponent } from '../my-printers.component';
import {
  ProducerPrinterService,
  PPCOFullDTO,
  ConsumableOptionBrandChoiceDTO,
  ConsumableOptionConsumableNameChoice,
  ConsumableOptionColorChoiceDTO,
  AuthService,
  RoleInCompany,
} from 'degineo-common';
import { MyPrintersItemComponent } from '../my-printers-item/my-printers-item.component';
import { L } from 'ic2-lib';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'app-my-printers-consumables',
  templateUrl: './my-printers-consumables.component.html',
  styleUrls: ['./my-printers-consumables.component.scss'],
})
export class MyPrintersConsumablesComponent implements OnInit {
  editMaterialCostMode: any = {};
  add: boolean = false;
  loadingAdd: boolean = false;
  loadingChoices: boolean = false;
  //noMoreConsumables: boolean = false;
  brandChoice: ConsumableOptionBrandChoiceDTO[] = [];
  selectedBrand: ConsumableOptionBrandChoiceDTO = null;
  nameChoice: ConsumableOptionConsumableNameChoice[] = [];
  selectedName: ConsumableOptionConsumableNameChoice = null;
  colorChoice: ConsumableOptionColorChoiceDTO[] = [];
  selectedColor: ConsumableOptionColorChoiceDTO = null;
  hasRight: boolean;

  constructor(
    @Host() public myPrintersItemComponent: MyPrintersItemComponent,
    @Inject(ProducerPrinterService) private producerPrinterService: ProducerPrinterService,
    @Inject(AuthService) private authService: AuthService,
    private intercom: Intercom
  ) {
    this.hasRight = this.authService.userBundle.role.id <= RoleInCompany.PRODUCER_ACCOUNTING.id;
  }

  ngOnInit() {}

  consumableBrandChanged() {
    this.selectedColor = null;
    this.colorChoice = [];
    this.selectedName = null;
    this.nameChoice = this.selectedBrand.names;
    if (this.nameChoice.length === 1) {
      this.selectedName = this.nameChoice[0];
      this.consumableNameChanged();
    }
  }

  consumableNameChanged() {
    this.selectedColor = null;
    this.colorChoice = this.selectedName.colors;
    if (this.colorChoice.length === 1) {
      this.selectedColor = this.colorChoice[0];
    }
  }

  cancelAdd() {
    this.brandChoice = [];
    this.selectedBrand = null;
    this.nameChoice = [];
    this.selectedName = null;
    this.colorChoice = [];
    this.selectedColor = null;
    this.add = false;
  }

  addConsumable() {
    if (this.loadingAdd) return;
    this.loadingAdd = true;
    this.producerPrinterService.addConsumable(this.myPrintersItemComponent.printerDto.idProducerPrinter, this.selectedColor.idConsumableOption).subscribe(
      (data) => {
        this.loadingAdd = false;
        this.myPrintersItemComponent.myPrintersComponent.reload();
      },
      (err) => {
        this.loadingAdd = false;
        L.e(err);
      }
    );
  }

  deleteConsumable(consumable: PPCOFullDTO) {
    if (!confirm('Voulez vous vraiment supprimer le consommable ' + consumable.brand + ' ' + consumable.name + ' ' + consumable.color + ' ?')) return;
    this.producerPrinterService.deleteConsumable(consumable.idProducerPrinterConsumableOption).subscribe(
      (data) => {
        this.myPrintersItemComponent.printerDto.ppcos.splice(this.myPrintersItemComponent.printerDto.ppcos.indexOf(consumable), 1);
        this.myPrintersItemComponent.myPrintersComponent.rebuildConsumablesForManagment();
      },
      (err) => {
        L.e(err);
      }
    );
  }

  addMode() {
    this.loadingChoices = true;
    this.producerPrinterService.getConsumableOptionChoices(this.myPrintersItemComponent.printerDto.idProducerPrinter).subscribe(
      (data) => {
        this.loadingChoices = false;
        /*if (data.length === 0) { //modif #637
          //Cas ou il n'y a plus de consommable ajoutables
          this.noMoreConsumables = true;
        } else {*/
        this.add = true;
        this.brandChoice = data;
        //}
      },
      (err) => {
        L.e(err);
        this.loadingChoices = false;
      }
    );
  }

  editMaterialCost(consumable: PPCOFullDTO, value: string) {
    const val = parseFloat(value);
    this.editMaterialCostMode[consumable.idProducerPrinterConsumableOption] = false;
    this.producerPrinterService.changeConsumableCost(consumable.idProducerPrinterConsumableOption, val).subscribe(
      (data) => {
        consumable.currentMaterialCost = val;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  changeActiveStatus(consumable: PPCOFullDTO) {
    this.producerPrinterService.changeConsumableActiveStatus(consumable.idProducerPrinterConsumableOption, !consumable.active).subscribe(
      (data) => {
        consumable.active = !consumable.active;
        this.myPrintersItemComponent.myPrintersComponent.rebuildConsumablesForManagment();
      },
      (err) => {
        L.e(err);
      }
    );
  }
  openChat() {
    this.intercom.show(
      'Bonjour, je souhaite ajouter un consommable suivant à ' +
        this.myPrintersItemComponent.printerDto.printerBrand +
        ' - ' +
        this.myPrintersItemComponent.printerDto.printerName +
        ' :\n* Marque : \n* Référence : \n* Couleur : '
    );
  }
}
