<div class="row">
  <div class="col text-center">
    <h2 style="font-size: 1.6rem">Signature</h2>
  </div>
</div>

<div class="row mt-5">
  <div class="col-6">
    <button (click)="previous()" [disabled]="saving" class="btn btn-secondary w-100"><i class="fas fa-arrow-left"></i></button>
  </div>
  <div class="col-6">
    <button [disabled]="saving" class="btn btn-success w-100" (click)="submit()">
      <i class="fas fa-check"></i><i class="fas fa-spinner spinning ml-3" *ngIf="saving"></i>
    </button>
  </div>
</div>
