import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LegalStatus } from 'degineo-common';
import { TakeControlService } from '../take-control.service';

@Component({
  selector: 'app-take-control-step4-company',
  templateUrl: './take-control-step4-company.component.html',
  styleUrls: ['./take-control-step4-company.component.scss'],
})
export class TakeControlStep4CompanyComponent implements OnInit {
  LegalStatus: typeof LegalStatus = LegalStatus;
  constructor(public takeControlService: TakeControlService, private fb: FormBuilder) {}

  ngOnInit(): void {}

  submit() {
    if (!this.takeControlService.step4form.valid) {
      console.log(this.takeControlService.step4form);
      return;
    }
    this.takeControlService.step = TakeControlService.STEP_5_PRINTERS;
  }

  previous() {
    this.takeControlService.step = TakeControlService.STEP_3_SIRET;
  }
}
