import { Component, OnInit, Input, Host, Inject, SimpleChanges, OnChanges } from '@angular/core';
import {
  InPreparationOrderPanelDTO,
  InPreparationOrderService,
  InPrepProducerPrinterDTO,
  InPrepPPCODTO,
  OrderFileStatus,
  AllowedModelExtensionsService,
  RoleInCompany,
  AuthService,
} from 'degineo-common';
import { environment } from 'environments/environment';
import * as moment_ from 'moment';
import { MyOrdersComponent } from '../my-orders.component';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-in-preparation-order-panel',
  templateUrl: './in-preparation-order-panel.component.html',
  styleUrls: ['./in-preparation-order-panel.component.scss'],
})
export class InPreparationOrderPanelComponent implements OnInit, OnChanges {
  @Input()
  id: number;
  dto: InPreparationOrderPanelDTO = null;
  moment = moment_;
  rpcHost: string = environment.rpcHost;
  loading: boolean = false;
  editPrinterMode: boolean = false;
  selectedPrinterId: number = null;
  consumableChoices: InPrepPPCODTO[] = [];
  selectedConsumableOption: InPrepPPCODTO = null;
  correct: boolean = false;
  aCorriger: boolean = false;
  progressPercent: string = null;
  errorFile: string = null;
  OrderFileStatus: typeof OrderFileStatus = OrderFileStatus;
  saved: boolean = false;

  RoleInCompany: typeof RoleInCompany = RoleInCompany;
  constructor(
    @Inject(InPreparationOrderService) private inPreparationOrderService: InPreparationOrderService,
    @Host() public myOrdersComponent: MyOrdersComponent,
    @Inject(AllowedModelExtensionsService) public allowedModelExtensionsService: AllowedModelExtensionsService,
    @Inject(AuthService) public authService: AuthService
  ) {
    this.moment.locale('fr');
  }

  ngOnInit() {
    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dto = null;
    this.load();
  }

  load() {
    this.inPreparationOrderService.getPreparation(this.id).subscribe(
      (data) => {
        this.dto = data;
        console.log(data);
        this.correct = false;
        this.aCorriger = false;
        if (this.dto.producerFileStatus === OrderFileStatus.FILE_OK) this.correct = true;
        if (this.dto.producerFileStatus === OrderFileStatus.FILE_TO_CORRECT) this.aCorriger = true;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  handleFileInput(event) {
    if (this.loading) return;
    const files: FileList = event.target.files;
    const file = files[0];
    this.loading = true;
    this.inPreparationOrderService
      .updateOrderModel(this.dto.idOrder)
      .withFile(file)
      .subscribeWithProgress(
        (data) => {
          this.progressPercent = null;
          this.dto.producerFileStatus = OrderFileStatus.FILE_CORRECTED;
          this.myOrdersComponent.inPreparationOrder.producerFileStatus = OrderFileStatus.FILE_CORRECTED;
          this.load();
          this.loading = false;
        },
        (loaded, total) => {
          this.progressPercent = ((loaded / total) * 100).toFixed(0);
        },
        (err) => {
          this.loading = false;
          this.progressPercent = null;
          L.e(err);
          this.errorFile = err.message;
        }
      );
  }

  onChangePrinter() {
    this.consumableChoices = this.dto.printerConsumablePossibilities.find((p) => p.idPrinter === this.selectedPrinterId).consumablesOptions;
    this.selectedConsumableOption = null;
    if (this.consumableChoices.length === 1) {
      this.selectedConsumableOption = this.consumableChoices[0];
      this.onChangeConsumable();
    }
  }

  onChangeConsumable() {
    this.inPreparationOrderService.changePrintMethod(this.dto.idOrder, this.selectedConsumableOption.idProducerPrinterConsumableOption).subscribe(
      (data) => {
        this.load();
        this.myOrdersComponent.reload();
        this.saved = true;
        console.log('AFTER SAVE', this);
        setTimeout(() => {
          this.saved = false;
        }, 4000);
      },
      (err) => {
        L.e(err);
      }
    );
  }

  correctChange(event) {
    if (this.dto.producerFileStatus === OrderFileStatus.FILE_OK) this.changeTo(OrderFileStatus.FILE_TO_CHECK);
    else this.changeTo(OrderFileStatus.FILE_OK);
  }

  aCorrigerChange() {
    if (this.dto.producerFileStatus === OrderFileStatus.FILE_TO_CORRECT) this.changeTo(OrderFileStatus.FILE_TO_CHECK);
    else this.changeTo(OrderFileStatus.FILE_TO_CORRECT);
  }

  changeTo(status: OrderFileStatus) {
    if (this.loading) return;
    this.loading = true;
    this.inPreparationOrderService.change3DFileStatus(this.dto.idOrder, status).subscribe(
      (data) => {
        this.dto.producerFileStatus = status;
        this.myOrdersComponent.inPreparationOrder.producerFileStatus = status;

        this.aCorriger = status === OrderFileStatus.FILE_TO_CORRECT;
        this.correct = status === OrderFileStatus.FILE_OK;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  cancel() {
    if (this.loading) return;
    this.loading = true;
    if (!confirm('Êtes-vous sûrs ?')) return;
    this.inPreparationOrderService.cancelOrder(this.dto.idOrder).subscribe(
      (data) => {
        this.myOrdersComponent.reload();
        this.myOrdersComponent.close();
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  accept() {
    if (this.loading) return;
    this.loading = true;
    this.inPreparationOrderService.launchInProduction(this.dto.idOrder).subscribe(
      (data) => {
        this.myOrdersComponent.reload();
        this.myOrdersComponent.close();
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }
}
