<ng-template #modalContent let-modal>
  <div class="container shadow-5">
    <div class="row mt-4">
      <div class="col text-center">
        Si vous souhaitez changer votre SIRET,<br />
        vous devez <span class="fw-700">nous contacter via le Chat live</span>.
      </div>
    </div>
    <div class="row mt-4 justify-content-center" style="margin-bottom: 2rem">
      <div class="col-6">
        <button type="button" class="btn btn-success w-100" (click)="modal.close('OK'); openChat()">OK</button>
      </div>
    </div>
  </div>
</ng-template>
<div class="container-small mt-4 mb-5">
  <div class="row" *ngIf="dto === null">
    <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
  </div>
  <ng-container *ngIf="dto !== null">
    <div class="row">
      <div class="col">
        <label>Nom commercial de l'entreprise</label>
      </div>
      <div class="col text-right">
        <a [routerLink]="null" *ngIf="!editCompany" (click)="editCompany = true">modifier</a>

        <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editCompany && !loading" (click)="saveEditCompany()"></i>
        <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editCompany && !loading" (click)="cancelEdit(); editCompany = false"></i>
        <i class="fas fa-spinner spinning" *ngIf="editCompany && loading"></i>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" class="form-control dashed-when-disabled" [(ngModel)]="dto.name" [disabled]="!editCompany" />
      </div>
    </div>

    <div class="row mt-5">
      <div class="col">
        <label>Raison sociale</label>
      </div>
      <div class="col text-right">
        <a [routerLink]="null" *ngIf="!editDenomination" (click)="editDenomination = true">modifier</a>

        <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editDenomination && !loading" (click)="saveEditDenomination()"></i>
        <i
          class="fas fa-times-circle fs-4 text-danger pointer no-select"
          *ngIf="editDenomination && !loading"
          (click)="cancelEdit(); editDenomination = false"
        ></i>
        <i class="fas fa-spinner spinning" *ngIf="editDenomination && loading"></i>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" class="form-control dashed-when-disabled" [(ngModel)]="dto.denomination" [disabled]="!editDenomination" />
      </div>
    </div>

    <div class="row mt-5">
      <div class="col">
        <label>Forme juridique</label>
      </div>
      <div class="col text-right">
        <a [routerLink]="null" *ngIf="!editLegalStatus" (click)="editLegalStatus = true">modifier</a>

        <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editLegalStatus && !loading" (click)="saveEditLegalStatus()"></i>
        <i
          class="fas fa-times-circle fs-4 text-danger pointer no-select"
          *ngIf="editLegalStatus && !loading"
          (click)="cancelEdit(); editLegalStatus = false"
        ></i>
        <i class="fas fa-spinner spinning" *ngIf="editLegalStatus && loading"></i>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <select class="form-control dashed-when-disabled" [(ngModel)]="dto.legalStatus" [disabled]="!editLegalStatus">
          <option [ngValue]="null" disabled="disabled">-- Choisir --</option>
          <option *ngFor="let ls of LegalStatus.values" [ngValue]="ls">{{ ls.lib }}</option>
        </select>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col">
        <label>SIRET</label>
      </div>
      <div class="col text-right">
        <a [routerLink]="null" (click)="showPopup($event, modalContent, 'mon siret')">modifier</a>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" class="form-control dashed-when-disabled" [(ngModel)]="dto.siret" mask="000 000 000 00000" [disabled]="true" />
      </div>
    </div>

    <ng-container
      *ngIf="
        authService.userBundle.subscribed &&
        dto.legalStatus != LegalStatus.STATUS_AE &&
        dto.legalStatus != LegalStatus.STATUS_EI &&
        dto.legalStatus != LegalStatus.STATUS_ASSOCIATION
      "
    >
      <div class="row mt-5">
        <div class="col">
          <label>Capital social</label>
        </div>
        <div class="col text-right">
          <a [routerLink]="null" *ngIf="!editCapital" (click)="editCapital = true">modifier</a>

          <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editCapital && !loading" (click)="saveEditCapital()"></i>
          <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editCapital && !loading" (click)="cancelEdit(); editCapital = false"></i>
          <i class="fas fa-spinner spinning" *ngIf="editCapital && loading"></i>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <input type="number" class="form-control dashed-when-disabled" [(ngModel)]="dto.capital" [disabled]="!editCapital" />
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="authService.userBundle.subscribed && dto.legalStatus == LegalStatus.STATUS_ASSOCIATION">
      <div class="row mt-5">
        <div class="col">
          <label>Numéro RNA</label>
        </div>
        <div class="col text-right">
          <a [routerLink]="null" *ngIf="!editRNA" (click)="editRNA = true">modifier</a>

          <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editRNA && !loading" (click)="saveEditRNA()"></i>
          <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editRNA && !loading" (click)="cancelEdit(); editRNA = false"></i>
          <i class="fas fa-spinner spinning" *ngIf="editRNA && loading"></i>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <input type="text" class="form-control dashed-when-disabled" [(ngModel)]="dto.RNANumber" [disabled]="!editRNA" />
        </div>
      </div>
    </ng-container>

    <div class="row mt-5">
      <div class="col">
        <label>Ville d'immatriculation au RCS</label>
      </div>
      <div class="col text-right">
        <a [routerLink]="null" *ngIf="!editRCS" (click)="editRCS = true">modifier</a>

        <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editRCS && !loading" (click)="saveEditRCS()"></i>
        <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editRCS && !loading" (click)="cancelEdit(); editRCS = false"></i>
        <i class="fas fa-spinner spinning" *ngIf="editRCS && loading"></i>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input type="text" class="form-control dashed-when-disabled" [(ngModel)]="dto.RCS" [disabled]="!editRCS" />
      </div>
    </div>

    <ng-container *ngIf="authService.userBundle.subscribed">
      <div class="row mt-5">
        <div class="col">
          <label>N° de TVA intracommunautaire</label>
        </div>
        <div class="col text-right">
          <a [routerLink]="null" *ngIf="!editVAT" (click)="editVAT = true">modifier</a>

          <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editVAT && !loading" (click)="saveEditUseVAT()"></i>
          <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editVAT && !loading" (click)="cancelEdit(); editVAT = false"></i>
          <i class="fas fa-spinner spinning" *ngIf="editVAT && loading"></i>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <ui-switch style="vertical-align: sub" size="small" color="#00c462" defaultBgColor="#d82929" [(ngModel)]="dto.useVAT" [disabled]="!editVAT">
          </ui-switch>
          <span class="ml-3" *ngIf="dto.useVAT">{{ getTVAIntracom(dto.siret) }}</span>
          <span class="ml-3" *ngIf="!dto.useVAT">Vous n'êtes pas soumis à la TVA intracommunautaire</span>
          <!--<input type="number" class="form-control dashed-when-disabled" [(ngModel)]="dto.RCS" [disabled]="!editVAT" />-->
        </div>
      </div>
    </ng-container>

    <div class="row mt-5">
      <div class="col">
        <label>Adresse de {{ authService.userBundle.subscribed ? 'facturation' : "l'entreprise" }}</label>
      </div>
      <div class="col text-right">
        <a [routerLink]="null" *ngIf="!editBilling" (click)="editBilling = true">modifier</a>

        <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editBilling && !loading" (click)="saveEditBillingAddress()"></i>
        <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editBilling && !loading" (click)="cancelEdit(); editBilling = false"></i>
        <i class="fas fa-spinner spinning" *ngIf="editBilling && loading"></i>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">Elle est utilisée pour la facturation et les devis.</div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <app-degineo-gmaps-address-input
          class="dashed-when-disabled w-100"
          [(ngModel)]="dto.billingAddress"
          [disabled]="!editBilling"
        ></app-degineo-gmaps-address-input>
      </div>
    </div>

    <ng-container *ngIf="authService.userBundle.subscribed">
      <div class="row mt-5">
        <div class="col">
          <label>Adresse de collecte</label>
        </div>
        <div class="col text-right">
          <a [routerLink]="null" *ngIf="!editCollecte" (click)="editCollecte = true">modifier</a>

          <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editCollecte && !loading" (click)="saveEditCollecteAddress()"></i>
          <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editCollecte && !loading" (click)="cancelEdit(); editCollecte = false"></i>
          <i class="fas fa-spinner spinning" *ngIf="editCollecte && loading"></i>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col">Adresse où le transporteur Degineo vient récupérer les colis à livrer.</div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <app-degineo-gmaps-address-input
            class="dashed-when-disabled w-100"
            [(ngModel)]="dto.collecteAddress"
            [disabled]="!editCollecte"
          ></app-degineo-gmaps-address-input>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col">
          <label>Coordonnées bancaires</label>
        </div>
        <div class="col text-right">
          <a [routerLink]="null" *ngIf="!editBank && !loadingClientSecret" (click)="toggleEditBank()">modifier</a>
          <i class="fas fa-spinner spinning" *ngIf="loadingClientSecret"></i>

          <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editBank && !loading" (click)="saveEditBank()"></i>
          <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editBank && !loading" (click)="cancelEdit(); editBank = false"></i>
          <i class="fas fa-spinner spinning" *ngIf="editBank && loading"></i>
        </div>
      </div>
      <div class="row mt-1" *ngIf="!editBank">
        <div class="col">
          Ce sont les comptes bancaires sur lesquels est débité votre abonnement Degineo, et sur lesquels vous recevez les versements de vos clients.
        </div>
      </div>
      <div class="row mt-3" *ngIf="!editBank">
        <div class="col">
          <ul>
            <li class="fw-500">
              Compte de prélèvement : {{ dto.stripeIbanLast4Digits === null ? 'Aucun compte paramétré' : '**** ' + dto.stripeIbanLast4Digits }}
            </li>
            <li class="fw-500">
              Compte de paiement :
              <span class="text-danger" *ngIf="dto.iban === null || dto.iban === undefined || dto.iban.length === 0; else compteOk"
                >Aucun compte paramétré</span
              >
              <ng-template #compteOk>**** {{ dto.iban.substr(dto.iban.length - 4) }}</ng-template>
            </li>
          </ul>
        </div>
      </div>
      <div class="mt-2 bg-light-30p rounded" style="padding: 1rem 1rem 1.5rem 1rem" *ngIf="editBank">
        <div class="row">
          <div class="col">
            <label>Compte de prélèvement</label><br />
            C'est sur ce compte qu'est débité votre abonnement Degineo.
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <label class="fs-2">IBAN de prélèvement</label
            ><span class="ml-2 fs-2 fw-500" [class.text-success]="dto.stripeIbanLast4Digits !== null" [class.text-danger]="dto.stripeIbanLast4Digits === null"
              >({{ dto.stripeIbanLast4Digits === null ? 'Aucun compte paramétré' : 'Actuellement: **** ' + dto.stripeIbanLast4Digits }})</span
            >
          </div>
        </div>
        <div class="row mt-1">
          <div class="col">
            <div class="alert alert-warning mb-2" *ngIf="debug">
              Iban test :
              <ul>
                <li>ok: FR1420041010050500013M02606</li>
                <li>rate: FR8420041010050500013M02607</li>
                <li>opposition bancaire: FR5720041010050500013M02608</li>
              </ul>
            </div>
            <div class="fakeinput" #iban>
              <!-- A Stripe Element will be inserted here. -->
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <label class="fs-2">BIC</label>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col">
            <input type="text" class="form-control dashed-when-disabled" [disabled]="!editBank" />
          </div>
        </div>
      </div>
      <div class="bg-light-30p rounded mt-3" style="padding: 1rem 1rem 1.5rem 1rem" *ngIf="editBank">
        <div class="row">
          <div class="col">
            <label>Compte de paiement</label><br />
            C'est sur ce compte que vous recevez les versements de vos clients.<br />
            Il peut être identique au compte de prélèvement.
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label class="fs-2">IBAN de paiement</label
            ><span
              class="ml-2 fs-2 fw-500"
              [class.text-success]="dto.iban !== null && dto.iban !== undefined && dto.iban.length > 0"
              [class.text-danger]="dto.iban === null || dto.iban === undefined || dto.iban.length === 0"
              >({{
                dto.iban === null || dto.iban === undefined || dto.iban.length === 0
                  ? 'Aucun compte paramétré'
                  : 'Actuellement : **** ' + dto.iban.substr(dto.iban.length - 4)
              }})</span
            >
          </div>
        </div>
        <div class="row mt-1">
          <div class="col">
            <input
              type="text"
              class="form-control dashed-when-disabled"
              [(ngModel)]="newIban"
              [disabled]="!editBank"
              mask="AAAA AAAA AAAA AAAA AAAA AAAA AAA"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <label class="fs-2">BIC</label>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col">
            <input type="text" class="form-control dashed-when-disabled" [(ngModel)]="newBic" [disabled]="!editBank" />
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col">
          <label>Délai de production</label>
        </div>
        <div class="col text-right">
          <a [routerLink]="null" *ngIf="!editDelay" (click)="editDelay = true">modifier</a>

          <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editDelay && !loading" (click)="saveEditDelay()"></i>
          <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editDelay && !loading" (click)="cancelEdit(); editDelay = false"></i>
          <i class="fas fa-spinner spinning" *ngIf="editDelay && loading"></i>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col">
          Indiquez ici le nombre de jour qu'il faut pour réaliser une demande&nbsp;:<br />
          de la prise de commande jusqu'au conditionnement (nous prenons en compte automatiquement le temps de livraison).<br />
          <div class="mt-2">Lors de sa commande, le client ne pourra pas vous demander un délai de production inférieur à celui que vous indiquez ici.</div>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-6">
          <div class="input-group">
            <input type="number" min="1" class="form-control dashed-when-disabled" [(ngModel)]="dto.productionDelay" [disabled]="!editDelay" />
            <div class="input-group-append">
              <span class="input-group-text">jours ouvrés</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
