import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscribeService } from '../subscribe.service';

@Component({
  selector: 'app-subscribe-step8-bravo',
  templateUrl: './subscribe-step8-bravo.component.html',
  styleUrls: ['./subscribe-step8-bravo.component.scss'],
})
export class SubscribeStep8BravoComponent implements OnInit {
  constructor(private router: Router, public subscribeService: SubscribeService) {}

  ngOnInit() {}

  submit() {
    this.router.navigate(['/']);
  }
}
