import { Component, OnInit } from '@angular/core';
import { ProducerDashboardService } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-dashboard-printers',
  templateUrl: './dashboard-printers.component.html',
  styleUrls: ['./dashboard-printers.component.scss'],
})
export class DashboardPrintersComponent implements OnInit {
  single = [
    {
      name: "En cours d'utilisation",
      value: 0,
    },
    {
      name: 'Non utilisée',
      value: 0,
    },
    {
      name: 'Indisponible',
      value: 0,
    },
  ];
  view: any[] = [600, 200];

  colorScheme = {
    domain: ['#00c462', '#ffc107', '#d82929'],
  };

  constructor(private producerDashboardService: ProducerDashboardService) {}

  ngOnInit(): void {
    this.producerDashboardService.getPrintersStats().subscribe(
      (data) => {
        this.single = [
          {
            name: "En cours d'utilisation",
            value: data.nbPrinterUsed,
          },
          {
            name: 'Non utilisée' + (data.nbAvailable > 1 ? 's' : ''),
            value: data.nbAvailable,
          },
          {
            name: 'Indisponible' + (data.nbUnavailable > 1 ? 's' : ''),
            value: data.nbUnavailable,
          },
        ];
      },
      (err) => {
        L.e(err);
      }
    );
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}
