<form
  autocomplete="off"
  #f="ngForm"
  [formGroup]="takeControlService.step3form"
  [class.show-errors]="f.submitted && !takeControlService.step3form.valid"
  (submit)="submit()"
>
  <div class="container-small py-5">
    <div class="row">
      <div class="col text-center">
        <img src="assets/header/logo_degineo_bleu_vert.svg" style="max-height: 4rem" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center fs-4 fw-500">Entrez le numéro de SIRET de votre entreprise.</div>
    </div>
    <div class="row mt-2">
      <div class="col text-center">Cela nous permet de vous inscrire en quelques clics.</div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <input type="text" class="form-control" formControlName="siret" mask="000 000 000 00000" />
      </div>
    </div>
    <div class="row mt-4" *ngIf="(f.submitted && !takeControlService.step3form.valid) || error !== null">
      <div class="col">
        <div class="alert alert-danger pr-3 pl-3 pr-md-5 pl-md-5 pt-3 pb-3">
          <ul>
            <li *ngIf="takeControlService.step3form.hasError('required', 'siret')">Tous les champs sont requis.</li>
            <li *ngIf="takeControlService.step3form.hasError('invalidSiret', 'siret')">Ce siret est invalide.</li>
            <li *ngIf="error != null">{{ error }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-6">
        <button (click)="previous()" class="btn btn-secondary w-100" [disabled]="saving"><i class="fas fa-arrow-left"></i></button>
      </div>
      <div class="col-6">
        <button type="submit" class="btn btn-success w-100" [disabled]="saving">
          <i class="fas fa-check"></i> <i class="fas fa-spinner spinning" *ngIf="saving"></i>
        </button>
      </div>
    </div>
  </div>
</form>
