<div class="container-fluid">
  <div class="row" [class.mt-3]="!first" *ngFor="let first = first; let atPrice; of: myPrintersItemComponent.printerDto.ppatps">
    <div class="col">
      <div class="d-inline-block">
        <span class="fw-500 mr-2">{{ atPrice.name }}&nbsp;:</span>
      </div>
      <div class="d-inline-block">
        <a [routerLink]="null" *ngIf="!edit[atPrice.idProducerPrinterArticleTypePrice]" (click)="edit[atPrice.idProducerPrinterArticleTypePrice] = true"
          >{{ atPrice.unitPrice | number: '.2':'fr-FR' }} €</a
        >
        <form class="form-inline" *ngIf="edit[atPrice.idProducerPrinterArticleTypePrice]">
          <div class="mr-3 input-group">
            <input type="number" class="form-control" #input [value]="atPrice.unitPrice" style="width: 90px !important;" />
            <div class="input-group-append">
              <span class="input-group-text">€</span>
            </div>
          </div>
          <i class="fas fa-check-circle text-success pointer no-select mr-2" (click)="saveEdit(atPrice, input.value)"></i>
          <i class="fas fa-times-circle text-danger pointer no-select mr-2" (click)="edit[atPrice.idProducerPrinterArticleTypePrice] = false"></i>
          <span class="fs-2 text-primary"
            ><i class="fas fa-info-circle"></i> Le coût production conseillé est de {{ atPrice.advisedUnitPrice | number: '.2':'fr-FR' }} €</span
          >
        </form>
        <i
          class="far fa-trash-alt pointer hover-danger ml-3"
          ngbTooltip="Retirer cette pièce unitaire"
          *ngIf="!edit[atPrice.idProducerPrinterArticleTypePrice]"
          (click)="deleteAt(atPrice)"
        ></i>
      </div>
    </div>
  </div>

  <div class="row mt-3 pointer no-select" *ngIf="!add" (click)="addMode()">
    <div class="col text-primary pointer no-select">
      <i class="fas fa-plus-circle"></i> Ajouter une pièce unitaire
      <span class="text-danger" *ngIf="noMoreArticleType">Aucune pièce unitaire disponible à l'ajout</span>
    </div>
  </div>
  <div class="row mt-3" *ngIf="add">
    <div class="col">
      <form class="form-inline">
        <select class="form-control mr-3" name="ct" [(ngModel)]="selectedAt" (ngModelChange)="onChange()">
          <option [ngValue]="null" disabled="disabled">-- Pièce unitaire --</option>
          <option *ngFor="let at of atChoice" [ngValue]="at">{{ at.name }}</option>
        </select>
        <div class="mr-3 input-group">
          <input type="number" class="form-control" name="marge" style="width: 90px !important;" [(ngModel)]="price" />
          <div class="input-group-append">
            <span class="input-group-text">€</span>
          </div>
        </div>
        <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" (click)="addAT()"></i>
        <i class="fas fa-times-circle fs-4 text-danger pointer no-select" (click)="add = false"></i>
      </form>
    </div>
  </div>
</div>
