<div class="container-fluid shadow-5 p-5">
  <ng-container *ngIf="uploadStep">
    <div class="row">
      <i class="close-icon fas fa-times" (click)="activeModal.close()"></i>
      <div class="col text-center">
        <h3>Ajouter une liste de clients <i class="fas fa-spinner spinning" *ngIf="loading"></i></h3>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-6">
        <ol class="mt-3">
          <!-- prettier-ignore -->
          <li>Téléchargez un modle de liste&nbsp;:
            <a href="assets/client_sample.xls" target="_blank">
              <img
                class="mx-2"
                [src]="hoverExcel ? 'assets/import-clients/icon-model-excel_hover.svg' : 'assets/import-clients/icon-model-excel.svg'"
                style="height:3rem"
                (mouseover)="hoverExcel = true"
                (mouseout)="hoverExcel = false"
              />
            </a>
            ou
            <a href="assets/client_sample.csv" target="_blank">
              <img
              class="ml-2"
                [src]="hoverCSV ? 'assets/import-clients/icon-model-csv_hover.svg' : 'assets/import-clients/icon-model-csv.svg'"
                style="height:3rem"
                (mouseover)="hoverCSV = true"
                (mouseout)="hoverCSV = false"
              />
            </a>
          </li>
          <li>Compétez la liste comme indiqué dans le modèle</li>
          <li>Enregistrez le fichier</li>
          <li>Et renvoyez le fichier complété</li>
        </ol>
      </div>
      <div class="col-6" style="min-height: 18rem;">
        <degineo-upload accept=".xls,.csv" text="complété" (fileUploaded)="onUpload($event)"></degineo-upload>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!uploadStep">
    <div class="row">
      <div class="col text-center">
        <h3
          [class.text-success]="getValidClients().length === clients.length"
          [class.text-warning]="getValidClients().length !== 0 && getValidClients().length !== clients.length"
          [class.text-danger]="getValidClients().length === 0"
        >
          Clients importés : {{ getValidClients().length }}/{{ clients.length }}
        </h3>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div class="table-responsive" style="max-height: 15rem;">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>
                  Entreprise
                </th>
                <th colspan="2">
                  Adresse email
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let client of clients">
                <td>{{ client.name }}</td>
                <td>{{ client.email }}</td>
                <td *ngIf="client.message === null"><i class="fas fa-check text-success"></i></td>
                <td *ngIf="client.message !== null" class="text-danger"><i class="fas fa-times mr-2"></i> {{ client.message }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col text-center">
        <button type="button" class="btn btn-danger mr-3" (click)="cancel()">
          Annuler l'importation
        </button>
        <button type="button" class="btn btn-success mr-3" *ngIf="getValidClients().length > 0" (click)="valid()">
          Valider l'importation <i class="fas fa-spinner spinning" *ngIf="loading"></i>
        </button>
      </div>
    </div>
  </ng-container>
</div>
