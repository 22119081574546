import { Component, OnInit, Input, Inject } from '@angular/core';
import { RoleInCompany, AuthService } from 'degineo-common';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class RolesComponent implements OnInit {
  @Input()
  role: RoleInCompany;

  companyName: string;

  RoleInCompany: typeof RoleInCompany = RoleInCompany;
  constructor(@Inject(AuthService) private authService: AuthService) {}

  ngOnInit() {
    this.companyName = this.authService.userBundle.producerName;
  }
}
