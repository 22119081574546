import { Component, OnInit, Inject } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UserProducerService, ProducerUserProfilDTO, AuthService, UserEditValidators, ForgotPwdPopupComponent } from 'degineo-common';
import { L } from 'ic2-lib';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
  dto: ProducerUserProfilDTO = null;
  dtoOriginal: ProducerUserProfilDTO = null;
  loading: boolean = false;
  editFirstName: boolean = false;
  editLastName: boolean = false;
  editPhoneNumber: boolean = false;
  editJob: boolean = false;
  editEmail: boolean = false;
  error: string = null;
  emailForm: FormControl = this.fb.control({ value: this.authService.userBundle.user.email, disabled: true }, [Validators.email, Validators.required]);
  phoneNumberForm: FormControl = this.fb.control({ value: this.authService.userBundle.du.phoneNumber, disabled: true }, [
    UserEditValidators.phoneNumber,
    Validators.required,
  ]);

  constructor(
    private modalService: NgbModal,
    @Inject(NgbActiveModal) public activeModal: any, //car je le fake en mode non modal
    private router: Router,
    @Inject(UserProducerService) private userProducerService: UserProducerService,
    private authService: AuthService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    //console.log(this.activeModal);
    this.userProducerService.getProfil().subscribe(
      (data) => {
        this.dto = data;
        this.dtoOriginal = new ProducerUserProfilDTO();
        Object.assign(this.dtoOriginal, this.dto);
      },
      (err) => {
        L.e(err);
      }
    );
  }

  cancelEdit() {
    Object.assign(this.dto, this.dtoOriginal);
    this.editEmail = false;
    this.editFirstName = false;
    this.editLastName = false;
    this.editPhoneNumber = false;
    this.editJob = false;
    this.emailForm.setValue(this.dtoOriginal.email);
    this.emailForm.disable();
    this.phoneNumberForm.setValue(this.dtoOriginal.phoneNumber);
    this.phoneNumberForm.disable();
  }

  saveEditFirstName() {
    this.loading = true;
    this.userProducerService.updateFirstName(this.dto.firstName).subscribe(
      (data) => {
        this.dtoOriginal = new ProducerUserProfilDTO();
        Object.assign(this.dtoOriginal, this.dto);
        this.authService.userBundle.user.firstName = this.dto.firstName;
        this.editFirstName = false;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  saveEditLastName() {
    this.loading = true;
    this.userProducerService.updateLastName(this.dto.lastName).subscribe(
      (data) => {
        this.dtoOriginal = new ProducerUserProfilDTO();
        Object.assign(this.dtoOriginal, this.dto);
        this.authService.userBundle.user.lastName = this.dto.lastName;
        this.editLastName = false;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  saveEditPhoneNumber() {
    if (!this.phoneNumberForm.valid) {
      console.log(this.phoneNumberForm);
      return;
    }
    this.loading = true;
    this.userProducerService.updatePhoneNumber(this.phoneNumberForm.value).subscribe(
      (data) => {
        this.authService.userBundle.du.phoneNumber = this.phoneNumberForm.value;
        this.dto.phoneNumber = this.phoneNumberForm.value;
        this.dtoOriginal = new ProducerUserProfilDTO();
        Object.assign(this.dtoOriginal, this.dto);
        this.editPhoneNumber = false;
        this.phoneNumberForm.disable();
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  saveEditJob() {
    this.loading = true;
    this.userProducerService.updateJob(this.dto.job).subscribe(
      (data) => {
        this.dtoOriginal = new ProducerUserProfilDTO();
        Object.assign(this.dtoOriginal, this.dto);
        this.editJob = false;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  saveEditEmail() {
    if (!this.emailForm.valid) {
      console.log(this.emailForm);
      return;
    }
    this.loading = true;
    this.userProducerService.updateEmail(this.emailForm.value).subscribe(
      (data) => {
        this.dto.email = this.emailForm.value;
        Object.assign(this.dtoOriginal, this.dto);
        this.authService.userBundle.user.email = this.emailForm.value;
        this.authService.userBundle.user.login = this.emailForm.value;
        this.editEmail = false;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
        if (err.isBusinessError()) this.error = 'Un compte avec cet email existe déjà.';
      }
    );
  }

  openResetPasswordPopup() {
    //if (!this.activeModal.fake) this.activeModal.dismiss();
    const modalRef = this.modalService.open(ForgotPwdPopupComponent, {
      centered: true,
      //@ts-ignore
      size: 'xl',
      beforeDismiss: () => {
        return false;
      },
    });
    modalRef.componentInstance.producer = true;
  }
}
