import { Component, OnInit, Inject, ViewChild, ElementRef, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { ShippingBox, ShippingBoxService } from 'degineo-common';
import { L } from 'ic2-lib';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-my-packages',
  templateUrl: './my-packages.component.html',
  styleUrls: ['./my-packages.component.scss'],
})
export class MyPackagesComponent implements OnInit, AfterViewInit {
  packages: ShippingBox[] = [];
  loading: boolean = false;
  form: FormGroup = this.fb.group({
    longueur: [null, [Validators.required]],
    largeur: [null, [Validators.required]],
    hauteur: [null, [Validators.required]],
    quantity: [null, [Validators.required]],
  });
  selected = [];
  edit = [];
  @ViewChildren('qty')
  quantiyInput: QueryList<ElementRef>;

  constructor(@Inject(ShippingBoxService) private shippingBoxService: ShippingBoxService, @Inject(FormBuilder) private fb: FormBuilder) {}

  ngOnInit() {
    this.reload();
  }

  ngAfterViewInit() {
    this.quantiyInput.changes.subscribe(() => {
      if (this.quantiyInput.length > 0) {
        const el = this.quantiyInput.first.nativeElement;
        //el.focus();
        setTimeout(() => {
          el.select();
        }, 100);
      }
    });
  }

  reload() {
    this.shippingBoxService.getMyShippingBoxes().subscribe(
      (data) => {
        this.packages = data;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  add() {
    if (!this.form.valid) {
      console.error(this.form);
      return;
    }
    if (this.loading) return;
    this.loading = true;
    const s = new ShippingBox();
    s.sizeXCm = this.form.value.largeur;
    s.sizeYCm = this.form.value.hauteur;
    s.sizeZCm = this.form.value.longueur;
    s.quantity = this.form.value.quantity;
    this.shippingBoxService.createShippingBox(s).subscribe(
      (data) => {
        this.packages.push(data);
        this.form.reset();
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  toggleEdit(box: ShippingBox) {
    if (this.edit.includes(box.id)) this.edit.splice(this.edit.indexOf(box.id), 1);
    else {
      this.edit.push(box.id);
    }
    console.log(this.edit);
  }

  toggleSelected(box: ShippingBox) {
    if (this.selected.includes(box.id)) this.selected.splice(this.selected.indexOf(box.id), 1);
    else this.selected.push(box.id);
  }

  save(box: ShippingBox) {
    if (this.loading) return;
    this.loading = true;
    this.shippingBoxService.updateStock(box.id, box.quantity).subscribe(
      (data) => {
        this.toggleEdit(box);
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  deleteSelected() {
    this.loading = true;
    this.shippingBoxService.deleteShippingBox(this.selected).subscribe(
      (data) => {
        this.selected = [];
        this.reload();
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }
}
