<div class="container-fluid px-5 pt-4" style="max-width: 1700px">
  <div class="row" *ngIf="!addMode">
    <div class="col">
      <button class="btn btn-primary mr-3" (click)="addMode = true"><i class="fas fa-plus mr-2"></i>Inviter un nouveau client</button>
      <button class="btn btn-primary" (click)="openInviteModal()"><i class="fas fa-file-medical mr-2"></i>Inviter une liste de nouveaux clients</button>
    </div>
    <div class="col" style="max-width: 17rem; width: 17rem">
      <input type="text" class="form-control rounded-pill search-bar" [(ngModel)]="searchText" (ngModelChange)="searchChange()" placeholder="Recherche" />
      <i class="fas fa-times search-reset-icon" *ngIf="searchText !== null && searchText.length != 0" (click)="searchText = null; searchChange()"></i>
      <i class="fas fa-search search-icon"></i>
    </div>
  </div>
  <div class="row" *ngIf="addMode">
    <div class="col">
      <form #f="ngForm" [formGroup]="form" [class.show-errors]="f.submitted && !form.valid" (submit)="add()">
        <div class="d-inline-block mr-3 align-top">
          <label>Nom de l'entreprise à inviter&nbsp;:</label><br /><input
            type="text"
            class="form-control mt-2"
            formControlName="companyName"
            style="width: 17rem"
            [disabled]="loading"
          />
        </div>
        <div class="d-inline-block mr-3 align-top">
          <div>
            <label>E-mail où envoyer l'invitation&nbsp;:</label><br /><input
              type="text"
              class="form-control mt-2"
              formControlName="email"
              style="width: 17rem"
              [disabled]="loading"
            />
            <div class="text-danger fs-2 fw-500 mt-1 ml-2" *ngIf="form.hasError('alreadyExists', 'email')">Ce client a déjà été ajouté.</div>
          </div>
        </div>
        <button type="submit" class="btn btn-success mr-3" style="margin-top: 1.9rem" [disabled]="loading">
          <i class="fas fa-paper-plane mr-2"></i>Envoyer l'invitation <i class="fas fa-spinner spinning" *ngIf="loading"></i>
        </button>
        <button class="btn btn-secondary" style="margin-top: 1.9rem" (click)="addMode = false" [disabled]="loading">Annuler</button>
      </form>
    </div>
  </div>

  <div class="row mt-3" style="line-height: 1.2">
    <div class="col">
      <degineo-my-table [rows]="clients" [builtFiltersOrder]="builtFiltersOrder" (orderChange)="reload()" (rowClick)="rowClick($event)">
        <degineo-my-column [disableOrder]="true" align="center" name="">
          <td *cv="let r" class="text-nowrap text-center align-middle" style="min-width: 3rem">
            <ng-container *ngIf="r.invitationAccepted">
              <i
                class="fas fa-exclamation-circle text-danger"
                *ngIf="r.paymentStatus === ProducerClientPaymentStatus.LATE_PAYMENT"
                ngbTooltip="Paiement en retard"
              ></i>
              <i
                class="fas fa-clock text-warning"
                *ngIf="r.paymentStatus === ProducerClientPaymentStatus.DELAYED_PAYMENT_WAITING"
                ngbTooltip="Paiement fin de mois en attente"
              ></i>
              <i class="fas fa-check-circle text-success" *ngIf="r.paymentStatus === ProducerClientPaymentStatus.ALL_OK" ngbTooltip="Paiement à jour"></i>
            </ng-container>
          </td>
        </degineo-my-column>
        <degineo-my-column align="center" name="Entreprise" orderName="companyName" [defaultOrder]="OrderBy.ASC">
          <ng-container *cv="let r">
            <td class="text-nowrap text-center align-middle">
              {{ r.companyName }}
            </td>
            <td class="text-nowrap text-center align-middle" colspan="4" *ngIf="!r.invitationAccepted">
              <div class="text-warning fw-500">Invitation en attente depuis le {{ r.dateInvitation | date: 'dd/MM/yyyy' }}</div>
            </td>
          </ng-container>
        </degineo-my-column>
        <degineo-my-column align="center" name="Volume de CA" orderName="totalCAHT">
          <ng-container *cv="let r">
            <td class="text-nowrap text-center align-middle" *ngIf="r.invitationAccepted">
              {{ r.totalCAHT | currency: 'EUR':'symbol':'1.0-2':'fr' }}
            </td>
          </ng-container>
        </degineo-my-column>
        <degineo-my-column align="center" name="Nombre de commandes" orderName="nbOrder">
          <ng-container *cv="let r">
            <td class="text-nowrap text-center align-middle" *ngIf="r.invitationAccepted">
              {{ r.nbOrder | number: '.0-2':'fr-FR' }}
            </td>
          </ng-container>
        </degineo-my-column>
        <degineo-my-column align="center" name="Dernière commande" orderName="dateLastOrder">
          <ng-container *cv="let r">
            <td class="text-nowrap text-center align-middle" *ngIf="r.invitationAccepted">
              {{ r.dateLastOrder == null ? 'Aucune commande' : (r.dateLastOrder | date: 'dd/MM/yyyy') }}
            </td>
          </ng-container>
        </degineo-my-column>
        <degineo-my-column orderName="allowedDelayedPayment" align="center" name="Paiement à 30 jours">
          <ng-container *cv="let r">
            <td class="text-nowrap text-center align-middle" *ngIf="r.invitationAccepted">
              {{ r.allowedDelayedPayment ? 'Oui' : 'Non' }}
            </td>
          </ng-container>
        </degineo-my-column>
      </degineo-my-table>
    </div>
  </div>
  <div class="row justify-content-center mt-3">
    <ngb-pagination
      [collectionSize]="count"
      [(page)]="page"
      [pageSize]="nbPerPage"
      [maxSize]="6"
      [rotate]="true"
      (pageChange)="reload()"
      [disabled]="loading"
    ></ngb-pagination>
  </div>
</div>

<app-right-panel class="blue" panelSize="30rem" [open]="selectedClient !== null" (close)="selectedClient = null">
  <app-panel-client [client]="selectedClient" *ngIf="selectedClient != null && selectedClient.invitationAccepted" [parent]="this"></app-panel-client>
  <app-panel-invited [client]="selectedClient" *ngIf="selectedClient != null && !selectedClient.invitationAccepted" [parent]="this"></app-panel-invited>
</app-right-panel>
