import { Component, OnInit, Input, Output, EventEmitter, Inject, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AddressNEW, ProducerSignupService } from 'degineo-common';
import { AccountCreationService } from '../account-creation.service';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-account-creation-step5-shipping',
  templateUrl: './account-creation-step5-shipping.component.html',
  styleUrls: ['./account-creation-step5-shipping.component.scss'],
})
export class AccountCreationStep5ShippingComponent implements OnInit {
  loading: boolean = true;
  saving: boolean = false;
  form: FormGroup = this.fb.group({
    sameAsBilling: [null, [Validators.required]],
    shippingAddress: [null, [Validators.required]],
  });
  billingAddress: AddressNEW;
  readOnly: boolean = false;

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(ProducerSignupService) private producerSignupService: ProducerSignupService,
    @Inject(AccountCreationService) public accountCreationService: AccountCreationService
  ) {}

  ngOnInit() {
    this.producerSignupService.getInfosStep5(this.accountCreationService.token).subscribe(
      (data) => {
        //console.log(data);
        this.loading = false;
        this.billingAddress = data.billingAddress;
        if (data.shippingAddress === null) {
          this.form.patchValue({
            sameAsBilling: true,
            shippingAddress: data.billingAddress,
          });
          this.readOnly = true;
        } else
          this.form.patchValue({
            sameAsBilling: false,
            shippingAddress: data.shippingAddress,
          });
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  submit() {
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }
    console.log(this.form.value);
    this.saving = true;
    this.producerSignupService.saveStep5(this.accountCreationService.token, this.form.value.shippingAddress).subscribe(
      (data) => {
        this.accountCreationService.next();
        this.saving = false;
      },
      (err) => {
        this.saving = false;
        L.e(err);
      }
    );
  }

  sameChanged(event) {
    if (event.target.checked) {
      //console.log('setting', this.billingAddress);
      this.form.patchValue({ shippingAddress: this.billingAddress });
      this.readOnly = true;
      console.log(this.form.value);
    } else {
      this.readOnly = false;
      this.form.patchValue({ shippingAddress: null });
    }
  }
}
