import { Component, OnInit, Inject } from '@angular/core';
import { AuthService, ProducerSignupService } from 'degineo-common';
import { AccountCreationService } from '../account-creation.service';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-account-creation-step10-sign',
  templateUrl: './account-creation-step10-sign.component.html',
  styleUrls: ['./account-creation-step10-sign.component.scss'],
})
export class AccountCreationStep10SignComponent implements OnInit {
  loading: boolean = true;
  saving: boolean = false;

  constructor(
    @Inject(AuthService) private authService: AuthService,
    @Inject(ProducerSignupService) private producerSignupService: ProducerSignupService,
    @Inject(AccountCreationService) public accountCreationService: AccountCreationService
  ) {}

  ngOnInit() {
    this.producerSignupService.getInfosStep10(this.accountCreationService.token).subscribe(
      (data) => {
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  submit() {
    this.saving = true;
    this.producerSignupService.saveStep10(this.accountCreationService.token).subscribe(
      (data) => {
        this.authService.loginWith(data);
        this.accountCreationService.next();
        this.saving = false;
      },
      (err) => {
        this.saving = false;
        L.e(err);
      }
    );
  }
}
