<div class="container-fluid shadow-5 p-5">
  <i class="close-icon fas fa-times" (click)="activeModal.close()"></i>
  <div class="row">
    <div class="col text-center">
      <h3>{{ data.clientName }}</h3>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col"><i class="fas fa-user mr-3"></i>{{ data.clientUserName }}</div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <i class="fas fa-phone-alt mr-3"></i><a href="tel:{{ data.phoneNumber }}">{{ data.phoneNumber | mask: '00 00 00 00 00' }}</a>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <i class="fas fa-envelope mr-3"></i><a href="mailto:{{ data.email }}">{{ data.email }}</a>
    </div>
  </div>
</div>
