import { Component, OnInit, Input } from '@angular/core';
import { AddressNEW } from 'degineo-common';

@Component({
  selector: 'app-panel-address',
  templateUrl: './panel-address.component.html',
  styleUrls: ['./panel-address.component.scss'],
})
export class PanelAddressComponent implements OnInit {
  @Input()
  address: AddressNEW = null;

  constructor() {}

  ngOnInit() {}
}
