import { Component, OnInit, Inject } from '@angular/core';
import { ProducerSignupService, Plan, ProducerSignupStep7DTO } from 'degineo-common';
import { AccountCreationService } from '../account-creation.service';
import { L } from 'ic2-lib';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'app-account-creation-step7-plan',
  templateUrl: './account-creation-step7-plan.component.html',
  styleUrls: ['./account-creation-step7-plan.component.scss'],
})
export class AccountCreationStep7PlanComponent implements OnInit {
  loading: boolean = true;
  saving: boolean = false;
  dto: ProducerSignupStep7DTO;

  constructor(
    @Inject(ProducerSignupService) private producerSignupService: ProducerSignupService,
    @Inject(AccountCreationService) public accountCreationService: AccountCreationService,
    private intercom: Intercom
  ) {}

  ngOnInit() {
    this.producerSignupService.getInfosStep7(this.accountCreationService.token).subscribe(
      (data) => {
        //console.log(data);
        this.dto = data;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  planSelected(plan: Plan) {
    if (plan.planButton) {
      this.intercom.show('Bonjour, je suis intéressé par le plan ' + plan.name + ', est-il possible de me recontacter au numéro suivant : ');
      return;
    }
    this.saving = true;
    this.producerSignupService.saveStep7(this.accountCreationService.token, plan.id).subscribe(
      (data) => {
        this.accountCreationService.next();
        this.saving = false;
      },
      (err) => {
        this.saving = false;
        L.e(err);
      }
    );
  }
}
