<i class="fas fa-spinner spinning" *ngIf="dto === null"></i>
<div class="container-small mt-4 mb-5" *ngIf="dto !== null">
  <form #f="ngForm" [class.show-errors]="f.submitted" (ngSubmit)="save(f)">
    <div class="row">
      <div class="col text-center"><h3>Saisissez l'e-mail à qui partager les factures</h3></div>
    </div>
    <div class="row mt-3" *ngFor="let email of dto.shareInvoiceEmails; let i = index; trackBy: trackByFn">
      <div class="col col-md-8 offset-md-2 form-inline">
        <input
          type="text"
          class="form-control flex-grow-1"
          name="shareInvoiceEmails{{ i }}"
          email
          required
          [readonly]="!edit"
          [(ngModel)]="dto.shareInvoiceEmails[i]"
        />
        <i
          class="far fa-trash-alt pointer hover-danger pointer ml-2"
          *ngIf="edit"
          (click)="deleteEmail(email)"
          placement="right"
          ngbTooltip="Retirer cet e-mail"
        ></i>
      </div>
    </div>

    <div class="row mt-2" *ngIf="edit">
      <div class="col col-md-8 offset-md-2">
        <a [routerLink]="null" (click)="addEmail()">+ ajouter un autre e-mail</a>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-10 offset-1">
        <hr />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col text-center"><h3>Indiquez le jour du mois d'envoi des factures</h3></div>
    </div>
    <div class="row mt-3">
      <div class="col justify-content-center form-inline">
        Envoyer les factures tous les
        <select
          class="form-control d-inline-block w-auto mx-2"
          [disabled]="!edit"
          name="shareInvoiceDayOfMonth"
          [(ngModel)]="dto.shareInvoiceDayOfMonth"
          required
        >
          <option [ngValue]="day" *ngFor="let day of days">{{ day }}</option>
        </select>
        du mois.
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-10 offset-1">
        <hr />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col text-center"><h3>Choisissez ce que vous souhaitez partager</h3></div>
    </div>
    <div class="row mt-3 justify-content-center">
      <div class="col-6">
        <div class="form-inline no-select">
          <label [class.pointer]="edit" class="fw-400 d-flex align-items-center justify-content-center">
            <input
              type="checkbox"
              style="width: 18px"
              class="form-control d-inline-block w-auto mr-2"
              name="shareBills"
              [disabled]="!edit"
              [(ngModel)]="dto.shareBills"
            />
            Mes factures de vente
          </label>
        </div>
        <div class="form-inline no-select">
          <label [class.pointer]="edit" class="fw-400 d-flex align-items-center justify-content-center">
            <input
              type="checkbox"
              style="width: 18px"
              class="form-control d-inline-block w-auto mr-2"
              name="shareFinancialReport"
              [disabled]="!edit"
              [(ngModel)]="dto.shareFinancialReport"
            />
            Rapport financier
          </label>
        </div>
        <div class="form-inline no-select">
          <label [class.pointer]="edit" class="fw-400 d-flex align-items-center justify-content-center">
            <input
              type="checkbox"
              style="width: 18px"
              class="form-control d-inline-block w-auto mr-2"
              name="shareDegineoInvoices"
              [disabled]="!edit"
              [(ngModel)]="dto.shareDegineoInvoices"
            />
            Mes factures Degineo
          </label>
        </div>
      </div>
    </div>

    <div class="row mt-5" *ngIf="!edit">
      <div class="col text-center">
        <button class="btn btn-primary" style="width: 10rem" (click)="edit = true">Modifier</button>
      </div>
    </div>
    <div class="row mt-5" *ngIf="edit">
      <div class="col text-right">
        <button class="btn btn-danger" style="width: 10rem" (click)="cancel()">Annuler</button>
      </div>
      <div class="col">
        <button type="submit" class="btn btn-success" style="width: 10rem">Valider</button>
      </div>
    </div>
  </form>
</div>
