<i class="fas fa-spinner spinning" *ngIf="dto === null"></i>
<div class="container-fluid" *ngIf="dto !== null">
  <app-panel-header
    [id]="dto.idOrder"
    [sinceDate]="dto.dateAccepted"
    [clientChoosedThisProducer]="dto.clientChoosedThisProducer"
    [client]="dto.client"
    dateLabel="En preparation"
    [depuis]="true"
    colorClass="text-k-purple"
  ></app-panel-header>
  <app-panel-detail
    [token3DModelFinal]="dto.token3DModelFinal"
    [filenameFinal]="dto.filenameFinal"
    [quantity]="dto.quantity"
    [sizeXMm]="dto.sizeXMm"
    [sizeYMm]="dto.sizeYMm"
    [sizeZMm]="dto.sizeZMm"
    [colorName]="dto.colorName"
    [colorHex]="dto.colorHex"
    [deadline]="dto.wishedDeliveryDate"
    [finishName]="dto.finishName"
  ></app-panel-detail>

  <app-panel-address class="mt-3" [address]="dto.shippingAddress"></app-panel-address>

  <ng-container *ngIf="dto.canChangePrinter && authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING">
    <div class="row" style="margin-top: 2rem"></div>
    <div class="row" *ngIf="!editPrinterMode">
      <div class="col">
        <a [routerLink]="null" (click)="editPrinterMode = true" *ngIf="dto.canChangePrinter">Changer d'imprimante et/ou de consommable</a>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <span class="fw-700"> À imprimer sur&nbsp;:</span>
        <span *ngIf="!editPrinterMode"> {{ dto.printerName }}</span> <span class="text-success fw-700 ml-3" *ngIf="saved">Sauvegardé</span>
      </div>
    </div>
  </ng-container>

  <div class="row mt-2" *ngIf="editPrinterMode">
    <div class="col">
      <select class="form-control" [(ngModel)]="selectedPrinterId" (ngModelChange)="onChangePrinter()">
        <option [ngValue]="null" disabled="disabled">-- Choisir --</option>
        <option *ngFor="let printer of dto.printerConsumablePossibilities" [ngValue]="printer.idPrinter">{{ printer.name }}</option>
      </select>
    </div>
  </div>

  <ng-container *ngIf="!dto.canChangePrinter">
    <div class="row" style="margin-top: 2rem">
      <div class="col"><span class="fw-700">À imprimer sur&nbsp;:</span> {{ dto.printerBrand }} - {{ dto.printerName }}</div>
    </div>
  </ng-container>

  <div
    class="row"
    [class.mt-3]="authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING"
    [ngStyle]="{ marginTop: authService.userBundle.role === RoleInCompany.PRODUCER_ACCOUNTING ? '2rem' : null }"
  >
    <div class="col">
      <span class="fw-700">Consommable à utiliser&nbsp;:</span><span class="text-success fw-700 ml-3" *ngIf="saved">Sauvegardé</span
      ><span *ngIf="!editPrinterMode"> {{ dto.consumableName }}</span>
    </div>
  </div>
  <div class="row mt-2" *ngIf="editPrinterMode">
    <div class="col">
      <select class="form-control" [(ngModel)]="selectedConsumableOption" (ngModelChange)="onChangeConsumable()">
        <option [ngValue]="null" disabled="disabled">-- Choisir --</option>
        <option *ngFor="let consumable of consumableChoices" [ngValue]="consumable">
          {{ consumable.name + (consumable.color !== null ? ' ' + consumable.color : '') }}
        </option>
      </select>
    </div>
  </div>

  <div class="row mt-3" *ngIf="!dto.dental">
    <div class="col">
      <span class="fw-700">Consommation matière&nbsp;:</span> {{ dto.materialQuantityPerPiece | number: '.2':'fr-FR' }} cm<sup>3</sup> par pièce
    </div>
  </div>
  <div class="row mt-3" *ngIf="!dto.dental">
    <div class="col"><span class="fw-700">Utilisation machine&nbsp;:</span> {{ dto.machineUsagePerPiece | number: '.2':'fr-FR' }} % par pièce</div>
  </div>
  <div class="row mt-3" *ngIf="dto.comment !== null">
    <div class="col fw-700">Commentaire client&nbsp;:</div>
  </div>
  <div class="row" *ngIf="dto.comment !== null">
    <div class="col">
      {{ dto.comment }}
    </div>
  </div>
  <div class="row mt-3" *ngIf="dto.noteDegineo !== null">
    <div class="col fw-700">Commentaire Degineo&nbsp;:</div>
  </div>
  <div class="row" *ngIf="dto.noteDegineo !== null">
    <div class="col">
      {{ dto.noteDegineo }}
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <div class="container-fluid border border-secondary rounded pt-2 pb-2">
        <div class="row">
          <div class="col-1 text-center align-self-center">
            <i class="fas fa-download text-primary" style="font-size: 1.6rem"></i>
          </div>
          <div class="col-6 text-primary ellipsis">
            <span class="fw-500">Fichier 3D {{ dto.degineoFile ? 'Degineo' : 'client' }} :</span><br />
            <u
              ><a target="_blank" [title]="dto.filename" href="{{ rpcHost }}orderModele3D/{{ dto.token3DModel }}/{{ dto.filename }}?dl">{{
                dto.filename
              }}</a></u
            >
          </div>
          <div class="col-5" *ngIf="authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING">
            <label class="text-success no-select"
              ><input
                type="checkbox"
                class="mr-2"
                [disabled]="loading"
                [(ngModel)]="correct"
                (click)="correctChange($event); $event.preventDefault()"
                (change)="correctChange($event)"
              />
              Fichier 3D correct</label
            ><br />
            <label class="text-danger no-select"
              ><input
                type="checkbox"
                class="mr-2"
                [disabled]="loading"
                [(ngModel)]="aCorriger"
                (click)="aCorrigerChange($event); $event.preventDefault()"
                (change)="aCorrigerChange($event)"
              />
              Fichier 3D à corriger</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row mt-3"
    style="height: 68px"
    *ngIf="dto.producerFileStatus === OrderFileStatus.FILE_TO_CORRECT && authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING"
  >
    <div class="col">
      <div class="container-fluid border border-danger rounded pt-2 pb-2 h-100" style="border-width: 2px !important">
        <div class="row align-items-center h-100">
          <div class="col text-center">
            <input
              type="file"
              id="file"
              style="display: none"
              [disabled]="loading"
              [accept]="allowedModelExtensionsService.getExtensions()"
              #fileInput
              (change)="handleFileInput($event)"
            />
            <span class="no-select text-primary fw-500 pointer" (click)="fileInput.click(); $event.preventDefault()">
              Envoyez le fichier 3D corrigé ici <i class="fas fa-upload text-primary ml-2" style="font-size: 1.6rem"></i
            ></span>
            <div class="progress mt-1" *ngIf="progressPercent">
              <div class="progress-bar" role="progressbar" [style.width]="progressPercent + '%'">{{ progressPercent }}%</div>
            </div>
            <span class="text-danger d-block" *ngIf="errorFile">{{ errorFile }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3" *ngIf="dto.producerFileStatus === OrderFileStatus.FILE_CORRECTED">
    <div class="col">
      <div class="container-fluid border border-success rounded pt-2 pb-2" style="border-width: 2px !important">
        <div class="row">
          <div class="col-1 text-center align-self-center">
            <i class="fas fa-sync text-primary" style="font-size: 1.6rem"></i>
          </div>
          <div class="col-5 text-primary ellipsis">
            <span class="fw-500">Fichier 3D corrigé :</span><br />
            <u
              ><a
                class=""
                target="_blank"
                [title]="dto.filenameProducer"
                href="{{ rpcHost }}orderModele3D/{{ dto.token3DModelProducer }}/{{ dto.filenameProducer }}?dl"
                >{{ dto.filenameProducer }}</a
              ></u
            >
          </div>
          <div class="col-6 text-center align-self-center" *ngIf="authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING">
            <input
              type="file"
              id="file"
              style="display: none"
              [accept]="allowedModelExtensionsService.getExtensions()"
              [disabled]="loading"
              #fileInput
              (change)="handleFileInput($event)"
            />
            <span class="no-select pointer text-primary fw-500" (click)="fileInput.click(); $event.preventDefault()">
              Modifier le fichier 3D ici <i class="fas fa-upload text-primary ml-2" style="font-size: 1.6rem"></i
            ></span>
            <div class="progress" *ngIf="progressPercent">
              <div class="progress-bar" role="progressbar" [style.width]="progressPercent + '%'">{{ progressPercent }}%</div>
            </div>
            <span class="text-danger d-block" *ngIf="errorFile">{{ errorFile }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col">
      <button class="btn btn-secondary w-100" [disabled]="loading" *ngIf="authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING" (click)="cancel()">
        <i class="fas fa-fast-backward text-white mr-2"></i>Annuler l'acceptation
      </button>
    </div>
    <div class="col">
      <button
        class="btn btn-success w-100"
        [disabled]="loading || dto.producerFileStatus === OrderFileStatus.FILE_TO_CORRECT || dto.producerFileStatus === OrderFileStatus.FILE_TO_CHECK"
        *ngIf="authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING"
        (click)="accept()"
      >
        Lancer en production <i class="fas fa-fast-forward text-white ml-2"></i>
      </button>
    </div>
  </div>
</div>
