import { Component, OnInit, Inject } from '@angular/core';
import { ProducerUserDTO, RoleInCompany, ProducerUserService, AuthService, ProducerPlanService } from 'degineo-common';
import { L } from 'ic2-lib';
import { environment } from '../../../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  loading: boolean = false;
  newUser: boolean = false;
  editUser: boolean = false;

  users: ProducerUserDTO[] = [];

  usersFiltered: ProducerUserDTO[] = [];
  selectedUser: ProducerUserDTO = null;

  roles: RoleInCompany[] = RoleInCompany.values;
  rolesSaufTitu = this.roles.filter((r) => r !== RoleInCompany.PRODUCER_HOLDER);
  selectedRole: RoleInCompany = null;
  searchRole: RoleInCompany = null;
  loggedUserRole: RoleInCompany = null;
  RoleInCompany: typeof RoleInCompany = RoleInCompany;

  rpc: string;
  canAddUser: boolean = false;

  constructor(
    @Inject(ProducerUserService) private producerUserService: ProducerUserService,
    @Inject(AuthService) public authService: AuthService,
    private router: Router,
    @Inject(ProducerPlanService) private producerPlanService: ProducerPlanService
  ) {}

  ngOnInit() {
    this.loggedUserRole = this.authService.userBundle.role;
    this.rpc = environment.rpcHost;
    this.getUsers();
  }

  getUsers() {
    this.producerUserService.getProducerUsers().subscribe(
      (data) => {
        this.users = data;
        this.usersFiltered = this.sortUsers(data);
      },
      (err) => {
        L.e(err);
      }
    );
    this.producerPlanService.canAddUser().subscribe(
      (data) => {
        this.canAddUser = data;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  searchUser(name: string) {
    this.usersFiltered = [];

    this.usersFiltered = this.users.filter(function (x) {
      return x.firstName.toLocaleLowerCase().indexOf(name.toLowerCase()) !== -1 || x.lastName.toLocaleLowerCase().indexOf(name.toLowerCase()) !== -1;
    });
    this.usersFiltered = this.sortUsers(this.usersFiltered);
  }

  selectUser(idUser: number) {
    this.selectedUser = this.users.find((x) => x.idUser === idUser);
    this.editUser = true;
    this.newUser = false;
  }

  addUser() {
    if (!this.canAddUser) {
      this.router.navigate(['/evolution-abonnement', 'utilisateur']);
      return;
    }
    this.editUser = false;
    this.newUser = true;
    this.selectedRole = null;
    this.selectedUser = null;
  }

  resetUser() {
    this.editUser = false;
    this.newUser = false;
    this.selectedRole = null;
    this.selectedUser = null;
  }

  pushUser(user: ProducerUserDTO) {
    this.users.push(user);
    this.usersFiltered = this.sortUsers(this.users);
    this.selectUser(user.idUser);
    this.producerPlanService.canAddUser().subscribe(
      (data) => {
        this.canAddUser = data;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  sortUsers(users: ProducerUserDTO[]) {
    return users.sort(function (a, b) {
      if (Number(a.accountCreationFinished) < Number(b.accountCreationFinished) && !a.disabled && !b.disabled) return -1;
      if (Number(a.accountCreationFinished) > Number(b.accountCreationFinished) && !a.disabled && !b.disabled) return 1;

      if (Number(a.disabled) < Number(b.disabled)) return -1;
      if (Number(a.disabled) > Number(b.disabled)) return 1;

      if (a.lastName.toLocaleLowerCase() > b.lastName.toLocaleLowerCase()) return 1;
      if (a.lastName.toLocaleLowerCase() < b.lastName.toLocaleLowerCase()) return -1;
    });
  }
  selectRole(role: RoleInCompany) {
    console.log('UPDATED role', role);
    this.selectedRole = role;
  }
}
