<div class="row mt-3">
  <div class="col-6">
    <degineo-preview-modele
      modelUrl="{{ rpcHost }}orderModele3D/{{ token3DModelFinal }}/{{ filenameFinal }}"
      [meshColor]="'#' + colorHex"
    ></degineo-preview-modele>
  </div>
  <div class="col-6">
    <div class="container-fluid d-flex flex-column h-100 justify-content-around">
      <div class="row" *ngIf="idOrder !== null">
        <div class="col fw-700">Impression N°&nbsp;{{ idOrder }}</div>
      </div>
      <div class="row">
        <div class="col"><span class="fw-700">Nombre d'exemplaires&nbsp;:</span> {{ quantity }}</div>
      </div>

      <div class="row">
        <div class="col">
          <span class="fw-700">Taille&nbsp;:</span> {{ sizeXMm | number: '.0-2':'fr-FR' }} x {{ sizeYMm | number: '.0-2':'fr-FR' }} x
          {{ sizeZMm | number: '.0-2':'fr-FR' }} mm
        </div>
      </div>

      <div class="row">
        <div class="col"><span class="fw-700">Couleur&nbsp;:</span> {{ colorName }}</div>
      </div>

      <div class="row">
        <div class="col"><span class="fw-700">Finition&nbsp;:</span> {{ finishName }}</div>
      </div>

      <div class="row" [class.text-danger]="isOutdated(deadline)" *ngIf="deadline !== null">
        <div class="col"><span class="fw-700">Deadline&nbsp;:</span> {{ deadline | date: 'dd/MM/yyyy' }}</div>
      </div>
    </div>
  </div>
</div>
