import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { environment } from 'environments/environment';
import { TakeControlService } from '../take-control.service';

@Component({
  selector: 'app-take-control-step1-user',
  templateUrl: './take-control-step1-user.component.html',
  styleUrls: ['./take-control-step1-user.component.scss'],
})
export class TakeControlStep1UserComponent implements OnInit {
  svUrl: string = environment.svUrl;
  svProducerUrl: string;
  clientUrl: string;
  svMentionsLegalesUrl: string;
  svConfidentialitesUrl: string;

  constructor(public takeControlService: TakeControlService) {
    this.svProducerUrl = environment.svUrl + 'imprimeur-3D';
    this.clientUrl = environment.clientUrl;
    this.svMentionsLegalesUrl = environment.svUrl + 'mentions-legales';
    this.svConfidentialitesUrl = environment.svUrl + 'confidentialite';
  }

  ngOnInit(): void {}

  submit() {
    if (!this.takeControlService.step1form.valid) {
      console.log(this.takeControlService.step1form);
      return;
    }
    this.takeControlService.step = TakeControlService.STEP_2_BUSINESSTYPE;
  }
}
