import { Component, OnInit, Output, EventEmitter, Inject, Input } from '@angular/core';
import { RoleInCompany, ProducerUserDTO, ProducerUserService } from 'degineo-common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
  @Input()
  loggedUserRole: RoleInCompany = null;
  @Output()
  updateRole: EventEmitter<RoleInCompany> = new EventEmitter<RoleInCompany>();
  @Output()
  addedUser: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  RoleInCompany: typeof RoleInCompany = RoleInCompany;

  roles: RoleInCompany[] = [
    RoleInCompany.PRODUCER_HOLDER,
    RoleInCompany.PRODUCER_ADMIN,
    RoleInCompany.PRODUCER_SUPERVISER,
    RoleInCompany.PRODUCER_COLLABORATOR,
    RoleInCompany.PRODUCER_ACCOUNTING,
  ];
  loading: boolean = false;
  emailUsed: boolean = false;

  form: FormGroup = this.fb.group({
    firstName: [null, [Validators.required]],
    lastName: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    job: [null, [Validators.required]],
    role: [null, [Validators.required]],
  });

  constructor(@Inject(FormBuilder) private fb: FormBuilder, @Inject(ProducerUserService) private producerUserService: ProducerUserService) {}

  ngOnInit() {
    if (this.loggedUserRole === RoleInCompany.PRODUCER_SUPERVISER) this.form.controls['role'].setValue(RoleInCompany.PRODUCER_COLLABORATOR);
  }

  emitRole() {
    this.updateRole.emit(this.form.get('role').value);
  }

  sendMail() {
    if (this.form.valid) {
      this.loading = true;
      this.producerUserService
        .addUser(
          this.form.get('firstName').value,
          this.form.get('lastName').value,
          this.form.get('email').value,
          this.form.get('job').value,
          this.form.get('role').value
        )
        .subscribe(
          (data) => {
            this.loading = false;
            this.addedUser.emit(data);
            this.emailUsed = false;
          },
          (err) => {
            L.e(err);
            this.loading = false;
            this.emailUsed = true;
          }
        );
    } else {
      console.log('invalid');
    }
  }
}
