import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, LoginService, DegineoRight, ForgotPwdPopupComponent } from 'degineo-common';
import { L, RpcError } from 'ic2-lib';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Intercom } from 'ng-intercom';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required, Validators.minLength(6)]],
  });
  loginError: string;
  loading: boolean = false;
  svProducerUrl: string;
  clientUrl: string;
  constructor(
    private modalService: NgbModal,
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(Router) private router: Router,
    @Inject(AuthService) private authService: AuthService,
    @Inject(LoginService) private loginService: LoginService,
    public intercom: Intercom
  ) {
    this.clientUrl = environment.clientUrl;
  }

  ngOnInit() {
    this.authService.isLoggedIn().subscribe((data) => {
      if (data) this.router.navigate(['/']);
    });
    this.svProducerUrl = environment.svUrl + 'imprimeur-3D';
  }

  submit() {
    console.log(this.form);
    if (!this.form.valid) return;
    if (this.loading) return;
    this.loading = true;

    this.authService.login(this.form.value.email, this.form.value.password).subscribe(
      (event) => {
        console.log(event);
        const loggedIn = event.userBundle && AuthService.has(event.userBundle, DegineoRight.PRODUCER);
        this.loading = false;
        if (!loggedIn) {
          event.permitLogin = false;
          this.loginError = "Ce compte n'est pas un compte producteur";
          return;
        }
        if (event.redirectUrl === null) event.redirectUrl = '/';
      },
      (error) => {
        this.loading = false;
        if (error.isBusinessError()) {
          if (error.data) {
            if (error.data.error_code === 1) this.loginError = 'Utilisateur ou mot de passe incorrect';
            else if (error.data.error_code === 2)
              this.loginError = "Votre compte a été désactivé par un membre de l'équipe en cas de questions, n'hésitez pas à les contacter";
            else if (error.data.error_code === 3)
              this.loginError = "votre compte a été supprimé par un membre de l'équipe en cas de questions, n'hésitez pas à les contacter";
          } else this.loginError = 'Une erreur inconnue est survenue';
        } else {
          L.e(error);
          this.loginError = 'Une erreur inconnue est survenue';
        }
      }
    );
  }

  openResetPasswordPopup() {
    const modalRef = this.modalService.open(ForgotPwdPopupComponent, {
      centered: true,
      //@ts-ignore
      size: 'xl',
      beforeDismiss: () => {
        return false;
      },
    });
    modalRef.componentInstance.producer = true;
  }
}
