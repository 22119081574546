<div class="row">
  <div class="col d-flex">
    <div class="fw-700">Adresse de livraison&nbsp;:</div>
    <div class="ml-1">
      {{ address.line1 }}
      <ng-container *ngIf="address.line2"> <br />{{ address.line2 }} </ng-container>
      <ng-container *ngIf="address.line3"> <br />{{ address.line3 }} </ng-container>
      <br />
      {{ address.zipCode }} {{ address.city }}
      <br />
      {{ address.country | uppercase }}
    </div>
  </div>
</div>
