import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { L } from 'ic2-lib';
import { ProducerSignupService, UserEditValidators } from 'degineo-common';
import { AccountCreationService } from '../account-creation.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-account-creation-step2-user',
  templateUrl: './account-creation-step2-user.component.html',
  styleUrls: ['./account-creation-step2-user.component.scss'],
})
export class AccountCreationStep2UserComponent implements OnInit {
  loading: boolean = true;
  saving: boolean = false;
  form: FormGroup = this.fb.group({
    firstname: [null, [Validators.required]],
    lastname: [null, [Validators.required]],
    job: [null, [Validators.required]],
    phoneNumber: [null, [Validators.required, UserEditValidators.phoneNumber]],
  });

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(ProducerSignupService) private producerSignupService: ProducerSignupService,
    @Inject(AccountCreationService) public accountCreationService: AccountCreationService
  ) {}

  ngOnInit() {
    this.producerSignupService.getInfosStep2(this.accountCreationService.token).subscribe(
      (data) => {
        this.loading = false;
        this.form.patchValue({ firstname: data.firstname, lastname: data.lastname, job: data.job, phoneNumber: data.phoneNumber });

        if (environment.name === 'DEV') {
          this.form.patchValue({ firstname: 'Prenom', lastname: 'Nom', job: 'Job', phoneNumber: '0615635188' });
        }
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  submit() {
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }
    this.saving = true;
    this.producerSignupService
      .saveStep2(this.accountCreationService.token, this.form.value.firstname, this.form.value.lastname, this.form.value.job, this.form.value.phoneNumber)
      .subscribe(
        (data) => {
          this.accountCreationService.next();
          this.saving = false;
        },
        (err) => {
          this.saving = false;
          L.e(err);
        }
      );
  }
}
