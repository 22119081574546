<ng-container *ngIf="getValidatedShipmentOrders().length === 1">
  <app-panel-header
    [id]="parent.dto.idShipment"
    [expedition]="true"
    [sinceDate]="parent.dto.shipmentCreationDate"
    [clientChoosedThisProducer]="getValidatedShipmentOrders()[0].clientChoosedThisProducer"
    [client]="getValidatedShipmentOrders()[0].client"
    dateLabel="En conditionnement"
    [depuis]="true"
    colorClass="text-k-yellow"
  ></app-panel-header>

  <app-panel-detail
    [token3DModelFinal]="getValidatedShipmentOrders()[0].token3DModelFinal"
    [filenameFinal]="getValidatedShipmentOrders()[0].filenameFinal"
    [idOrder]="getValidatedShipmentOrders()[0].idOrder"
    [quantity]="getValidatedShipmentOrders()[0].quantity"
    [sizeXMm]="getValidatedShipmentOrders()[0].sizeXMm"
    [sizeYMm]="getValidatedShipmentOrders()[0].sizeYMm"
    [sizeZMm]="getValidatedShipmentOrders()[0].sizeZMm"
    [colorName]="getValidatedShipmentOrders()[0].colorName"
    [colorHex]="getValidatedShipmentOrders()[0].colorHex"
    [deadline]="getValidatedShipmentOrders()[0].wishedDeliveryDate"
    [finishName]="getValidatedShipmentOrders()[0].finishName"
  ></app-panel-detail>
</ng-container>

<ng-container *ngIf="getValidatedShipmentOrders().length > 1">
  <app-panel-header
    [id]="parent.dto.idShipment"
    [expedition]="true"
    [sinceDate]="parent.dto.shipmentCreationDate"
    [clientChoosedThisProducer]="parent.dto.shipmentOrders[0].clientChoosedThisProducer"
    [client]="parent.dto.shipmentOrders[0].client"
    [deadline]="parent.dto.minWishedDeliveryDate"
    dateLabel="En conditionnement"
    colorClass="text-k-yellow"
  ></app-panel-header>
</ng-container>

<ng-container *ngIf="parent.dto.shipmentOrders[0].shippingAddress !== null">
  <app-panel-address class="mt-3" [address]="parent.dto.shipmentOrders[0].shippingAddress"></app-panel-address>
</ng-container>

<div class="row mt-5">
  <div class="col text-center"><h3>Service de livraison Degineo</h3></div>
</div>
<div class="row mt-2">
  <div class="col text-center"><h4>Préparation colis</h4></div>
</div>
<div class="row mt-4 mb-3">
  <div class="col">
    Veuillez indiquer ci&#8209;dessous les dimensions du ou des cartons que vous avez utilisé pour préparer votre ou vos colis, ainsi que le poids de chaque
    colis.<br />
    Puis sélectionnez la ou les impressions qu'ils contiennent en indiquant leur nombre.
  </div>
</div>

<div class="row" [class.mt-4]="!first" *ngFor="let first = first; let index = index; let p; of: parent.packages">
  <div class="col">
    <div class="container-fluid border border-secondary rounded-lg py-3" style="border-width: 2px !important">
      <div class="row">
        <div class="col text-center">
          <i class="fas fa-times text-danger pointer float-right" *ngIf="!first" (click)="deletePackage(p)"></i>
          <h4 class="text-secondary">Carton {{ index + 1 }}</h4>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label>Dimensions du carton utilisé&nbsp;:</label>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col form-inline justify-content-between">
          <div class="input-group">
            <input type="number" class="form-control" style="width: 100px" placeholder="Largeur" [(ngModel)]="p.sizeXCm" />
            <div class="input-group-append">
              <span class="input-group-text">cm</span>
            </div>
          </div>
          x
          <div class="input-group">
            <input type="number" class="form-control" style="width: 100px" placeholder="Longueur" [(ngModel)]="p.sizeZCm" />
            <div class="input-group-append">
              <span class="input-group-text">cm</span>
            </div>
          </div>
          x
          <div class="input-group">
            <input type="number" class="form-control" style="width: 100px" placeholder="Hauteur" [(ngModel)]="p.sizeYCm" />
            <div class="input-group-append">
              <span class="input-group-text">cm</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <label>Poids total du colis&nbsp;:</label>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <div class="input-group">
            <input type="number" class="form-control" min="0" required [(ngModel)]="p.weightInGr" />
            <div class="input-group-append">
              <span class="input-group-text">grammes</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-8">
          <label>Impressions contenues&nbsp;:</label>
        </div>
        <div class="col-4"><label>Exemplaires&nbsp;:</label></div>
      </div>

      <div class="row mt-2" *ngFor="let firstOrder = first; let spho; of: p.orders">
        <div class="col-8">
          <select class="form-control" [(ngModel)]="spho.idOrder">
            <option [ngValue]="0" disabled>-- Impression --</option>
            <option [ngValue]="order.idOrder" *ngFor="let order of getValidatedShipmentOrdersNotAlreadyIn(p, spho)">
              N° {{ order.idOrder }}

              <ng-container *ngIf="getRemainingQuantity(order) >= 0">
                ({{ getRemainingQuantity(order) }} pièce{{ getRemainingQuantity(order) > 1 ? 's' : '' }} restante{{
                  getRemainingQuantity(order) > 1 ? 's' : ''
                }})
              </ng-container>
              <ng-container *ngIf="getRemainingQuantity(order) < 0">
                ({{ abs(getRemainingQuantity(order)) }} pièce{{ abs(getRemainingQuantity(order)) > 1 ? 's' : '' }} en trop)
              </ng-container>
            </option>
          </select>
        </div>
        <div class="col-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">x</span>
            </div>
            <input type="number" min="0" class="form-control" [(ngModel)]="spho.quantity" />
          </div>
        </div>
        <div class="col-1 align-self-center" *ngIf="!firstOrder">
          <i class="far fa-trash-alt text-danger pointer" (click)="deletePrint(p, spho)"></i>
        </div>
      </div>

      <div class="row mt-2" *ngIf="canAddOtherOrders(p)">
        <div class="col">
          <a [routerLink]="null" (click)="addPrint(p)">+ ajouter une impression</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-3" *ngIf="canAddPackage()">
  <div class="col">
    <button class="btn btn-outline-primary w-100" style="border-width: 2px !important" (click)="addPackage()">
      <i class="fas fa-plus mr-2"></i>Ajouter un carton
    </button>
  </div>
</div>

<div class="row mt-5" *ngIf="error">
  <div class="col">
    <div class="alert alert-danger px-3 px-md-5 py-3">
      <ul>
        <li [innerHTML]="error"></li>
      </ul>
    </div>
  </div>
</div>

<div class="row justify-content-center" [class.mt-5]="!error" [class.mt-3]="error">
  <div class="col-6">
    <button class="btn btn-secondary w-100" [disabled]="loading" *ngIf="authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING" (click)="cancel()">
      Retour
    </button>
  </div>
  <div class="col-6">
    <button
      class="btn btn-success w-100 text-left"
      [disabled]="loading"
      *ngIf="authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING"
      (click)="callTransport()"
    >
      Appeler le transporteur <i class="fas fa-fast-forward text-white mr-2 float-right"></i>
    </button>
  </div>
</div>
