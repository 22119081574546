import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GenericFilter, ProducerClientService, ProducerClientDTO, FilterOrder, Filter, OrderBy, ProducerClientPaymentStatus } from 'degineo-common';
import { L } from 'ic2-lib';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImportClientPopupComponent } from './import-client-popup/import-client-popup.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-my-clients',
  templateUrl: './my-clients.component.html',
  styleUrls: ['./my-clients.component.scss'],
})
export class MyClientsComponent implements OnInit {
  clients: ProducerClientDTO[] = [];
  nbPerPage: number = 20;
  page: number = 1;
  count: number = 0;
  loading: boolean = false;
  addMode: boolean = false;
  form: FormGroup = this.fb.group({
    companyName: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
  });
  searchText: string = null;
  searchChanged: Subject<string> = new Subject<string>();
  builtFiltersOrder: FilterOrder[] = [];
  OrderBy: typeof OrderBy = OrderBy;
  ProducerClientPaymentStatus: typeof ProducerClientPaymentStatus = ProducerClientPaymentStatus;
  selectedClient: ProducerClientDTO = null;
  constructor(private producerClientService: ProducerClientService, private modalService: NgbModal, private fb: FormBuilder) {
    this.searchChanged.pipe(debounceTime(300), distinctUntilChanged()).subscribe((model) => {
      this.reload();
    });
  }

  ngOnInit() {
    this.reload();
  }

  reload() {
    const genericFilter = new GenericFilter();
    genericFilter.filters = [];
    if (this.searchText !== null && this.searchText.trim().length > 0) {
      const f = new Filter();
      f.name = 'search';
      f.stringValues = [this.searchText];
      genericFilter.filters.push(f);
    }
    genericFilter.orderFilters = this.builtFiltersOrder;
    genericFilter.page = this.page;
    genericFilter.nbPerPage = this.nbPerPage;
    console.log('genericFilter', genericFilter);
    this.loading = true;
    this.producerClientService.getClients(genericFilter).subscribe(
      (data) => {
        this.clients = data.data;
        this.count = data.count;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  add() {
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }
    this.loading = true;
    this.producerClientService.inviteNewClient(this.form.value.companyName, this.form.value.email).subscribe(
      (data) => {
        this.loading = false;
        this.reload();
        this.form.reset();
        this.addMode = false;
      },
      (err) => {
        this.loading = false;
        this.form.get('email').setErrors({ alreadyExists: true });
        L.e(err);
      }
    );
  }

  openInviteModal() {
    const modalRef = this.modalService.open(ImportClientPopupComponent, {
      centered: true,
      //@ts-ignore
      size: 'xl',
      beforeDismiss: () => {
        return false;
      },
    });
    modalRef.result.then((data) => {
      if (data) this.reload();
    });
  }

  searchChange() {
    this.searchChanged.next(this.searchText);
  }

  rowClick(row) {
    this.selectedClient = row;
    console.log('rowclick', row);
  }
}
