<h3 class="text-center mb-3 mt-2">Vision des 30 derniers jours</h3>
<ngx-charts-bar-horizontal
  [view]="view"
  [scheme]="colorScheme"
  [results]="single"
  [xAxis]="true"
  [yAxis]="true"
  [legend]="false"
  [showXAxisLabel]="false"
  [showYAxisLabel]="false"
  (select)="onSelect($event)"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)"
>
</ngx-charts-bar-horizontal>
