import { Component, OnInit, EventEmitter, Input, Output, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountCreationService } from '../account-creation.service';
import { ProducerSignupService, UserEditValidators } from 'degineo-common';
import { L } from 'ic2-lib';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-account-creation-step3-siret',
  templateUrl: './account-creation-step3-siret.component.html',
  styleUrls: ['./account-creation-step3-siret.component.scss'],
})
export class AccountCreationStep3SiretComponent implements OnInit {
  error: string = null;
  loading: boolean = true;
  saving: boolean = false;
  form: FormGroup = this.fb.group({
    siret: [null, [Validators.required, UserEditValidators.siretValide]],
  });

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(ProducerSignupService) private producerSignupService: ProducerSignupService,
    @Inject(AccountCreationService) public accountCreationService: AccountCreationService
  ) {}

  ngOnInit() {
    this.producerSignupService.getInfosStep3(this.accountCreationService.token).subscribe(
      (data) => {
        this.loading = false;
        this.form.patchValue({ siret: data });
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  submit() {
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }
    this.saving = true;
    this.producerSignupService.saveStep3(this.accountCreationService.token, this.form.value.siret, this.accountCreationService.idPlan).subscribe(
      (data) => {
        this.accountCreationService.next();
        this.saving = false;
      },
      (err) => {
        this.saving = false;
        if (err.isBusinessError()) this.error = err.message;
        else L.e(err);
      }
    );
  }
}
