import { Component, OnInit, Host, Inject } from '@angular/core';
import { WaitingForShippingPanelComponent } from '../waiting-for-shipping-panel.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FabricationOrderPopupComponent } from '../../fabrication-order-popup/fabrication-order-popup.component';
import { ShipmentOrderPanelDTO, WaitingCarrierShipmentService, AuthService, RoleInCompany } from 'degineo-common';
import { PanelHeaderComponent } from '../../panel-header/panel-header.component';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-shipment-producer',
  templateUrl: './shipment-producer.component.html',
  styleUrls: ['./shipment-producer.component.scss'],
})
export class ShipmentProducerComponent implements OnInit {
  loading: boolean = false;

  isOutdated = PanelHeaderComponent.isOutdatedStatic;
  RoleInCompany: typeof RoleInCompany = RoleInCompany;

  constructor(
    @Host() public parent: WaitingForShippingPanelComponent,
    @Inject(WaitingCarrierShipmentService) public waitingCarrierShipmentService: WaitingCarrierShipmentService,
    @Inject(NgbModal) private modalService: NgbModal,
    @Inject(AuthService) public authService: AuthService
  ) {}

  ngOnInit() {}

  openPopup(order: ShipmentOrderPanelDTO) {
    //console.log(this.parent.dto.shipmentOrders, order);
    const modalRef = this.modalService.open(FabricationOrderPopupComponent, { size: 'lg', windowClass: 'yellow' });
    modalRef.componentInstance.dto = order;
    modalRef.componentInstance.canCancel = false;
  }

  accept() {
    if (!confirm('Confirmez-vous la livraison ?')) return;
    this.loading = true;
    this.waitingCarrierShipmentService.transportOk(this.parent.dto.idShipment).subscribe(
      (data) => {
        this.loading = false;
        this.parent.myOrdersComponent.reload();
        this.parent.myOrdersComponent.close();
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  cancel() {
    this.loading = true;
    this.waitingCarrierShipmentService.cancel(this.parent.dto.idShipment).subscribe(
      (data) => {
        this.loading = false;
        this.parent.myOrdersComponent.reload();
        this.parent.myOrdersComponent.close();
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }
}
