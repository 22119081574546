<div class="container my-4">
  <div class="row" *ngIf="loading">
    <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
  </div>

  <ng-container *ngIf="!loading">
    <div class="row">
      <div class="col text-center">
        <h3>Demande de devis n°{{ devis.numDevisProducteur }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">Client: {{ dto.clientFirstName + ' ' + dto.clientLastName }}</div>
    </div>
    <div class="row">
      <div class="col text-center">Tél: {{ dto.clientPhoneNumber === null ? 'Non renseigné' : dto.clientPhoneNumber }}</div>
    </div>

    <ng-container *ngIf="step == STEP_INFOS">
      <div class="row mt-3">
        <div class="col"><label>Type de pièce :</label> {{ dto.articleTypeName }}</div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label>Fichier 3D :</label>
          <a class="ml-2 underline" href="{{ rpcHost }}modele3D/{{ dto.token3DModel }}/{{ dto.filename }}">{{ dto.filename }}</a>
          <span class="ml-3 text-primary pointer" (click)="showPreview = !showPreview"
            ><i class="fas fs-4" [class.fa-eye]="!showPreview" [class.fa-eye-slash]="showPreview"></i
          ></span>
          <div style="width: 400px; height: 400px" *ngIf="showPreview">
            <degineo-preview-modele modelUrl="{{ rpcHost }}modele3D/{{ dto.token3DModel }}/{{ dto.filename }}"></degineo-preview-modele>
          </div>
        </div>
      </div>
      <!--
      <div class="row">
        <div class="col"><label>Fichier MTL:</label> {{ devis }}</div>
      </div>
      -->
      <div class="row mt-3">
        <div class="col">
          <label>Taille de l'impression :</label> {{ devis.sizeInMmX | number: '.2':'fr-FR' }} x {{ devis.sizeInMmY | number: '.2':'fr-FR' }} x
          {{ devis.sizeInMmZ | number: '.2':'fr-FR' }} mm
        </div>
      </div>
      <div class="row mt-3">
        <div class="col"><label>Matière :</label> {{ dto.materialTypeName }}</div>
      </div>
      <div class="row mt-3">
        <div class="col"><label>Couleur :</label> {{ dto.colorName }}</div>
      </div>
      <div class="row mt-3">
        <div class="col"><label>Finition :</label> {{ dto.finishName }}</div>
      </div>
      <div class="row mt-3">
        <div class="col"><label>Nombre d'exemplaires :</label> {{ devis.quantity }}</div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="alert-medium rounded p-3">
            <span class="fw-500">Commentaire du client :</span><br />
            <div>{{ devis.clientComment }}</div>
          </div>
        </div>
      </div>
      <div class="row mt-3" *ngIf="devis.clientModificationComment">
        <div class="col">
          <div class="alert-primary rounded p-3">
            <span class="fw-500">Modification demandées :</span>
            <div>{{ devis.clientModificationComment }}</div>
          </div>
        </div>
      </div>
      <div class="row mt-3" *ngIf="devis.clientRefusalComment !== null">
        <div class="col">
          <div class="alert-danger rounded p-3">
            <span class="fw-500">Motif du refus donné par le client :</span>
            <div>{{ devis.clientRefusalComment }}</div>
          </div>
        </div>
      </div>
      <div class="row mt-3" *ngIf="devis.producerRefusalComment !== null">
        <div class="col">
          <div class="alert-danger rounded p-3">
            <span class="fw-500">Motif du refus producteur :</span>
            <div>{{ devis.producerRefusalComment }}</div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <button class="btn btn-secondary" routerLink="/devis">Retour</button>
        </div>
        <ng-container *ngIf="devis.status === DevisStatus.DEMANDE_ENVOYEE">
          <div class="col-4 text-center">
            <button class="btn btn-danger" routerLink="/devis/{{ devis.id }}/refusal">Refuser la demande</button>
          </div>
          <div class="col-4 text-right">
            <button class="btn btn-success" (click)="goStepDevis()">Faire le devis</button>
          </div>
        </ng-container>
        <ng-container *ngIf="devis.status === DevisStatus.DEVIS_EN_COURS_REVISION">
          <div class="col-4 text-center">
            <button class="btn btn-danger" routerLink="/devis/{{ devis.id }}/refusal">Refuser la révision</button>
          </div>
          <div class="col-4 text-right">
            <button class="btn btn-success" (click)="goStepDevis()">Réviser le devis</button>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="step == STEP_DEVIS">
      <form [formGroup]="devisForm" #f="ngForm" [class.show-errors]="f.submitted && !devisForm.valid" (ngSubmit)="previewDevis()">
        <div class="row mt-3">
          <div class="col"><label>Type de pièce :</label> {{ dto.articleTypeName }}</div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label>Fichier 3D :</label>
            <a class="ml-2 underline" href="{{ rpcHost }}modele3D/{{ dto.token3DModel }}/{{ dto.filename }}">{{ dto.filename }}</a>
            <span class="ml-3 text-primary pointer" (click)="showPreview = !showPreview"
              ><i class="fas fs-4" [class.fa-eye]="!showPreview" [class.fa-eye-slash]="showPreview"></i
            ></span>
            <div style="width: 400px; height: 400px" *ngIf="showPreview">
              <degineo-preview-modele modelUrl="{{ rpcHost }}modele3D/{{ dto.token3DModel }}/{{ dto.filename }}"></degineo-preview-modele>
            </div>
          </div>
        </div>
        <!--
          <div class="row">
            <div class="col"><label>Fichier MTL:</label> {{ devis }}</div>
          </div>
          -->
        <div class="row mt-3">
          <div class="col">
            <label>Taille de l'impression :</label> {{ devis.sizeInMmX | number: '.2':'fr-FR' }} x {{ devis.sizeInMmY | number: '.2':'fr-FR' }} x
            {{ devis.sizeInMmZ | number: '.2':'fr-FR' }} mm
          </div>
        </div>
        <div class="row mt-3">
          <div class="col"><label>Matière :</label> {{ dto.materialTypeName }}</div>
        </div>
        <div class="row mt-3">
          <div class="col"><label>Couleur :</label> {{ dto.colorName }}</div>
        </div>
        <div class="row mt-3">
          <div class="col"><label>Nombre d'exemplaires :</label> {{ devis.quantity }}</div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <div class="alert-medium rounded p-3">
              <span class="fw-500">Commentaire du client :</span><br />
              <div>{{ devis.clientComment }}</div>
            </div>
          </div>
        </div>
        <div class="row mt-3" *ngIf="devis.clientModificationComment">
          <div class="col">
            <div class="alert-primary rounded p-3">
              <span class="fw-500">Modification demandées :</span>
              <div>{{ devis.clientModificationComment }}</div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col"><label>Date souhaitée client :</label> {{ devis.wishedDeliveryDate | date: 'dd/MM/yyyy' }}</div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label>Livrer avant le :</label>
            <div class="input-group w-100 w-md-200px">
              <input
                type="text"
                class="form-control"
                angular-mydatepicker
                formControlName="deliveryDate"
                [options]="INgxMyDpOptionsSansWeekend"
                #dp="angular-mydatepicker"
              />
              <div class="input-group-append">
                <button type="button" class="btn btn-outline-secondary" (click)="dp.toggleCalendar()">
                  <i class="far fa-calendar-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label>Devis valide jusqu'au :</label>
            <div class="input-group w-100 w-md-200px">
              <input required class="form-control" angular-mydatepicker formControlName="validUntil" [options]="INgxMyDpOptions" #dp2="angular-mydatepicker" />
              <div class="input-group-append">
                <button type="button" class="btn btn-outline-secondary" (click)="dp2.toggleCalendar()">
                  <i class="far fa-calendar-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label>Quelle imprimante allez-vous utiliser pour réaliser cette impression :</label>
            <select class="form-control form-inline" formControlName="printer" (change)="loadConsumable(); loadFinish()">
              <option [ngValue]="null">-- Imprimantes --</option>
              <option [value]="printer.producerPrinter.id" *ngFor="let printer of printers">{{ printer.printer.brand }} - {{ printer.printer.name }}</option>
            </select>
            <div class="text-muted ml-2 mt-1">Imprimante conseillée : {{ dto.advisedPrinterName }}</div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label>Quel consommable allez-vous utiliser pour réaliser cette impression :</label>
            <select class="form-control form-inline" formControlName="consumable" (change)="loadFinish()">
              <option [ngValue]="null">-- Consommables --</option>
              <option [value]="consumable.producerPrinterConsumableOption.id" *ngFor="let consumable of consumables">{{ consumable.consumable.name }}</option>
            </select>
            <div class="text-muted ml-2 mt-1">Consommable conseillé : {{ dto.advisedConsumableName }}</div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label>Quelle finition allez-vous appliquer sur cette impression 3D :</label>
            <select class="form-control form-inline" formControlName="finish">
              <option [ngValue]="null">-- Finitions --</option>
              <option [value]="finish.id" *ngFor="let finish of finishs">{{ finish.name }}</option>
            </select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label>Indiquez votre temps de production :</label>
            <div class="input-group">
              <input type="number" class="form-control" formControlName="printTimeInMin" />
              <div class="input-group-append">
                <span class="input-group-text">minutes</span>
              </div>
            </div>
            <br />
            <span class="text-muted">Temps de production estimé: {{ devis.advisedPrintTimeInMin | number: '.2':'fr-FR' }} minutes</span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label>Indiquez votre quantité de matière :</label>
            <div class="input-group">
              <input type="number" class="form-control" formControlName="materialQuantityInCm3" />
              <div class="input-group-append">
                <span class="input-group-text">cm{{ '\u00B3' }}</span>
              </div>
            </div>
            <br />
            <span class="text-muted">Quantité de matière estimée: {{ devis.advisedMaterialQuantityInCm3 | number: '.2':'fr-FR' }} cm{{ '\u00B3' }}</span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label>Prix de vente unitaire HT :</label>

            <div class="input-group">
              <input type="number" class="form-control" formControlName="unitPriceHT" />
              <div class="input-group-append">
                <span class="input-group-text">€</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <div class="alert alert-primary">
              Prix de la prestation totale HT : {{ devisForm.value.unitPriceHT * devis.quantity | number: '.2':'fr-FR' }}&nbsp;€
            </div>
          </div>
        </div>
        <div class="row mt-4" *ngIf="f.submitted && devisForm.invalid">
          <div class="col">
            <div class="alert alert-danger">
              <ul>
                <li *ngIf="devisForm.hasError('required', 'deliveryDate')">Une date de livraison est requise.</li>
                <li *ngIf="devisForm.hasError('invalidDateFormat', 'deliveryDate')">La date de livraison n'est pas valide.</li>
                <li *ngIf="devisForm.hasError('required', 'validUntil')">Une date limite de validité est requise.</li>
                <li *ngIf="devisForm.hasError('invalidDateFormat', 'validUntil')">La date limite de validité n'est pas valide.</li>
                <li *ngIf="devisForm.hasError('required', 'printer')">Une imprimante est requise.</li>
                <li *ngIf="devisForm.hasError('required', 'consumable')">Un consomable est requis.</li>
                <li *ngIf="devisForm.hasError('required', 'printTimeInMin') || devisForm.hasError('min', 'printTimeInMin')">
                  Une durée d'impression est requise.
                </li>
                <li *ngIf="devisForm.hasError('required', 'materialQuantityInCm3') || devisForm.hasError('min', 'materialQuantityInCm3')">
                  Une quantité de matière est requise.
                </li>
                <li *ngIf="devisForm.hasError('required', 'unitPriceHT') || devisForm.hasError('min', 'unitPriceHT')">
                  Un prix de vente unitaire HT est requis.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6 text-right">
            <button class="btn btn-secondary btn-allonge" (click)="step = STEP_INFOS">Retour</button>
          </div>
          <div class="col-6">
            <button type="submit" class="btn btn-success btn-allonge">Sauvegarder et prévisualiser</button>
          </div>
        </div>
      </form>
    </ng-container>

    <ng-container *ngIf="sending">
      <div class="row mt-5">
        <div class="col text-center text-success">
          <h3><i class="fas fa-check mr-3"></i>Envoyé</h3>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
