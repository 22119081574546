import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SubscribeService {
  //Step 1 is a standalone page
  public static readonly STEP_2_USER = 2;
  public static readonly STEP_3_COMPANY = 3;
  public static readonly STEP_4_SHIPPING = 4;
  public static readonly STEP_5_CONTRACT = 5;
  public static readonly STEP_6_BANKACCOUNT = 6;
  public static readonly STEP_7_SIGN = 7;
  public static readonly STEP_8_BRAVO = 8;

  public step: number = SubscribeService.STEP_2_USER;
  public idPlan: number = null;

  constructor(public router: Router) {}

  checkPlanSelected() {
    if (this.idPlan === null) {
      this.router.navigate(['/abonnement/mes-commandes']);
    }
  }
}
