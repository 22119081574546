<div class="container-fluid">
  <div class="row" style="min-height: 100vh !important">
    <div class="col-2 bg-primary d-md-block d-none" style="min-width: 20rem; max-width: 21rem">
      <div class="container-fluid mt-5">
        <div class="row">
          <div class="col text-center"><img src="assets/header/logo_degineo_blanc_vert.svg" style="height: 3rem" /></div>
        </div>
        <div class="ligne">
          <div
            class="row mt-5 bullet-text"
            [class.active]="
              accountCreationService.step === null ||
              accountCreationService.step === ProducerSignupStep.STEP_1_PWD ||
              accountCreationService.step === ProducerSignupStep.STEP_2_USER
            "
          >
            <div class="col">Bienvenue</div>
          </div>
          <div
            class="row mt-4 bullet-text"
            [class.active]="
              accountCreationService.step === ProducerSignupStep.STEP_3_SIRET ||
              accountCreationService.step === ProducerSignupStep.STEP_4_COMPANY ||
              accountCreationService.step === ProducerSignupStep.STEP_5_SHIPPING
            "
          >
            <div class="col">Votre entreprise</div>
          </div>
          <div class="row mt-4 bullet-text" [class.active]="accountCreationService.step === ProducerSignupStep.STEP_6_PRINTERS">
            <div class="col">Vos imprimantes&nbsp;3D</div>
          </div>
          <div
            class="row mt-4 bullet-text"
            [class.active]="
              accountCreationService.step === ProducerSignupStep.STEP_8_CONTRACTS || accountCreationService.step === ProducerSignupStep.STEP_7_PLAN
            "
          >
            <div class="col">Contrat</div>
          </div>
          <div class="row mt-4 bullet-text" [class.active]="accountCreationService.step === ProducerSignupStep.STEP_9_BANKACCOUNTS">
            <div class="col">Coordonnées Bancaires</div>
          </div>
          <div class="row mt-4 bullet-text" [class.active]="accountCreationService.step === ProducerSignupStep.STEP_10_SIGN">
            <div class="col">Signature</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <!--align-items-center d-flex pour centrer completement-->
      <div
        class="mt-5 mb-5"
        [ngClass]="{
          'container-small': accountCreationService.step !== ProducerSignupStep.STEP_7_PLAN,
          'container-fluid': accountCreationService.step === ProducerSignupStep.STEP_7_PLAN
        }"
      >
        <div class="row" *ngIf="accountCreationService.step === null">
          <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
        </div>
        <app-account-creation-step1-account *ngIf="accountCreationService.step === ProducerSignupStep.STEP_1_PWD"></app-account-creation-step1-account>
        <app-account-creation-step2-user *ngIf="accountCreationService.step === ProducerSignupStep.STEP_2_USER"></app-account-creation-step2-user>
        <app-account-creation-step3-siret *ngIf="accountCreationService.step === ProducerSignupStep.STEP_3_SIRET"></app-account-creation-step3-siret>
        <app-account-creation-step4-company *ngIf="accountCreationService.step === ProducerSignupStep.STEP_4_COMPANY"></app-account-creation-step4-company>
        <app-account-creation-step5-shipping *ngIf="accountCreationService.step === ProducerSignupStep.STEP_5_SHIPPING"></app-account-creation-step5-shipping>
        <app-account-creation-step6-printers *ngIf="accountCreationService.step === ProducerSignupStep.STEP_6_PRINTERS"></app-account-creation-step6-printers>
        <app-account-creation-step7-plan *ngIf="accountCreationService.step === ProducerSignupStep.STEP_7_PLAN"></app-account-creation-step7-plan>
        <app-account-creation-step8-contract *ngIf="accountCreationService.step === ProducerSignupStep.STEP_8_CONTRACTS"></app-account-creation-step8-contract>
        <app-account-creation-step9-bankaccount
          *ngIf="accountCreationService.step === ProducerSignupStep.STEP_9_BANKACCOUNTS"
        ></app-account-creation-step9-bankaccount>
        <app-account-creation-step10-sign *ngIf="accountCreationService.step === ProducerSignupStep.STEP_10_SIGN"></app-account-creation-step10-sign>
        <app-account-creation-step11-bravo *ngIf="accountCreationService.step === ProducerSignupStep.STEP_11_BRAVO"></app-account-creation-step11-bravo>
      </div>
    </div>
  </div>
</div>
