import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProducerSignupStep8DTO, ProducerSubscriptionTunnelService } from 'degineo-common';
import { L } from 'ic2-lib';
import { DegineoGmapsAddressInputComponent } from '../../account-creation/degineo-gmaps-address-input/degineo-gmaps-address-input.component';
import { SubscribeService } from '../subscribe.service';

@Component({
  selector: 'app-subscribe-step5-contract',
  templateUrl: './subscribe-step5-contract.component.html',
  styleUrls: ['./subscribe-step5-contract.component.scss'],
})
export class SubscribeStep5ContractComponent implements OnInit {
  form: FormGroup = this.fb.group({
    engagment: [false, [Validators.requiredTrue]],
  });
  loading: boolean = true;
  saving: boolean = false;
  dto: ProducerSignupStep8DTO;
  formatAddress = DegineoGmapsAddressInputComponent.formatLineAddress;

  constructor(
    private fb: FormBuilder,
    public subscribeService: SubscribeService,
    private modalService: NgbModal,
    private producerSubscriptionTunnelService: ProducerSubscriptionTunnelService
  ) {}

  ngOnInit() {
    this.producerSubscriptionTunnelService.getInfosStep5().subscribe(
      (data) => {
        this.dto = data;
        this.loading = false;
      },
      (err) => {
        L.e(err);
        this.loading = false;
      }
    );
  }

  previous() {
    this.subscribeService.step = SubscribeService.STEP_4_SHIPPING;
  }

  submit() {
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }
    this.saving = true;
    this.producerSubscriptionTunnelService.saveStep5().subscribe(
      (data) => {
        this.subscribeService.step = SubscribeService.STEP_6_BANKACCOUNT;
        this.saving = false;
      },
      (err) => {
        this.saving = false;
        L.e(err);
      }
    );
  }

  showPopup(evt, content, canClose) {
    evt.preventDefault();
    this.modalService.open(content, { centered: true, backdrop: canClose ? true : 'static', keyboard: false }).result.then(
      (result) => {
        //`Closed with: ${result}`;
      },
      (reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
}
