<div class="container-small py-5">
  <div class="row" *ngIf="dto === null">
    <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
  </div>
  <ng-container *ngIf="dto !== null">
    <div class="row">
      <div class="col px-0">
        <h3 style="font-size: 1.1rem" class="text-center">Activez les systèmes de prise de commande que vous souhaitez utiliser&nbsp;:</h3>
      </div>
    </div>

    <div class="row mt-5 shadow-2 rounded">
      <div class="col">
        <div class="container-fluid py-4">
          <div class="row">
            <div class="col-8">
              <h3>
                <ul>
                  <li>Commandes en ligne</li>
                </ul>
              </h3>
            </div>
            <div class="col-4 text-right">
              <h3 class="text-success d-inline-block mr-3" *ngIf="dto.marqueBlanchePrint">Activées</h3>
              <h3 class="text-danger d-inline-block mr-3" *ngIf="!dto.marqueBlanchePrint">Désactivées</h3>
              <i class="fas fa-spinner spinning" *ngIf="loadingPrint"></i>
              <ui-switch
                style="vertical-align: sub"
                size="small"
                color="#00c462"
                defaultBgColor="#d82929"
                *ngIf="!loadingPrint && hasRight"
                [checked]="dto.marqueBlanchePrint"
                (change)="updatePrint($event)"
              >
              </ui-switch>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col"><label>Lien de votre système de commande en ligne</label><i class="fas fa-spinner spinning ml-2" *ngIf="dto === null"></i></div>
          </div>

          <div class="row">
            <div class="col d-flex">
              <input type="text" [value]="printUrl" readonly class="form-control flex-grow-1 mr-3" />
              <button class="btn w-200px" [class.btn-primary]="!copiePrint" [class.btn-success]="copiePrint" (click)="copy('impression-en-ligne')">
                {{ copiePrint ? 'Copié' : 'Copier le lien' }}
              </button>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col">
              Vos clients commandent directement en ligne en passant par un système de prise de commande automatisé. Vous n'avez rien à faire, le prix de la
              prestation est directement calculé pour votre client en fonction des <a routerLink="/mes-imprimantes">paramétrages de vos machines</a>&nbsp;: prix
              production, prix matière, ainsi que vos marges.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4 shadow-2 rounded">
      <div class="col">
        <div class="container-fluid py-4">
          <div class="row">
            <div class="col-8">
              <h3>
                <ul>
                  <li>Demandes de devis</li>
                </ul>
              </h3>
            </div>
            <div class="col-4 text-right">
              <h3 class="text-success d-inline-block mr-3" *ngIf="dto.marqueBlancheDevis">Activées</h3>
              <h3 class="text-danger d-inline-block mr-3" *ngIf="!dto.marqueBlancheDevis">Désactivées</h3>
              <i class="fas fa-spinner spinning" *ngIf="loadingDevis"></i>
              <ui-switch
                style="vertical-align: sub"
                size="small"
                color="#00c462"
                defaultBgColor="#d82929"
                *ngIf="!loadingDevis && hasRight"
                [checked]="dto.marqueBlancheDevis"
                (change)="updateDevis($event)"
              >
              </ui-switch>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col"><label>Lien de votre système de demande de devis</label><i class="fas fa-spinner spinning ml-2" *ngIf="dto === null"></i></div>
          </div>

          <div class="row">
            <div class="col d-flex">
              <input type="text" [value]="devisUrl" readonly class="form-control flex-grow-1 mr-3" />
              <button class="btn w-200px" [class.btn-primary]="!copieDevis" [class.btn-success]="copieDevis" (click)="copy('demande-devis')">
                {{ copieDevis ? 'Copié' : 'Copier le lien' }}
              </button>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col">
              Vos clients passent par un formulaire afin de vous communiquer leurs demandes. Vous recevrez alors cette demande dans votre onglet
              <a routerLink="/devis">Gestion des devis</a>. Puis vous définissez vous-même le prix de la prestation avant d'envoyer le devis à faire signer par
              votre client directement dans son espace Degineo.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5 alert alert-info">
      <div class="col mt-2">
        <h2>Infos</h2>
        <p class="mt-3">
          Les liens de vos systèmes de commande en ligne ou de demande de devis sont à mettre sur votre site web, idéalement derrière des boutons comme : «
          Passer commande en ligne » ou « Demander un devis ».
        </p>
        <p>Si possible, il est préférable de n'utiliser qu'un seul système de commande afin de ne pas perturber vos clients.</p>
        <p>
          Si vous souhaitez stopper momentanément un des systèmes de commande, vous pouvez le désactiver. Tant qu'il sera désactivé, les clients qui essayeront
          de passer commande par ce système seront redirigés vers une page leur indiquant que le système de prise de commande ou de devis est actuellement
          indisponible.
        </p>
        <p>
          Si vous souhaitez ne plus du tout utiliser un des systèmes, il est vraiment préférable de le désactiver <span class="fw-700">ET</span> de le supprimer
          de votre site web (par exemple : supprimer le bouton qui utilise le lien concerné).
        </p>
      </div>
    </div>
  </ng-container>
</div>
