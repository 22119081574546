import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LegalStatus, Plan, ProducerPlanService } from 'degineo-common';
import { L } from 'ic2-lib';
import { SubscribeService } from '../subscribe.service';

@Component({
  selector: 'app-subscribe-step1-plan',
  templateUrl: './subscribe-step1-plan.component.html',
  styleUrls: ['./subscribe-step1-plan.component.scss'],
})
export class SubscribeStep1PlanComponent implements OnInit {
  page: TemplateRef<any> = null;
  loading: boolean = false;
  legalStatus: LegalStatus = null;
  nbPrinter: number = 0;
  @ViewChild('mescommandes') mescommandes: TemplateRef<any>;
  @ViewChild('devis') devis: TemplateRef<any>;
  @ViewChild('monportefeuilleclient') monportefeuilleclient: TemplateRef<any>;
  @ViewChild('administratif') administratif: TemplateRef<any>;
  @ViewChild('utilisateurs') utilisateurs: TemplateRef<any>;
  @ViewChild('prisedecommande') prisedecommande: TemplateRef<any>;
  @ViewChild('dashboard') dashboard: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private producerPlanService: ProducerPlanService,
    private subscribeService: SubscribeService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.producerPlanService.getLegalStatus().subscribe(
      (data) => {
        this.legalStatus = data;
        this.producerPlanService.getNbPrinter().subscribe(
          (data2) => {
            this.nbPrinter = data2;
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            L.e(err);
          }
        );
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  ngAfterViewInit(): void {
    this.route.params.subscribe((params) => {
      this.page = this[params['page'].replace(/-/g, '')];
      console.log(params['page'], this.page);
    });
  }

  selected(plan: Plan) {
    console.log(plan);
    this.subscribeService.idPlan = plan.id;
    this.router.navigate(['/abonnement']);
  }
}
