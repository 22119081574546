import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AddressNEW } from 'degineo-common';
@Component({
  selector: 'app-degineo-gmaps-address-input',
  templateUrl: './degineo-gmaps-address-input.component.html',
  styleUrls: ['./degineo-gmaps-address-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DegineoGmapsAddressInputComponent), multi: true }],
})
export class DegineoGmapsAddressInputComponent implements OnInit, ControlValueAccessor {
  options = {
    componentRestrictions: { country: ['fr', 'be', 'ch'] },
    types: ['address'],
  };
  @Input()
  placeholder: string = '';

  val: AddressNEW;
  text: string = null;
  @Input()
  disabled: boolean = false;
  @Input()
  readOnly: boolean = false;
  @Input() class: string = ''; // override the standard class attr with a new one.

  get classes(): string {
    return [
      this.class, // include our new one
      'form-control',
    ].join(' ');
  }

  constructor() {}

  static createAddressFromGooglePlace(place): AddressNEW {
    const address = new AddressNEW();
    //address.societeOrPerson = shipping ? this.shippingPersonOrCompany : this.billingPersonOrCompany;
    //address.livraison = shipping;
    //address.facturation = this.facturationSame ? true : !shipping;
    address.latitude = place.geometry.location.lat();
    address.longitude = place.geometry.location.lng();
    for (const component of place.address_components) {
      //console.log(component);
      if (component.types.includes('street_number')) address.line1 = component.long_name;
      if (component.types.includes('route')) address.line1 += ' ' + component.long_name;
      if (component.types.includes('locality')) address.city = component.long_name;
      if (component.types.includes('country')) address.country = component.long_name;
      if (component.types.includes('postal_code')) address.zipCode = component.long_name;
    }

    if (!DegineoGmapsAddressInputComponent.isFilled(address.line1)) return null;
    return address;
  }

  static isFilled(txt: string) {
    return txt && txt.trim().length !== 0;
  }

  static formatMultiLineAddress(address: AddressNEW) {
    let addr = '';
    //if (DegineoGmapsAddressInputComponent.isFilled(address.societeOrPerson)) addr += address.societeOrPerson + '<br />';
    if (DegineoGmapsAddressInputComponent.isFilled(address.line1)) addr += address.line1 + '<br />';
    if (DegineoGmapsAddressInputComponent.isFilled(address.line2)) addr += address.line2 + '<br />';
    if (DegineoGmapsAddressInputComponent.isFilled(address.line3)) addr += address.line3 + '<br />';
    if (DegineoGmapsAddressInputComponent.isFilled(address.zipCode)) addr += address.zipCode + ' ';
    if (DegineoGmapsAddressInputComponent.isFilled(address.city)) addr += address.city;
    if (DegineoGmapsAddressInputComponent.isFilled(address.country)) addr += ', ' + address.country;
    return addr;
  }

  static formatLineAddress(address: AddressNEW) {
    let addr = '';
    if (address === null || address === undefined) {
      return addr;
    }
    //if (DegineoGmapsAddressInputComponent.isFilled(address.societeOrPerson)) val += address.societeOrPerson + ', ';
    if (DegineoGmapsAddressInputComponent.isFilled(address.line1)) addr += address.line1 + ', ';
    if (DegineoGmapsAddressInputComponent.isFilled(address.line2)) addr += address.line2 + ', ';
    if (DegineoGmapsAddressInputComponent.isFilled(address.line3)) addr += address.line3 + ', ';
    if (DegineoGmapsAddressInputComponent.isFilled(address.zipCode)) addr += address.zipCode + ' ';
    if (DegineoGmapsAddressInputComponent.isFilled(address.city)) addr += address.city + ', ';
    if (DegineoGmapsAddressInputComponent.isFilled(address.country)) addr += address.country;
    return addr;
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  ngOnInit() {}

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  set value(val) {
    //console.log('set value : ', val);
    // this value is updated by programmatic changes
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }
  // this method sets the value programmatically
  writeValue(value: AddressNEW) {
    this.value = value;
    this.writeFormattedAddress(value);
  }
  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  handleShippingAddressChange(event) {
    //console.log(event);
    this.value = DegineoGmapsAddressInputComponent.createAddressFromGooglePlace(event);
    this.writeFormattedAddress(this.val);
  }

  innerOnChange(event) {
    //console.log('innerOnChange', event);
    this.value = null;
  }

  onBlur(event) {
    if (this.val === null) this.text = '';
  }

  writeFormattedAddress(address: AddressNEW) {
    this.text = DegineoGmapsAddressInputComponent.formatLineAddress(address);
  }
}
