<form autocomplete="off" #f="ngForm" [formGroup]="form" [class.show-errors]="f.submitted && !form.valid" (submit)="submit()">
  <div class="row">
    <div class="col text-center">
      <h2 style="font-size: 1.6rem">Livraison Degineo</h2>
    </div>
  </div>
  <div class="row" *ngIf="loading">
    <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
  </div>

  <ng-container *ngIf="!loading">
    <div class="row mt-5">
      <div class="col">Indiquez l'adresse où les transporteurs vont venir récupérer les colis à livrer.</div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <app-degineo-gmaps-address-input [readOnly]="readOnly" formControlName="shippingAddress"></app-degineo-gmaps-address-input>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label class="fw-400">
          <input type="checkbox" class="mr-2" formControlName="sameAsBilling" (change)="sameChanged($event)" />
          Utiliser la même adresse que l'adresse de l'entreprise</label
        >
      </div>
    </div>
    <div class="row mt-4" *ngIf="f.submitted && !form.valid">
      <div class="col">
        <div class="alert alert-danger pr-3 pl-3 pr-md-5 pl-md-5 pt-3 pb-3">
          <ul>
            <li *ngIf="form.hasError('required', 'shippingAddress')">Une addresse est requise.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-6">
        <button (click)="previous()" [disabled]="saving" class="btn btn-secondary w-100"><i class="fas fa-arrow-left"></i></button>
      </div>
      <div class="col-6">
        <button type="submit" [disabled]="saving" class="btn btn-success w-100">
          <i class="fas fa-check"></i><i class="fas fa-spinner spinning ml-3" *ngIf="saving"></i>
        </button>
      </div></div
  ></ng-container>
</form>
