<div class="container-fluid px-4" *ngIf="client !== null">
  <div class="row">
    <div class="col text-center fs-4 fw-700 text-warning">Invitation en attente</div>
  </div>

  <div class="row mt-2">
    <div class="col text-center fw-500">Envoyée le {{ client.dateInvitation | date: 'dd/MM/yyyy' }}</div>
  </div>

  <div class="row mt-4">
    <div class="col">
      <label>Entreprise</label>
    </div>
    <div class="col text-right">
      <a [routerLink]="null" *ngIf="!editCompany" (click)="editCompany = true">modifier</a>

      <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editCompany && !loading" (click)="saveEditCompany()"></i>
      <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editCompany && !loading" (click)="cancelEdit(); editCompany = false"></i>
      <i class="fas fa-spinner spinning" *ngIf="editCompany && loading"></i>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <input type="text" class="form-control dashed-when-disabled" [(ngModel)]="companyName" [disabled]="!editCompany" />
    </div>
  </div>

  <div class="row mt-4">
    <div class="col">
      <label>E-mail</label>
    </div>
    <div class="col text-right">
      <a [routerLink]="null" *ngIf="!editEmail" (click)="editEmail = true">modifier</a>

      <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2" *ngIf="editEmail && !loading" (click)="saveEditEmail()"></i>
      <i class="fas fa-times-circle fs-4 text-danger pointer no-select" *ngIf="editEmail && !loading" (click)="cancelEdit(); editEmail = false"></i>
      <i class="fas fa-spinner spinning" *ngIf="editEmail && loading"></i>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <input type="text" class="form-control dashed-when-disabled" [(ngModel)]="email" [disabled]="!editEmail" />
    </div>
  </div>
  <div class="row mt-2" *ngIf="error">
    <div class="col">
      <div class="alert alert-danger">
        <ul>
          <li>Une erreur est survenue : {{ error }}</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <hr />
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      Si votre client n'arrive pas à recevoir l'e-mail d'invitation ou qu'il l'a perdu, demandez-lui de créer un compte Degineo avec l'adresse&nbsp;:<br />
      <span class="fw-700">{{ client.email }}</span>
      <br />
      Il pourra alors accepter l'invitation depuis son espace Degineo.
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <hr />
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      Si vous vous êtes trompé d'e-mail pour l'envoi de l'invitation, vous pouvez le modifier ci-dessus et
      <span class="fw-700">une nouvelle invitation sera automatiquement</span> renvoyée à cette nouvelle adresse e-mail.
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <hr />
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <a class="text-danger underline" [routerLink]="null" (click)="del()"
        >Annuler cette invitation <i class="fas fa-spinner spinning" *ngIf="loadingDelete"></i
      ></a>
    </div>
  </div>
</div>
