import { Component, OnInit, Inject, PLATFORM_ID, HostListener } from '@angular/core';
import { AuthService, ClientService, RoleInCompany } from 'degineo-common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AvatarChangeService } from 'projects/client/src/app/Front/front-layout/avatar-change.service';
import { isPlatformBrowser } from '@angular/common';
import { L } from 'ic2-lib';
import { ContentClickService } from './content-click.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MyProfileComponent } from '../Pages/my-profile/my-profile.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-producer-layout',
  templateUrl: './producer-layout.component.html',
  styleUrls: ['./producer-layout.component.scss'],
})
export class ProducerLayoutComponent implements OnInit {
  mobile = false;
  show = true;
  errorFile: string = null;
  public file: File = null;
  progressPercent = null;
  oldImgURL: any = null;
  loading: boolean = false;
  RoleInCompany: typeof RoleInCompany = RoleInCompany;
  changeEmailForm: FormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
  });
  leftMenuLinks: any = [
    {
      name: 'Tableau de bord',
      routerLink: '/',
      activeLink: ['/abonnement/dashboard'],
      img: { path: 'assets/menu', ext: '.svg', name: 'tableau-de-bord', hover: false },
    },
    {
      name: 'Mes commandes',
      routerLink: '/mes-commandes',
      activeLink: ['/abonnement/mes-commandes'],
      img: { path: 'assets/menu', ext: '.svg', name: 'mes-commandes', hover: false },
    },
    {
      name: 'Gestion des devis',
      routerLink: '/devis',
      activeLink: ['/abonnement/devis'],
      img: { path: 'assets/menu', ext: '.svg', name: 'gestion-des-devis', hover: false },
      panier: true,
      minRole: RoleInCompany.PRODUCER_ADMIN,
    },
    {
      name: 'Prise de commande',
      img: { path: 'assets/menu', ext: '.svg', name: 'prise-de-commande', hover: false },
      routerLink: '/prise-de-commande',
      activeLink: ['/abonnement/prise-de-commande'],
    },
    {
      name: 'Mes imprimantes',
      routerLink: '/mes-imprimantes',
      img: { path: 'assets/menu', ext: '.svg', name: 'mes-imprimantes', hover: false },
    },
    {
      name: 'Mon portefeuille client',
      routerLink: '/mon-portefeuille-client',
      activeLink: ['/abonnement/mon-portefeuille-client'],
      img: { path: 'assets/menu', ext: '.svg', name: 'mon-portefeuille-client', hover: false },
      minRole: RoleInCompany.PRODUCER_ACCOUNTING,
    },
    /* caché pour le moment
    {
      name: 'Mes conditionnements',
      routerLink: '/mes-conditionnements',
      img: { path: 'assets/menu', ext: '.svg', name: 'mes-conditionnements', hover: false },
    },*/
    {
      name: 'Infos entreprise',
      img: { path: 'assets/menu', ext: '.svg', name: 'infos-entreprise', hover: false },
      routerLink: '/infos-entreprise',
      minRole: RoleInCompany.PRODUCER_ACCOUNTING,
    },
    {
      name: 'Ma page Degineo',
      routerLink: '/ma-page-degineo',
      img: { path: 'assets/menu', ext: '.svg', name: 'ma-page-degineo', hover: false },
      minRole: RoleInCompany.PRODUCER_ACCOUNTING,
    },
    {
      name: 'Utilisateurs',
      routerLink: '/utilisateurs',
      activeLink: ['/abonnement/utilisateurs'],
      img: { path: 'assets/menu', ext: '.svg', name: 'utilisateurs', hover: false },
      okRoles: [RoleInCompany.PRODUCER_HOLDER, RoleInCompany.PRODUCER_ADMIN, RoleInCompany.PRODUCER_SUPERVISER],
    },
    { name: 'Se déconnecter', logout: true, img: { path: 'assets/menu', ext: '.svg', name: 'se-deconnecter', hover: false } },
  ];

  administratifSubscribed = {
    name: 'Administratif',
    img: { path: 'assets/menu', ext: '.svg', name: 'administratif', hover: false },
    minRole: RoleInCompany.PRODUCER_ACCOUNTING,
    open: false,
    active: false,
    children: [
      {
        name: 'Mes factures de vente',
        routerLink: '/mes-factures-de-vente',
      },
      {
        name: 'Partage des factures',
        routerLink: '/partage-des-factures',
      },
      {
        name: 'Paiements en attente',
        routerLink: '/paiements-en-attente',
      },
      {
        name: 'Mon abonnement Degineo',
        routerLink: '/mon-abonnement-degineo',
      },
    ],
  };
  administratifNotSubscribed = {
    name: 'Administratif',
    routerLink: '/abonnement/administratif',
    img: { path: 'assets/menu', ext: '.svg', name: 'administratif', hover: false },
    minRole: RoleInCompany.PRODUCER_ACCOUNTING,
  };

  public innerWidth: any;

  constructor(
    @Inject(PLATFORM_ID) private platform: Object,
    public authService: AuthService,
    public router: Router,
    @Inject(AvatarChangeService) public avatarChangeService: AvatarChangeService,
    @Inject(ClientService) private clientService: ClientService,
    @Inject(ContentClickService) private contentClickService: ContentClickService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    public fb: FormBuilder
  ) {
    avatarChangeService.reload();
    //console.log(activatedRoute);
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //console.log(event, activatedRoute);
        this.activeMenus(event.url);
      }
    });
  }

  getLinks() {
    return this.leftMenuLinks.filter((link) => this.canSee(link));
  }

  canSee(link) {
    if (!link.minRole && !link.okRoles) return true;
    if (link.okRoles) return link.okRoles.find((role) => role.id === this.authService.userBundle.role.id) !== undefined;
    //console.log(this.authService.userBundle.role.id, '<=', link.minRole.id, this.authService.userBundle.role.id <= link.minRole.id);
    if (link.minRole) return this.authService.userBundle.role.id <= link.minRole.id;
  }

  activeMenus(url: string) {
    for (const menu of this.leftMenuLinks) {
      if (menu.children) {
        menu.active = false;
        menu.open = false;
      }
    }
    for (const menu of this.leftMenuLinks) {
      if (menu.children) {
        for (const child of menu.children) {
          if (child.routerLink === url) {
            menu.active = true;
            menu.open = true;
          }
        }
      }
    }
  }

  getLink(link, active, hoverEffect) {
    if (active || this.isActive(link)) return link.img.path + '/svg-white/' + link.img.name + '_white' + link.img.ext;
    if (link.img.hover || hoverEffect) return link.img.path + '/svg-primary/' + link.img.name + '_primary' + link.img.ext;
    return link.img.path + '/svg-dark/' + link.img.name + '_dark' + link.img.ext;
  }
  getLinkMobile(link, active) {
    if (active || this.isActive(link)) return link.img.path + '/svg-dark/' + link.img.name + '_dark' + link.img.ext;
    return link.img.path + '/svg-white/' + link.img.name + '_white' + link.img.ext;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (window.innerWidth >= 992) {
      this.mobile = false;
      this.show = true;
    } else {
      this.mobile = true;
      this.show = false;
    }
  }

  ngOnInit() {
    //placer administratif avant ma-page-degineo
    this.leftMenuLinks.splice(
      this.leftMenuLinks.findIndex((r) => r.routerLink === '/ma-page-degineo'),
      0,
      this.authService.userBundle.subscribed ? this.administratifSubscribed : this.administratifNotSubscribed
    );
    console.log(this.leftMenuLinks);
    if (isPlatformBrowser(this.platform)) {
      this.innerWidth = window.innerWidth;
      if (window.innerWidth >= 992) {
        this.mobile = false;
        this.show = true;
      } else {
        this.mobile = true;
        this.show = false;
      }
    }
  }

  clicked() {
    //console.log('clicked', event);
    if (this.mobile) this.show = false;
    this.contentClickService.clickEvent.emit();
    return true;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/connexion']);
  }

  handleFileInput(event) {
    const files: FileList = event.target.files;
    const fr = new FileReader();

    fr.onload = (e) => {
      // file is loaded
      const img = new Image();
      img.onload = () => {
        console.log(img.width + ' x ' + img.height);
        const dimensionsMin = { w: 150, h: 150 };
        if (img.width < dimensionsMin.w || img.height < dimensionsMin.h) {
          //dimensions minis
          this.errorFile = 'Votre fichier doit au minimum faire ' + dimensionsMin.w + 'x' + dimensionsMin.h;
          this.avatarChangeService.avatar = this.oldImgURL;
        } else {
          this.file = files[0];
          console.log(this.file, this.file.size / 1024 / 1024 + 'MB', this.file.name.split('.').pop());
          this.uploadLogo();
        }
      };
      this.oldImgURL = this.avatarChangeService.avatar;
      this.avatarChangeService.avatar = (e.target as any).result;
      img.src = (e.target as any).result; // is the data URL because called with readAsDataURL
    };
    fr.readAsDataURL(files[0]);
  }

  uploadLogo() {
    this.errorFile = null;
    this.clientService
      .changeLogo()
      .withFile(this.file)
      .subscribeWithProgress(
        (data) => {
          this.progressPercent = null;
          this.file = null;
        },
        (loaded, total) => {
          this.progressPercent = ((loaded / total) * 100).toFixed(0);
        },
        (err) => {
          L.e(err);
          this.progressPercent = null;
          this.errorFile = err.message;
        }
      );
  }

  openProfilePopup() {
    this.modalService.open(MyProfileComponent, { centered: true });
  }

  showPopup(evt, content) {
    evt.preventDefault();
    this.modalService
      .open(content, {
        centered: true,
        backdrop: 'static',
        keyboard: false,
        beforeDismiss: () => {
          return !this.loading;
        },
      })
      .result.then(
        (result) => {
          //`Closed with: ${result}`;
        },
        (reason) => {
          //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  resendEmail(evt, content) {
    this.clientService.resendValidationEmail().subscribe(
      (data) => {
        this.showPopup(evt, content);
      },
      (err) => {
        L.e(err);
      }
    );
  }

  changeEmail(modal: NgbActiveModal) {
    if (!this.changeEmailForm.valid) {
      console.log(this.changeEmailForm);
      return;
    }
    this.loading = true;
    this.changeEmailForm.disable();
    this.clientService.changeEmail(this.changeEmailForm.value.email).subscribe(
      (data) => {
        this.loading = false;
        this.changeEmailForm.enable();
        this.authService.userBundle.user.email = this.changeEmailForm.value.email;
        this.authService.userBundle.user.login = this.changeEmailForm.value.email;
        modal.close();
      },
      (err) => {
        this.changeEmailForm.enable();
        this.loading = false;
        if (err.isBusinessError()) {
          if (err.message.includes('No change')) this.changeEmailForm.get('email').setErrors({ noChange: true });
          else if (err.message.includes('email already exists')) this.changeEmailForm.get('email').setErrors({ alreadyExists: true });
        } else L.e(err);
      }
    );
  }

  isActive(link) {
    if (!link.activeLink) return false;
    return (<Array<any>>link.activeLink).some((url) => {
      //console.log(url, this.activatedRoute, this.router);
      return this.router.url === url;
    });
  }
}
