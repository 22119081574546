<div class="d-flex flex-column" style="height: 100vh !important">
  <div class="container-fluid bg-light-30p bg-logo flex-grow-1 pb-5">
    <div class="row mt-5">
      <div class="col-12 text-center">
        <a [href]="svProducerUrl">
          <img src="assets/header/logo_degineo_bleu_vert.svg" width="310px" />
        </a>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 text-center fw-300 fs-5 text-dark">Espace Imprimeur 3D</div>
    </div>

    <div class="row mt-5">
      <div class="col-12">
        <div class="container-small bg-white rounded shadow-2 py-4">
          <div class="row">
            <div class="col text-center">
              <h3>J'ai déjà un compte Degineo</h3>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-center text-uppercase">
              <a class="fw-500" style="letter-spacing: 0.025em" routerLink="/connexion">Se connecter</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <div class="container-small bg-white rounded shadow-2">
          <!--<div style="width: 23rem"></div>-->

          <form
            autocomplete="off"
            #f="ngForm"
            [formGroup]="takeControlService.step1form"
            [class.show-errors]="f.submitted && !takeControlService.step1form.valid"
            (submit)="submit()"
          >
            <div class="row" style="padding-top: 2rem !important">
              <div class="col text-center">
                <h3>Nouveau sur Degineo ?</h3>
              </div>
            </div>
            <div class="container-fluid goodwith p-0 mt-4">
              <div class="row">
                <div class="col">
                  <div class="form-group m-0">
                    <i class="fas fa-envelope input-icon"></i>
                    <input
                      type="email"
                      class="form-control input-with-icon"
                      id="email"
                      aria-describedby="emailHelp"
                      autocomplete="username"
                      placeholder="E-mail"
                      formControlName="email"
                    />
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top: 2rem">
                <div class="col">
                  <div class="form-group m-0">
                    <i class="fas fa-lock input-icon"></i>
                    <input
                      type="password"
                      class="form-control input-with-icon"
                      id="password"
                      autocomplete="new-password"
                      placeholder="Mot de passe"
                      formControlName="password"
                    />
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top: 2rem">
                <div class="col">
                  <div class="form-group m-0">
                    <i class="fas fa-lock input-icon"></i>
                    <input
                      type="password"
                      class="form-control input-with-icon"
                      id="password2"
                      autocomplete="new-password"
                      placeholder="Confirmez votre mot de passe"
                      formControlName="password2"
                    />
                  </div>
                </div>
              </div>
              <div class="row mt-4" *ngIf="f.submitted && !takeControlService.step1form.valid">
                <div class="col">
                  <div class="alert alert-danger pr-3 pl-3 pr-md-5 pl-md-5 pt-3 pb-3">
                    <ul>
                      <li *ngIf="takeControlService.step1form.hasError('required', 'email')">Tous les champs sont requis.</li>
                      <li *ngIf="takeControlService.step1form.hasError('email', 'email')">L'e-mail est incorrect.</li>
                      <li *ngIf="takeControlService.step1form.hasError('alreadyExists', 'email')">Cet e-mail est déjà utilisé.</li>
                      <li *ngIf="takeControlService.step1form.hasError('required', 'password') || takeControlService.step1form.hasError('pattern', 'password')">
                        Le mot de passe doit comporter au moins 6 caractères dont 1 caractère spécial.
                      </li>
                      <li *ngIf="takeControlService.step1form.hasError('notSame')">
                        La confirmation du mot de passe et le mot de passe doivent être identiques.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col text-center">
                <button type="submit" class="btn btn-success text-uppercase text-nowrap w-100">Créer un compte</button>
              </div>
            </div>
            <div class="row my-2">
              <div class="col text-center fs-1">
                En créant un compte, vous acceptez nos <a [href]="svMentionsLegalesUrl" target="_blank">conditions générales de vente</a
                ><br class="d-none d-xl-inline" />
                et notre <a [href]="svConfidentialitesUrl" target="_blank">politique de confidentialité</a>.
              </div>
            </div>

            <div class="row my-2 justify-content-center">
              <div class="col-9">
                <hr />
              </div>
            </div>
            <div class="row pb-4">
              <div class="col text-center fs-2">
                Si vous souhaitez trouver un imprimeur 3D<br />
                <a class="fw-500" href="{{ clientUrl }}inscription">Inscrivez-vous ici</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
