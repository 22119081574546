import { Component, OnInit, SimpleChanges, Host, Inject, OnChanges, Input } from '@angular/core';
import { MyOrdersComponent } from '../my-orders.component';
import { WaitingCarrierShipmentService, WaitingCarrierShipmentPanelDTO, ShipmentStatus, AuthService } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-waiting-for-shipping-panel',
  templateUrl: './waiting-for-shipping-panel.component.html',
  styleUrls: ['./waiting-for-shipping-panel.component.scss'],
})
export class WaitingForShippingPanelComponent implements OnInit, OnChanges {
  @Input()
  id: number;
  dto: WaitingCarrierShipmentPanelDTO = null;
  ShipmentStatus: typeof ShipmentStatus = ShipmentStatus;

  constructor(
    @Inject(WaitingCarrierShipmentService) private waitingCarrierShipmentService: WaitingCarrierShipmentService,
    @Host() public myOrdersComponent: MyOrdersComponent
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.id) {
      this.dto = null;
      this.load();
    }
  }

  load() {
    this.waitingCarrierShipmentService.getShipment(this.id).subscribe(
      (data) => {
        this.dto = data;
        console.log(data);
      },
      (err) => {
        L.e(err);
      }
    );
  }
}
