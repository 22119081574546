<div class="container-fluid" style="padding-left: 40px; padding-right: 40px;">
  <div class="row my-4 mb-5">
    <div class="col text-center">
      <h2 class="fw-300">Gérer mes consommables</h2>
    </div>
  </div>
  <div class="row" *ngIf="myPrintersComponent.consumables.length === 0">
    <div class="col">
      Aucun consommable validé disponible
    </div>
  </div>
  <div class="row" [class.mt-3]="!first" *ngFor="let first = first; let consumable; of: myPrintersComponent.consumables">
    <div class="col-9 p-0">
      {{ consumable.brand }} - {{ consumable.name }} - {{ consumable.color }}<br />
      <span
        class="fs-2 fw-500 pl-4"
        [class.text-success]="consumable.machineActive > 0"
        [class.text-danger]="consumable.machineActive === 0"
        *ngIf="consumable.checked"
        >Activé sur {{ consumable.machineActive }}/{{ consumable.machineTotal }} imprimante{{ consumable.machineTotal > 1 ? 's' : '' }} 3D</span
      >
      <span class="fs-2 fw-500 text-warning pl-4" *ngIf="!consumable.checked">
        En attente de validation <ng-container *ngIf="consumable.machineAttente > 1">sur {{ consumable.machineAttente }} imprimantes 3D</ng-container></span
      >
    </div>
    <div class="col-3 p-0 text-right" *ngIf="consumable.checked">
      <i *ngIf="hasRight" class="far fa-trash-alt hover-danger pointer mr-4" ngbTooltip="Retirer ce consommable" (click)="delete(consumable)"></i>
      <ui-switch
        style="vertical-align: sub;"
        size="small"
        color="#00c462"
        defaultBgColor="#d82929"
        [checked]="consumable.active"
        (change)="onChange(consumable, $event)"
        ngbTooltip="{{ consumable.active ? 'Rendre ce consommable indisponible' : 'Rendre ce consommable disponible' }}"
      >
      </ui-switch>
    </div>
  </div>
</div>
