import { Component, OnInit } from '@angular/core';
import { AuthService, MarqueBlancheManagmentDTO, ProducerMarqueBlancheManagmentService, RoleInCompany, SlugService } from 'degineo-common';
import { environment } from 'environments/environment';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-marque-blanche-managment',
  templateUrl: './marque-blanche-managment.component.html',
  styleUrls: ['./marque-blanche-managment.component.scss'],
})
export class MarqueBlancheManagmentComponent implements OnInit {
  printUrl: string;
  devisUrl: string;
  dto: MarqueBlancheManagmentDTO = null;
  loadingDevis: boolean = false;
  loadingPrint: boolean = false;
  hasRight: boolean = false;
  copieDevis: boolean = false;
  copiePrint: boolean = false;

  constructor(
    private slugService: SlugService,
    public authService: AuthService,
    private producerMarqueBlancheManagmentService: ProducerMarqueBlancheManagmentService
  ) {
    this.printUrl =
      environment.clientUrl +
      this.slugService.string_to_slug(this.authService.userBundle.producerName) +
      '/' +
      this.authService.userBundle.idProducer +
      '/impression-en-ligne';
    this.devisUrl =
      environment.clientUrl +
      this.slugService.string_to_slug(this.authService.userBundle.producerName) +
      '/' +
      this.authService.userBundle.idProducer +
      '/demande-devis';
    this.hasRight = this.authService.userBundle.role.id <= RoleInCompany.PRODUCER_ADMIN.id;
  }

  ngOnInit(): void {
    this.producerMarqueBlancheManagmentService.getInfos().subscribe(
      (data) => {
        this.dto = data;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  updatePrint(val) {
    this.loadingPrint = true;
    this.producerMarqueBlancheManagmentService.updateMarqueBlanchePrint(val).subscribe(
      (data) => {
        this.loadingPrint = false;
        this.dto.marqueBlanchePrint = val;
      },
      (err) => {
        this.loadingPrint = false;
        L.e(err);
      }
    );
  }

  updateDevis(val) {
    this.loadingDevis = true;
    this.producerMarqueBlancheManagmentService.updateMarqueBlancheDevis(val).subscribe(
      (data) => {
        this.loadingDevis = false;
        this.dto.marqueBlancheDevis = val;
      },
      (err) => {
        this.loadingDevis = false;
        L.e(err);
      }
    );
  }

  copy(val) {
    let selBox = document.createElement('textarea');

    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value =
      environment.clientUrl +
      this.slugService.string_to_slug(this.authService.userBundle.producerName) +
      '/' +
      this.authService.userBundle.idProducer +
      '/' +
      val;

    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();

    document.execCommand('copy');
    document.body.removeChild(selBox);

    if (val === 'demande-devis') {
      this.copieDevis = true;
      setTimeout(() => {
        this.copieDevis = false;
      }, 2000);
    } else {
      this.copiePrint = true;
      setTimeout(() => {
        this.copiePrint = false;
      }, 2000);
    }
  }
}
