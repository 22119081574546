import { Component, OnInit, Host, Inject } from '@angular/core';
import { MyPrintersItemComponent } from '../my-printers-item/my-printers-item.component';
import { ProducerPrinterService, ProducerPrinterClientTypeMargin, BusinessTargetLevel1 } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-my-printers-producer-margin',
  templateUrl: './my-printers-producer-margin.component.html',
  styleUrls: ['./my-printers-producer-margin.component.scss'],
})
export class MyPrintersProducerMarginComponent implements OnInit {
  add: boolean = false;
  edit: { [key: number]: boolean } = {};
  noMoreMarges: boolean = false;
  ctChoice: BusinessTargetLevel1[] = [];
  selectedCt: BusinessTargetLevel1 = null;
  marge: number = null;

  constructor(
    @Host() public myPrintersItemComponent: MyPrintersItemComponent,
    @Inject(ProducerPrinterService) private producerPrinterService: ProducerPrinterService
  ) {}

  ngOnInit() {}

  addMode() {
    this.marge = null;
    this.ctChoice = BusinessTargetLevel1.values.filter(
      (ct) => this.myPrintersItemComponent.printerDto.ppctms.find((ppctm) => ppctm.clientType.id === ct.id) === undefined
    );
    if (this.ctChoice.length === 0) this.noMoreMarges = true;
    else this.add = true;
  }

  addCT() {
    if (this.marge === null || this.selectedCt === null) return;
    this.producerPrinterService.addMargin(this.myPrintersItemComponent.printerDto.idProducerPrinter, this.selectedCt, this.marge).subscribe(
      (data) => {
        this.add = false;
        this.myPrintersItemComponent.printerDto.ppctms.push(data);
      },
      (err) => {
        L.e(err);
      }
    );
  }

  saveEdit(ppctm: ProducerPrinterClientTypeMargin, value: string) {
    const val = parseFloat(value);
    this.producerPrinterService.editMargin(ppctm.id, val).subscribe(
      (data) => {
        ppctm.producerMargin = val;
        this.edit[ppctm.id] = false;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  deleteMarge(ppctm: ProducerPrinterClientTypeMargin) {
    if (!confirm('Voulez vous vraiment supprimer la marge sectorielle : ' + ppctm.clientType?.lib + ' ?')) return;
    this.producerPrinterService.deleteMargin(ppctm.id).subscribe(
      (data) => {
        this.myPrintersItemComponent.printerDto.ppctms.splice(this.myPrintersItemComponent.printerDto.ppctms.indexOf(ppctm), 1);
      },
      (err) => {
        L.e(err);
      }
    );
  }
}
