<div class="row mt-4">
  <div class="col">
    <div class="text-center alert alert-success">
      <div class="container-fluid">
        <div class="row">
          <div class="col text-center">
            Le ou les colis sont à livrer par vos soins à l'adresse suivante :
          </div>
        </div>
        <div class="row mt-2">
          <div class="col text-center fw-500">
            {{ parent.dto.shippingAddress.line1 }}
            <ng-container *ngIf="parent.dto.shippingAddress.line2"> <br />{{ parent.dto.shippingAddress.line2 }} </ng-container>
            <ng-container *ngIf="parent.dto.shippingAddress.line3"> <br />{{ parent.dto.shippingAddress.line3 }} </ng-container>
            <br />
            {{ parent.dto.shippingAddress.zipCode }} {{ parent.dto.shippingAddress.city }} {{ parent.dto.shippingAddress.country }}<br />
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-center" [class.text-danger]="isOutdated(parent.dto.minWishedDeliveryDate)">
          Avant le : <span class="fw-500">{{ parent.dto.minWishedDeliveryDate | date: 'dd/MM/yyyy' }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-4">
  <div class="col">
    <div class="text-center alert bg-light-30p border border-secondary">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <label>Impressions et nombre d'exemplaires à livrer&nbsp;:</label>
          </div>
        </div>

        <div class="row mt-2" *ngFor="let firstOrder = first; let spho; of: parent.dto.shipmentOrders">
          <div class="col ml-4">
            N° <u class="pointer" (click)="openPopup(spho)">{{ spho.idOrder }}</u> x {{ spho.quantity }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-5">
  <div class="col">
    <button class="btn btn-secondary w-100" [disabled]="loading" *ngIf="authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING" (click)="cancel()">
      <i class="fas fa-fast-backward text-white mr-2"></i>Revoir le conditionnement
    </button>
  </div>
  <div class="col">
    <button class="btn btn-success w-100" [disabled]="loading" *ngIf="authService.userBundle.role !== RoleInCompany.PRODUCER_ACCOUNTING" (click)="accept()">
      Livraison effectuée <i class="far fa-check-circle text-white ml-2"></i>
    </button>
  </div>
</div>
